import React from 'react';

const TOSPopOut = ({ closeFunction }: any) => {
  return (
    <div className='absolute inset-0 bg-black/80 backdrop-blur-sm z-10 flex justify-center items-center'>
      <div className='w-full sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[30%] bg-[#241B23] rounded-md p-6'>
        <div className='overflow-y-scroll'>
          <h3 className='font-bold mb-2'>Terms of Service for My Hi Performance</h3>
          <div className='h-[500px]'>
            <h4 className='mt-2'>Effective Date: 06/07/2023</h4>
            <p className='mt-2'>
              Please read these Terms of Service ("Terms") carefully before using the website
              located at www.myhiperformance.com (the "Site"). These Terms govern your access to and
              use of the Site, including any content, features, products, or services provided by My
              Hi Performance ("we," "us," or "our").
            </p>
            <h4 className='font-bold mt-4'>1.0 Acceptance of Terms</h4>
            <p>
              By accessing or using the Site, you agree to be bound by these Terms and any
              additional terms and conditions referenced herein. If you do not agree to these Terms,
              please do not use the Site.
            </p>
            <h4 className='font-bold mt-4'>2.0 Use of the Site</h4>
            <h5 className='mt-2'>2.1 Eligibility:</h5>
            <p>
              You must be at least 18 years old to use the Site. By using the Site, you represent
              and warrant that you are 18 years of age or older.
            </p>
            <h5 className='mt-2'>2.2 User Accounts:</h5>
            <p>
              Certain features or services on the Site may require you to create an account. When
              creating an account, you agree to provide accurate and complete information and to
              keep your account information updated. You are responsible for maintaining the
              confidentiality of your account credentials and for all activities that occur under
              your account. You agree to notify us immediately of any unauthorized use of your
              account.
            </p>
            <h5 className='mt-2'>2.3 Prohibited Activities:</h5>
            <p>
              When using the Site, you agree not to engage in any of the following prohibited
              activities:
              <ul className='list-disc space-y-2 ml-6 mt-2'>
                <li>Violating any applicable laws, regulations, or third-party rights.</li>
                <li>
                  Impersonating any person or entity, or falsely stating or misrepresenting your
                  affiliation with any person or entity.
                </li>
                <li>
                  Interfering with or disrupting the operation of the Site or any networks connected
                  to the Site.
                </li>
                <li>
                  Uploading, posting, or transmitting any content that is unlawful, harmful,
                  defamatory, obscene, or otherwise objectionable.
                </li>
                <li>
                  Engaging in any activity that may negatively affect the performance or
                  functionality of the Site.
                </li>
              </ul>
            </p>
            <h4 className='font-bold mt-4'>3.0 Intellectual Property</h4>
            <h5 className='mt-2'>3.1 User Accounts:</h5>
            <p>
              All content and materials available on the Site, including text, graphics, logos,
              images, audio clips, videos, and software, are the property of My Hi Performance or
              its licensors and are protected by copyright, trademark, and other intellectual
              property laws.
            </p>
            <h5 className='mt-2'>3.2 Limited License:</h5>
            <p>
              Subject to your compliance with these Terms, we grant you a limited, non-exclusive,
              non-transferable, revocable license to access and use the Site for personal,
              non-commercial purposes.
            </p>
            <h5 className='mt-2'>3.3 User Content:</h5>
            <p>
              By submitting or posting any content on the Site, you grant us a worldwide,
              royalty-free, perpetual, irrevocable, non-exclusive right and license to use,
              reproduce, modify, adapt, publish, translate, distribute, and display such content for
              any purpose.
            </p>
            <h4 className='font-bold mt-4'>4.0 Privacy</h4>
            <p>
              Your privacy is important to us. Please review our Privacy Policy [include hyperlink
              to Privacy Policy] to understand how we collect, use, disclose, and protect your
              personal information.
            </p>
            <h4 className='font-bold mt-4'>5.0 Third-Party Websites and Advertisers</h4>
            <p>
              The Site may contain links to third-party websites or advertisements that are not
              owned or controlled by us. We are not responsible for the content, privacy policies,
              or practices of any third-party websites or advertisers. Your interactions with such
              third parties are solely between you and them, and we encourage you to read the terms
              and privacy policies of any third-party websites you visit.
            </p>
            <h4 className='font-bold mt-4'>6.0 Disclaimer of Warranties</h4>
            <p>
              THE SITE AND ALL CONTENT, FEATURES, PRODUCTS, AND SERVICES PROVIDED ON OR THROUGH THE
              SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF
              ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR
              IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
            </p>
            <h4 className='font-bold mt-4'>7.0 Limitation of Liability</h4>
            <p>
              IN NO EVENT SHALL WE (INCLUDING OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND
              AFFILIATES) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, CONSEQUENTIAL,
              INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR
              LOST PROFITS, LOSS OF DATA, OR LOSS OF USE, ARISING OUT OF OR RELATED TO YOUR USE OF
              THE SITE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <h4 className='font-bold mt-4'>8.0 Indemnification</h4>
            <p>
              You agree to indemnify and hold us (including our directors, officers, employees,
              agents, and affiliates) harmless from and against any claims, liabilities, damages,
              losses, costs, and expenses, including reasonable attorneys' fees, arising out of or
              related to your use of the Site, any violation of these Terms, or any activity related
              to your account.
            </p>
            <h4 className='font-bold mt-4'>9.0 Modifications to the Site and Terms</h4>
            <p>
              We reserve the right to modify, suspend, or discontinue the Site, in whole or in part,
              at any time and without prior notice. We also reserve the right to modify these Terms
              at any time. Any changes to the Terms will be effective immediately upon posting on
              the Site. Your continued use of the Site after the posting of any changes constitutes
              your acceptance of the modified Terms.
            </p>
            <h4 className='font-bold mt-4'>10.0 Governing Law and Jurisdiction</h4>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of
              Australia, without regard to its conflict of law principles. Any disputes arising out
              of or relating to these Terms or the use of the Site shall be subject to the exclusive
              jurisdiction of the courts located in Australia.
            </p>
            <h4 className='font-bold mt-4'>11.0 Copyright</h4>
            <p>
              All content, materials, and intellectual property created by My Hi Performance Pty Ltd
              on this website, including but not limited to text, images, graphics, logos, videos,
              and software are the exclusive property of My Hi Performance Pty Ltd and are protected
              by copyright laws and other intellectual property rights. No part of this website or
              its contents may be reproduced, distributed, transmitted, modified, adapted, or
              otherwise used, in whole or in part, without the express written permission of the
              directors of My Hi Performance Pty Ltd. Unauthorised use or reproduction of any
              content on this website is strictly prohibited and may result in legal action. To
              request permission for the use, reproduction, or distribution of any materials or
              content from this website, please contact the directors of My Hi Performance Pty Ltd
              in writing (see contact details below). My Hi Performance Pty Ltd reserves the right
              to enforce its intellectual property rights to the fullest extent of the law. Any
              unauthorised use of this website's materials or content may result in legal
              consequences and liabilities. By accessing and using this website, you agree to abide
              by these copyright terms and conditions.
            </p>
            <h4 className='font-bold mt-4'>12.0 Severability</h4>
            <p>
              If any provision of these Terms is found to be unlawful, void, or unenforceable, that
              provision shall be deemed severable from the remaining provisions and shall not affect
              their validity and enforceability.
            </p>
            <h4 className='font-bold mt-4'>13.0 Entire Agreement</h4>
            <p>
              These Terms constitute the entire agreement between you and My Hi Performance
              regarding your use of the Site and supersede all prior and contemporaneous agreements,
              understandings, representations, and warranties, whether written or oral.
            </p>
            <h4 className='font-bold mt-4'>Contact Us</h4>
            <p>If you have any questions or concerns about these Terms, please contact us at:</p>
            <h4 className='font-bold mt-4'>My Hi Performance</h4>
            <p>134A Charles Street, West Perth, WA 6005</p>
            <p>trevor@myhiperformance.com</p>
            <p>+61 429 916 282</p>
            <p className='mt-4'>
              By using the Site, you acknowledge that you have read and understood these Terms and
              agree to be bound by them.
            </p>
          </div>
        </div>
        <div className='w-full flex justify-center'>
          <div
            className='cursor-pointer transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 mt-6 w-fit text-sm font-semibold rounded-full'
            onClick={closeFunction}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default TOSPopOut;
