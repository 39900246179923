import { gql } from '@apollo/client';

export const UPLOAD_PHOTO = gql`
  mutation UploadPhotoMutation($uploadPhotoPhoto: String) {
    uploadPhoto(photo: $uploadPhotoPhoto)
  }
`;

export const SIGNED_UPLOAD_URL = gql`
  mutation SignedUrlMutation {
    uploadUrl {
      timestamp,
      signature,
      cloudName,
      apiKey,
      folder
    }
  }
`;
