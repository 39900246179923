import { useQuery } from '@apollo/client';
import { GET_ALL_TAGS } from '../../utils/queries/queries';
import { Link } from 'react-router-dom';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';

function FeaturedArticles({ shuffledFeaturedArticles }: any, { featuredArticlesLoading }: any) {
  const { loading: tagsLoading, error: tagsError, data: tagsData } = useQuery(GET_ALL_TAGS);

  // TODO: Fix below access to undefined elements, need to defensively code,
  // added small fix to prevent app from breaking when trying to access undefined[0]
  return (
    <div className='grid grid-cols-6 gap-4'>
      <h4 className='text-white font-semibold text-xl col-span-full flex justify-between align-baseline'>
        <span>Featured Articles</span>
        <Link
          to={'/featured-articles'}
          className='text-[10pt] text-red hover:underline font-regular'
        >
          View all
        </Link>
      </h4>
      {!shuffledFeaturedArticles?.length || featuredArticlesLoading || tagsLoading ? (
        <div className='col-span-6'>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className='col-span-6 h-[400px]'>
            <Link to={`/articles/${shuffledFeaturedArticles[0]._id}`}>
              <div
                className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:100%] h-[400px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:102%]'
                style={{
                  backgroundImage: `url(${shuffledFeaturedArticles[0]?.images[0]?.url})`,
                }}
              >
                <div className='rounded-full m-5 py-1 px-2 w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
                  {shuffledFeaturedArticles[0]?.articleTemplate}
                </div>

                <div className='h-auto w-full p-5 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
                  <h2 className='text-white text-4xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                    {shuffledFeaturedArticles[0].heading}
                  </h2>
                  <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                    {shuffledFeaturedArticles[0].subHeading}
                  </h3>

                  <span className='text-red font-black uppercase'>
                    By {shuffledFeaturedArticles[0].firstName}
                    {' | '}
                  </span>
                  <span className='text-red font-black'>
                    {new Date(shuffledFeaturedArticles[0].createdAt)?.toLocaleDateString()}
                  </span>
                </div>

                {/* <button className='m-5 bottom-0 right-0 transition ease-in-out duration-200 bg-red hover:bg-[#A60626] py-2 px-3 text-sm font-semibold rounded-full absolute'>
                    Read Article
                  </button> */}
              </div>
            </Link>
          </div>

          <div className='col-span-3 sm:col-span-2 h-[220px]'>
            <Link to={`/articles/${shuffledFeaturedArticles[1]?._id}`}>
              <div
                className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:165%] h-[220px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:170%]'
                style={{
                  backgroundImage: `url(${shuffledFeaturedArticles[1]?.images[0]?.url})`,
                }}
              >
                <div className='rounded-full m-4 py-[3px] px-[6px] w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
                  {shuffledFeaturedArticles[1]?.articleTemplate}
                </div>

                <div className='h-auto w-full p-4 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
                  <h2 className='text-white text-xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                    {shuffledFeaturedArticles[1]?.heading}
                  </h2>
                  <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                    {shuffledFeaturedArticles[1]?.subHeading}
                  </h3>
                  <span className='text-red font-black uppercase'>
                    By {shuffledFeaturedArticles[1]?.firstName}
                    {' | '}
                  </span>
                  <span className='text-red font-black'>
                    {new Date(shuffledFeaturedArticles[1]?.createdAt)?.toLocaleDateString()}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className='col-span-3 sm:col-span-2 h-[220px]'>
            <Link to={`/articles/${shuffledFeaturedArticles[2]?._id}`}>
              <div
                className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:165%] h-[220px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:170%]'
                style={{
                  backgroundImage: `url(${shuffledFeaturedArticles[2]?.images[0]?.url})`,
                }}
              >
                <div className='rounded-full m-4 py-[3px] px-[6px] w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
                  {shuffledFeaturedArticles[2]?.articleTemplate}
                </div>

                <div className='h-auto w-full p-4 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
                  <h2 className='text-white text-xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                    {shuffledFeaturedArticles[2]?.heading}
                  </h2>
                  <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                    {shuffledFeaturedArticles[2]?.subHeading}
                  </h3>
                  <span className='text-red font-black uppercase'>
                    By {shuffledFeaturedArticles[2]?.firstName}
                    {' | '}
                  </span>
                  <span className='text-red font-black'>
                    {new Date(shuffledFeaturedArticles[2]?.createdAt)?.toLocaleDateString()}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className='col-span-3 sm:col-span-2 h-[220px]'>
            <Link to={`/articles/${shuffledFeaturedArticles[3]?._id}`}>
              <div
                className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:165%] h-[220px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:170%]'
                style={{
                  backgroundImage: `url(${shuffledFeaturedArticles[3]?.images[0]?.url})`,
                }}
              >
                <div className='rounded-full m-4 py-[3px] px-[6px] w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
                  {shuffledFeaturedArticles[3]?.articleTemplate}
                </div>

                <div className='h-auto w-full p-4 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
                  <h2 className='text-white text-xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                    {shuffledFeaturedArticles[3]?.heading}
                  </h2>
                  <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                    {shuffledFeaturedArticles[3]?.subHeading}
                  </h3>
                  <span className='text-red font-black uppercase'>
                    By {shuffledFeaturedArticles[3]?.firstName}
                    {' | '}
                  </span>
                  <span className='text-red font-black'>
                    {new Date(shuffledFeaturedArticles[3]?.createdAt)?.toLocaleDateString()}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className='col-span-3 h-[220px] sm:hidden'>
            <Link to={`/articles/${shuffledFeaturedArticles[4]?._id}`}>
              <div
                className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:165%] h-[220px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:170%]'
                style={{
                  backgroundImage: `url(${shuffledFeaturedArticles[4]?.images[0]?.url})`,
                }}
              >
                <div className='rounded-full m-4 py-[3px] px-[6px] w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
                  {shuffledFeaturedArticles[4]?.articleTemplate}
                </div>

                <div className='h-auto w-full p-4 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
                  <h2 className='text-white text-xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                    {shuffledFeaturedArticles[4]?.heading}
                  </h2>
                  <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                    {shuffledFeaturedArticles[4]?.subHeading}
                  </h3>
                  <span className='text-red font-black uppercase'>
                    By {shuffledFeaturedArticles[4]?.firstName}
                    {' | '}
                  </span>
                  <span className='text-red font-black'>
                    {new Date(shuffledFeaturedArticles[4]?.createdAt)?.toLocaleDateString()}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default FeaturedArticles;
