import { useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { Link, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import TestImage from '@/assets/images/no_image_found.png';
import {
  GatedAnchor,
  GatedNavLink,
  REDIRECT_TO_LOGIN_HREF,
  useShowLogin,
} from '@/components/LoginModal';

const UserMenu = ({ userData = {} }: { userData?: any }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );

  const [activeMenuItem, setActiveMenuItem] = useState({
    activeLink: null,
    navMenuItems: [
      {
        id: 0,
        title: 'Home',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 27.369 21.283"><path d="M13.32,7.775l-8.76,7.215v7.787a.76.76,0,0,0,.76.76l5.324-.014a.76.76,0,0,0,.756-.76V18.215a.76.76,0,0,1,.76-.76H15.2a.76.76,0,0,1,.76.76v4.544a.76.76,0,0,0,.76.763l5.322.015a.76.76,0,0,0,.76-.76V14.984L14.047,7.775A.579.579,0,0,0,13.32,7.775Zm13.837,4.9L23.185,9.4V2.824a.57.57,0,0,0-.57-.57H19.955a.57.57,0,0,0-.57.57v3.45l-4.254-3.5a2.281,2.281,0,0,0-2.9,0L.206,12.679a.57.57,0,0,0-.076.8l1.212,1.473a.57.57,0,0,0,.8.077l11.176-9.2a.579.579,0,0,1,.727,0l11.176,9.2a.57.57,0,0,0,.8-.076l1.212-1.473a.57.57,0,0,0-.081-.8Z" transform="translate(0.001 -2.254)" /></svg>',
        link: '/',
        gated: false,
      },
      {
        id: 1,
        title: 'Featured Articles',
        link: '/featured-articles',
        icon: '<svg class=icon xmlns="http://www.w3.org/2000/svg" width="40.5" height="34" viewBox="0 0 40.5 27"><path d="M38.813,4.5H6.188A1.687,1.687,0,0,0,4.5,6.188V6.75H1.688A1.687,1.687,0,0,0,0,8.438V27.563A3.937,3.937,0,0,0,3.938,31.5H37.125A3.375,3.375,0,0,0,40.5,28.125V6.188A1.687,1.687,0,0,0,38.813,4.5ZM3.938,28.125a.563.563,0,0,1-.562-.562V10.125H4.5V27.563A.563.563,0,0,1,3.938,28.125ZM20.531,27H9.844A.844.844,0,0,1,9,26.156v-.562a.844.844,0,0,1,.844-.844H20.531a.844.844,0,0,1,.844.844v.563A.844.844,0,0,1,20.531,27Zm14.625,0H24.469a.844.844,0,0,1-.844-.844v-.562a.844.844,0,0,1,.844-.844H35.156a.844.844,0,0,1,.844.844v.563A.844.844,0,0,1,35.156,27ZM20.531,20.25H9.844A.844.844,0,0,1,9,19.406v-.562A.844.844,0,0,1,9.844,18H20.531a.844.844,0,0,1,.844.844v.563A.844.844,0,0,1,20.531,20.25Zm14.625,0H24.469a.844.844,0,0,1-.844-.844v-.562A.844.844,0,0,1,24.469,18H35.156a.844.844,0,0,1,.844.844v.563A.844.844,0,0,1,35.156,20.25Zm0-6.75H9.844A.844.844,0,0,1,9,12.656V9.844A.844.844,0,0,1,9.844,9H35.156A.844.844,0,0,1,36,9.844v2.813A.844.844,0,0,1,35.156,13.5Z" transform="translate(0,-3)"/></svg>',
        gated: false,
      },
      {
        id: 2,
        title: 'Videos',
        link: '/videos',
        icon: '<svg class=icon xmlns="http://www.w3.org/2000/svg" width="40.5" height="37" viewBox="0 0 40.5 27"><path d="M23.639,4.5H3.361A3.361,3.361,0,0,0,0,7.861V28.139A3.361,3.361,0,0,0,3.361,31.5H23.639A3.361,3.361,0,0,0,27,28.139V7.861A3.361,3.361,0,0,0,23.639,4.5ZM36.956,7.151,29.25,12.466V23.534l7.706,5.309A2.255,2.255,0,0,0,40.5,27.028V8.965A2.255,2.255,0,0,0,36.956,7.151Z" transform="translate(0 -4.5)"/></svg>',
        gated: false,
      },
      {
        id: 3,
        title: 'Messages',
        link: '/messages',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.51 121.57"><path d="m105.33,0H16.18C7.25,0,0,8.08,0,18.03v38.57h105.33c8.94,0,16.18-8.08,16.18-18.03v-20.54c0-9.95-7.24-18.03-16.18-18.03Zm-16.1,41.8H15.02v-7.92h74.21v7.92Zm17.26-19.08H15.02v-7.91h91.47v7.91Z"/><path d="m105.33,64.97H16.18c-8.93,0-16.18,8.07-16.18,18.03v20.53c0,9.96,7.25,18.04,16.18,18.04h105.33v-38.57c0-9.96-7.24-18.03-16.18-18.03Zm1.16,41.79h-54.36v-7.91h54.36v7.91Zm0-19.07H15.02v-7.92h91.47v7.92Z"/></svg>',
        gated: true,
      },
      {
        id: 4,
        title: 'Clubs',
        link: '/clubs',
        icon: '<svg style="margin-top:2px" class="icon" xmlns="http://www.w3.org/2000/svg" width="45" height="31.5" viewBox="0 0 45 31.5"><path id="Icon_awesome-user-friends" data-name="Icon awesome-user-friends" d="M13.5,18a7.875,7.875,0,1,0-7.875-7.875A7.871,7.871,0,0,0,13.5,18Zm5.4,2.25h-.584a10.873,10.873,0,0,1-9.633,0H8.1A8.1,8.1,0,0,0,0,28.35v2.025A3.376,3.376,0,0,0,3.375,33.75h20.25A3.376,3.376,0,0,0,27,30.375V28.35A8.1,8.1,0,0,0,18.9,20.25ZM33.75,18A6.75,6.75,0,1,0,27,11.25,6.752,6.752,0,0,0,33.75,18Zm3.375,2.25h-.267a8.867,8.867,0,0,1-6.216,0h-.267a7.81,7.81,0,0,0-3.916,1.083A10.29,10.29,0,0,1,29.25,28.35v2.7c0,.155-.035.3-.042.45H41.625A3.376,3.376,0,0,0,45,28.125a7.871,7.871,0,0,0-7.875-7.875Z" transform="translate(0 -2.25)"/></svg>',
        gated: false,
      },
      {
        id: 5,
        title: 'Suppliers',
        link: '/suppliers',
        icon: '<svg style="margin-top:-2px" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.45 121.57"><path d="m104.16,0h-6.11v121.57h6.11c1.82,0,3.29-1.47,3.29-3.29V3.28c0-1.81-1.47-3.28-3.29-3.28Z"/><path d="m11.62,0c-1.81,0-3.28,1.47-3.28,3.28v22H2.55c-1.4,0-2.55,1.14-2.55,2.54v8.56c0,1.4,1.15,2.55,2.55,2.55h5.79v15.03H2.55c-1.4,0-2.55,1.14-2.55,2.55v8.55c0,1.41,1.15,2.55,2.55,2.55h5.79v15.03H2.55c-1.4,0-2.55,1.14-2.55,2.55v8.55c0,1.41,1.15,2.55,2.55,2.55h5.79v21.99c0,1.82,1.47,3.29,3.28,3.29h79.43V0H11.62Zm69.2,82.92h-45.86v-8.86c0-5.85,7.11-9.63,14.38-11.34.95-.22,1.27-1.33.57-1.98-2.47-2.31-4.02-5.69-4.02-9.45,0-6.99,5.37-12.65,12-12.65,3.46,0,6.57,1.54,8.76,4,2.01,2.26,3.25,5.31,3.25,8.65s-1.24,6.39-3.26,8.65c-.25.28-.51.55-.78.81-.69.65-.38,1.75.58,1.98.06,0,.12.03.18.04,7.21,1.73,14.2,5.5,14.2,11.31v8.84Z"/></svg>',
        gated: false,
      },
      {
        id: 6,
        title: 'Events',
        link: '/events',
        icon: '<svg style="margin-top:-2px" class="icon" xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30"><path d="M24,1.5v3H12v-3H9v3H7.5a2.986,2.986,0,0,0-2.985,3L4.5,28.5a3,3,0,0,0,3,3h21a3.009,3.009,0,0,0,3-3V7.5a3.009,3.009,0,0,0-3-3H27v-3Zm4.5,27H7.5V12h21Z" transform="translate(-4.5 -1.5)"/></svg>',
        gated: false,
      },
      {
        id: 7,
        title: 'Map',
        link: '/map',
        icon: '<svg class="icon" style="margin-top:-1px" xmlns="http://www.w3.org/2000/svg" width="30.849" height="30.849" viewBox="0 0 30.849 30.849"><path id="Icon_metro-earth" data-name="Icon metro-earth" d="M17.995,1.928A15.424,15.424,0,1,0,33.419,17.352,15.424,15.424,0,0,0,17.995,1.928Zm0,28.921a13.451,13.451,0,0,1-5.339-1.1l7.024-7.9a.964.964,0,0,0,.243-.64V18.316a.964.964,0,0,0-.964-.964c-3.4,0-7-3.539-7.031-3.574a.964.964,0,0,0-.682-.282H7.391a.964.964,0,0,0-.964.964v5.784a.964.964,0,0,0,.533.862l3.323,1.662v5.66A13.5,13.5,0,0,1,5.8,11.568H9.319A.964.964,0,0,0,10,11.286L13.857,7.43a.964.964,0,0,0,.282-.682V4.416a13.534,13.534,0,0,1,9.769.8c-.125.105-.246.216-.363.332a5.785,5.785,0,0,0,4.086,9.875q.143,0,.287-.007a22.638,22.638,0,0,1-.253,11.217.961.961,0,0,0-.025.157,13.454,13.454,0,0,1-9.644,4.057Z" transform="translate(-2.571 -1.928)"/></svg>',
        gated: false,
      },
      {
        id: 8,
        title: 'My Projects',
        link: '/projects',
        icon: '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.294 21.283"><path d="M1067.677,334.854l.609,1.674a8.873,8.873,0,0,1,8.772,2.654,1.315,1.315,0,0,1-.422,2.067l-3.612,1.7a1.2,1.2,0,0,1-1.146-.081,2.662,2.662,0,0,0-3.938,1.433,1.2,1.2,0,0,1-.825.8l-3.859,1.02a1.315,1.315,0,0,1-1.652-1.312,8.873,8.873,0,0,1,5.014-7.672l-.609-1.674a10.644,10.644,0,1,0,1.667-.607Zm2.488,19.139a8.894,8.894,0,0,1-6.6-3.3,1.315,1.315,0,0,1,.693-2.092l3.5-.926a1.308,1.308,0,0,1,1.418.532l1.146,1.7,1.125,1.991A1.411,1.411,0,0,1,1070.164,353.993Zm.914-7.2a1.774,1.774,0,1,1,1.06-2.274A1.774,1.774,0,0,1,1071.078,346.793Zm5.328,4.928a1.411,1.411,0,0,1-2.333-.786l-.417-2.249-.213-2.037a1.307,1.307,0,0,1,.744-1.319l3.279-1.543a1.315,1.315,0,0,1,1.875,1.157A8.9,8.9,0,0,1,1076.406,351.721Z" transform="translate(-1059.824 -334.484)"/></svg>',
        gated: true,
      },
    ],
  });

  const { requiresLogin, showLogin } = useShowLogin();

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
    navigate(REDIRECT_TO_LOGIN_HREF);
    window.location.reload();
  };

  return (
    <Sticky top={84} className='w-full'>
      <div className='grid lg:block col-span-12 lg:col-span-3'>
        <div className='transition ease-in-out duration-200'>
          {requiresLogin ? (
            <GatedNavLink
              to='/'
              className='flex mt-5 rounded-full bg-red hover:bg-[#A60626] transition-all cursor-pointer text-white text-center w-fit justify-center px-8 py-2'
            >
              <div>Sign in</div>
              <div className='fill-white mt-[2px] ml-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='20'
                  viewBox='0 0 36 27.565'
                >
                  <path
                    d='M29.25,31.5H23.344a.846.846,0,0,1-.844-.844V27.844A.846.846,0,0,1,23.344,27H29.25a2.248,2.248,0,0,0,2.25-2.25V11.25A2.248,2.248,0,0,0,29.25,9H23.344a.846.846,0,0,1-.844-.844V5.344a.846.846,0,0,1,.844-.844H29.25A6.752,6.752,0,0,1,36,11.25v13.5A6.752,6.752,0,0,1,29.25,31.5Zm-3.3-14.133L14.133,5.555a1.69,1.69,0,0,0-2.883,1.2V13.5H1.688A1.683,1.683,0,0,0,0,15.188v6.75a1.683,1.683,0,0,0,1.688,1.688H11.25v6.75a1.69,1.69,0,0,0,2.883,1.2L25.945,19.758A1.7,1.7,0,0,0,25.945,17.367Z'
                    transform='translate(0 -4.5)'
                  />
                </svg>
              </div>
            </GatedNavLink>
          ) : (
            userData && (
              <div className='flex items-center mt-6'>
                <Link to={`/profile/${userData.username}`}>
                  <img
                    className='rounded-full w-16 h-16 object-cover'
                    src={userData?.profilePicture ? userData.profilePicture : TestImage}
                    // Add referrer policy to stop Google profile images src issue.
                    referrerPolicy='no-referrer'
                    alt='Profile Pic'
                  />
                </Link>
                <span className='ml-4'>
                  <Link
                    className='block text-lg font-bold ml-[2px]'
                    to={`/profile/${userData.username}`}
                  >
                    {userData.firstName} {userData.lastName}
                  </Link>
                  <Link
                    to={`/profile/${userData.username}/edit`}
                    className='text-[#705F6E] text-[10pt] ml-[1px]'
                  >
                    Edit Profile
                  </Link>
                </span>
              </div>
            )
          )}
          <div className='sidebarmenu pt-10'>
            <div className='menulinks'>
              {activeMenuItem.navMenuItems.map((navItem, index) =>
                navItem.gated ? (
                  <GatedNavLink
                    key={index}
                    id={navItem.id.toString()}
                    to={navItem.link}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-white font-bold mb-8 transition-colors flex active'
                        : 'text-white font-bold mb-8 transition-colors flex'
                    }
                    end
                  >
                    <div
                      className='iconwrap w-16 pl-3'
                      dangerouslySetInnerHTML={{ __html: navItem.icon }}
                    />
                    {navItem.title}
                  </GatedNavLink>
                ) : (
                  <NavLink
                    key={index}
                    id={navItem.id.toString()}
                    to={navItem.link}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-white font-bold mb-8 transition-colors flex active'
                        : 'text-white font-bold mb-8 transition-colors flex'
                    }
                    end
                  >
                    <div
                      className='iconwrap w-16 pl-3'
                      dangerouslySetInnerHTML={{ __html: navItem.icon }}
                    />
                    {navItem.title}
                  </NavLink>
                ),
              )}
              {userData.userAccountType === 'admin' && (
                <GatedNavLink
                  to='/articles'
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg
                      className='icon'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 121.4 121.57'
                    >
                      <polygon points='25.02 27.75 19.45 27.75 22.24 20.83 25.02 27.75' />
                      <path d='m98.99.47v109.89c0,.62.04,1.24.12,1.84V.92c0-.16-.04-.32-.12-.45Z' />
                      <path d='m104.48,121.57c-2.88-2.24-4.88-5.57-5.37-9.37-.08-.6-.12-1.22-.12-1.84V.47c-.15-.28-.45-.47-.8-.47H.93C.42,0,0,.41,0,.92v111.08c0,5.28,4.29,9.57,9.57,9.57h88.62c.51,0,.92-.42.92-.93v.93h5.37ZM19.27,14.83h6.03l9.58,21.64h-6.37l-1.7-4.2h-9.15l-1.7,4.2h-6.24l9.55-21.64Zm69.3,92.51H10.57v-7h78v7Zm0-14.22H10.57v-7h78v7Zm0-14.22H10.57v-7h78v7Zm0-14.21H10.57v-7h78v7Zm0-14.22H10.57v-7h78v7Zm0-14.22h-48.23v-7h48.23v7Zm0-14.22h-48.23v-7h48.23v7Z' />
                      <path d='m121.4,52.13v58.23c0,4.53-3.68,8.21-8.2,8.21s-8.21-3.68-8.21-8.21v-58.23h16.41Z' />
                      <path d='m99.11.92v111.28c-.08-.6-.12-1.22-.12-1.84V.47c.08.13.12.29.12.45Z' />
                    </svg>
                  </div>
                  My Articles
                </GatedNavLink>
              )}

              {(userData.userAccountType === 'advertiser' ||
                userData.userAccountType === 'admin') && (
                <GatedNavLink
                  to='/products'
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg className='icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                      <path d='M0 155.2C0 147.9 2.153 140.8 6.188 134.7L81.75 21.37C90.65 8.021 105.6 0 121.7 0H518.3C534.4 0 549.3 8.021 558.2 21.37L633.8 134.7C637.8 140.8 640 147.9 640 155.2C640 175.5 623.5 192 603.2 192H36.84C16.5 192 .0003 175.5 .0003 155.2H0zM64 224H128V384H320V224H384V464C384 490.5 362.5 512 336 512H112C85.49 512 64 490.5 64 464V224zM512 224H576V480C576 497.7 561.7 512 544 512C526.3 512 512 497.7 512 480V224z' />
                    </svg>
                  </div>
                  My Products
                </GatedNavLink>
              )}

              {userData.userAccountType === 'admin' && (
                <GatedNavLink
                  to='/adminarea/tags'
                  className={({ isActive }) =>
                    isActive
                      ? 'text-white font-bold mb-8 transition-colors flex active'
                      : 'text-white font-bold mb-8 transition-colors flex'
                  }
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg
                      className='icon mt-[5px]'
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 150.58 78.97'
                    >
                      <path d='m145.88,28.77L118.71,3.83c-2.68-2.47-6.19-3.83-9.84-3.83H14.54C6.51,0,0,6.51,0,14.54v49.89c0,8.03,6.51,14.54,14.54,14.54h94.33c3.65,0,7.16-1.37,9.84-3.83l27.17-24.95c6.27-5.76,6.27-15.66,0-21.42Zm-21.92,19.61c-4.92,0-8.91-3.98-8.91-8.9s3.99-8.9,8.91-8.9,8.9,3.98,8.9,8.9-3.99,8.9-8.9,8.9Z' />
                    </svg>
                  </div>
                  Admin: Manage Tags
                </GatedNavLink>
              )}
              {!requiresLogin && (
                <div
                  onClick={handleLogout}
                  className='text-white flex font-bold transition-colors cursor-pointer hover:fill-red hover:text-red'
                >
                  <div className='iconwrap w-16 pl-3'>
                    <svg className='icon' viewBox='0 0 24 24'>
                      <path d='M12,10c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2s-2,0.9-2,2v4C10,9.1,10.9,10,12,10z' />
                      <path d='M19.1,4.9L19.1,4.9c-0.3-0.3-0.6-0.4-1.1-0.4c-0.8,0-1.5,0.7-1.5,1.5c0,0.4,0.2,0.8,0.4,1.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0    c1.3,1.3,2,3,2,4.9c0,3.9-3.1,7-7,7s-7-3.1-7-7c0-1.9,0.8-3.7,2.1-4.9l0,0C7.3,6.8,7.5,6.4,7.5,6c0-0.8-0.7-1.5-1.5-1.5    c-0.4,0-0.8,0.2-1.1,0.4l0,0C3.1,6.7,2,9.2,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,9.2,20.9,6.7,19.1,4.9z' />
                    </svg>
                  </div>
                  Logout
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <AdvertisersWidgets /> */}
      </div>
    </Sticky>
  );
};

export default UserMenu;
