import ProjectSearch from '@/components/utils/ProjectSearch';
import { ImageUploadError, ImageUploadOptional } from '@/components/utils/imageUploadHook';
import { useCallback, useEffect, useState } from 'react';

// NOTE - Added project search to component so all images can have an array of projects linked
export const ImageUploadSection = ({
  labelText,
  articleImages,
  setImage,
  articleImageError,
  smallPreview,
  positionInArticle,
}: {
  labelText?: string,
  articleImages: any,
  setImage: (image: ImageUploadOptional, ix: number) => void,
  positionInArticle: number,
  articleImageError: ImageUploadError,
  smallPreview?: boolean,
}) => {
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>('');
  const [selectedProjects, setSelectedProjects] = useState<Array<any>>([]);
  const [imageCaption, setImageCaption] = useState<string>('');

  const onInputClick = (event: any) => {
    // TODO: - Fix bug where sometimes the file upload field doesnt work unless you kill the browser?
  };

  const handleRemoveImage = () => {
    setImagePreview('');
  };

  const handleImagePreview = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        reader.onloadend = async () => {
          setImagePreview(reader.result);
        };
      } else {
        console.log(`Incorrect file type. Please upload .jpg, .jpeg or .png`);
        return;
      }
    } catch (err: any) {
      console.log('err: ', err);
      return null;
    }
  };

  useEffect(() => {
    if (!imagePreview) {
      setImage(undefined, positionInArticle)
    } else {
      setImage({
        index: positionInArticle,
        imageData: imagePreview,
        url: '', // DONT TOUCH :P -> This will be updated when image is uploaded
        projects: selectedProjects,
        caption: imageCaption,
      }, positionInArticle);
    }
  }, [imageCaption, imagePreview, positionInArticle, selectedProjects]);

  function renderImageError() {
    switch (articleImageError) {
      case "failed": 
        return <span className='text-red text-lg'>Upload failed. The image may be too large. Try resubmitting or using a smaller image.</span>
      case "missing":
        return <span className='text-red text-lg'>This image is required</span>
    }
  }

  return (
    <div className='w-full'>
      <label className='block text-sm font-medium text-gray-700'>{labelText ?? 'Image'}</label>
      <div
        className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md relative ${
          articleImages?.length === 0 ? 'px-6 pt-5 pb-6' : ''
        }`}
      >
        <div
          className={`${
            imagePreview ? 'opacity-1' : 'opacity-0'
          } text-white cursor-pointer absolute -top-2 -right-2 w-5 h-5 bg-red rounded-full z-10 text-center text-xs hover:text-black flex items-center justify-center font-bold`}
          onClick={() => {
            handleRemoveImage();
          }}
        >
          X
        </div>
        <div className='text-center rounded overflow-hidden w-full h-full'>
          {imagePreview === '' ? (
            <div className='h-40 sm:h-40 md:h-40 lg:h-40 xl:h-40 w-full flex flex-col py-6 items-center justify-center'>
              <svg
                className='mx-auto h-12 w-12 text-gray-400'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 48 48'
                aria-hidden='true'
              >
                <path
                  d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <div className='flex text-sm text-gray-600 text-center justify-center items-center'>
                <label
                  htmlFor='file-upload'
                  className={`relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 ${
                    smallPreview ? 'w-[8rem]' : ''
                  }`}
                >
                  <span>Upload a file</span>
                  <input
                    id='file-upload'
                    name='file-upload'
                    type='file'
                    onChange={(event) => {
                      handleImagePreview(event);
                    }}
                    onClick={(e) => {
                      onInputClick(e);
                    }}
                    className='sr-only'
                  />
                </label>
                {smallPreview ? '' : <p className='pl-1'>or drag and drop</p>}
              </div>
              <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
            </div>
          ) : typeof imagePreview === 'string' ? (
            <div className='h-56 sm:h-64 md:h-72 lg:h-76 xl:h-96 w-full relative'>
              <img className='object-cover h-full w-full' src={imagePreview} alt='ProfileImage' />
            </div>
          ) : null}
        </div>
      </div>
      {renderImageError()}
      <div>
        <label className='block text-sm font-medium text-gray-700 pt-2'>Image caption</label>
        <input
          id='image-caption'
          name='image-caption'
          type='search'
          className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          onChange={(e) => {
            setImageCaption(e.target.value);
          }}
        />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700 pt-2'>
          Link projects to this image
        </label>
        <ProjectSearch handleFormUpdates={setSelectedProjects} />
      </div>
    </div>
  );
};
