import { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import { GET_ALL_USER_LOCATIONS, GET_ALL_EVENT_LOCATIONS } from '@/utils/queries/queries';
import { useQuery } from '@apollo/client';

type Location = {
  _id?: string;
  username?: string | null;
  locationName: string;
  locationAddress: string;
  pinType?: string;
  date?: string;
};

export default function MHPMapPage() {
  const { data: userLocations2, loading: userLocationsLoading } = useQuery(GET_ALL_USER_LOCATIONS);
  const { data: eventLocations2, loading: eventLocationsLoading } =
    useQuery(GET_ALL_EVENT_LOCATIONS);

  const [userLocations, setUserLocations] = useState<Location[]>();
  const [eventLocations, setEventLocations] = useState<Location[]>();
  const [allLocations, setAllLocations] = useState<Location[]>();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    setAllLocations([...(userLocations || []), ...(eventLocations || [])]);
  }, [userLocations, eventLocations]);

  useEffect(() => {
    if (userLocations2?.getAllUsers) {
      const transformedData = transformUserLocations(userLocations2.getAllUsers);
      setUserLocations(transformedData);
    }
  }, [userLocations2]);

  useEffect(() => {
    if (eventLocations2?.getAllEvents) {
      const transformedData = transformEventLocations(eventLocations2.getAllEvents);
      setEventLocations(transformedData);
    }
  }, [eventLocations2]);

  function transformUserLocations(locationsData: any[]): Location[] {
    const transformedUserArray: Location[] = [];

    locationsData.forEach((user) => {
      user.locations.forEach((location: Location) => {
        if (location.locationAddress) {
          transformedUserArray.push({
            username: user.username,
            _id: user._id,
            locationAddress: location.locationAddress,
            locationName: location.locationName,
            pinType: 'Supplier',
          });
        }
      });
    });

    return transformedUserArray;
  }

  function transformEventLocations(locationsData: any[]): Location[] {
    const transformedEventArray: Location[] = [];

    locationsData.forEach((event) => {
      if (event.location && event.eventType === 'event') {
        transformedEventArray.push({
          username: null,
          _id: event._id,
          locationAddress: event.location,
          locationName: event.name,
          pinType: 'Event',
          date: new Date(event.dates[0].date).toLocaleDateString(),
        });
      }
    });

    return transformedEventArray;
  }

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='mt-4 mb-10'>
      <div className='text-white mt-1 mb-4'>
        <h1 className='font-semibold text-xl'>MHP Map</h1>
        <p className='font-light text-[10pt]'>
          Explore our network of official suppliers and upcoming events
        </p>
      </div>
      {allLocations && !eventLocationsLoading && !userLocationsLoading && (
        <div
          className='w-full rounded-md overflow-hidden'
          style={{ height: windowHeight - 250 + 'px' }}
        >
          <MapComponent locations={allLocations} variant='Map' />
        </div>
      )}
    </div>
  );
}
