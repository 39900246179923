import { useState } from 'react';
import { SIGNED_UPLOAD_URL } from '@/utils/mutations/mutations';
import { useMutation } from '@apollo/client';

export type ProjectData = {
  name: string;
  id: string;
};

export type ImageUpload = {
  index: number;
  caption: string;
  url: string;
  projects: ProjectData[];
  imageData?: any;
};

type SignedUploadUrl = {
  timestamp: string,
  signature: string,
  cloudName: string,
  apiKey: string,
  folder: string
}

export type ImageUploadOptional = ImageUpload | undefined

export type ImageUploadError = "missing" | "failed" | undefined

async function uploadToSignedUrl(
  {
    timestamp,
    signature,
    cloudName,
    apiKey,
    folder
  }: SignedUploadUrl,
  dataUri: string
): Promise<string> {
  const formData = new FormData();

  formData.append("file", dataUri)
  formData.append("api_key", apiKey);
  formData.append("timestamp", timestamp);
  formData.append("signature", signature);
  formData.append("folder", folder);
  formData.append("allowed_formats", "jpeg,jpg,png,webp,avix")

  const result = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`, {
    method: "POST",
    body: formData
  }).then(it => it.json());

  return result.secure_url;
}

export function useImageUpload() {
  const [signedUrl] = useMutation(SIGNED_UPLOAD_URL);

  const [imageUploadErrors, setImageUploadErrors] = useState<ImageUploadError[]>([]);
  const [articleImages, setArticleImages] = useState<ImageUploadOptional[]>([]);

  const setImage = (image: ImageUploadOptional, ix: number) => {
    const newImages = [...articleImages]

    /* ensure array is long enough to contain the image */
    while (newImages.length <= ix) newImages.push(undefined)

    newImages[ix] = image

    /* ensure array ends on an image (shrinks image array when end is deleted) */
    while (newImages.length && !newImages[newImages.length - 1]) {
      newImages.pop()
    }

    setArticleImages(newImages)
  }

  // Utility method to upload images
  const handleImageUpload = async (image: ImageUpload) => {
    const { index, caption, projects, imageData } = image;

    const signed: SignedUploadUrl = await signedUrl()
      .then(it => it.data.uploadUrl);

    const url = await uploadToSignedUrl(signed, imageData);

    return {
      url,
      index,
      caption,
      projects,
    };
  };

  const getImageUrlsFromState = async (): Promise<any> => {
    if (articleImages.some(it => !it)) {
      setImageUploadErrors(articleImages.map(it => !it ? "missing" : undefined))

      throw new Error("Missing images for upload")
    }

    const promises = articleImages.map((_, index) =>
      /* can assume all images defined at this point */
      handleImageUpload(articleImages[index]!)
        .catch((err) => {
          setImageUploadErrors(articleImages.map((_, ix) => ix == index ? "failed" : undefined))
          
          console.trace(err)

          throw err
        }),
    );

    return await Promise.all(promises)
      .then((results) => {
        return results?.length ? [...results] : undefined; // TODO - do this after updating image types
      });
  };

  return {
    imageUploadErrors,
    getImageUrlsFromState,
    setImage,
    articleImages,
    setArticleImages,
  };
}
