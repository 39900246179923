import { gql } from '@apollo/client';

export const GET_ALL_ARTICLES = gql`
  query GetArticles {
    getAllArticles {
      approved
      _id
      createdAt
      creatorId
      firstName
      lastName
      username
      profilePicture
      heading
      subHeading
      type
      tags
      bodyContentList {
        index
        text
      }
      images {
        index
        caption
        url
        projects {
          name
          id
        }
      }
      articleTemplate
      mainProject {
        name
        id
      }
    }
  }
`;

export const GET_ALL_ADVERTISERS = gql`
  query GetAllAdvertisers {
    getAllAdvertisers {
      _id
      userAccountType
      username
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
    }
  }
`;

export const GET_ALL_CLUBS = gql`
  query GetAllClubs {
    getAllClubs {
      _id
      userAccountType
      username
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
    }
  }
`;

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    getAllProjects {
      _id
      createdBy
      createdAt
      name
      year
      make
      model
      badge
      tags
      images
      description
      paint
      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query Query($userId: ID, $filters: GetProductFilter) {
    getAllProducts(userId: $userId, filters: $filters) {
      _id
      title
      productImageURL
      bodyContent
      price
      createdBy
    }
  }
`;

export const GET_ALL_TAGS = gql`
  query GetTags {
    getAllTags
  }
`;

export const FIND_PROJECT_BY_NAME = gql`
  query FindProjectByName($name: String) {
    findProjectByName(name: $name) {
      name
      _id
      images
    }
  }
`;

export const FIND_ADVERTISER_BY_NAME = gql`
  query FindAdvertiserByCompanyName($companyName: String) {
    findAdvertiserByCompanyName(companyName: $companyName) {
      advertiserDetails {
        companyLogo
        coverPhoto
        companyName
      }
      _id
    }
  }
`;

export const FIND_TAG_BY_QUERY = gql`
  query GetTagsBySearch($searchQuery: String) {
    getTagsBySearch(searchQuery: $searchQuery) {
      tag
    }
  }
`;

export const FIND_ARTICLES_BY_TAGGED_ADVERTISER_ID = gql`
  query FindArticlesByTaggedAdvertiserId($advertiserId: ID) {
    findArticlesByTaggedAdvertiserId(advertiserId: $advertiserId) {
      heading
      subHeading
      type
    }
  }
`;

export const GET_ALL_POSTS = gql`
  query GetAllFeeds {
    getAllFeeds {
      _id
      createdAt
      createdBy
      heading
      bodyContent
      tags
      price
      feedImage
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($userId: ID) {
    getUserById(userId: $userId) {
      _id
      firstName
      lastName
      username
      profilePicture
      advertiserDetails {
        companyName
        coverPhoto
      }
    }
  }
`;

export const GET_USER_PROJECTS = gql`
  query GetUserProjects($userId: ID) {
    getProjects(userId: $userId) {
      name
      _id
      images
    }
  }
`;

export const GET_ALL_EVENTS = gql`
  query GetAllEvents {
    getAllEvents {
      _id
      name
      location
      eventImages
      eventType
      eventDetails
      ticketLink
      dates {
        date
        endTime
        startTime
      }
      destinations {
        arrivalTime
        eventStop
        location
      }
      createdBy
      createdAt
      tags
    }
  }
`;

export const GET_EVENT_BY_ID = gql`
  query GetEventById($eventId: ID) {
    getEventById(eventId: $eventId) {
      _id
      name
      location
      eventImages
      eventType
      eventDetails
      ticketLink
      dates {
        date
        endTime
        startTime
      }
      destinations {
        arrivalTime
        eventStop
        location
      }
      createdBy
      createdAt
      tags
    }
  }
`;

export const GET_ALL_VIDEOS = gql`
  query GetVideos {
    getAllVideos {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const GET_VIDEO_BY_ID = gql`
  query GetVideoById($videoId: ID) {
    getVideoById(videoId: $videoId) {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const GET_RANDOM_VIDEO = gql`
  query GetRandomVideo($videoId: ID!) {
    getRandomVideo(videoId: $videoId) {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const GET_RANDOM_SHORT = gql`
  query GetRandomShort($videoId: ID!) {
    getRandomShort(videoId: $videoId) {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const CREATE_POST = gql`
  mutation Mutation($input: FeedInput) {
    createFeed(input: $input) {
      heading
      bodyContent
    }
  }
`;

export const CREATE_VIDEO = gql`
  mutation CreateVideo($input: VideoInput) {
    createVideo(input: $input) {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const GET_VIDEOS_BY_USER_ID = gql`
  query GetVideosByUserId($userId: ID!) {
    getVideosByUserId(userId: $userId) {
      _id
      createdAt
      createdBy
      tags
      videoUrl
      caption
    }
  }
`;

export const GET_ALL_USER_LOCATIONS = gql`
  query GetAllUsers {
    getAllUsers {
      locations {
        locationName
        locationAddress
        locationPhone
      }
      username
      _id
    }
  }
`;

export const GET_ALL_EVENT_LOCATIONS = gql`
  query GetAllEvents {
    getAllEvents {
      _id
      name
      location
      eventType
      dates {
        date
        endTime
        startTime
      }
      destinations {
        arrivalTime
        eventStop
        location
      }
    }
  }
`;
