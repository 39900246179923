import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LeftArrowCircle from '@/assets/icons/LeftArrowCircle';
import RightArrowCircle from '@/assets/icons/RightArrowCircle';
import ReactPlayer from 'react-player';

SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay, A11y]);

interface Video {
  _id: string;
  createdBy: string;
  createdAt: Date;
  videoUrl: string;
  caption: String;
  tags: string[];
}

const VideoSwiper = ({
  videosData,
  swiperTitle,
  viewAllActive,
  isPlaying,
  slidesPerView,
  aspectRatio,
}: {
  videosData: Video[];
  swiperTitle: string;
  viewAllActive: boolean;
  isPlaying: boolean;
  slidesPerView: number;
  aspectRatio: string;
}) => {
  return (
    <div>
      <CustomSwiper
        videos={videosData}
        title={swiperTitle}
        viewAllActive={viewAllActive}
        isPlaying={isPlaying}
        slidesPerView={slidesPerView}
        aspectRatio={aspectRatio}
      />
    </div>
  );
};

export default VideoSwiper;

function VideoSlide({
  videoData,
  isPlaying,
  aspectRatio,
}: {
  videoData: Video;
  isPlaying: boolean;
  aspectRatio: string;
}) {
  const playerRef = useRef<ReactPlayer | null>(null);
  const { videoUrl } = videoData;
  let hoverTimeout: NodeJS.Timeout | undefined;
  let playerProps = {};

  const handleVideoEnded = () => {
    if (playerRef.current) {
      if (videoUrl.includes('youtube.com')) {
        const internalPlayer = playerRef.current.getInternalPlayer();
        if (internalPlayer && typeof internalPlayer.seekTo === 'function') {
          internalPlayer.seekTo(0);
          internalPlayer.playVideo();
        }
      } else {
        const internalPlayer = playerRef.current.getInternalPlayer();
        if (internalPlayer && typeof internalPlayer.seek === 'function') {
          internalPlayer.seek(0);
          internalPlayer.play();
        }
      }
    }
  };

  const handleMouseEnter = () => {
    if (playerRef.current && !isPlaying) {
      hoverTimeout = setTimeout(() => {
        const internalPlayer = playerRef.current!.getInternalPlayer();
        if (internalPlayer) {
          if (videoUrl.includes('youtube.com')) {
            if (typeof internalPlayer.playVideo === 'function') {
              internalPlayer.playVideo();
            }
          } else {
            if (typeof internalPlayer.play === 'function') {
              internalPlayer.play();
            }
          }
        }
      }, 750);
    }
  };

  const handleMouseLeave = () => {
    if (playerRef.current && !isPlaying) {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
      const internalPlayer = playerRef.current!.getInternalPlayer();
      if (internalPlayer) {
        if (videoUrl.includes('youtube.com')) {
          if (typeof internalPlayer.pauseVideo === 'function') {
            internalPlayer.pauseVideo();
          }
        } else {
          if (typeof internalPlayer.pause === 'function') {
            internalPlayer.pause();
          }
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };
  }, []);

  if (videoUrl.includes('youtube.com')) {
    playerProps = {
      url: videoUrl,
      config: {
        youtube: {
          playerVars: { modestBranding: 1, showinfo: 1, autoplay: `${isPlaying ? 1 : 0}`, loop: 1 },
        },
      },
      muted: true,
    };
  } else if (videoUrl.includes('vimeo.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      muted: true,
      config: {
        vimeo: {
          playerOptions: {
            title: false,
            portrait: true,
          },
        },
      },
    };
  } else if (videoUrl.includes('facebook.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      muted: true,
      controls: false,
    };
  } else {
    return <div>Unsupported video source</div>;
  }

  return (
    <div
      className={`w-full relative ${aspectRatio}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        to={`/videos/${videoData?._id}`}
        className='cursor-pointer absolute flex p-2 opacity-0 hover:opacity-100 text-[0pt] hover:text-[10pt] hover:bg-white/10 transition-all duration-100 inset-0 z-20'
      ></Link>
      <ReactPlayer
        {...playerProps}
        onEnded={handleVideoEnded}
        ref={playerRef}
        muted
        width='100%'
        height='100%'
      />
    </div>
  );
}

function CustomSwiper({
  videos,
  title,
  viewAllActive,
  isPlaying,
  slidesPerView,
  aspectRatio,
}: {
  videos: Video[];
  title: string;
  viewAllActive: boolean;
  isPlaying: boolean;
  slidesPerView: number;
  aspectRatio: string;
}) {
  const swiperRef = useRef<SwiperCore | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(1);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex);
    setCurrentSlidesPerView(swiper.params.slidesPerView);
  };

  useEffect(() => {
    swiperRef.current = new SwiperCore('.custom-swiper', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
    });
  }, []);

  return (
    <div>
      <Swiper
        className='relative'
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        onSlideChange={handleSlideChange}
        // autoplay
        slidesPerView={1}
        spaceBetween={16}
        breakpoints={{
          400: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: slidesPerView,
          },
        }}
      >
        <div className='text-white font-semibold text-xl absolute w-full top-0 z-10 grid grid-cols-5'>
          <div className='col-span-4 flex space-x-2 items-center'>
            <div className='mr-2'>{title}</div>
            <SwiperButtonPrevious currentSlideIndex={currentSlideIndex}>
              <LeftArrowCircle width='20px' height='20px' />
            </SwiperButtonPrevious>
            <SwiperButtonNext
              eventArrayLength={videos?.length}
              currentSlideIndex={currentSlideIndex}
              slidesPerView={currentSlidesPerView}
            >
              <RightArrowCircle width='20px' height='20px' />
            </SwiperButtonNext>
          </div>

          {viewAllActive && (
            <div className='w-auto flex justify-end'>
              <Link to={'/videos'} className='text-[10pt] text-red hover:underline font-regular'>
                View all
              </Link>
            </div>
          )}
        </div>

        <div>
          {videos &&
            videos
              .map((video: Video) => (
                <SwiperSlide className='group relative flex-col mt-11 space-y-3' key={video?._id}>
                  <div className='overflow-hidden rounded-md'>
                    <VideoSlide videoData={video} isPlaying={isPlaying} aspectRatio={aspectRatio} />
                  </div>
                  {!video.videoUrl.includes('/shorts/') && (
                    <div className='group-hover:underline font-[Montserrat] text-md font-extrabold italic line-clamp-2'>
                      {video.caption}
                    </div>
                  )}
                </SwiperSlide>
              ))
              .reverse()
              .splice(0, 8)}
        </div>
      </Swiper>
    </div>
  );
}

function SwiperButtonPrevious({
  children,
  className,
  currentSlideIndex,
}: {
  children?: React.ReactNode;
  className?: string;
  currentSlideIndex: number;
}) {
  const swiper = useSwiper();

  return (
    <>
      {currentSlideIndex < 1 ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}

function SwiperButtonNext({
  children,
  eventArrayLength,
  className,
  currentSlideIndex,
  slidesPerView,
}: {
  children?: React.ReactNode;
  eventArrayLength: number;
  className?: string;
  currentSlideIndex: number;
  slidesPerView: number;
}) {
  const swiper = useSwiper();
  const [isSlideEnd, setIsSlideEnd] = useState<Boolean>();
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setIsSlideEnd(swiper.isEnd);
    setMaxIndex(eventArrayLength - slidesPerView);
  }, [swiper, eventArrayLength, slidesPerView, currentSlideIndex]);

  return (
    <>
      {isSlideEnd || currentSlideIndex >= maxIndex ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}
