export default function LeftArrowCircle({
  width,
  height,
  className,
}: {
  width?: string;
  height?: string;
  className?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 29.25 29.25'
      className={className}
      fill='#f2123d'
    >
      <path
        d='M18,3.375A14.625,14.625,0,1,0,32.625,18,14.623,14.623,0,0,0,18,3.375ZM21.052,23.7a1.362,1.362,0,0,1,0,1.92,1.34,1.34,0,0,1-.956.394,1.364,1.364,0,0,1-.963-.4l-6.609-6.63a1.355,1.355,0,0,1,.042-1.87l6.708-6.729a1.357,1.357,0,1,1,1.92,1.92L15.434,18Z'
        transform='translate(-3.375 -3.375)'
      />
    </svg>
  );
}
