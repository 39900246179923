import { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import TestImage from '@/assets/images/no_image_found.png';
import { getHTMLFromDraftState } from '@/utils/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import 'swiper/css';
import Shop from './Shop';
import NewMessagePopup from '@/components/usercomponents/NewMessagePopup';
import MessageIcon from '@/assets/icons/MessageIcon';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';
import { useShowLogin } from '@/components/LoginModal';
import { useForm } from 'react-hook-form';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import LoadingSpinner from '@/components/LoadingSpinner';
import { CREATE_VIDEO, GET_VIDEOS_BY_USER_ID } from '@/utils/queries/queries';
import ReactPlayer from 'react-player';
import VideoSwiper from './videos/VideoSwiper';
import MapComponent from './MapComponent';

interface Video {
  _id: string;
  createdBy: string;
  createdAt: Date;
  videoUrl: string;
  caption: string;
  tags: string[];
}

const GET_MY_ID = gql`
  query Query {
    me {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      profilePicture
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
      locations {
        locationName
        locationAddress
        locationPhone
      }
    }
  }
`;

const GET_USERS_DATA = gql`
  query Query($userId: ID) {
    getProjects(userId: $userId) {
      _id
      createdBy
      name
      year
      make
      model
      badge
      images
    }
    getArticles(userId: $userId) {
      approved
      _id
      createdAt
      creatorId
      firstName
      lastName
      username
      profilePicture
      heading
      subHeading
      type
      tags
      bodyContentList {
        index
        text
      }
      images {
        index
        caption
        url
      }
      articleTemplate
    }
    getProducts(userId: $userId) {
      _id
      createdBy
      title
      price
      productImageURL
      comparePrice
      bodyContent
      type
      vendor
      categories
      subcategories
      tags
    }
  }
`;

const GET_USER_PROFILE = gql`
  query UserProfile($username: String) {
    userProfile(username: $username) {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      profilePicture
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
      locations {
        locationName
        locationAddress
        locationPhone
      }
    }
  }
`;

const GET_POSTS = gql`
  query Query($userId: ID) {
    getFeeds(userId: $userId) {
      heading
      bodyContent
      feedImage
      tags
      _id
    }
  }
`;

const DELETE_POST = gql`
  mutation DeleteFeed($feedId: ID) {
    deleteFeed(feedId: $feedId)
  }
`;

const UPDATE_USER_ACCOUNT_TYPE = gql`
  mutation UpdateUserAccountType($userId: ID!, $newAccountType: String!) {
    updateUserAccountType(userId: $userId, newAccountType: $newAccountType) {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      profilePicture
      referralCode
      state
      completedInitialProfileSetup
      tags
      about
    }
  }
`;

export const FIND_ARTICLES_BY_TAGGED_ADVERTISER_ID = gql`
  query FindArticlesByTaggedAdvertiserId($advertiserId: ID) {
    findArticlesByTaggedAdvertiserId(advertiserId: $advertiserId) {
      _id
      heading
      subHeading
      articleTemplate
      images {
        url
      }
    }
  }
`;

export const FIND_PROJECTS_BY_TAGGED_ADVERTISER_ID = gql`
  query FindProjectsByTaggedAdvertiserId($advertiserId: ID) {
    findProjectsByTaggedAdvertiserId(advertiserId: $advertiserId) {
      _id
      images
      name
      make
    }
  }
`;

const Profile = () => {
  const navigate = useNavigate();
  const { username } = useParams();
  const { userData } = useSelector((state: RootState) => state.user);
  const { data: profileData } = useQuery(GET_USER_PROFILE, {
    variables: { username },
  });
  const [fetchUserData, { data }] = useLazyQuery(GET_USERS_DATA);
  const [fetchPostsData, { data: getPostsData }] = useLazyQuery(GET_POSTS);
  const [fetchArticlesData, { data: getArticlesData }] = useLazyQuery(
    FIND_ARTICLES_BY_TAGGED_ADVERTISER_ID,
  );
  const [fetchRelatedProjectsData, { data: getRelatedProjectsData }] = useLazyQuery(
    FIND_PROJECTS_BY_TAGGED_ADVERTISER_ID,
  );

  const { loading: myIdLoading, data: myIdData } = useQuery(GET_MY_ID);
  const [updateUserAccountType, { loading: updateProfileLoading }] =
    useMutation(UPDATE_USER_ACCOUNT_TYPE);
  const [selected, setSelected] = useState<AdvertiserFeedSelection>(AdvertiserFeedSelection.FEED);
  const [messageUser, setMessageUser] = useState<boolean>(false);

  useEffect(() => {
    profileData &&
      profileData.userProfile &&
      (() => {
        fetchUserData({ variables: { userId: profileData.userProfile._id } });
        fetchPostsData({ variables: { userId: profileData.userProfile._id } });
        fetchArticlesData({ variables: { advertiserId: profileData.userProfile._id } });
        fetchRelatedProjectsData({ variables: { advertiserId: profileData.userProfile._id } });
      })();
  }, [profileData]);

  function getProfileType(accountType: String) {
    // These should be enums
    switch (accountType) {
      case 'admin':
        return 'MHP Admin';
      case 'advertiser':
        return 'MHP Advertiser';
      default:
        return 'MHP User';
    }
  }

  const handleUpdateUserAccountType = async (input: string) => {
    try {
      const { data } = await updateUserAccountType({
        variables: {
          userId: profileData.userProfile._id,
          newAccountType: input,
        },
      });

      if (data) {
        window.location.reload();
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleMessageUserClickFnc = () => {
    setMessageUser(!messageUser);
  };

  const [isYourProfile, setIsYourProfile] = useState<boolean>(false);

  // Makes sure the user can only delete their own posts
  useEffect(() => {
    if (myIdData?.me?._id === profileData?.userProfile?._id) {
      setIsYourProfile(true);
    } else {
      setIsYourProfile(false);
    }
  }, [profileData, myIdData]);

  const { requiresLogin, showLogin } = useShowLogin();

  return (
    <div className='mb-10'>
      <div className='md:grid md:grid-cols-1 xl:grid-cols-3 lg:mt-6 '>
        <div className='md:col-span-2 bg-[#241B23] rounded-md'>
          <div className=''>
            {profileData?.userProfile?.userAccountType === 'standard' && (
              <>
                <div className='w-full flex justify-center gap-6 mt-8'>
                  <div
                    className='w-36 h-36 bg-center bg-cover rounded-full'
                    style={{ backgroundImage: `url(${profileData?.userProfile?.profilePicture})` }}
                  ></div>
                  <div className='self-center'>
                    <div className='text-3xl font-[Montserrat] italic font-bold'>
                      {profileData?.userProfile?.firstName} {profileData?.userProfile?.lastName}
                    </div>
                    <div className=''>{profileData?.userProfile?.username}</div>
                    <div
                      className='cursor-pointer w-fit flex py-2 px-4 mt-4 bg-red hover:bg-[#A60626] rounded-full'
                      onClick={requiresLogin ? showLogin : handleMessageUserClickFnc}
                    >
                      <MessageIcon />
                      <div className='text-[10pt] w-auto ml-2 font-semibold'>{`Message ${profileData?.userProfile?.firstName}`}</div>
                    </div>
                    {myIdData?.me?.userAccountType === 'admin' && (
                      <>
                        <div
                          className='cursor-pointer w-fit flex py-2 px-4 mt-4 bg-red hover:bg-[#A60626] rounded-full text-[10pt] font-semibold'
                          onClick={() => handleUpdateUserAccountType('club')}
                        >
                          Admin: Upgrade to Club
                        </div>
                        <div
                          className='cursor-pointer w-fit flex py-2 px-4 mt-4 bg-red hover:bg-[#A60626] rounded-full text-[10pt] font-semibold'
                          onClick={() => handleUpdateUserAccountType('advertiser')}
                        >
                          Admin: Upgrade to Advertiser
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='p-4'>
                  <h2 className='col-start-1 text-lg font-bold mt-4 mb-3 text-center'>
                    {myIdData?.me?._id === profileData?.userProfile?._id
                      ? `Your `
                      : `${profileData?.userProfile?.username}'s `}
                    Projects
                  </h2>
                  <div className=''>
                    {data?.getProjects?.length ? (
                      data?.getProjects?.slice(0, 6).map((project: any) => {
                        return (
                          <div
                            key={project?._id}
                            className='relative xl:col-span-1 mt-4 grid grid-flow-row'
                          >
                            <Link
                              className='single-project transition-all h-[350px] bg-center bg-cover rounded-md text-[0pt] hover:text-[2pt] relative lg:bg-[length:100%] lg:hover:bg-[length:102%]'
                              style={{
                                backgroundImage: `url(${
                                  project?.images[0] !== '' ? project?.images[0] : TestImage
                                })`,
                              }}
                              to={`/project/${project?._id}`}
                            >
                              <div className='h-auto w-full bg-gradient-to-t from-[#080607d5] to-[#08060700] absolute bottom-0'>
                                <h2 className='text-white text-8xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
                                  {project?.make}
                                </h2>

                                <h2 className='text-3xl font-[Montserrat] text-white font-extrabold p-3 line-clamp-2'>
                                  {project?.name}
                                </h2>
                                <div className='opacity-0 relative'>
                                  Hello, I hope you're having a good day.
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className='-ml-4 md:ml-0 text-center'>
                        {myIdData?.me?._id === profileData?.userProfile?._id
                          ? `You have no projects... yet. `
                          : `${profileData?.userProfile?.username} has no projects... yet.`}
                      </h3>
                    )}
                  </div>
                </div>
              </>
            )}

            {(profileData?.userProfile?.userAccountType === 'advertiser' ||
              profileData?.userProfile?.userAccountType === 'admin' ||
              profileData?.userProfile?.userAccountType === 'club') && (
              <div>
                <div
                  className={`relative rounded-tl-md rounded-tr-md h-[330px] bg-center bg-cover`}
                  style={{
                    backgroundImage: `url(${
                      profileData?.userProfile?.advertiserDetails?.coverPhoto
                        ? profileData?.userProfile?.advertiserDetails?.coverPhoto
                        : TestImage
                    })`,
                  }}
                >
                  <div className='h-auto w-full bg-gradient-to-t from-[#241B23] to-[#241B2300] absolute bottom-0'>
                    <div className='flex flex-col items-center -mb-[60px]'>
                      <div className='flex items-center justify-center mb-3'>
                        <img
                          className='drop-shadow-lg max-w-full max-h-[160px] px-4'
                          src={
                            profileData?.userProfile
                              ? profileData?.userProfile?.advertiserDetails?.companyLogo
                              : TestImage
                          }
                          // Add referrer policy to stop Google profile images src issue.
                          referrerPolicy='no-referrer'
                          alt='Profile Pic'
                        />
                      </div>

                      <p className='text-[8pt] font-black uppercase text-red mt-1'>
                        {profileData?.userProfile
                          ? getProfileType(profileData.userProfile.userAccountType)
                          : ''}
                      </p>

                      {myIdData?.me?.userAccountType === 'admin' && (
                        <>
                          {profileData?.userProfile?.userAccountType !==
                            myIdData?.me?.userAccountType && (
                            <div
                              className='cursor-pointer w-fit flex py-2 px-4 mt-4 bg-red hover:bg-[#A60626] rounded-full text-[10pt] font-semibold'
                              onClick={() => handleUpdateUserAccountType('standard')}
                            >
                              Admin: Downgrade to Standard User
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='px-4 pb-4'>
                  <TabSelector
                    selected={selected}
                    handleSelection={setSelected}
                    handleMessageUserClick={handleMessageUserClickFnc}
                    profileData={profileData}
                  />

                  <TabContent
                    data={data}
                    isYourPost={isYourProfile}
                    tabSelected={selected}
                    feedData={getPostsData}
                    profileData={profileData}
                    productData={data?.getProducts}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='mt-10 xl:mt-0 md:col-span-1 xl:ml-9 space-y-8'>
          <div className='h-auto bg-[#241B23] rounded-md overflow-hidden'>
            <div className='relative'>
              {profileData?.userProfile?.locations[0]?.locationAddress && (
                <div className='w-full h-[300px] overflow-hidden'>
                  <MapComponent
                    locations={profileData?.userProfile?.locations}
                    variant={'Profile'}
                  />
                </div>
              )}

              <div className='space-y-3 p-4'>
                <div className='text-lg font-[Montserrat] font-bold italic mb-2'>
                  {profileData?.userProfile?.advertiserDetails.companyName}
                </div>
                {profileData?.userProfile?.advertiserDetails.primaryTags[0] && (
                  <div className=''>
                    <div className='text-red font-extrabold uppercase text-[8pt]'>
                      {profileData?.userProfile?.userAccountType === 'club'
                        ? 'Club Tags'
                        : 'Primary Services'}
                    </div>
                    {profileData?.userProfile?.advertiserDetails.primaryTags.map(
                      (tag: string, index: number) => {
                        return (
                          <span key={index}>
                            {tag}
                            {index !==
                              profileData?.userProfile?.advertiserDetails.primaryTags.length - 1 &&
                              ', '}
                          </span>
                        );
                      },
                    )}
                  </div>
                )}
                {profileData?.userProfile?.advertiserDetails.secondaryTags[0] && (
                  <div className=''>
                    <div className='text-red font-extrabold uppercase text-[8pt]'>
                      More Services
                    </div>
                    {profileData?.userProfile?.advertiserDetails.secondaryTags.map(
                      (tag: string, index: number) => {
                        return (
                          <span key={index}>
                            {tag}
                            {index !==
                              profileData?.userProfile?.advertiserDetails.secondaryTags.length -
                                1 && ', '}
                          </span>
                        );
                      },
                    )}
                  </div>
                )}

                {profileData?.userProfile?.locations.length > 1 ? (
                  <div>
                    <div className='text-red font-extrabold uppercase text-[8pt]'>Locations</div>
                    <div className='space-y-4'>
                      {profileData?.userProfile?.locations.map((location: any) => {
                        return (
                          <div>
                            <div className='font-[Montserrat] text-white font-bold'>
                              {location?.locationName}
                            </div>
                            <div>
                              <a
                                className='text-[9pt] hover:underline'
                                href={`tel:${location?.locationPhone}`}
                              >
                                {location?.locationPhone}
                              </a>
                            </div>
                            <div>
                              <a
                                className='text-[9pt] hover:underline'
                                href={`https://www.google.com/maps?q=${encodeURIComponent(
                                  location?.locationAddress,
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                {location?.locationAddress}
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <>
                    {profileData?.userProfile?.locations[0]?.locationPhone && (
                      <>
                        <div className='text-red font-extrabold uppercase text-[8pt]'>Phone</div>
                        <a
                          className='cursor-pointer mb-3 hover:underline'
                          href={`tel:${profileData?.userProfile?.locations[0]?.locationPhone}`}
                        >
                          {profileData?.userProfile?.locations[0]?.locationPhone}
                        </a>
                      </>
                    )}
                    {profileData?.userProfile?.locations[0]?.locationAddress && (
                      <>
                        <div className='text-red font-extrabold uppercase text-[8pt]'>Address</div>
                        <a
                          className='cursor-pointer mb-3 hover:underline'
                          href={`https://www.google.com/maps?q=${encodeURIComponent(
                            profileData?.userProfile?.locations[0]?.locationAddress,
                          )}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {profileData?.userProfile?.locations[0]?.locationAddress}
                        </a>
                      </>
                    )}
                  </>
                )}

                {myIdData?.me?.username !== profileData?.userProfile?.username && (
                  <div className='w-full flex justify-center'>
                    <div
                      className='cursor-pointer w-full flex justify-center py-2 px-4 text-sm text-center bg-red hover:bg-[#A60626] rounded-full
                        transition-all duration-100'
                      onClick={requiresLogin ? showLogin : handleMessageUserClickFnc}
                    >
                      <MessageIcon />
                      <div className='text-[10pt] ml-2 font-semibold'>{`Message ${profileData?.userProfile?.advertiserDetails.companyName}`}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {myIdData?.me?._id === profileData?.userProfile?._id ? (
            <div className=''>
              <div className='hidden xl:grid grid-cols-2 gap-4'>
                <Link
                  className='col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-md'
                  to='/projects/add'
                >
                  <span className='text-xl mr-1'>+</span> Add Project
                </Link>

                {myIdData?.me?.userAccountType === 'admin' ||
                myIdData?.me?.userAccountType === 'advertiser' ? (
                  <Link
                    className='col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-md'
                    to='/post/add'
                  >
                    <span className='text-xl mr-1'>+</span> Add Post
                  </Link>
                ) : (
                  <div className='select-none col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-[#241B23] text-[#705F6E] py-2 px-4 text-sm font-semibold rounded-md'>
                    <span className='text-xl mr-1'>+</span> Add Post
                  </div>
                )}

                {myIdData?.me?.userAccountType === 'admin' ||
                myIdData?.me?.userAccountType === 'advertiser' ? (
                  <Link
                    className='col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-md'
                    to='/products/add'
                  >
                    <span className='text-xl mr-1'>+</span> Add Product
                  </Link>
                ) : (
                  <div className='select-none col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-[#241B23] text-[#705F6E] py-2 px-4 text-sm font-semibold rounded-md'>
                    <span className='text-xl mr-1'>+</span> Add Product
                  </div>
                )}

                {myIdData?.me?.userAccountType === 'admin' ? (
                  <Link
                    className='col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-md'
                    to='/products/add'
                  >
                    <span className='text-xl mr-1'>+</span> Add Article
                  </Link>
                ) : (
                  <div className='select-none col-span-1 justify-center flex items-center whitespace-nowrap transition ease-in-out duration-100 bg-[#241B23] text-[#705F6E] py-2 px-4 text-sm font-semibold rounded-md'>
                    <span className='text-xl mr-1'>+</span> Add Article
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {profileData?.userProfile?.userAccountType === 'admin' && (
            <div>
              <h2 className='text-lg font-bold mb-3'>Recent articles</h2>
              <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4'>
                {data && profileData && data.getArticles.length ? (
                  data?.getArticles
                    ?.map((article: any) => {
                      return (
                        <div key={article._id}>
                          <Link to={`/articles/${article._id}`}>
                            <div className='grid grid-cols-2 col-span-1 h-full items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                              <div
                                className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                                style={{
                                  backgroundImage: `url(${article.images[0].url})`,
                                }}
                              ></div>
                              <div className='col-span-1 p-4 text-white'>
                                <h4 className='font-extrabold uppercase text-[8pt] mb-1'>
                                  {article.articleTemplate}
                                </h4>
                                <h4 className='line-clamp-2 mb-1 font-semibold'>
                                  {article.heading}
                                </h4>
                                <h5 className='text-[8pt] line-clamp-3 font-light'>
                                  {article.subHeading}
                                </h5>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                    .reverse()
                    .splice(0, 5)
                ) : (
                  <h3 className='-ml-4 md:ml-0'>
                    {myIdData?.me?._id === profileData?.userProfile?._id
                      ? `You have no articles... yet. `
                      : `${
                          profileData?.userProfile?.advertiserDetails?.companyName ||
                          profileData?.userProfile?.username
                        } has no articles... yet.`}
                  </h3>
                )}
              </div>
            </div>
          )}

          {profileData?.userProfile?.userAccountType === 'advertiser' && (
            <div>
              {getArticlesData?.findArticlesByTaggedAdvertiserId[0] && (
                <>
                  <h2 className='text-lg font-bold mb-3'>Related articles</h2>

                  <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4'>
                    {getArticlesData?.findArticlesByTaggedAdvertiserId
                      ?.map((article: any) => {
                        return (
                          <div key={article._id}>
                            <Link to={`/articles/${article._id}`}>
                              <div className='grid grid-cols-2 col-span-1 h-auto items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                                <div
                                  className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                                  style={{
                                    backgroundImage: `url(${article.images[0].url})`,
                                  }}
                                ></div>
                                <div className='col-span-1 p-4 text-white'>
                                  <h4 className='font-extrabold uppercase text-[8pt] mb-1'>
                                    {article.articleTemplate}
                                  </h4>
                                  <h4 className='line-clamp-2 mb-1 font-semibold'>
                                    {article.heading}
                                  </h4>
                                  <h5 className='text-[8pt] line-clamp-3 font-light'>
                                    {article.subHeading}
                                  </h5>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                      .reverse()
                      .splice(0, 5)}
                  </div>
                </>
              )}
            </div>
          )}

          {(profileData?.userProfile?.userAccountType === 'advertiser' ||
            profileData?.userProfile?.userAccountType === 'admin') && (
            <>
              {data?.getProjects[0] && (
                <div>
                  <h2 className='text-lg font-bold mb-3'>Recent projects</h2>

                  <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4'>
                    {data?.getProjects
                      .map((project: any) => {
                        return (
                          <div
                            key={project._id}
                            className='relative xl:col-span-1 grid grid-flow-row'
                          >
                            <Link
                              className='transition-all h-48 bg-center bg-cover rounded-md text-[0pt] hover:text-[2pt] relative lg:bg-[length:100%] lg:hover:bg-[length:102%]'
                              style={{
                                backgroundImage: `url(${
                                  project.images[0] !== '' ? project.images[0] : TestImage
                                })`,
                              }}
                              to={`/project/${project._id}`}
                            >
                              <div className='h-auto w-full bg-gradient-to-t from-[#080607d5] to-[#08060700] absolute bottom-0'>
                                <h2 className='text-white text-6xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
                                  {project.make}
                                </h2>

                                <h2 className='text-xl font-[Montserrat] text-white font-extrabold p-3 line-clamp-2'>
                                  {project.name}
                                </h2>
                                <div className='opacity-0 relative'>
                                  Hello, I hope you're having a good day.
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                      .reverse()
                      .splice(0, 4)}
                  </div>
                </div>
              )}

              {getRelatedProjectsData?.findProjectsByTaggedAdvertiserId[0] && (
                <div>
                  <h2 className='text-lg font-bold mb-3'>Related projects</h2>
                  <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4'>
                    {getRelatedProjectsData?.findProjectsByTaggedAdvertiserId
                      ?.map((project: any) => {
                        return (
                          <div
                            key={project?._id}
                            className='relative xl:col-span-1 mb-1 grid grid-flow-row'
                          >
                            <Link
                              className='single-project transition-all h-48 bg-center bg-cover rounded-md text-[0pt] hover:text-[2pt] relative lg:bg-[length:100%] lg:hover:bg-[length:102%]'
                              style={{
                                backgroundImage: `url(${
                                  project?.images[0] !== '' ? project?.images[0] : TestImage
                                })`,
                              }}
                              to={`/project/${project?._id}`}
                            >
                              <div className='h-auto w-full bg-gradient-to-t from-[#080607d5] to-[#08060700] absolute bottom-0'>
                                <h2 className='text-white text-6xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
                                  {project?.make}
                                </h2>

                                <h2 className='text-xl font-[Montserrat] text-white font-extrabold p-3 line-clamp-2'>
                                  {project?.name}
                                </h2>
                                <div className='opacity-0 relative'>
                                  Hello, I hope you're having a good day.
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                      .reverse()
                      .splice(0, 4)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {messageUser && <NewMessagePopup userId={profileData?.userProfile?._id} />}
    </div>
  );
};

export default Profile;

const TabContent = ({
  data,
  tabSelected,
  isYourPost,
  feedData,
  profileData,
  productData,
}: {
  data: any;
  tabSelected: AdvertiserFeedSelection;
  isYourPost: boolean;
  feedData: any;
  profileData: any;
  productData: any;
}) => {
  const [productList, setProductList] = useState();

  useEffect(() => {
    setProductList(productData);
  }, [productData]);

  switch (tabSelected) {
    case AdvertiserFeedSelection.FEED:
      return <PostsFeed data={feedData} isYourPost={isYourPost} />;
    case AdvertiserFeedSelection.PRODUCT:
      return <Shop backgroundColour='#080607' deleteButton={false} productData={productList} />;
    case AdvertiserFeedSelection.VIDEO:
      return <VideoFeed profileData={profileData} isYourProfile={isYourPost} />;
    default:
      return <PostsFeed data={feedData} isYourPost={isYourPost} />;
  }
};

const PostsFeed = ({ data, isYourPost }: { data: any; isYourPost: boolean }) => {
  if (data && data.getFeeds.length > 0) {
    return (
      <div className='space-y-8'>
        {data &&
          data.getFeeds
            .map((post: any, index: any) => (
              <Post post={post} key={index} isYourPost={isYourPost} />
            ))
            .reverse()}
      </div>
    );
  } else {
    return <div className='my-8 text-center text-[#705F6E]'>No posts yet</div>;
  }
};

export const Post = ({ post, isYourPost, backgroundColour }: any) => {
  const [textContent, setTextContent] = useState<string>('');

  useEffect(() => {
    setTextContent(getHTMLFromDraftState(post.bodyContent));
  }, [post]);

  const [deletePost] = useMutation(DELETE_POST, {
    refetchQueries: [GET_POSTS, 'Query'],
  });

  const handleDeletePost = (data: any, id: any) => {
    if (data) {
      deletePost({
        variables: {
          feedId: id,
        },
      });
    }
  };

  return (
    <div className='border-[1px] border-black rounded-md'>
      <img
        className='h-full rounded-t-md w-full object-cover'
        src={post.feedImage}
        referrerPolicy='no-referrer'
        alt='Post'
      />
      <div
        className='w-full h-auto rounded-b-md relative transition-all duration-200 ease-in-out'
        style={{ backgroundColor: `${backgroundColour ? '#241b23' : '#080607'}` }}
      >
        <div className='p-4'>
          <div className='text-xl text-left font-bold'>{post.heading}</div>
          <div
            className='text-[10pt] font-light space-y-2 mt-2 max-h-[250px] overflow-y-scroll pr-2 mb-4'
            dangerouslySetInnerHTML={{ __html: textContent }}
          ></div>
          {post.tags ? (
            <ul className='flex flex-wrap'>
              {post.tags.map((tag: string) => {
                return (
                  <li
                    className='inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-[#352833] text-red text-[8pt] font-bold'
                    key={tag}
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          ) : (
            <></>
          )}
          {isYourPost && (
            <div className='bottom-0 right-0 absolute p-3'>
              <DeleteItemButton deleteFunction={(e: Event) => handleDeletePost(e, post._id)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export enum AdvertiserFeedSelection {
  FEED,
  VIDEO,
  PRODUCT,
}

const TabSelector = ({
  selected,
  handleSelection,
  handleMessageUserClick,
  profileData,
}: {
  selected: AdvertiserFeedSelection;
  handleSelection: Function;
  handleMessageUserClick: Function;
  profileData: any;
}) => {
  return (
    <>
      <div
        className={`grid mt-[80px] mb-10 gap-4 ${
          profileData?.userProfile?.userAccountType === 'club' ? 'grid-cols-2' : 'grid-cols-3'
        }`}
      >
        <div
          className={`transition-all duration-200 border-b-[2px] p-2 ${
            selected === AdvertiserFeedSelection.FEED ? 'border-red text-red' : 'border-[#110d11]'
          }`}
        >
          <div
            onClick={() => {
              handleSelection(AdvertiserFeedSelection.FEED);
            }}
            className={`cursor-pointer col-start-1 col-span-1 justify-center flex text-[10pt]`}
          >
            Posts
          </div>
        </div>

        <div
          className={`transition-all duration-200 border-b-[2px] p-2 ${
            selected === AdvertiserFeedSelection.VIDEO ? 'border-red text-red' : 'border-[#110d11]'
          }`}
        >
          <div
            onClick={() => {
              handleSelection(AdvertiserFeedSelection.VIDEO);
            }}
            className={`cursor-pointer col-span-1 justify-center flex text-[10pt]`}
          >
            Videos
          </div>
        </div>

        {profileData?.userProfile?.userAccountType !== 'club' && (
          <div
            className={`col-span-1 pt-2 transition-all duration-200 border-b-2 p-2 ${
              selected === AdvertiserFeedSelection.PRODUCT
                ? 'border-red text-red'
                : 'border-[#110d11]'
            }`}
          >
            <div
              onClick={() => {
                handleSelection(AdvertiserFeedSelection.PRODUCT);
              }}
              className={`cursor-pointer justify-center flex text-[10pt]`}
            >
              Products & Services
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function VideoFeed({ profileData, isYourProfile }: { profileData: any; isYourProfile: boolean }) {
  const { data: videoData2, refetch } = useQuery(GET_VIDEOS_BY_USER_ID, {
    variables: {
      userId: profileData?.userProfile?._id,
    },
  });

  return (
    <div className='w-full space-y-14'>
      {isYourProfile && <VideoForm refetchVideoData={refetch} />}

      {videoData2 && profileData && (
        <VideoSwiper
          videosData={videoData2.getVideosByUserId.filter((video: Video) =>
            video.videoUrl.includes('/shorts/'),
          )}
          isPlaying={false}
          swiperTitle={`${profileData?.userProfile?.username}'s Shorts`}
          viewAllActive={false}
          aspectRatio='aspect-[9/16]'
          slidesPerView={4}
        ></VideoSwiper>
      )}

      {videoData2 && profileData && (
        <VideoSwiper
          videosData={videoData2.getVideosByUserId.filter(
            (video: Video) => !video.videoUrl.includes('/shorts/'),
          )}
          isPlaying={false}
          swiperTitle={`${profileData?.userProfile?.username}'s Videos`}
          viewAllActive={false}
          aspectRatio='aspect-[16/9]'
          slidesPerView={3}
        ></VideoSwiper>
      )}
    </div>
  );
}

function VideoForm({ refetchVideoData }: { refetchVideoData: () => void }) {
  const [formOpen, setFormOpen] = useState<Boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');

  function handleRefetchVideoData() {
    refetchVideoData();
  }

  // const navigate = useNavigate();
  // const { userData } = useSelector((state: RootState) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [addVideoMutation, { data: addVideoData, loading: addVideoloading }] =
    useMutation(CREATE_VIDEO);

  const [videoTags, setVideoTags] = useState<string[]>();

  useEffect(() => {
    if (videoUrl.length > 1) {
      if (!videoUrl.includes('youtube.com') && !videoUrl.includes('facebook.com')) {
        setUrlError('You must use a supported video link');
      } else {
        setUrlError('');
      }
    } else {
      setUrlError('');
    }
  }, [videoUrl]);

  const handleAddVideo = async (inputs: any) => {
    const { videoUrl, caption } = inputs;

    if (
      !videoUrl.includes('youtube.com') &&
      !videoUrl.includes('facebook.com') &&
      videoUrl !== null
    ) {
      setUrlError('You must use a supported video link');
      return;
    }

    try {
      setUrlError('');

      const { data } = await addVideoMutation({
        variables: {
          input: {
            videoUrl,
            caption,
            tags: videoTags,
          },
        },
      });

      if (data) {
        // navigate(`/profile/${userData.username}`);
        handleRefetchVideoData();
        setFormOpen(false);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <div>
      {formOpen ? (
        <form onSubmit={handleSubmit(handleAddVideo)} className=''>
          <div className='p-6 border-[1px] border-[#523D4F] text-white rounded-md overflow-hidden relative'>
            <div
              className='absolute top-3 right-3 fill-white hover:fill-white/70 transition-all duration-150 cursor-pointer'
              onClick={() => setFormOpen(false)}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='10' viewBox='0 0 30.849 30.848'>
                <path
                  id='Icon_metro-cross'
                  data-name='Icon metro-cross'
                  d='M33.138,26.711h0l-9.358-9.358,9.358-9.358h0a.966.966,0,0,0,0-1.363L28.717,2.21a.967.967,0,0,0-1.363,0h0L18,11.568,8.637,2.21h0a.966.966,0,0,0-1.363,0L2.852,6.631a.966.966,0,0,0,0,1.363h0l9.358,9.358L2.852,26.711h0a.966.966,0,0,0,0,1.363l4.421,4.421a.966.966,0,0,0,1.363,0h0L18,23.136l9.358,9.358h0a.966.966,0,0,0,1.363,0l4.421-4.421a.966.966,0,0,0,0-1.363Z'
                  transform='translate(-2.571 -1.928)'
                />
              </svg>
            </div>

            <div className='col-span-3 grid grid-cols-5 gap-6'>
              <div className='col-start-1 col-span-2 space-y-6'>
                <h3 className='text-lg font-[Montserrat] italic font-bold'>+ Share a video</h3>
                <div>
                  <label htmlFor='videoUrl' className='block text-sm font-medium'>
                    Video link
                  </label>
                  <div className='mt-2'>
                    <input
                      id='videoUrl'
                      {...register('videoUrl', { required: 'Video URL required' })}
                      className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      placeholder='Enter video URL'
                      defaultValue={''}
                      onChange={(e) => {
                        setVideoUrl(e.target.value);
                      }}
                    />
                    {urlError && <div className='text-red mt-2'>{`${urlError}`}</div>}
                    {errors.videoUrl && (
                      <div className='text-red mt-2'>{`${
                        errors.videoUrl && errors.videoUrl.message
                      }`}</div>
                    )}
                  </div>
                </div>

                <div>
                  <label htmlFor='caption' className='block text-sm font-medium'>
                    Video caption
                  </label>
                  <div className='mt-2'>
                    <input
                      id='caption'
                      {...register('caption', { required: 'Caption required' })}
                      className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      placeholder='Enter video caption'
                      defaultValue={''}
                    />
                    {errors.caption && (
                      <div className='text-red mt-2'>{`${
                        errors.caption && errors.caption.message
                      }`}</div>
                    )}
                  </div>
                </div>

                <div className='relative pb-1'>
                  <label htmlFor='tags' className='block text-sm font-medium'>
                    Video tags
                  </label>
                  <div className='mt-2'>
                    <TagSearch tagType={MHPSearchTagType.Video} handleFormTags={setVideoTags} />
                  </div>
                </div>

                {addVideoloading ? (
                  <LoadingSpinner />
                ) : (
                  <button
                    type='submit'
                    className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                  >
                    Share video
                  </button>
                )}
              </div>
              <div className='col-span-3 flex items-center justify-center'>
                {videoUrl ? (
                  <div
                    className={`max-h-[400px] h-full rounded-md overflow-hidden ${
                      videoUrl.includes('/shorts/') ? 'aspect-[9/16]' : 'aspect-[16/9]'
                    }`}
                  >
                    <VideoPlayer videoUrl={videoUrl} isPlaying={false} />
                  </div>
                ) : (
                  <div className='bg-[#523D4F]/20 w-full h-full flex items-center justify-center rounded-md'>
                    <div className='text-[10pt] space-y-2 w-fit h-fit'>
                      <div>MHP currently supports:</div>
                      <ul className='list-disc ml-5'>
                        <li>Youtube Video links</li>
                        <li>Youtube Short links</li>
                        <li>Facebook Video links</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='px-4 py-3 text-right sm:px-6'></div>
        </form>
      ) : (
        <div
          className='p-4 border-[1px] border-[#523D4F] hover:bg-[#523D4F]/20 transition-all duration-150 ease-in-out border-dashed text-white rounded-md overflow-hidden cursor-pointer'
          onClick={() => setFormOpen(true)}
        >
          <h3 className='text-lg font-[Montserrat] italic font-bold w-full text-center'>
            + Share a video
          </h3>
        </div>
      )}
    </div>
  );
}

function VideoPlayer({ videoUrl, isPlaying }: { videoUrl: string; isPlaying: Boolean }) {
  const playerRef = useRef<ReactPlayer | null>(null);
  let playerProps: any = {};

  const handleVideoEnded = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      if (videoUrl.includes('youtube.com')) {
        playerRef.current.getInternalPlayer().playVideo();
      }
    }
  };

  if (videoUrl.includes('youtube.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      config: {
        youtube: {
          playerVars: { autoplay: isPlaying ? 1 : 0, loop: 1 },
        },
      },
    };
  } else if (videoUrl.includes('facebook.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      config: {
        facebook: {
          autoplay: isPlaying ? 1 : 0,
        },
      },
    };
  } else {
    return (
      <div className='bg-[#523D4F]/20 w-full h-full flex items-center justify-center rounded-md'>
        <div className='text-[10pt]'>Unsupported video link</div>
      </div>
    );
  }

  return (
    <ReactPlayer
      {...playerProps}
      onEnded={() => {
        return videoUrl.includes('/shorts/') ? handleVideoEnded() : null;
      }}
      ref={playerRef}
      width='100%'
      height='100%'
      controls
    />
  );
}
