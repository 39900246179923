import { GET_USER_PROJECTS } from '@/utils/queries/queries';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function EventProjectSearch({
  userId,
  onSelectedProjectsChange,
  initialSelectedProjects,
}: {
  userId: string;
  onSelectedProjectsChange: (selectedProjects: string[]) => void;
  initialSelectedProjects: string[] | undefined;
}) {
  const { data: projectData2 } = useQuery(GET_USER_PROJECTS, {
    variables: {
      userId: userId,
    },
  });

  const [projectData, setProjectData] = useState<any>();

  useEffect(() => {
    setProjectData(projectData2?.getProjects);
  }, [projectData2]);

  const [selectedProjects, setSelectedProjects] = useState<string[]>(initialSelectedProjects || []);

  function toggleProjectSelection(projectId: string) {
    if (selectedProjects.includes(projectId)) {
      setSelectedProjects(selectedProjects.filter((id) => id !== projectId));
    } else {
      setSelectedProjects([...selectedProjects, projectId]);
    }
  }

  useEffect(() => {
    onSelectedProjectsChange(selectedProjects);
  }, [selectedProjects]);

  return (
    <div className='space-y-4 relative grid grid-cols-10 bg-[#352833] px-4 pt-6 -pb-2 rounded-md'>
      <div className='col-start-1 col-span-full'>
        Select the car(s) you are taking to this event (optional)
      </div>
      <div className='col-start-10 col-span-1 absolute inset-0 bg-gradient-to-r from-transparent to-[#352833] select-none z-10'></div>
      <div className='col-start-1 col-span-full overflow-x-scroll flex space-x-4 scrollbar-thin scroll-mt-10'>
        {projectData2?.getProjects[0] ? (
          <>
            {projectData?.map((project: any) => {
              const isSelected = selectedProjects.includes(project._id);

              return (
                <div
                  key={project._id}
                  onClick={() => {
                    toggleProjectSelection(project._id);
                  }}
                >
                  <ProjectCard project={project} isSelected={isSelected} />
                </div>
              );
            })}
          </>
        ) : (
          <div>
            <Link
              to='/projects/add'
              className={`h-[120px] w-[120px] rounded-md mb-4 hover:bg-black2 transition-all duration-100 border-red border-[1px] select-none cursor-pointer justify-center text-center flex p-4`}
            >
              <div className='self-center font-[Montserrat]'>
                <div className='italic font-bold'>+ Add Car</div>
                <div className='text-xs font-light'>
                  Add your first car to list it on this event
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

function ProjectCard({ project, isSelected }: { project: any; isSelected: boolean }) {
  return (
    <div
      className={`h-[120px] w-[120px] rounded-md mb-4 bg-cover bg-center relative ${
        isSelected ? 'border-[2px] border-green-500' : ''
      }`}
      style={{
        backgroundImage: `url(${project.images[0]})`,
      }}
    >
      <div
        className={`font-[Montserrat] transition-all duration-100 col-start-10 col-span-1 absolute inset-0 backdrop-blur-sm hover:backdrop-blur-none ${
          isSelected ? 'bg-[#4CAF5080] hover:bg-[#4CAF5040]' : 'bg-[#00000080] hover:bg-[#00000040]'
        } rounded-md cursor-pointer select-none justify-center text-center flex`}
      >
        <div className='self-center'>
          <div className='italic font-bold line-clamp-1'>{project.name}</div>
          <div className='text-xs font-light'>{isSelected ? 'Bringing' : 'Click to add'}</div>
        </div>
      </div>
    </div>
  );
}
