import { RootState } from '@/store/reducers/rootReducer';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_USER_BY_ID, GET_ALL_EVENTS } from '@/utils/queries/queries';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LeftArrowCircle from '@/assets/icons/LeftArrowCircle';
import RightArrowCircle from '@/assets/icons/RightArrowCircle';
import { GatedLink } from '@/components/LoginModal';

SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay, A11y]);

interface EventDate {
  date: Date;
  startTime: string;
  endTime: string;
}

interface Destination {
  location: string;
  arrivalTime: string;
}

interface EventGroup {
  year: string;
  months: Month[];
}

interface Month {
  month: string;
  events: Event[];
}

interface Event {
  _id: string;
  name: string;
  location: string;
  eventImages: string[];
  eventType: string;
  eventDetails: string;
  dates: EventDate[];
  destinations: Destination[];
  createdBy: string;
  createdAt: Date;
  tags: string[];
}

const Events = () => {
  const { userData } = useSelector((state: RootState) => state.user);
  const { data: eventsData2, refetch } = useQuery(GET_ALL_EVENTS);

  useEffect(() => {
    refetch();
  }, [eventsData2]);

  const [eventsData, setEventsData] = useState<Event[]>();
  const [groupedEvents, setGroupedEvents] = useState<EventGroup[]>([]);

  useEffect(() => {
    setEventsData(eventsData2?.getAllEvents);
  }, [eventsData2]);

  const getMonthName = (monthNumeric: number) => {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return months[monthNumeric];
  };

  useEffect(() => {
    const groupEventsByMonth = () => {
      const grouped: { [year: string]: { [month: string]: any[] } } = {};

      eventsData?.forEach((event: Event) => {
        const date = new Date(event.dates[0].date);
        const monthNumeric = date.getMonth();
        const year = date.getFullYear().toString();

        if (!grouped[year]) {
          grouped[year] = {};
        }

        if (!grouped[year][monthNumeric]) {
          grouped[year][monthNumeric] = [];
        }

        grouped[year][monthNumeric].push(event);
      });

      for (const year in grouped) {
        for (const monthNumeric in grouped[year]) {
          grouped[year][monthNumeric].sort((a, b) => {
            const dateA = new Date(a.dates[0].date);
            const dateB = new Date(b.dates[0].date);
            return dateA.getTime() - dateB.getTime();
          });
        }
      }

      const result: any | { year: string; months: { month: string; events: Event[] }[] }[] =
        Object.entries(grouped).map(([year, months]) => ({
          year,
          months: Object.entries(months).map(([monthNumeric, events]) => ({
            month: getMonthName(Number(monthNumeric)),
            events,
          })),
        }));

      result.sort((a: EventGroup, b: EventGroup) => {
        return Number(a.year) - Number(b.year);
      });
      setGroupedEvents(result);
    };

    groupEventsByMonth();
  }, [eventsData]);

  return (
    <div className='mb-10'>
      <div className='text-white font-semibold items-center text-xl mt-1 flex mb-3'>
        <h1>Upcoming Events</h1>
        {userData.userAccountType !== 'standard' && (
          <GatedLink
            className='transition ease-in-out duration-200 bg-red hover:bg-[#A60626] my-2 py-2 px-4 ml-4 text-sm font-semibold rounded-full'
            to='/events/add'
          >
            + Create Event
          </GatedLink>
        )}
      </div>

      <div className='space-y-10'>
        {groupedEvents?.map((year: EventGroup, index: number) => {
          return (
            <div
              className='w-full border-l-[1px] border-[#705F6E] pl-4 sm:pl-6 space-y-4'
              key={year.year}
            >
              <div className='col-start-1 text-[#705F6E] text-sm'>{year.year}</div>
              <div className='col-start-1 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-10'>
                {index === 0 && (
                  <div className='md:col-span-full hidden md:block'>
                    <div className='transition-all duration-150 h-auto'>
                      <div className='mb-6 text-white text-4xl font-[Montserrat] font-light italic line-clamp-2 col-span-full'>
                        {groupedEvents[0].months[0].month}
                      </div>
                      <div className='h-[400px] grid grid-cols-6 md:grid-cols-5 gap-6'>
                        <EventCard
                          eventData={groupedEvents[0].months[0].events[0]}
                          key={groupedEvents[0].months[0].events[0]._id}
                          className='h-full col-span-3'
                        />
                        <div className='col-span-3 md:col-span-2 space-y-6 max-h-[400px]'>
                          {groupedEvents[0].months[0].events[1] && (
                            <EventCard
                              eventData={groupedEvents[0].months[0].events[1]}
                              key={groupedEvents[0].months[0].events[1]._id}
                              className='h-[188px]'
                            />
                          )}
                          {groupedEvents[0].months[0].events[2] && (
                            <EventCard
                              eventData={groupedEvents[0].months[0].events[2]}
                              key={groupedEvents[0].months[0].events[2]._id}
                              className='h-[188px]'
                            />
                          )}
                        </div>
                      </div>
                      <div className='mt-6 hidden md:block'>
                        {groupedEvents && (
                          <CustomSwiper events={groupedEvents[0].months[0].events.slice(3)} />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {index > 0 && (
                  <>
                    {year.months.map((month: Month) => {
                      return (
                        <div className='col-span-full' key={month.month}>
                          <div className='transition-all duration-150 h-auto'>
                            <div className='mb-6 text-white text-4xl font-[Montserrat] font-light italic line-clamp-2'>
                              {month.month}
                            </div>
                            <CustomSwiper events={month.events} />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
                {index === 0 && (
                  <div className='block md:hidden space-y-10 col-span-full'>
                    {year.months.map((month: Month) => {
                      return (
                        <div className='col-span-full' key={month.month}>
                          <div className='transition-all duration-150 h-auto'>
                            <div className='mb-6 text-white text-4xl font-[Montserrat] font-light italic line-clamp-2'>
                              {month.month}
                            </div>
                            <CustomSwiper events={month.events} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {index === 0 && (
                  <div className='hidden md:block col-span-full space-y-10'>
                    {year.months
                      .map((month: Month) => {
                        return (
                          <div className='col-span-full' key={month.month}>
                            <div className='transition-all duration-150 h-auto'>
                              <div className='mb-6 text-white text-4xl font-[Montserrat] font-light italic line-clamp-2'>
                                {month.month}
                              </div>
                              <CustomSwiper events={month.events} />
                            </div>
                          </div>
                        );
                      })
                      .splice(1)}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Events;

const EventCard = ({ eventData, className }: { eventData: Event; className?: string }) => {
  const { data: eventCreatorData2 } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: eventData?.createdBy,
    },
  });

  const [eventCreatorData, setEventCreatorDataLoaded] = useState<any>();

  useEffect(() => {
    setEventCreatorDataLoaded(eventCreatorData2?.getUserById);
  }, [eventCreatorData2]);

  return (
    <div className={`${className || 'h-full'} rounded-md`}>
      <Link to={`/events/${eventData._id}`}>
        <div
          className={`rounded-md bg-center bg-cover ${
            className || 'h-full'
          } relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100`}
          style={{
            backgroundImage: `url(${eventData.eventImages[0]})`,
          }}
        >
          <div
            className={`rounded-full m-4 py-1 px-2 w-auto uppercase text-[8pt] font-bold bg-opacity-70 absolute backdrop-blur-sm ${
              eventData.eventType === 'event'
                ? 'bg-red text-[#ffc1cd]'
                : 'bg-[#219181] text-[#c9fff7]'
            }`}
          >
            {`${eventData.eventType} on ${
              new Date(eventData.dates[0].date).toLocaleDateString() ===
              new Date().toLocaleDateString()
                ? 'today'
                : new Date(eventData.dates[0].date).toLocaleDateString()
            }`}
          </div>

          <div className='h-auto w-full p-4 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
            <h2 className='text-white text-2xl font-[Montserrat] font-extrabold italic line-clamp-2'>
              {eventData.name}
            </h2>
            <div className='w-full flex space-x-2'>
              <div className='h-[10px] fill-white'>
                <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 21 30'>
                  <path
                    d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
                    transform='translate(-7.5 -3)'
                  />
                </svg>
              </div>

              <h3 className='text-white text-[10pt] font-light line-clamp-2'>
                {eventData.eventType === 'event'
                  ? eventData.location
                  : `Starts at: ${eventData.destinations[0].location} at ${eventData.destinations[0].arrivalTime}`}
              </h3>
            </div>

            <span
              className={`font-black uppercase ${
                eventData.eventType === 'event' ? 'text-red' : 'text-blue'
              }`}
            >
              Event by{' '}
              {eventCreatorData?.advertiserDetails?.companyName || eventCreatorData?.username}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
};

function EventSlide({ eventData }: { eventData: Event }) {
  const { data: eventCreatorData2 } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: eventData?.createdBy,
    },
  });

  const [eventCreatorData, setEventCreatorDataLoaded] = useState<any>();

  useEffect(() => {
    setEventCreatorDataLoaded(eventCreatorData2?.getUserById);
  }, [eventCreatorData2]);
  return (
    <Link to={`/events/${eventData._id}`} className='w-full group'>
      <div
        className='h-[150px] w-full bg-center bg-cover rounded-md mb-2'
        style={{ backgroundImage: `url(${eventData.eventImages[0]})` }}
      ></div>
      <div className='absolute top-0 h-[150px] w-full rounded-md group-hover:bg-white/10 transition-all ease-in-out duration-100'></div>

      <h2 className='group-hover:underline font-[Montserrat] text-lg font-extrabold italic'>
        {eventData.name}
      </h2>

      <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 36 36'>
            <path d='M18,20.25A10.125,10.125,0,1,0,7.875,10.125,10.128,10.128,0,0,0,18,20.25Zm9,2.25H23.126a12.24,12.24,0,0,1-10.252,0H9a9,9,0,0,0-9,9v1.125A3.376,3.376,0,0,0,3.375,36h29.25A3.376,3.376,0,0,0,36,32.625V31.5A9,9,0,0,0,27,22.5Z' />
          </svg>
        </div>

        <div className={`mt-[1px] text-white text-[10pt] font-light`}>
          Event by {eventCreatorData?.advertiserDetails?.companyName || eventCreatorData?.username}
        </div>
      </div>

      <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 21 30'>
            <path
              d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
              transform='translate(-7.5 -3)'
            />
          </svg>
        </div>

        <h3 className='text-white text-[10pt] font-light line-clamp-2'>
          {eventData.eventType === 'event'
            ? eventData.location
            : `Starts at: ${eventData.destinations[0].location} at ${eventData.destinations[0].arrivalTime}`}
        </h3>
      </div>

      <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 31.5 36'>
            <path
              id='Icon_awesome-calendar-day'
              data-name='Icon awesome-calendar-day'
              d='M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0Zm4.5-13.5A1.128,1.128,0,0,1,5.625,18h6.75A1.128,1.128,0,0,1,13.5,19.125v6.75A1.128,1.128,0,0,1,12.375,27H5.625A1.128,1.128,0,0,1,4.5,25.875ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z'
            />
          </svg>
        </div>

        <h3 className='text-white text-[10pt] font-light line-clamp-2 mt-[1px]'>
          {new Date(eventData.dates[0].date).toDateString()}
        </h3>
      </div>

      {eventData.tags && (
        <ul className='flex flex-wrap mt-3'>
          {eventData.tags
            .map((tag: string) => {
              return (
                <li
                  className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'
                  key={tag}
                >
                  {tag}
                </li>
              );
            })
            .splice(0, 2)}
          {eventData.tags.length > 2 && (
            <li className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'>
              + {eventData.tags.length - 2} more
            </li>
          )}
        </ul>
      )}
    </Link>
  );
}

function CustomSwiper({ events }: { events: Event[] }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(1);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex);
    setCurrentSlidesPerView(swiper.params.slidesPerView); // Update currentSlidesPerView
  };

  return (
    <Swiper
      className='space-y-4 relative'
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      onSlideChange={handleSlideChange}
      autoplay
      breakpoints={{
        500: {
          spaceBetween: 24,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 24,
          slidesPerView: 3,
        },
        1400: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
      }}
    >
      {events.map((event: Event) => {
        return (
          <SwiperSlide>
            <EventSlide eventData={event} key={event._id} />
          </SwiperSlide>
        );
      })}
      <div className='w-full flex justify-between'>
        <SwiperButtonPrevious currentSlideIndex={currentSlideIndex} className='z-10'>
          <LeftArrowCircle width='22px' height='22px' />
        </SwiperButtonPrevious>
        <SwiperButtonNext
          eventArrayLength={events.length}
          currentSlideIndex={currentSlideIndex}
          slidesPerView={currentSlidesPerView}
          className='z-10'
        >
          <RightArrowCircle width='22px' height='22px' />
        </SwiperButtonNext>
      </div>
    </Swiper>
  );
}

function SwiperButtonPrevious({
  children,
  className,
  currentSlideIndex,
}: {
  children?: React.ReactNode;
  className?: string;
  currentSlideIndex: number;
}) {
  const swiper = useSwiper();

  return (
    <>
      {currentSlideIndex < 1 ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}

function SwiperButtonNext({
  children,
  eventArrayLength,
  className,
  currentSlideIndex,
  slidesPerView,
}: {
  children?: React.ReactNode;
  eventArrayLength: number;
  className?: string;
  currentSlideIndex: number;
  slidesPerView: number;
}) {
  const swiper = useSwiper();
  const [isSlideEnd, setIsSlideEnd] = useState<Boolean>();
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setIsSlideEnd(swiper.isEnd);
    setMaxIndex(eventArrayLength - slidesPerView);
  }, [swiper, eventArrayLength, slidesPerView, currentSlideIndex]);

  return (
    <>
      {isSlideEnd || currentSlideIndex >= maxIndex ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}
