import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import TestImage from '@/assets/images/no_image_found.png';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { PopupModalActionTypes } from '@/utils/types';
import Shop from './Shop';

export const GET_ALL_PRODUCTS = gql`
  query UserProfile($userId: ID) {
    getProducts(userId: $userId) {
      _id
      createdBy
      title
      price
      productImageURL
      comparePrice
      bodyContent
      type
      vendor
      categories
      subcategories
      tags
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProductMutation($deleteProductId: ID) {
    deleteProduct(productId: $deleteProductId)
  }
`;

const Products = () => {
  // const { userData } = useSelector((state: RootState) => state.user);

  const { data: productData, error, loading, refetch } = useQuery(GET_ALL_PRODUCTS);

  const handleRefetch = () => {
    refetch();
  };

  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    refetchQueries: [GET_ALL_PRODUCTS, 'Query'],
  });

  const handleDeleteProduct = (data: any, _id: any) => {
    if (data) {
      deleteProduct({
        variables: {
          deleteProductId: _id,
        },
      });
    }
  };

  useEffect(() => {
    handleRefetch();
  }, [productData]);

  return (
    <>
      <div className='text-white font-semibold items-center text-xl mt-1 flex mb-3'>
        <h1>My Products</h1>
        <Link
          className='transition ease-in-out duration-200 bg-red hover:bg-[#A60626] my-2 py-2 px-4 ml-4 text-sm font-semibold rounded-full'
          to='/products/add'
        >
          Add Product
        </Link>
      </div>
      <Shop
        productData={productData?.getProducts}
        backgroundColour='#241B23'
        deleteButton={true}
        handleDeleteProduct={handleDeleteProduct}
      />

      {/* {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='grid grid-cols-12'>
          <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
            <div className='flex flex-row text-white font-semibold items-center text-xl justify-between'>
              <h1>Products</h1>
              <Link
                className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
                to='/products/add'
              >
                Add Product
              </Link>
            </div>
            {data &&
              data.getProducts.map((product: any) => (
                <div key={product._id} className='grid grid-cols-12 lg:h-auto mt-8 relative'>
                  <div
                    className='h-64 md:h-full bg-red col-span-12 md:col-span-6 w-auto left-0 bg-cover lg:bg-center rounded-b-none rounded-t-lg md:w-full md:rounded-l-lg md:rounded-r-none'
                    style={{
                      backgroundImage: `url(${
                        product?.productImageURL && product?.productImageURL !== ''
                          ? product.productImageURL
                          : TestImage
                      })`,
                    }}
                  />
                  <div className='col-span-12 md:col-span-6 right-0 bg-white rounded-t-none rounded-b-lg p-8 md:p-16 md:w-auto md:rounded-l-none md:rounded-r-lg'>
                    <h3 className='mb-4 text-black text-xl text-center font-semibold'>
                      {product.title}
                    </h3>
                    <p className='mb-4 text-black text-center'>Price: ${product.price}</p>
                    <div className='grid grid-cols-12 mt-12'>
                      <Link className='col-span-12' to={`/products/${product._id}`}>
                        <button className='w-full text-center my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white transition ease-in-out duration-300 bg-red hover:bg-darkgrey'>
                          View
                        </button>
                      </Link>
                      <button
                        className='delete-project-btn col-span-12 my-2 bg-red px-1 py-2 rounded-lg font-semibold text-lg uppercase text-white text-center transition ease-in-out duration-300 bg-red hover:bg-darkgrey'
                        onClick={(e) => handleDeleteProduct(e, product._id)}
                      >
                        Delete
                      </button>
                      {popupActive && (
                        <ConfirmPopup
                          heading='Delete product?'
                          content='Are you sure you want to delete this product? This action cannot be undone.'
                          actionType={PopupModalActionTypes.DELETE}
                          onPopupStatusChange={(data: any) =>
                            handlePopupStatusChange(data, product._id)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )} */}
    </>
  );
};

export default Products;
