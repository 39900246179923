import { LoginForm } from '@/pages/auth/Login';
import { ReactNode, createContext, useContext, useEffect, useRef } from 'react';
import { Link, LinkProps, NavLink, NavLinkProps, useSearchParams } from 'react-router-dom';

const ShowLoginModalContext = createContext<(() => void) | undefined>(undefined);

export const REDIRECT_TO_LOGIN_HREF = '/?login_required';

export function useShowLogin(): {
  requiresLogin: boolean;
  showLogin: () => void;
} {
  const showLogin = useContext(ShowLoginModalContext);

  return {
    requiresLogin: showLogin !== undefined,
    showLogin: showLogin ?? (() => {}),
  };
}

/* GatedLink, GatedNavLink, GatedAnchor and GatedButton replace <Link>, <NavLink>, <a> and <button> respectively */
export function GatedLink(props: LinkProps) {
  const { requiresLogin, showLogin } = useShowLogin();

  if (requiresLogin)
    return (
      <a
        {...{
          ...props,
          to: undefined,
          className: `cursor-pointer ${props.className ?? ''}`,
        }}
        onClick={showLogin}
      />
    );

  return <Link {...props} />;
}

export function GatedNavLink(props: NavLinkProps) {
  const { requiresLogin, showLogin } = useShowLogin();

  if (requiresLogin)
    return (
      <NavLink
        {...props}
        onClick={(e) => {
          e.preventDefault();
          showLogin();
        }}
      />
    );

  return <NavLink {...props} />;
}

export function GatedAnchor(
  props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
) {
  const { requiresLogin, showLogin } = useShowLogin();

  if (requiresLogin)
    return (
      <a
        {...{
          ...props,
          href: undefined,
          className: `cursor-pointer ${props.className}`,
        }}
        onClick={showLogin}
      />
    );

  return <a {...props} />;
}

export function GatedButton(
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) {
  const { requiresLogin, showLogin } = useShowLogin();

  if (requiresLogin)
    return (
      <button
        {...{
          ...props,
        }}
        onClick={showLogin}
      />
    );

  return <button {...props} />;
}

export default function LoginModal({ children }: { children: ReactNode }) {
  const dialogRef = useRef<HTMLDialogElement>(null);

  // const [params] = useSearchParams();

  // const showModalImmediately = params.get('login_required') !== null;

  // useEffect(() => {
  //   if (showModalImmediately) {
  //     dialogRef.current!.showModal();
  //   }
  // }, [showModalImmediately]);

  return (
    <>
      <ShowLoginModalContext.Provider
        value={() => {
          dialogRef.current!.showModal();
        }}
      >
        {children}
      </ShowLoginModalContext.Provider>

      <dialog ref={dialogRef}>
        <div
          className='
          fixed top-0 left-0
          w-full h-full flex items-center justify-center
          max-h-none max-w-none m-0 overflow-hidden
          bg-opacity-70 bg-black backdrop-blur-md
        '
        >
          <div>
            <form
              method='dialog'
              className='lg:w-full lg:flex lg:justify-end lg:relative lg:top-0 lg:right-0 absolute top-8 right-8 z-20'
            >
              <button>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='14'
                  height='14'
                  viewBox='0 0 30.849 30.849'
                >
                  <path
                    id='Icon_metro-cross'
                    data-name='Icon metro-cross'
                    d='M33.138,26.711h0l-9.358-9.358,9.358-9.358h0a.966.966,0,0,0,0-1.363L28.717,2.21a.967.967,0,0,0-1.363,0h0L18,11.568,8.637,2.21h0a.966.966,0,0,0-1.363,0L2.852,6.631a.966.966,0,0,0,0,1.363h0l9.358,9.358L2.852,26.711h0a.966.966,0,0,0,0,1.363l4.421,4.421a.966.966,0,0,0,1.363,0h0L18,23.136l9.358,9.358h0a.966.966,0,0,0,1.363,0l4.421-4.421a.966.966,0,0,0,0-1.363Z'
                    transform='translate(-2.571 -1.928)'
                    fill='#fff'
                  />
                </svg>
              </button>
            </form>
            <LoginForm
              setPolicyPopOutOpen={() => {}}
              setTOSPopOutOpen={() => {}}
              onLogin={() => {
                window.location.href = '/'; /* force refresh */
              }}
            />
          </div>

          <form method='dialog' className='fixed w-full h-full z-[-1]'>
            {/* for dismissing the modal on click outside */}
            <button className='w-full h-full cursor-unset'></button>
          </form>
        </div>
      </dialog>
    </>
  );
}
