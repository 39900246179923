import { useState } from 'react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import TagSearch from '../utils/TagSearch';
import WYSIWYGEditor from '../utils/WYSIWYGEditor';
import { CREATE_POST } from '@/utils/queries/queries';

export enum MHPSearchTagType {
  Article = 'article',
  Project = 'project',
  Product = 'product',
}

const EditPostForm = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [postMainImage, setPostMainImage] = useState('');
  const [imagePreview, setImagePreview] = useState<any>();
  const [addPostMutation, { data: addProductData, loading: addProductloading }] =
    useMutation(CREATE_POST);
  const [postMainImageError, setPostMainImageError] = useState(false);
  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  const [postTags, setPostTags] = useState<string[]>();
  const [descriptionContent, setDescriptionContent] = useState<string>();

  const handleWYSIWYGFormStateUpdate = (convertedWYSIWYGContent: string) => {
    const newDescriptionContent = convertedWYSIWYGContent;
    setDescriptionContent(newDescriptionContent);
  };

  const [popOverStateManager, setPopOverStateManager] = useState<any>({
    tags: {
      values: {
        activeSearchValue: '',
        searchResults: [],
        selectedTags: [],
      },
      properties: {
        isActive: false,
      },
    },
  });

  const handleImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setImagePreview(reader.result);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          setPostMainImage(data?.data?.uploadPhoto);
          setPostMainImageError(false);
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleAddPost = async (inputs: any) => {
    if (postMainImage === '' || postMainImage === null) {
      setPostMainImageError(true);
      return;
    }
    setFormError('');
    const { heading } = inputs;
    try {
      const { data } = await addPostMutation({
        variables: {
          input: {
            heading,
            bodyContent: descriptionContent,
            // tags: popOverStateManager.tags.values.selectedTags,
            tags: postTags,
            feedImage: postMainImage,
          },
        },
      });

      if (data) {
        navigate(`/profile/${userData.username}`);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  // const handleTagSearch = (e: any) => {
  //   if (e.target.value.length >= 1) {
  //     setPopOverStateManager({
  //       ...popOverStateManager,
  //       tags: {
  //         ...popOverStateManager.tags,
  //         values: {
  //           ...popOverStateManager.tags.values,
  //           activeSearchValue: e.target.value,
  //         },
  //         properties: {
  //           isActive: true,
  //         },
  //       },
  //     });
  //   } else {
  //     setPopOverStateManager({
  //       ...popOverStateManager,
  //       tags: {
  //         ...popOverStateManager.tags,
  //         values: {
  //           ...popOverStateManager.tags.values,
  //           activeSearchValue: e.target.value,
  //         },
  //         properties: {
  //           isActive: false,
  //         },
  //       },
  //     });
  //   }
  // };

  // const addProductTag = () => {
  //   setPopOverStateManager({
  //     ...popOverStateManager,
  //     tags: {
  //       ...popOverStateManager.tags,
  //       values: {
  //         ...popOverStateManager.tags.values,
  //         selectedTags: [
  //           ...popOverStateManager.tags.values.selectedTags,
  //           popOverStateManager.tags.values.activeSearchValue.trim(),
  //         ],
  //         activeSearchValue: '',
  //       },
  //       properties: {
  //         isActive: false,
  //       },
  //     },
  //   });
  // };

  // const handleRemoveTag = (tag: string) => {
  //   setPopOverStateManager({
  //     ...popOverStateManager,
  //     tags: {
  //       ...popOverStateManager.tags,
  //       values: {
  //         ...popOverStateManager.tags.values,
  //         selectedTags: popOverStateManager.tags.values.selectedTags.filter(
  //           (selectedTag: string) => selectedTag !== tag,
  //         ),
  //       },
  //       properties: {
  //         isActive: false,
  //       },
  //     },
  //   });
  // };

  return (
    <form onSubmit={handleSubmit(handleAddPost)} className=''>
      <div className='sm:rounded-md sm:overflow-hidden'>
        <div className='p-6 bg-white'>
          <div className='col-span-3 grid grid-cols-1 gap-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>Post photo</label>
              <div
                className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
                  postMainImage === '' ? 'px-6 pt-5 pb-6' : ''
                }`}
              >
                <div className='space-y-1 text-center'>
                  {uploadPhotoLoading ? (
                    <LoadingSpinner />
                  ) : postMainImage === '' ? (
                    <>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='flex text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <span>Upload a file</span>
                          <input
                            id='file-upload'
                            name='file-upload'
                            type='file'
                            onChange={(event) => {
                              handleImageUpload(event);
                            }}
                            className='sr-only'
                          />
                        </label>
                        <p className='pl-1'>or drag and drop</p>
                      </div>
                      <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                    </>
                  ) : (
                    <div className='h-full w-full max-h-48 relative'>
                      <img
                        className='object-cover h-full w-full'
                        src={imagePreview}
                        alt='ProfileImage'
                      />
                    </div>
                  )}
                </div>
              </div>
              {postMainImageError ? (
                <span className='text-red'>Product cover image required</span>
              ) : null}
            </div>
            <div>
              <label htmlFor='heading' className='block text-sm font-medium text-gray-700'>
                Post title
              </label>
              <div className='mt-1'>
                <input
                  id='heading'
                  {...register('heading', { required: 'post title required' })}
                  className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                  placeholder='Enter post heading'
                  defaultValue={''}
                />
                {errors.heading !== undefined && (
                  <span className='text-red'>{`${errors.heading && errors.heading.message}`}</span>
                )}
              </div>
            </div>
            <div>
              <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
                Post detail
              </label>
              <div className='mt-1'>
                <div className='col-span-full'>
                  <WYSIWYGEditor
                    formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
                    formLabel={'Content/Description'}
                    contentSectionCount={0}
                  />
                </div>
              </div>
            </div>

            <div className='relative pb-1'>
              <label htmlFor='tags' className='block text-sm font-medium text-gray-700'>
                Post Tags
              </label>
              <div className='mt-1'>
                <TagSearch tagType={MHPSearchTagType.Product} handleFormTags={setPostTags} />
                {/* <input
                  id='tags'
                  onFocus={handleTagSearch}
                  onChange={handleTagSearch}
                  className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                  placeholder=''
                  value={popOverStateManager.tags.values.activeSearchValue}
                /> */}
              </div>
              {/* {popOverStateManager.tags.values.selectedTags && (
                <ul>
                  {popOverStateManager.tags.values.selectedTags.map((tag: any, index: any) => (
                    <div
                      className='bg-indigo-100 inline-flex items-center text-xs rounded mt-2 mr-1 p-0.5 text-black'
                      key={index}
                    >
                      <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs' key={index}>
                        {tag}
                      </span>
                      <span onClick={() => handleRemoveTag(tag)} className='ml-0.5 cursor-pointer'>
                        <XIcon className='h-3 w-3 text-red' aria-hidden='true' />
                      </span>
                    </div>
                  ))}
                </ul>
              )}
              {popOverStateManager.tags.properties.isActive && (
                <div className='absolute left-0 top-16 bg-white w-full shadow-sm rounded-md border border-gray-300'>
                  <div
                    className='flex items-center text-black p-2 hover:bg-gray-100 cursor-pointer'
                    onClick={addProductTag}
                  >
                    <PlusCircleIcon
                      className='h-5 w-5 flex-shrink-0 text-gray-400'
                      aria-hidden='true'
                    />
                    <span className='ml-2 w-0 flex-1 text-sm'>
                      <span className='font-bold'>Add</span>{' '}
                      {popOverStateManager.tags.values.activeSearchValue}
                    </span>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
          {addProductloading ? (
            <LoadingSpinner />
          ) : (
            <button
              type='submit'
              className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            >
              Create post
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditPostForm;
