import { Navigate } from 'react-router-dom'
import useIsAuthenticated from '@/utils/useIsAuthenticated';
import { REDIRECT_TO_LOGIN_HREF } from '@/components/LoginModal';

const PrivateRoute = ({ children }: any) => {
  const { loading, isAuthenticated } = useIsAuthenticated()

  if(!loading) {
    /* replace=true so guest users can use the "back" button without immediately re-triggering the /login navigation */
    return isAuthenticated ? children : <Navigate to={REDIRECT_TO_LOGIN_HREF} replace={true} />;
  } else {
    return null
  }
};

export default PrivateRoute;