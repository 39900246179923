import { useNavigate } from 'react-router-dom';

import EditProductForm from '@/components/products/EditProductForm';

const EditProduct = () => {
  const navigate = useNavigate();

  return (
    <div className='grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-4 mt-3'>
          <h1>Update Product or Service</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-full'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <EditProductForm />
      </div>
    </div>
  );
};

export default EditProduct;
