import copy from 'clipboard-copy';
import { useState } from 'react';

const CopyURLButton = ({
  urlToCopy,
  buttonColour,
}: {
  urlToCopy: string;
  buttonColour?: string;
}) => {
  const [buttonText, setButtonText] = useState<Boolean>();
  const handleCopyClick = () => {
    copy(urlToCopy);
    setButtonText(true);
  };

  return (
    <button
      className={`transition ease-in-out duration-100 py-2 px-4 text-sm font-semibold rounded-full ${buttonColour}`}
      onClick={handleCopyClick}
    >
      {buttonText ? 'Copied!' : 'Copy Share URL'}
    </button>
  );
};

export default CopyURLButton;
