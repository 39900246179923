import { gql } from '@apollo/client';

export const GET_CHAT_INFO = gql`
  query ChatInfoQuery($chatId: ID) {
    getUserChatInfo(chatId: $chatId) {
      chatSlug
      chatName
      users {
        profilePicture
        _id
      }
      isChatEnquiry
      advertiserResponsed
      createdAt
      enquiryMessage {
        topic
        location
        phone
        description
      }
    }
  }
`;

export const GET_CHAT_MESSAGES = gql`
  query GetChatMessages($chatId: ID) {
    getChatMessages(chatId: $chatId) {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;
