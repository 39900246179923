import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import { Link } from 'react-router-dom';
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

const GET_ALL_ARTICLES = gql`
  query GetArticles {
    getAllArticles {
      createdAt
      heading
      subHeading
      images {
        index
        caption
        url
        projects {
          name
          id
        }
      }
      articleTemplate
    }
  }
`;

const GET_ALL_ADVERTISERS = gql`
  query GetAllAdvertisers {
    getAllAdvertisers {
      _id
      advertiserDetails {
        companyLogo
        coverPhoto
      }
    }
  }
`;

const GET_ALL_PROJECTS = gql`
  query GetAllProjects {
    getAllProjects {
      _id
      name
      make
      images
    }
  }
`;

export default function LandingPage() {
  const { data: articlesData } = useQuery(GET_ALL_ARTICLES);

  const { data: advertisersData } = useQuery(GET_ALL_ADVERTISERS);

  const { data: projectsData } = useQuery(GET_ALL_PROJECTS);

  const [pageSelector, setPageSelector] = useState<number>(0);

  const [shuffledArticles, setShuffledArticles] = useState([]);
  const [shuffledProjects, setShuffledProjects] = useState([]);
  const [shuffledSuppliers, setShuffledSuppliers] = useState([]);

  useEffect(() => {
    if (articlesData?.getAllArticles) {
      setShuffledArticles(articlesData?.getAllArticles);
    }
    if (projectsData?.getAllProjects) {
      setShuffledProjects(projectsData?.getAllProjects);
    }
    if (advertisersData?.getAllAdvertisers) {
      setShuffledSuppliers(advertisersData?.getAllAdvertisers);
    }
  }, [articlesData, projectsData, advertisersData]);

  const shuffleArticles = () => {
    setShuffledArticles((prevArticles) => {
      const shuffled = [...prevArticles];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    });
  };

  const shuffleProjects = () => {
    setShuffledProjects((prevProjects) => {
      const shuffled = [...prevProjects];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    });
  };

  const shuffleSuppliers = () => {
    setShuffledSuppliers((prevSuppliers) => {
      const shuffled = [...prevSuppliers];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      shuffleArticles();
      shuffleSuppliers();
      shuffleProjects();
    }, 8000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const pageInfo = [
    {
      heading: 'My Hi Performance',
      subheading: [
        'Discover a groundbreaking platform that unites car enthusiasts, car features, technical articles, event coverage and leading automotive businesses - all on one interactive platform.',
      ],
      listContent: [],
    },
    {
      heading: 'What we do',
      subheading: ['A dynamic and interactive new experience for car enthusiasts.'],
      listContent: [
        {
          head: 'Create Your Own Personal Car Projects',
          body: 'Sign up for free and showcase your cars or car projects. Share photos, specifications, and background information with fellow enthusiasts.',
        },
        {
          head: 'Stay Informed',
          body: 'View bespoke featured articles and relevant events that pique your interest.',
        },
        {
          head: 'Connect with Suppliers and Clubs',
          body: 'Link to your favourite Suppliers or Car Club, fostering connections within the automotive world.',
        },
        {
          head: 'Effortless Exploration',
          body: 'Explore the MHP Quick Enquiry feature, enabling you to contact suppliers and find everything your car needs with a single click.',
        },
        {
          head: 'Seamless Interaction',
          body: 'Engage in direct conversations with other Users and Supplier Companies through your dedicated Message Inbox.',
        },
      ],
    },
    {
      heading: 'Projects',
      subheading: [
        'Elevate your automotive experience.',
        'Sign up for free and create dedicated pages for each of your cars or unfinished projects.',
        'Showcase them with photos, specs, background info and even link to Suppliers that have helped you along the way.',
      ],
      listContent: [],
    },
    {
      heading: 'Articles',
      subheading: ['Tap into the best articles in the performance car industry.'],
      listContent: [
        {
          head: 'Car Features',
          body: 'Check out the latest cars from around the country in our Car Feature articles. The best cars to admire and take inspiration from.',
        },
        {
          head: 'Technical Articles',
          body: 'Tap into indepth technical articles that cover every aspect of performance car building - giving you the information to do it right the first time with proven combinations from industry professionals.',
        },
        {
          head: 'Event Coverage',
          body: 'Keep your finger on the pulse of the latest events right across Australia from burnout competitions, drag racing meeting, car shows and club runs.',
        },
      ],
    },
    {
      heading: 'Suppliers',
      subheading: [
        'MHP is a brand new way to do business in the performance automotive industry.',
        'From engine building to dyno tuning, custom wheels and tyres to show winning paint and panel - join our industry leaders and grow your business.',
        'Join our platform as a Supplier and receive your own dedicated page to create posts on your work in progress, promote specials or just let everyone know what is happening at your business.',
      ],
      listContent: [
        {
          head: 'MHP Quick Enquiry Feature',
          body: 'The MHP Quick Enquiry feature enables our Users to find your products and services with a single click.',
        },
        {
          head: 'Create Catalogues',
          body: "Create a detailed catalogue of your company's products and services.",
        },
        {
          head: 'Create Projects',
          body: 'Built your own company race car? Create a Project page for it to show your workmanship.',
        },
        {
          head: 'Connect with customers',
          body: 'We allow Users to link your Supplier page to their Projects. Create recognition and goodwill.',
        },
        {
          head: 'Featured Articles',
          body: 'We can link your Supplier page to relevant car features, technical articles or event coverage for maximum exposure.',
        },
      ],
    },
  ];

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // ReactPlayer re-scale when window size changes
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='absolute p-[40px] inset-0'>
      <div
        className='absolute inset-0 bg-cover bg-center'
        // style={{
        //   backgroundImage: `url(https://res.cloudinary.com/my-hi-performance/image/upload/v1692068337/mhp_media/649bdfe5c229ff822cb6776f/mm7stwtyhhzxlhyv9l8k.jpg)`,
        // }}
      ></div>

      <div className='absolute inset-0 -ml-[300px]'>
        <ReactPlayer
          url='https://www.youtube.com/watch?v=dDJ-eRcICZc&ab_channel=IgnitionSourceTv'
          playing={true}
          muted={true}
          loop={true}
          controls={false}
          width={windowDimensions.width}
          height={windowDimensions.height}
        />
      </div>

      <div className='absolute inset-0 bg-black2 mix-blend-color'></div>
      <div className='absolute inset-0 bg-black2 mix-blend-hard-light'></div>
      <div className='absolute inset-0 bg-black2 opacity-50'></div>

      <div className='absolute inset-0 p-[60px]'>
        <div
          className='transition-all absolute left-[235px] w-[180px] h-[1px] mt-7 bg-red'
          style={{
            left: `${180 * pageSelector + 60}px`,
          }}
        ></div>
        <div className='flex uppercase font-[Montserrat] transition-all'>
          <div
            className={`transition-all select-none cursor-pointer w-[180px] text-center ${
              pageSelector === 0 ? 'text-red' : ''
            }`}
            onClick={() => setPageSelector(0)}
          >
            MHP
          </div>
          <div
            className={`transition-all select-none cursor-pointer w-[180px] text-center ${
              pageSelector === 1 ? 'text-red' : ''
            }`}
            onClick={() => setPageSelector(1)}
          >
            What we do
          </div>
          <div
            className={`transition-all select-none cursor-pointer w-[180px] text-center ${
              pageSelector === 2 ? 'text-red' : ''
            }`}
            onClick={() => setPageSelector(2)}
          >
            Projects
          </div>
          <div
            className={`transition-all select-none cursor-pointer w-[180px] text-center ${
              pageSelector === 3 ? 'text-red' : ''
            }`}
            onClick={() => setPageSelector(3)}
          >
            Articles
          </div>
          <div
            className={`transition-all select-none cursor-pointer w-[180px] text-center ${
              pageSelector === 4 ? 'text-red' : ''
            }`}
            onClick={() => setPageSelector(4)}
          >
            Suppliers
          </div>
        </div>

        <div className='mt-4 select-none text-9xl uppercase font-[Montserrat] font-light text-black/30 italic -mb-16'>
          /0{pageSelector + 1}
        </div>
        <div className='text-6xl select-none uppercase font-[Montserrat] font-black italic'>
          {pageInfo[pageSelector].heading}
        </div>

        <div className='grid grid-cols-6 3xl:grid-cols-9 gap-4 text-xs select-none font-light mt-4'>
          <div
            className={`text-lg col-span-5 3xl:grid-cols-3 select-none font-[Montserrat] font-light space-y-3`}
          >
            {pageInfo[pageSelector].subheading.map((text) => {
              return <div>{text}</div>;
            })}
          </div>
          {pageSelector === 4 && (
            <a
              href='mailto:sendit@myhiperformance.com'
              target='_blank'
              rel='noreferrer'
              className='p-4 bg-black/30 hover:bg-black backdrop-blur-md rounded-md h-full cursor-pointer flex items-center text-center justify-center border-red border-[1px] transition-all duration-100'
            >
              <div className='space-y-2'>
                <div className='font-black italic text-xl font-[Montserrat] text-red uppercase'>
                  Become a Supplier
                </div>
                <div>Click here to contact us</div>
                <div style={{ fill: 'white' }} className='h-10 w-10 -right-4 -bottom-4 absolute'>
                  <svg id='a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.03 32.08'>
                    <defs>
                      <clipPath id='b'>
                        <rect x='0' y='0' width='31.03' height='32.08' />
                      </clipPath>
                    </defs>
                    <path d='m21.91,32.08c-1.63,0-3.24-.68-4.38-1.98-.37-.42-.79-.75-1.25-.98l-8.81-4.36c-1.45-.58-2.21-2.26-1.66-3.8.49-1.37,1.89-2.21,3.32-1.98l2.17.34-6.23-10.36c-.78-1.3-.35-3,.95-3.78,1.32-.79,2.98-.38,3.74.9l2.8,4.64c.22-.3.51-.57.85-.77.63-.38,1.37-.49,2.08-.31.35.09.68.24.97.45.23-.39.55-.72.96-.97,1.09-.65,2.45-.46,3.33.38.23-.35.53-.64.9-.86,1.32-.79,2.97-.38,3.74.9l1.86,3.1c2.57,4.28,3.13,6.82,3.15,8.21,0,.49.09.97.26,1.41.97,2.63-.07,5.58-2.47,7.03l-3.28,1.97c-.94.56-1.98.84-3.01.84Zm-13.25-11.49c-.58,0-1.1.36-1.3.92-.25.7.1,1.47.79,1.75l8.87,4.39c.65.32,1.24.78,1.75,1.37,1.33,1.52,3.57,1.87,5.3.82l3.28-1.97c1.73-1.04,2.47-3.16,1.77-5.04-.23-.61-.35-1.27-.36-1.96-.01-.97-.41-3.21-2.92-7.38l-1.86-3.1c-.3-.5-.95-.66-1.44-.36-.3.18-.48.42-.55.71-.07.29-.03.58.12.84l1.73,2.87c.23.39.11.89-.28,1.13-.39.23-.9.11-1.13-.28l-.02-.04-2.61-4.35c-.32-.52-1-.69-1.52-.38-.52.31-.69,1-.38,1.52l1.8,2.99c.23.39.11.9-.28,1.13-.39.23-.9.11-1.13-.28l-2.5-4.15c-.15-.25-.39-.43-.68-.5-.29-.07-.59-.03-.84.13-.27.16-.46.4-.54.67-.05.18-.08.47.11.77l1.92,3.19c.23.39.11.89-.28,1.13-.39.23-.89.11-1.13-.28l-5.99-9.91c-.3-.5-.95-.66-1.44-.36-.57.34-.74,1.03-.43,1.55l7.13,11.88c.16.27.16.62-.02.88-.18.26-.49.4-.81.35l-3.92-.62c-.07-.01-.15-.02-.22-.02Z' />
                    <path d='m1.58,12.12l-.13-.17c-.19-.25-.13-.62.15-.78l.81-.49c.23-.14.53-.08.7.13.17.22.14.52-.05.71l-.68.66c-.23.22-.6.19-.79-.06' />
                    <path d='m.03,8.56l-.03-.21c-.04-.32.2-.6.52-.6l.95-.02c.27,0,.5.19.54.46.04.27-.14.52-.4.59l-.92.23c-.31.08-.62-.13-.66-.45' />
                    <path d='m.48,4.7l.08-.2c.12-.29.47-.42.75-.26l.83.46c.24.13.34.42.24.67-.1.25-.38.38-.64.31l-.91-.26c-.31-.09-.47-.42-.35-.72' />
                    <path d='m2.8,1.58l.17-.13c.25-.19.62-.13.78.15l.49.81c.14.23.09.53-.13.7-.21.17-.52.14-.71-.05l-.66-.68c-.22-.23-.19-.6.06-.79' />
                    <path d='m6.36.03l.21-.03c.32-.04.6.2.6.52l.02.95c0,.27-.19.5-.46.54-.27.04-.52-.14-.59-.4l-.23-.92c-.08-.31.13-.62.45-.66' />
                    <path d='m10.22.48l.2.08c.29.12.42.47.26.75l-.46.83c-.13.24-.42.34-.67.24-.25-.1-.38-.38-.31-.64l.26-.91c.09-.31.42-.47.72-.35' />
                    <path d='m13.34,2.8l.13.17c.19.25.13.62-.15.78l-.81.49c-.23.14-.53.08-.7-.13-.17-.22-.14-.52.05-.71l.68-.66c.23-.22.6-.19.79.06' />
                    <path d='m14.89,6.36l.03.21c.04.32-.2.6-.52.6l-.95.02c-.27,0-.5-.19-.54-.46-.04-.27.14-.52.4-.59l.92-.23c.31-.08.62.13.66.45' />
                    <path d='m4.7,14.44l-.2-.08c-.29-.12-.42-.47-.26-.75l.46-.83c.13-.24.42-.34.67-.24.25.1.38.38.31.64l-.26.91c-.09.31-.42.47-.72.35' />
                  </svg>
                </div>
              </div>
            </a>
          )}
          <div className='col-start-1 col-span-6 grid grid-cols-5 gap-8 text-center mt-2'>
            {pageInfo[pageSelector].listContent.map((text) => {
              return (
                <div className='p-4 bg-black/30 backdrop-blur-md rounded-md h-full flex justify-center'>
                  <div>
                    <div className='font-bold text-sm mb-2'>{text.head}</div>
                    <div className='leading-5'>{text.body}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {pageSelector === 0 && (
          <div
            onClick={() => setPageSelector(1)}
            className='select-none cursor-pointer hover:ml-1 mt-3 w-fit flex justify-center py-2 font-bold px-4 border-red border-[1px] rounded-md shadow-sm text-sm text-white border-1-red hover:bg-black transition-all duration-100'
          >
            <div>
              <div>Learn more</div>
              <div style={{ fill: 'white' }} className='h-10 w-10 ml-20 -mt-2 absolute'>
                <svg id='a' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.03 32.08'>
                  <defs>
                    <clipPath id='b'>
                      <rect x='0' y='0' width='31.03' height='32.08' />
                    </clipPath>
                  </defs>
                  <path d='m21.91,32.08c-1.63,0-3.24-.68-4.38-1.98-.37-.42-.79-.75-1.25-.98l-8.81-4.36c-1.45-.58-2.21-2.26-1.66-3.8.49-1.37,1.89-2.21,3.32-1.98l2.17.34-6.23-10.36c-.78-1.3-.35-3,.95-3.78,1.32-.79,2.98-.38,3.74.9l2.8,4.64c.22-.3.51-.57.85-.77.63-.38,1.37-.49,2.08-.31.35.09.68.24.97.45.23-.39.55-.72.96-.97,1.09-.65,2.45-.46,3.33.38.23-.35.53-.64.9-.86,1.32-.79,2.97-.38,3.74.9l1.86,3.1c2.57,4.28,3.13,6.82,3.15,8.21,0,.49.09.97.26,1.41.97,2.63-.07,5.58-2.47,7.03l-3.28,1.97c-.94.56-1.98.84-3.01.84Zm-13.25-11.49c-.58,0-1.1.36-1.3.92-.25.7.1,1.47.79,1.75l8.87,4.39c.65.32,1.24.78,1.75,1.37,1.33,1.52,3.57,1.87,5.3.82l3.28-1.97c1.73-1.04,2.47-3.16,1.77-5.04-.23-.61-.35-1.27-.36-1.96-.01-.97-.41-3.21-2.92-7.38l-1.86-3.1c-.3-.5-.95-.66-1.44-.36-.3.18-.48.42-.55.71-.07.29-.03.58.12.84l1.73,2.87c.23.39.11.89-.28,1.13-.39.23-.9.11-1.13-.28l-.02-.04-2.61-4.35c-.32-.52-1-.69-1.52-.38-.52.31-.69,1-.38,1.52l1.8,2.99c.23.39.11.9-.28,1.13-.39.23-.9.11-1.13-.28l-2.5-4.15c-.15-.25-.39-.43-.68-.5-.29-.07-.59-.03-.84.13-.27.16-.46.4-.54.67-.05.18-.08.47.11.77l1.92,3.19c.23.39.11.89-.28,1.13-.39.23-.89.11-1.13-.28l-5.99-9.91c-.3-.5-.95-.66-1.44-.36-.57.34-.74,1.03-.43,1.55l7.13,11.88c.16.27.16.62-.02.88-.18.26-.49.4-.81.35l-3.92-.62c-.07-.01-.15-.02-.22-.02Z' />
                  <path d='m1.58,12.12l-.13-.17c-.19-.25-.13-.62.15-.78l.81-.49c.23-.14.53-.08.7.13.17.22.14.52-.05.71l-.68.66c-.23.22-.6.19-.79-.06' />
                  <path d='m.03,8.56l-.03-.21c-.04-.32.2-.6.52-.6l.95-.02c.27,0,.5.19.54.46.04.27-.14.52-.4.59l-.92.23c-.31.08-.62-.13-.66-.45' />
                  <path d='m.48,4.7l.08-.2c.12-.29.47-.42.75-.26l.83.46c.24.13.34.42.24.67-.1.25-.38.38-.64.31l-.91-.26c-.31-.09-.47-.42-.35-.72' />
                  <path d='m2.8,1.58l.17-.13c.25-.19.62-.13.78.15l.49.81c.14.23.09.53-.13.7-.21.17-.52.14-.71-.05l-.66-.68c-.22-.23-.19-.6.06-.79' />
                  <path d='m6.36.03l.21-.03c.32-.04.6.2.6.52l.02.95c0,.27-.19.5-.46.54-.27.04-.52-.14-.59-.4l-.23-.92c-.08-.31.13-.62.45-.66' />
                  <path d='m10.22.48l.2.08c.29.12.42.47.26.75l-.46.83c-.13.24-.42.34-.67.24-.25-.1-.38-.38-.31-.64l.26-.91c.09-.31.42-.47.72-.35' />
                  <path d='m13.34,2.8l.13.17c.19.25.13.62-.15.78l-.81.49c-.23.14-.53.08-.7-.13-.17-.22-.14-.52.05-.71l.68-.66c.23-.22.6-.19.79.06' />
                  <path d='m14.89,6.36l.03.21c.04.32-.2.6-.52.6l-.95.02c-.27,0-.5-.19-.54-.46-.04-.27.14-.52.4-.59l.92-.23c.31-.08.62.13.66.45' />
                  <path d='m4.7,14.44l-.2-.08c-.29-.12-.42-.47-.26-.75l.46-.83c.13-.24.42-.34.67-.24.25.1.38.38.31.64l-.26.91c-.09.31-.42.47-.72.35' />
                </svg>
              </div>
            </div>
          </div>
        )}
        {pageSelector === 1 && (
          <Link
            to='/register'
            className='select-none cursor-pointer hover:ml-1 mt-8 w-fit flex justify-center py-2 font-bold px-4 border-red border-[1px] rounded-md shadow-sm text-sm text-white border-1-red hover:bg-black transition-all duration-100'
          >
            Get started
          </Link>
        )}
        {pageSelector === 2 && (
          <Link
            to='/register'
            className='select-none cursor-pointer hover:ml-1 mt-8 w-fit flex justify-center py-2 font-bold px-4 border-red border-[1px] rounded-md shadow-sm text-sm text-white border-1-red hover:bg-black transition-all duration-100'
          >
            + Create your first project
          </Link>
        )}
        {pageSelector === 3 && (
          <Link
            to='/register'
            className='select-none cursor-pointer hover:ml-1 mt-8 w-fit flex justify-center py-2 font-bold px-4 border-red border-[1px] rounded-md shadow-sm text-sm text-white border-1-red hover:bg-black transition-all duration-100'
          >
            Read articles
          </Link>
        )}
      </div>

      <div className='grid grid-cols-4 h-auto bottom-[60px] px-[60px] absolute left-0 right-0 gap-[60px]'>
        <div className='col-span-2 space-y-4'>
          <div>New Articles</div>
          <div className='block 3xl:hidden'>
            <div className='grid grid-cols-3 gap-3'>
              {shuffledArticles
                .map((article: any) => {
                  return <Article article={article} />;
                })
                .reverse()
                .splice(0, 6)}
            </div>
          </div>
          <div className='hidden 3xl:block'>
            <div className='grid grid-cols-4 gap-3'>
              {shuffledArticles
                .map((article: any) => {
                  return <Article article={article} />;
                })
                .reverse()
                .splice(0, 8)}
            </div>
          </div>
        </div>

        <div className='space-y-4'>
          <div>New Projects</div>
          <div className='grid grid-cols-2 gap-3'>
            {shuffledProjects
              .map((project: any) => {
                return <Project project={project} />;
              })
              .reverse()
              .splice(0, 4)}
          </div>
        </div>

        <div className='space-y-4'>
          <div>New Suppliers</div>
          <div className='grid grid-cols-1 gap-3'>
            {shuffledSuppliers
              .map((advertiser: any) => {
                return <Supplier advertiser={advertiser} />;
              })
              .reverse()
              .splice(0, 2)}
          </div>
        </div>
      </div>
    </div>
  );
}

function Article({ article }: any) {
  return (
    <Link
      to='/register'
      className='cursor-pointer rounded-md bg-center bg-cover lg:bg-[length:107%] h-[90px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:109%]'
      style={{
        backgroundImage: `url(${article.images[0].url})`,
      }}
    >
      <div className='rounded-full m-2 py-[2px] px-1 w-auto uppercase bg-[#219181] text-[#4adec7] text-[6pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
        {article.articleTemplate}
      </div>

      <div className='h-auto w-full p-2 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
        <h2 className='text-white text-lg font-[Montserrat] font-extrabold italic line-clamp-2 -mb-1'>
          {article.heading}
        </h2>
        {/* <h3 className='text-white text-[10pt] font-light line-clamp-2'>{article.subHeading}</h3> */}
        <span className='text-red font-black uppercase'>By MHP </span>
        <span className='text-red font-black'>
          {new Date(article.createdAt).toLocaleDateString()}
        </span>
      </div>
    </Link>
  );
}

function Project({ project }: any) {
  return (
    <Link
      to='/register'
      className='cursor-pointer transition-all h-[90px] bg-center bg-cover rounded-md text-[0pt] hover:text-[2pt] relative lg:bg-[length:125%] lg:hover:bg-[length:127%]'
      style={{
        backgroundImage: `url(${project.images[0]})`,
      }}
    >
      <div className='h-auto w-full bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
        <h2 className='text-white text-4xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
          {project.make}
        </h2>

        <h2 className='text-lg font-[Montserrat] text-white font-extrabold italic p-2 line-clamp-2 -mb-1'>
          {project.name}
        </h2>
        <div className='opacity-0 relative'>Hello, I hope you're having a good day.</div>
      </div>
    </Link>
  );
}

function Supplier({ advertiser }: any) {
  return (
    <Link
      to='/register'
      key={advertiser._id}
      className='rounded-md h-[90px] bg-cover bg-center cursor-pointer'
      style={{
        backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
      }}
    >
      <div className='bg-[#0000009a] hover:bg-[#00000070] transition-all ease-in-out duration-100 w-full h-full p-4 flex flex-col justify-center items-center backdrop-blur-sm hover:backdrop-blur-none'>
        <div className='flex justify-center items-center'>
          {advertiser.advertiserDetails.companyLogo ? (
            <img
              className='max-h-16'
              src={`${advertiser.advertiserDetails.companyLogo}`}
              alt='Company Logo'
            />
          ) : (
            <div className='w-16 h-16' style={{ fill: `#f2123d` }}>
              <svg viewBox='0 0 121.65 121.59'>
                <path d='m44.86,2.11l3.48,9.57c18.55-4.74,37.78,1.5,50.12,15.16,3.37,3.73,2.13,9.67-2.41,11.81l-20.63,9.7c-2.11,1-4.57.78-6.54-.46-3.83-2.38-8.68-3.04-13.24-1.38s-7.86,5.29-9.26,9.57c-.72,2.22-2.46,3.97-4.72,4.56l-22.04,5.83c-4.86,1.28-9.62-2.47-9.44-7.49.66-18.4,11.38-35.54,28.64-43.83l-3.48-9.57C6.73,18.75-7.37,52,3.87,82.09c11.56,30.93,46.14,47.06,77.26,36.03,32.06-11.36,48.56-46.76,36.66-78.62C106.76,10,74.8-6.04,44.86,2.11Zm14.21,109.34c-14.64-.51-28.49-7.4-37.73-18.88-3.4-4.23-1.29-10.56,3.96-11.95l20.02-5.29c3.07-.81,6.32.41,8.1,3.04l6.55,9.7,6.43,11.38c3.1,5.49-1.03,12.23-7.33,12.01Zm5.22-41.13c-5.26,1.91-11.08-.8-12.99-6.06-1.91-5.26.8-11.08,6.06-12.99s11.08.8,12.99,6.06c1.91,5.26-.8,11.08-6.06,12.99Zm30.44,28.16c-4.69,4.22-12.18,1.71-13.33-4.49l-2.39-12.85-1.22-11.64c-.33-3.16,1.38-6.18,4.25-7.53l18.73-8.81c4.91-2.31,10.61,1.19,10.72,6.61.3,14.73-5.88,28.91-16.77,38.71Z' />
              </svg>
            </div>
          )}
        </div>
        {/* <div className='line-clamp-3 md:line-clamp-2 text-center mt-2'>
          {advertiser.advertiserDetails.primaryTags.map((tag: string, index: number) => {
            return (
              <span key={index}>
                {tag}
                {index !== advertiser.advertiserDetails.primaryTags.length - 1 && ', '}
              </span>
            );
          })}
        </div> */}
      </div>
    </Link>
  );
}

function Pointer({ text }: any) {
  return (
    <div className='p-4 space-y-2 flex-col items-center bg-black/30 rounded-md backdrop-blur-lg'>
      <div className='w-full flex justify-center'>
        <div className='w-6 h-6' style={{ fill: `#f2123d` }}>
          <svg viewBox='0 0 121.65 121.59'>
            <path d='m44.86,2.11l3.48,9.57c18.55-4.74,37.78,1.5,50.12,15.16,3.37,3.73,2.13,9.67-2.41,11.81l-20.63,9.7c-2.11,1-4.57.78-6.54-.46-3.83-2.38-8.68-3.04-13.24-1.38s-7.86,5.29-9.26,9.57c-.72,2.22-2.46,3.97-4.72,4.56l-22.04,5.83c-4.86,1.28-9.62-2.47-9.44-7.49.66-18.4,11.38-35.54,28.64-43.83l-3.48-9.57C6.73,18.75-7.37,52,3.87,82.09c11.56,30.93,46.14,47.06,77.26,36.03,32.06-11.36,48.56-46.76,36.66-78.62C106.76,10,74.8-6.04,44.86,2.11Zm14.21,109.34c-14.64-.51-28.49-7.4-37.73-18.88-3.4-4.23-1.29-10.56,3.96-11.95l20.02-5.29c3.07-.81,6.32.41,8.1,3.04l6.55,9.7,6.43,11.38c3.1,5.49-1.03,12.23-7.33,12.01Zm5.22-41.13c-5.26,1.91-11.08-.8-12.99-6.06-1.91-5.26.8-11.08,6.06-12.99s11.08.8,12.99,6.06c1.91,5.26-.8,11.08-6.06,12.99Zm30.44,28.16c-4.69,4.22-12.18,1.71-13.33-4.49l-2.39-12.85-1.22-11.64c-.33-3.16,1.38-6.18,4.25-7.53l18.73-8.81c4.91-2.31,10.61,1.19,10.72,6.61.3,14.73-5.88,28.91-16.77,38.71Z' />
          </svg>
        </div>
      </div>
      <div>{text}</div>
    </div>
  );
}
