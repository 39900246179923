import { useMutation, gql } from '@apollo/client';
import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';

const SEND_ENQUIRY_MUTATION = gql`
  mutation Mutation($input: EnquiryInput) {
    createQuickEnquiry(input: $input)
  }
`;

const QuickEnquiry = ({ showQuickEnquiry }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { userData } = useSelector((state: RootState) => state.user);
  const [sendEnquiryMutation] = useMutation(SEND_ENQUIRY_MUTATION);

  const [enquirySubmitting, setEnquirySubmitting] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const [formError, setFormError] = useState('');
  const [enquiryTags, setEnquiryTags] = useState<string[]>([]);

  const handleEnquirySubmit = async (inputs: any) => {
    setEnquirySubmitting(true);
    const { location, phone, description } = inputs;
    try {
      const { data } = await sendEnquiryMutation({
        variables: {
          input: {
            tags: enquiryTags,
            location,
            phone,
            description,
            userId: userData._id,
          },
        },
      });
      if (data.createQuickEnquiry) {
        setEnquirySubmitting(false);
        setSubmissionComplete(true);
      }
    } catch (err: any) {
      setEnquirySubmitting(false);
      setFormError(err.message);
    }
  };

  // Close Quick Enquiry when clicked outside of area of #quick-enquiry
  const quickEnquiryRef = useRef<any>(null);
  const mainArea = document.querySelector('.contentwrapper');

  useEffect(() => {
    function clickOutsideQuickEnquiry(e: any) {
      if (!quickEnquiryRef.current?.includes(e.target)) {
        showQuickEnquiry(false);
      }
    }

    mainArea?.addEventListener('click', clickOutsideQuickEnquiry);
    return () => mainArea?.removeEventListener('click', clickOutsideQuickEnquiry);
  }, [quickEnquiryRef]);

  return (
    <>
      <div
        className='w-full absolute bg-black border-red border-[1px] rounded-b-md p-4 z-10'
        id='quick-enquiry'
        ref={quickEnquiryRef.current}
      >
        {submissionComplete ? (
          <div className='text-center py-4'>
            <h3 className='font-bold text-lg'>Thank you for your enquiry.</h3>
            <p>We will get back to you as soon as we can.</p>
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit(handleEnquirySubmit)} className='quick-enquiryform'>
              {enquirySubmitting ? (
                <LoadingSpinner />
              ) : (
                <>
                  <p className='text-[8pt] mb-4 text-center'>
                    Send an enquiry to every relevant supplier on MHP.
                  </p>
                  <div className='pb-4'>
                    <TagSearch
                      tagType={MHPSearchTagType.QuickEnquiry}
                      handleFormTags={setEnquiryTags}
                    />
                    {errors.topic && (
                      <span role='alert' className='text-xs block -mb-1 text-red'>
                        {`${errors.topic.message}`}
                      </span>
                    )}
                  </div>

                  <div className='pb-4'>
                    <input
                      {...register('location')}
                      className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      type='text'
                      placeholder='Location'
                    />
                    {errors.location && (
                      <span role='alert' className='text-xs block -mb-1 text-red'>
                        {`${errors.location.message}`}
                      </span>
                    )}
                  </div>
                  <div className='pb-4'>
                    <input
                      {...register('phone', {
                        required: 'Please type your phone number.',
                        minLength: {
                          value: 5,
                          message: 'Minimum 5 character.',
                        },
                      })}
                      className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      type='text'
                      placeholder='Phone number'
                    />
                    {errors.phone && (
                      <span role='alert' className='text-xs block -mb-1 text-red'>
                        {`${errors.phone.message}`}
                      </span>
                    )}
                  </div>
                  <div className='pb-4'>
                    <textarea
                      {...register('description', {
                        required: 'Please add a message.',
                        minLength: {
                          value: 5,
                          message: 'Minimum 5 character.',
                        },
                      })}
                      className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      rows={4}
                      placeholder='Description'
                    />
                    {errors.description && (
                      <span role='alert' className='text-xs block -mb-1 text-red'>
                        {`${errors.description.message}`}
                      </span>
                    )}
                  </div>
                  <button
                    className='text-white bg-red hover:bg-[#A60626] rounded-full px-6 py-2 my-1 inline-block order-2 text-center float-right transition-all ease-in-out duration-100'
                    type='submit'
                  >
                    Send Enquiry
                  </button>
                </>
              )}
            </form>
          </>
        )}
      </div>
    </>
  );
};

export default QuickEnquiry;
