const MessageIcon = () => {
  return (
    <svg
      class='icon'
      xmlns='http://www.w3.org/2000/svg'
      width='21.283'
      height='21.283'
      viewBox='0 0 21.283 21.283'
    >
      <path
        d='M22.155,3H5.128A2.125,2.125,0,0,0,3.011,5.128L3,24.283l4.257-4.257h14.9A2.135,2.135,0,0,0,24.283,17.9V5.128A2.135,2.135,0,0,0,22.155,3ZM20.026,15.77H7.257V13.641h12.77Zm0-3.192H7.257V10.449h12.77Zm0-3.192H7.257V7.257h12.77Z'
        transform='translate(-3 -3)'
      />
    </svg>
  );
};

export default MessageIcon;
