import { useEffect, useState } from 'react';
import { PlusCircleIcon, XIcon } from '@heroicons/react/outline';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import WYSIWYGEditor from '@/components/utils/WYSIWYGEditor';
import TagSearch from '@/components/utils/TagSearch';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

export enum MHPSearchTagType {
  Article = 'article',
  Project = 'project',
  Product = 'product',
  Event = 'event',
}

const CREATE_EVENT = gql`
  mutation CreateEvent($input: EventInput) {
    createEvent(input: $input) {
      tags
      name
      location
      eventImages
      eventDetails
      destinations {
        location
        arrivalTime
      }
      dates {
        startTime
        endTime
        date
      }
      createdBy
      createdAt
    }
  }
`;

const AddEvent = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [eventCoverImage, setEventCoverImage] = useState('');
  const [eventPosterImage, setEventPosterImage] = useState('');
  const [coverImagePreview, setCoverImagePreview] = useState<any>();
  const [posterImagePreview, setPosterImagePreview] = useState<any>();
  const [addEventMutation, { data: addEventData, loading: addEventloading }] =
    useMutation(CREATE_EVENT);
  const [eventMainImageError, setEventMainImageError] = useState(false);

  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  const [eventTags, setEventTags] = useState<string[]>();
  const [eventDetails, setEventDetails] = useState<string>();
  const [eventType, setEventType] = useState<Boolean>(false);
  const [dateArray, setDateArray] = useState<Array<any>>([
    { date: new Date(), startTime: '12:00 AM', endTime: '12:00 AM' },
  ]);
  const [destinationArray, setDestinationArray] = useState<Array<any>>([
    { location: null, arrivalTime: '12:00 AM' },
  ]);

  const populateDateArray = (
    index: number,
    newDate: Date,
    newStartTime: string,
    newEndTime: string,
  ) => {
    const newArray = [...dateArray];
    newArray[index] = {
      date: newDate,
      startTime: newStartTime,
      endTime: newEndTime,
    };
    setDateArray(newArray);
  };

  const addDate = () => {
    if (dateArray.length < 10) {
      const newArray = [...dateArray];
      newArray.push({ date: new Date(), startTime: '12:00 AM', endTime: '12:00 AM' });
      setDateArray(newArray);
    }
  };

  const removeDate = (removeIndex: number) => {
    const newArray = [...dateArray];
    newArray.splice(removeIndex, 1);
    setDateArray(newArray);
  };

  const populateDestinationArray = (index: number, newLocation: string, newArrivalTime: string) => {
    const newArray = [...destinationArray];
    newArray[index] = {
      location: newLocation,
      arrivalTime: newArrivalTime,
    };
    setDestinationArray(newArray);
  };

  const addDestination = () => {
    if (dateArray.length < 10) {
      const newArray = [...destinationArray];
      newArray.push({ location: null, arrivalTime: '12:00 AM' });
      setDestinationArray(newArray);
    }
  };

  const removeDestination = (removeIndex: number) => {
    const newArray = [...destinationArray];
    newArray.splice(removeIndex, 1);
    setDestinationArray(newArray);
  };

  const handleWYSIWYGFormStateUpdate = (convertedWYSIWYGContent: string) => {
    const newDescriptionContent = convertedWYSIWYGContent;
    setEventDetails(newDescriptionContent);
  };

  const handleCoverImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setCoverImagePreview(reader.result);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          setEventCoverImage(data?.data?.uploadPhoto);
          setEventMainImageError(false);
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handlePosterImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        setPosterImagePreview(reader.result);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          setEventPosterImage(data?.data?.uploadPhoto);
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleAddEvent = async (inputs: any) => {
    if (eventCoverImage === '' || eventCoverImage === null) {
      setEventMainImageError(true);
      return;
    }

    setFormError('');

    const { name, location, ticketLink } = inputs;
    try {
      const { data } = await addEventMutation({
        variables: {
          input: {
            name,
            location,
            dates: dateArray,
            destinations: destinationArray,
            eventType: eventType ? 'cruise' : 'event',
            eventDetails: eventDetails,
            ticketLink,
            tags: eventTags,
            eventImages: [eventCoverImage, eventPosterImage],
          },
        },
      });

      if (data) {
        navigate(`/events`);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <div className='grid grid-cols-12 mt-1 mb-10'>
      <div className='cursor-pointer flex flex-row col-start-1 col-span-full lg:col-span-8 text-white font-semibold items-center text-xl justify-between'>
        <h1>Add New Event</h1>
        <div
          className='col-span-6 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
          onClick={() => navigate(-1)}
        >
          Go Back
        </div>
      </div>
      <form
        onSubmit={handleSubmit(handleAddEvent)}
        className='col-span-full xl:col-span-8 col-start-1 mt-2'
      >
        <div className='sm:rounded-md sm:overflow-hidden'>
          <div className='p-6 bg-white rounded-t-md'>
            <div className='col-span-3 grid grid-cols-1 gap-6'>
              <div>
                <label className='block text-sm font-medium text-gray-700'>Event cover photo</label>
                <div
                  className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
                    eventCoverImage === '' ? 'px-6 pt-5 pb-6' : ''
                  }`}
                >
                  <div className='space-y-1 text-center'>
                    {uploadPhotoLoading ? (
                      <LoadingSpinner />
                    ) : eventCoverImage === '' ? (
                      <>
                        <svg
                          className='mx-auto h-12 w-12 text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                          aria-hidden='true'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <div className='flex text-sm text-gray-600'>
                          <label
                            htmlFor='file-upload'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span className=''>Upload an image</span>
                            <input
                              id='file-upload'
                              name='file-upload'
                              type='file'
                              onChange={(event) => {
                                handleCoverImageUpload(event);
                              }}
                              className='sr-only'
                            />
                          </label>
                        </div>
                        <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                      </>
                    ) : (
                      <div className='h-full w-full max-h-48 relative'>
                        <img
                          className='object-cover h-full w-full'
                          src={coverImagePreview}
                          alt='ProfileImage'
                        />
                      </div>
                    )}
                  </div>
                </div>
                {eventMainImageError ? (
                  <span className='text-red'>Product cover image required</span>
                ) : null}
              </div>
              <div>
                <label htmlFor='heading' className='block text-sm font-medium text-gray-700'>
                  Event name
                </label>
                <div className='mt-1'>
                  <input
                    {...register('name', { required: 'Event name required' })}
                    className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter event name'
                    defaultValue={''}
                  />
                  {errors.name !== undefined && (
                    <span className='text-red'>{`${errors.name && errors.name.message}`}</span>
                  )}
                </div>
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Ticket URL (optional)
                </label>
                <div className='mt-1'>
                  <input
                    {...register('ticketLink')}
                    className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter ticket URL'
                    defaultValue={''}
                  />
                  {errors.ticketLink !== undefined && (
                    <span className='text-red'>{`${
                      errors.ticketLink && errors.ticketLink.message
                    }`}</span>
                  )}
                </div>
              </div>
              <div>
                <div className='mt-1 grid grid-cols-3 gap-6'>
                  <div className='col-span-2'>
                    <WYSIWYGEditor
                      formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
                      formLabel={'Event description (optional)'}
                      contentSectionCount={0}
                    />
                  </div>

                  <div className='col-span-1'>
                    <label className='block text-sm font-medium text-gray-700'>
                      Event poster (optional)
                    </label>
                    <div
                      className={`mt-1 flex justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        eventPosterImage === '' ? 'px-6 pt-5 pb-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : eventPosterImage === '' ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='flex text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <span className=''>Upload an image</span>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handlePosterImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='h-full w-full max-h-48 relative'>
                            <img
                              className='object-cover h-full w-full'
                              src={posterImagePreview}
                              alt='ProfileImage'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {eventMainImageError ? (
                      <span className='text-red'>Product cover image required</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className='relative pb-1'>
                <label htmlFor='tags' className='block text-sm font-medium text-gray-700'>
                  Event tags (optional)
                </label>
                <div className='mt-1'>
                  <TagSearch tagType={MHPSearchTagType.Event} handleFormTags={setEventTags} />
                </div>
              </div>

              <div className='col-span-full grid grid-cols-2 gap-6'>
                <div
                  className={`cursor-pointer transition-all duration-150 px-1 py-2 rounded-md text-center bg-gray-400 ${
                    eventType ? 'hover:bg-gray-500 bg-gray-400' : 'bg-red'
                  }`}
                  onClick={() => {
                    setEventType(false);
                  }}
                >
                  Event
                </div>
                <div
                  className={`cursor-pointer transition-all duration-150 px-1 py-2 rounded-md text-center  ${
                    eventType ? 'bg-red' : 'hover:bg-gray-500 bg-gray-400'
                  }`}
                  onClick={() => {
                    setEventType(true);
                  }}
                >
                  Cruise
                </div>
              </div>

              {eventType ? (
                <>
                  <div className='col-span-full text-gray-700 p-4 bg-gray-100 rounded-md'>
                    <div className='mb-1 w-full text-center'>
                      <span>Cruise Date</span>
                    </div>
                    <div className='w-full flex justify-center'>
                      <DatePicker
                        className='rounded-md px-[8px] py-[8px] w-full sm:text-sm border border-gray-300'
                        dateFormat='dd/MM/yyyy'
                        selected={dateArray[0].date}
                        onChange={(date: Date) => {
                          setDateArray([
                            {
                              date: date,
                              startTime: dateArray[0].startTime,
                              endTime: dateArray[0].endTime,
                            },
                          ]);
                        }}
                        inline
                      />
                    </div>
                  </div>

                  {destinationArray.map((item, index) => {
                    return (
                      <div className='text-gray-700 grid grid-cols-8 gap-6 bg-gray-100 p-4 rounded-md'>
                        <div className='col-span-full md:col-span-4'>
                          <div className='w-full grid grid-cols-2'>
                            <div className='col-span-1'>Meet Location {index + 1}</div>
                            {index >= 1 && (
                              <div
                                className='col-span-1 text-end font-light text-red cursor-pointer hover:underline'
                                onClick={() => removeDestination(index)}
                              >
                                Remove destination
                              </div>
                            )}
                          </div>
                          <div className='mt-1'>
                            <input
                              {...register(`meetLocation${index + 1}`, {
                                required: 'Event name required',
                              })}
                              className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder={`Enter location ${index + 1}`}
                              defaultValue={''}
                              value={item.location || ''}
                              onChange={(e) => {
                                populateDestinationArray(index, e.target.value, item.arrivalTime);
                              }}
                            />
                          </div>
                          {errors[`meetLocation${index + 1}`] && (
                            <span className='text-red'>{`Event location ${
                              index + 1
                            } is required`}</span>
                          )}
                        </div>
                        <div className='col-span-full md:col-span-4'>
                          <div>Arrival time</div>
                          <select
                            className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                            placeholder='Start time'
                            value={item.arrivalTime || ''}
                            onChange={(e) => {
                              populateDestinationArray(index, item.location, e.target.value);
                            }}
                          >
                            <option value='12:00 AM'>12:00 AM</option>
                            <option value='12:15 AM'>12:15 AM</option>
                            <option value='12:30 AM'>12:30 AM</option>
                            <option value='12:45 AM'>12:45 AM</option>
                            <option value='1:00 AM'>1:00 AM</option>
                            <option value='1:15 AM'>1:15 AM</option>
                            <option value='1:30 AM'>1:30 AM</option>
                            <option value='1:45 AM'>1:45 AM</option>
                            <option value='2:00 AM'>2:00 AM</option>
                            <option value='2:15 AM'>2:15 AM</option>
                            <option value='2:30 AM'>2:30 AM</option>
                            <option value='2:45 AM'>2:45 AM</option>
                            <option value='3:00 AM'>3:00 AM</option>
                            <option value='3:15 AM'>3:15 AM</option>
                            <option value='3:30 AM'>3:30 AM</option>
                            <option value='3:45 AM'>3:45 AM</option>
                            <option value='4:00 AM'>4:00 AM</option>
                            <option value='4:15 AM'>4:15 AM</option>
                            <option value='4:30 AM'>4:30 AM</option>
                            <option value='4:45 AM'>4:45 AM</option>
                            <option value='5:00 AM'>5:00 AM</option>
                            <option value='5:15 AM'>5:15 AM</option>
                            <option value='5:30 AM'>5:30 AM</option>
                            <option value='5:45 AM'>5:45 AM</option>
                            <option value='6:00 AM'>6:00 AM</option>
                            <option value='6:15 AM'>6:15 AM</option>
                            <option value='6:30 AM'>6:30 AM</option>
                            <option value='6:45 AM'>6:45 AM</option>
                            <option value='7:00 AM'>7:00 AM</option>
                            <option value='7:15 AM'>7:15 AM</option>
                            <option value='7:30 AM'>7:30 AM</option>
                            <option value='7:45 AM'>7:45 AM</option>
                            <option value='8:00 AM'>8:00 AM</option>
                            <option value='8:15 AM'>8:15 AM</option>
                            <option value='8:30 AM'>8:30 AM</option>
                            <option value='8:45 AM'>8:45 AM</option>
                            <option value='9:00 AM'>9:00 AM</option>
                            <option value='9:15 AM'>9:15 AM</option>
                            <option value='9:30 AM'>9:30 AM</option>
                            <option value='9:45 AM'>9:45 AM</option>
                            <option value='10:00 AM'>10:00 AM</option>
                            <option value='10:15 AM'>10:15 AM</option>
                            <option value='10:30 AM'>10:30 AM</option>
                            <option value='10:45 AM'>10:45 AM</option>
                            <option value='11:00 AM'>11:00 AM</option>
                            <option value='11:15 AM'>11:15 AM</option>
                            <option value='11:30 AM'>11:30 AM</option>
                            <option value='11:45 AM'>11:45 AM</option>
                            <option value='12:00 PM'>12:00 PM</option>
                            <option value='12:15 PM'>12:15 PM</option>
                            <option value='12:30 PM'>12:30 PM</option>
                            <option value='12:45 PM'>12:45 PM</option>
                            <option value='1:00 PM'>1:00 PM</option>
                            <option value='1:15 PM'>1:15 PM</option>
                            <option value='1:30 PM'>1:30 PM</option>
                            <option value='1:45 PM'>1:45 PM</option>
                            <option value='2:00 PM'>2:00 PM</option>
                            <option value='2:15 PM'>2:15 PM</option>
                            <option value='2:30 PM'>2:30 PM</option>
                            <option value='2:45 PM'>2:45 PM</option>
                            <option value='3:00 PM'>3:00 PM</option>
                            <option value='3:15 PM'>3:15 PM</option>
                            <option value='3:30 PM'>3:30 PM</option>
                            <option value='3:45 PM'>3:45 PM</option>
                            <option value='4:00 PM'>4:00 PM</option>
                            <option value='4:15 PM'>4:15 PM</option>
                            <option value='4:30 PM'>4:30 PM</option>
                            <option value='4:45 PM'>4:45 PM</option>
                            <option value='5:00 PM'>5:00 PM</option>
                            <option value='5:15 PM'>5:15 PM</option>
                            <option value='5:30 PM'>5:30 PM</option>
                            <option value='5:45 PM'>5:45 PM</option>
                            <option value='6:00 PM'>6:00 PM</option>
                            <option value='6:15 PM'>6:15 PM</option>
                            <option value='6:30 PM'>6:30 PM</option>
                            <option value='6:45 PM'>6:45 PM</option>
                            <option value='7:00 PM'>7:00 PM</option>
                            <option value='7:15 PM'>7:15 PM</option>
                            <option value='7:30 PM'>7:30 PM</option>
                            <option value='7:45 PM'>7:45 PM</option>
                            <option value='8:00 PM'>8:00 PM</option>
                            <option value='8:15 PM'>8:15 PM</option>
                            <option value='8:30 PM'>8:30 PM</option>
                            <option value='8:45 PM'>8:45 PM</option>
                            <option value='9:00 PM'>9:00 PM</option>
                            <option value='9:15 PM'>9:15 PM</option>
                            <option value='9:30 PM'>9:30 PM</option>
                            <option value='9:45 PM'>9:45 PM</option>
                            <option value='10:00 PM'>10:00 PM</option>
                            <option value='10:15 PM'>10:15 PM</option>
                            <option value='10:30 PM'>10:30 PM</option>
                            <option value='10:45 PM'>10:45 PM</option>
                            <option value='11:00 PM'>11:00 PM</option>
                            <option value='11:15 PM'>11:15 PM</option>
                            <option value='11:30 PM'>11:30 PM</option>
                            <option value='11:45 PM'>11:45 PM</option>
                          </select>
                        </div>
                      </div>
                    );
                  })}

                  <div
                    className='transition-all duration-150 cursor-pointer text-gray-500 gap-6 bg-gray-100 hover:bg-gray-200 p-4 rounded-md text-center'
                    onClick={() => addDestination()}
                  >
                    + Add Another Destination
                  </div>
                </>
              ) : (
                <>
                  <div className='text-gray-700 gap-6 bg-gray-100 p-4 rounded-md'>
                    <span>Event Location</span>
                    <input
                      {...register('location', { required: 'Event location required' })}
                      className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                      placeholder='Enter event location'
                      defaultValue={''}
                    />
                    {errors.location !== undefined && (
                      <span className='text-red'>{`${
                        errors.location && errors.location.message
                      }`}</span>
                    )}
                  </div>
                  {dateArray.map((item, index) => {
                    return (
                      <div className='text-gray-700 grid grid-cols-8 gap-6 bg-gray-100 p-4 rounded-md'>
                        <div className='col-span-full md:col-span-4'>
                          <div className='mb-1'>
                            <span>Event Date {index + 1}</span>
                            {index >= 1 && (
                              <span
                                className='ml-3 font-light text-red cursor-pointer hover:underline'
                                onClick={() => removeDate(index)}
                              >
                                Remove date
                              </span>
                            )}
                          </div>
                          <DatePicker
                            className='rounded-md px-[8px] py-[8px] w-full sm:text-sm border border-gray-300'
                            dateFormat='dd/MM/yyyy'
                            selected={dateArray[index].date}
                            onChange={(date: Date) => {
                              populateDateArray(index, date, item.startTime, item.endTime);
                            }}
                          />
                        </div>
                        <div className='col-span-4 md:col-span-2'>
                          <div>Start time</div>
                          <select
                            className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                            placeholder='Start time'
                            value={item.startTime || ''}
                            onChange={(e) => {
                              populateDateArray(index, item.date, e.target.value, item.endTime);
                            }}
                          >
                            <option value='12:00 AM'>12:00 AM</option>
                            <option value='12:15 AM'>12:15 AM</option>
                            <option value='12:30 AM'>12:30 AM</option>
                            <option value='12:45 AM'>12:45 AM</option>
                            <option value='1:00 AM'>1:00 AM</option>
                            <option value='1:15 AM'>1:15 AM</option>
                            <option value='1:30 AM'>1:30 AM</option>
                            <option value='1:45 AM'>1:45 AM</option>
                            <option value='2:00 AM'>2:00 AM</option>
                            <option value='2:15 AM'>2:15 AM</option>
                            <option value='2:30 AM'>2:30 AM</option>
                            <option value='2:45 AM'>2:45 AM</option>
                            <option value='3:00 AM'>3:00 AM</option>
                            <option value='3:15 AM'>3:15 AM</option>
                            <option value='3:30 AM'>3:30 AM</option>
                            <option value='3:45 AM'>3:45 AM</option>
                            <option value='4:00 AM'>4:00 AM</option>
                            <option value='4:15 AM'>4:15 AM</option>
                            <option value='4:30 AM'>4:30 AM</option>
                            <option value='4:45 AM'>4:45 AM</option>
                            <option value='5:00 AM'>5:00 AM</option>
                            <option value='5:15 AM'>5:15 AM</option>
                            <option value='5:30 AM'>5:30 AM</option>
                            <option value='5:45 AM'>5:45 AM</option>
                            <option value='6:00 AM'>6:00 AM</option>
                            <option value='6:15 AM'>6:15 AM</option>
                            <option value='6:30 AM'>6:30 AM</option>
                            <option value='6:45 AM'>6:45 AM</option>
                            <option value='7:00 AM'>7:00 AM</option>
                            <option value='7:15 AM'>7:15 AM</option>
                            <option value='7:30 AM'>7:30 AM</option>
                            <option value='7:45 AM'>7:45 AM</option>
                            <option value='8:00 AM'>8:00 AM</option>
                            <option value='8:15 AM'>8:15 AM</option>
                            <option value='8:30 AM'>8:30 AM</option>
                            <option value='8:45 AM'>8:45 AM</option>
                            <option value='9:00 AM'>9:00 AM</option>
                            <option value='9:15 AM'>9:15 AM</option>
                            <option value='9:30 AM'>9:30 AM</option>
                            <option value='9:45 AM'>9:45 AM</option>
                            <option value='10:00 AM'>10:00 AM</option>
                            <option value='10:15 AM'>10:15 AM</option>
                            <option value='10:30 AM'>10:30 AM</option>
                            <option value='10:45 AM'>10:45 AM</option>
                            <option value='11:00 AM'>11:00 AM</option>
                            <option value='11:15 AM'>11:15 AM</option>
                            <option value='11:30 AM'>11:30 AM</option>
                            <option value='11:45 AM'>11:45 AM</option>
                            <option value='12:00 PM'>12:00 PM</option>
                            <option value='12:15 PM'>12:15 PM</option>
                            <option value='12:30 PM'>12:30 PM</option>
                            <option value='12:45 PM'>12:45 PM</option>
                            <option value='1:00 PM'>1:00 PM</option>
                            <option value='1:15 PM'>1:15 PM</option>
                            <option value='1:30 PM'>1:30 PM</option>
                            <option value='1:45 PM'>1:45 PM</option>
                            <option value='2:00 PM'>2:00 PM</option>
                            <option value='2:15 PM'>2:15 PM</option>
                            <option value='2:30 PM'>2:30 PM</option>
                            <option value='2:45 PM'>2:45 PM</option>
                            <option value='3:00 PM'>3:00 PM</option>
                            <option value='3:15 PM'>3:15 PM</option>
                            <option value='3:30 PM'>3:30 PM</option>
                            <option value='3:45 PM'>3:45 PM</option>
                            <option value='4:00 PM'>4:00 PM</option>
                            <option value='4:15 PM'>4:15 PM</option>
                            <option value='4:30 PM'>4:30 PM</option>
                            <option value='4:45 PM'>4:45 PM</option>
                            <option value='5:00 PM'>5:00 PM</option>
                            <option value='5:15 PM'>5:15 PM</option>
                            <option value='5:30 PM'>5:30 PM</option>
                            <option value='5:45 PM'>5:45 PM</option>
                            <option value='6:00 PM'>6:00 PM</option>
                            <option value='6:15 PM'>6:15 PM</option>
                            <option value='6:30 PM'>6:30 PM</option>
                            <option value='6:45 PM'>6:45 PM</option>
                            <option value='7:00 PM'>7:00 PM</option>
                            <option value='7:15 PM'>7:15 PM</option>
                            <option value='7:30 PM'>7:30 PM</option>
                            <option value='7:45 PM'>7:45 PM</option>
                            <option value='8:00 PM'>8:00 PM</option>
                            <option value='8:15 PM'>8:15 PM</option>
                            <option value='8:30 PM'>8:30 PM</option>
                            <option value='8:45 PM'>8:45 PM</option>
                            <option value='9:00 PM'>9:00 PM</option>
                            <option value='9:15 PM'>9:15 PM</option>
                            <option value='9:30 PM'>9:30 PM</option>
                            <option value='9:45 PM'>9:45 PM</option>
                            <option value='10:00 PM'>10:00 PM</option>
                            <option value='10:15 PM'>10:15 PM</option>
                            <option value='10:30 PM'>10:30 PM</option>
                            <option value='10:45 PM'>10:45 PM</option>
                            <option value='11:00 PM'>11:00 PM</option>
                            <option value='11:15 PM'>11:15 PM</option>
                            <option value='11:30 PM'>11:30 PM</option>
                            <option value='11:45 PM'>11:45 PM</option>
                          </select>
                        </div>
                        <div className='col-span-4 md:col-span-2'>
                          <div>End time</div>
                          <select
                            className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                            placeholder='End time'
                            value={item.endTime || ''}
                            onChange={(e) => {
                              populateDateArray(index, item.date, item.startTime, e.target.value);
                            }}
                          >
                            <option value='12:00 AM'>12:00 AM</option>
                            <option value='12:15 AM'>12:15 AM</option>
                            <option value='12:30 AM'>12:30 AM</option>
                            <option value='12:45 AM'>12:45 AM</option>
                            <option value='1:00 AM'>1:00 AM</option>
                            <option value='1:15 AM'>1:15 AM</option>
                            <option value='1:30 AM'>1:30 AM</option>
                            <option value='1:45 AM'>1:45 AM</option>
                            <option value='2:00 AM'>2:00 AM</option>
                            <option value='2:15 AM'>2:15 AM</option>
                            <option value='2:30 AM'>2:30 AM</option>
                            <option value='2:45 AM'>2:45 AM</option>
                            <option value='3:00 AM'>3:00 AM</option>
                            <option value='3:15 AM'>3:15 AM</option>
                            <option value='3:30 AM'>3:30 AM</option>
                            <option value='3:45 AM'>3:45 AM</option>
                            <option value='4:00 AM'>4:00 AM</option>
                            <option value='4:15 AM'>4:15 AM</option>
                            <option value='4:30 AM'>4:30 AM</option>
                            <option value='4:45 AM'>4:45 AM</option>
                            <option value='5:00 AM'>5:00 AM</option>
                            <option value='5:15 AM'>5:15 AM</option>
                            <option value='5:30 AM'>5:30 AM</option>
                            <option value='5:45 AM'>5:45 AM</option>
                            <option value='6:00 AM'>6:00 AM</option>
                            <option value='6:15 AM'>6:15 AM</option>
                            <option value='6:30 AM'>6:30 AM</option>
                            <option value='6:45 AM'>6:45 AM</option>
                            <option value='7:00 AM'>7:00 AM</option>
                            <option value='7:15 AM'>7:15 AM</option>
                            <option value='7:30 AM'>7:30 AM</option>
                            <option value='7:45 AM'>7:45 AM</option>
                            <option value='8:00 AM'>8:00 AM</option>
                            <option value='8:15 AM'>8:15 AM</option>
                            <option value='8:30 AM'>8:30 AM</option>
                            <option value='8:45 AM'>8:45 AM</option>
                            <option value='9:00 AM'>9:00 AM</option>
                            <option value='9:15 AM'>9:15 AM</option>
                            <option value='9:30 AM'>9:30 AM</option>
                            <option value='9:45 AM'>9:45 AM</option>
                            <option value='10:00 AM'>10:00 AM</option>
                            <option value='10:15 AM'>10:15 AM</option>
                            <option value='10:30 AM'>10:30 AM</option>
                            <option value='10:45 AM'>10:45 AM</option>
                            <option value='11:00 AM'>11:00 AM</option>
                            <option value='11:15 AM'>11:15 AM</option>
                            <option value='11:30 AM'>11:30 AM</option>
                            <option value='11:45 AM'>11:45 AM</option>
                            <option value='12:00 PM'>12:00 PM</option>
                            <option value='12:15 PM'>12:15 PM</option>
                            <option value='12:30 PM'>12:30 PM</option>
                            <option value='12:45 PM'>12:45 PM</option>
                            <option value='1:00 PM'>1:00 PM</option>
                            <option value='1:15 PM'>1:15 PM</option>
                            <option value='1:30 PM'>1:30 PM</option>
                            <option value='1:45 PM'>1:45 PM</option>
                            <option value='2:00 PM'>2:00 PM</option>
                            <option value='2:15 PM'>2:15 PM</option>
                            <option value='2:30 PM'>2:30 PM</option>
                            <option value='2:45 PM'>2:45 PM</option>
                            <option value='3:00 PM'>3:00 PM</option>
                            <option value='3:15 PM'>3:15 PM</option>
                            <option value='3:30 PM'>3:30 PM</option>
                            <option value='3:45 PM'>3:45 PM</option>
                            <option value='4:00 PM'>4:00 PM</option>
                            <option value='4:15 PM'>4:15 PM</option>
                            <option value='4:30 PM'>4:30 PM</option>
                            <option value='4:45 PM'>4:45 PM</option>
                            <option value='5:00 PM'>5:00 PM</option>
                            <option value='5:15 PM'>5:15 PM</option>
                            <option value='5:30 PM'>5:30 PM</option>
                            <option value='5:45 PM'>5:45 PM</option>
                            <option value='6:00 PM'>6:00 PM</option>
                            <option value='6:15 PM'>6:15 PM</option>
                            <option value='6:30 PM'>6:30 PM</option>
                            <option value='6:45 PM'>6:45 PM</option>
                            <option value='7:00 PM'>7:00 PM</option>
                            <option value='7:15 PM'>7:15 PM</option>
                            <option value='7:30 PM'>7:30 PM</option>
                            <option value='7:45 PM'>7:45 PM</option>
                            <option value='8:00 PM'>8:00 PM</option>
                            <option value='8:15 PM'>8:15 PM</option>
                            <option value='8:30 PM'>8:30 PM</option>
                            <option value='8:45 PM'>8:45 PM</option>
                            <option value='9:00 PM'>9:00 PM</option>
                            <option value='9:15 PM'>9:15 PM</option>
                            <option value='9:30 PM'>9:30 PM</option>
                            <option value='9:45 PM'>9:45 PM</option>
                            <option value='10:00 PM'>10:00 PM</option>
                            <option value='10:15 PM'>10:15 PM</option>
                            <option value='10:30 PM'>10:30 PM</option>
                            <option value='10:45 PM'>10:45 PM</option>
                            <option value='11:00 PM'>11:00 PM</option>
                            <option value='11:15 PM'>11:15 PM</option>
                            <option value='11:30 PM'>11:30 PM</option>
                            <option value='11:45 PM'>11:45 PM</option>
                          </select>
                        </div>
                      </div>
                    );
                  })}

                  <div
                    className='transition-all duration-150 cursor-pointer text-gray-500 gap-6 bg-gray-100 hover:bg-gray-200 p-4 rounded-md text-center'
                    onClick={() => addDate()}
                  >
                    + Add Another Date
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            {addEventloading ? (
              <LoadingSpinner />
            ) : (
              <button
                type='submit'
                className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 text-sm font-semibold rounded-3xl'
              >
                Create Event
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEvent;
