import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { CREATE_ARTICLE } from '../ArticleMutations';
import { ArticleTemplate } from '../AddArticle';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import WYSIWYGEditor from '@/components/utils/WYSIWYGEditor';
import { ImageUploadSection } from './small-components/ImageUploadSection';
import { disableKeyEnter } from '@/utils/utilities';
import ProjectSearch from '@/components/utils/ProjectSearch';
import { ImageUpload, useImageUpload } from '@/components/utils/imageUploadHook';
import AdvertiserSearch from '@/components/utils/AdvertiserSearch';

function ArticleTemplateComponent({
  template,
  projectSelector,
}: {
  template: ArticleTemplate;
  projectSelector?: boolean;
}) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [addArticleMutation] = useMutation(CREATE_ARTICLE);
  const [articleCoverImageError, setArticleCoverImageError] = useState(false);
  const [formTags, setFormTags] = useState<any[]>([]);
  // const [bodyContent, setBodyContent] = useState<{ index: number; text: string } | undefined>(
  //   undefined,
  // );

  const [bodyContentList, setBodyContentList] = useState<string[]>([]);

  // const [selectedProjects, setSelectedProjects] = useState<any>([]);
  const [selectedMainProject, setSelectedMainProject] = useState<any>([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<any>([]);

  const [articleUploading, setArticleUploading] = useState<boolean>(false);
  const { imageUploadErrors, getImageUrlsFromState, articleImages, setImage } = useImageUpload();

  const [additionalImageCount, setImageCount] = useState<number>(0);

  const handleAddArticle = async (inputs: any) => {
    if (!articleImages?.length) {
      setArticleCoverImageError(true);
      return;
    }

    setArticleUploading(true);

    await uploadArticle({ ...inputs, bodyContentList });
  };

  const uploadArticle = async (inputs: any) => {
    setFormError('');
    const { heading, subHeading } = inputs;

    try {
      const images = await getImageUrlsFromState();

      const { data } = await addArticleMutation({
        variables: {
          input: {
            heading,
            subHeading,
            type: '', // TODO: this field still required?
            bodyContentList: bodyContentList.map((content: string, index: number) => ({
              index,
              text: content,
            })),
            tags: formTags,
            articleTemplate: template,
            mainProject: selectedMainProject[0],
            taggedAdvertisers: selectedAdvertisers,
            images,
          },
        },
      });

      if (data) {
        navigate('/articles');
        setArticleUploading(false);
      }
    } catch (err: any) {
      setFormError(err.message);
      setArticleUploading(false);
    }
  };

  // const handleWYSIWYGFormStateUpdate = (convertedWYSIWYGContent: string) => {
  //   setBodyContent({ index: 0, text: convertedWYSIWYGContent });
  // };

  const handleWYSIWYGFormStateUpdate = (
    convertedWYSIWYGContent: string,
    contentPosition: number,
  ) => {
    const newBodyContentList = [
      ...bodyContentList.slice(0, contentPosition),
      convertedWYSIWYGContent,
      ...bodyContentList.slice(contentPosition + 1),
    ];
    setBodyContentList(newBodyContentList);
  };

  // Generate new templates to be rendered dynamically
  return (
    <form
      onSubmit={handleSubmit(handleAddArticle)}
      onKeyDown={(e) => disableKeyEnter(e)}
      className=''
    >
      <div className='sm:rounded-md sm:overflow-hidden'>
        <div className='bg-white grid sm:p-6 gap-6 relative'>
          <div className='col-span-full'>
            <ImageUploadSection
              labelText={'Cover Image'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[0]}
              positionInArticle={0}
              smallPreview={true}
            />
          </div>
          {articleCoverImageError ? (
            <span className='text-red'>Article cover image required</span>
          ) : null}

          <div className='col-span-full'>
            <label htmlFor='heading' className='text-lg font-bold text-gray-700'>
              Heading
            </label>
            <input
              id='heading'
              {...register('heading', { required: 'Article name required' })}
              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border rounded-md'
              placeholder='Enter the heading of your new Article'
              defaultValue={''}
            />
            {errors.heading !== undefined && (
              <span className='text-red'>{`${errors.heading && errors.heading.message}`}</span>
            )}
          </div>

          <div className='col-span-full'>
            <label htmlFor='subheading' className='block font-bold text-sm text-gray-700'>
              Sub heading <span className='text-[8px]'>(optional)</span>
            </label>
            <input
              id='subheading'
              {...register('subHeading')}
              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
              placeholder='Enter the subheading of your new Article'
              defaultValue={''}
            />
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-700'>Search for Tags</label>
            <TagSearch tagType={MHPSearchTagType.Article} handleFormTags={setFormTags} />
          </div>

          {projectSelector ? (
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Add Main Article Project
              </label>
              <span className='text-[8pt] font-medium text-gray-700'>Select One</span>
              <ProjectSearch handleFormUpdates={setSelectedMainProject} maxSelection={1} />
            </div>
          ) : (
            <></>
          )}

          <div>
            <label className='block text-sm font-medium text-gray-700'>
              Add Advertisers to Article
            </label>
            <span className='text-[8pt] font-medium text-gray-700'>Select up to five</span>
            <AdvertiserSearch handleFormUpdates={setSelectedAdvertisers} maxSelection={5} />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={0}
            />
            {errors.bodyContent !== undefined && (
              <span className='text-red'>{`${
                errors.bodyContent && errors.bodyContent.message
              }`}</span>
            )}
          </div>

          <div className='lg:col-span-1 lg:mr-2 break'>
            <ImageUploadSection
              labelText={'Image #1'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[1]}
              positionInArticle={1}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={1}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #2'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[2]}
              positionInArticle={2}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={2}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #3'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[3]}
              positionInArticle={3}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={3}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #4'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[4]}
              positionInArticle={4}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={4}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #5'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[5]}
              positionInArticle={5}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={5}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #6'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[6]}
              positionInArticle={6}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={6}
            />
          </div>

          <div className='lg:col-span-1 lg:mr-2'>
            <ImageUploadSection
              labelText={'Image #7'}
              articleImages={articleImages}
              setImage={setImage}
              articleImageError={imageUploadErrors[7]}
              positionInArticle={7}
              smallPreview={true}
            />
          </div>

          <div className='col-span-full'>
            <WYSIWYGEditor
              formStateUpdateMethod={handleWYSIWYGFormStateUpdate}
              formLabel={'Content/Description'}
              contentSectionCount={7}
            />
          </div>

          <div className='grid grid-cols-3 gap-6'>
            {Array.from(Array(additionalImageCount), (e, i) => {
              return (
                <ImageUploadSection
                  labelText={`Extra image ${i + 1}`}
                  articleImages={articleImages}
                  setImage={setImage}
                  articleImageError={imageUploadErrors[i + 8]}
                  positionInArticle={i + 8}
                  smallPreview={true}
                />
              );
            })}
          </div>

          <div
            className='justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-150 bg-red hover:bg-darkgrey my-2 mx-1 text-sm font-semibold rounded-full w-fit'
            onClick={() =>
              setImageCount((prevCount) => {
                return prevCount + 1;
              })
            }
          >
            + Add another image
          </div>
        </div>
        <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
          {articleUploading ? (
            <LoadingSpinner />
          ) : (
            <button
              type='submit'
              className='inline-flex justify-center whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            >
              Create Article
            </button>
          )}
        </div>
      </div>
    </form>
  );
}

export default ArticleTemplateComponent;
