// Import packages
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';

// Import icons
import PistonIcon from '@/assets/icons/PistonIcon';
import TestImage from '@/assets/images/no_image_found.png';
import { useState, useEffect } from 'react';
import PhotoPopOut from '@/utils/photos/PhotoPopOut';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';

export interface ProjectData {
  _id: string;
  createdBy: any;
  name: string;
  year: string;
  make: string;
  model: string;
  badge: string;
  paint: string;
  description: string;
  tags: Array<any>;
  taggedAdvertisers: [{ companyName: string; id: string }];
  images: Array<string>;
  engineManufacturer: string;
  manifold: string;
  cylinderHeads: string;
  fuel: string;
  exhaust: string;
  horsepower: string;
  engine: any;
  driveline: any;
  undercarriage: any;
  rollingStock: any;
}

export interface UserData {
  username: string;
}

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: ID) {
    getProjectById(projectId: $projectId) {
      _id
      createdBy
      name
      year
      make
      model
      badge
      tags
      taggedAdvertisers {
        companyName
        id
      }
      images
      description
      paint
      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query Query($userId: ID) {
    getUserById(userId: $userId) {
      _id
      firstName
      lastName
      username
      profilePicture
      advertiserDetails {
        companyName
        coverPhoto
      }
    }
  }
`;

export const FIND_ARTICLES_BY_MAIN_PROJECT_ID = gql`
  query FindArticlesByMainProjectId($projectId: ID) {
    findArticlesByMainProjectId(projectId: $projectId) {
      _id
      heading
      subHeading
      articleTemplate
      images {
        url
      }
    }
  }
`;

const SingleProject = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { projectId } = useParams();
  const { userData } = useSelector((state: RootState) => state.user);

  const { data: projectData2 } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: projectId,
    },
  });

  const { data: projectCreatorData2 } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: projectData2?.getProjectById?.createdBy,
    },
  });

  const { data: articlesData2 } = useQuery(FIND_ARTICLES_BY_MAIN_PROJECT_ID, {
    variables: {
      projectId: projectId,
    },
  });

  const [getAdvertiserById, { loading: advertiserSearchLoading, error: advertiserError }] =
    useLazyQuery(GET_USER_BY_ID);

  const [projectData, setProjectDataLoaded] = useState<ProjectData>();
  const [projectCreatorData, setProjectCreatorDataLoaded] = useState<any>();
  const [taggedAdvertisersData, setTaggedAdvertisersLoaded] = useState<any[]>();
  const [relatedArticlesData, setRelatedArticlesLoaded] = useState<any[]>();

  useEffect(() => {
    const fetchTaggedAdvertisers = async () => {
      if (projectData2) {
        setProjectDataLoaded(projectData2?.getProjectById);

        if (projectData2?.getProjectById.taggedAdvertisers) {
          const taggedAdvertisers = projectData2?.getProjectById.taggedAdvertisers;
          const fetchedAdvertisers = [];

          for (const advertiser of taggedAdvertisers) {
            const { data, loading, error } = await getAdvertiserById({
              variables: { userId: advertiser.id },
            });

            if (!loading && !error && data && data.getUserById) {
              fetchedAdvertisers.push(data.getUserById);
            }
          }
          setTaggedAdvertisersLoaded(fetchedAdvertisers);
        }
      }
    };
    if (projectCreatorData2) {
      setProjectCreatorDataLoaded(projectCreatorData2?.getUserById);
    }
    if (articlesData2) {
      setRelatedArticlesLoaded(articlesData2?.findArticlesByMainProjectId);
    }
    fetchTaggedAdvertisers();
    console.log(projectData2);
  }, [projectData2, projectCreatorData2, articlesData2]);

  const {
    year,
    make,
    model,
    badge,
    paint,
    description,
    tags,
    taggedAdvertisers,
    images,
    engine,
    driveline,
    undercarriage,
    rollingStock,
  } = projectData ?? ({} as ProjectData);

  const checkObjectFields = (obj: any) => {
    for (const key in obj) {
      if (key !== '__typename') {
        const value = obj[key];

        if (Array.isArray(value)) {
          // If the value is an array, recursively check its elements
          for (const element of value) {
            if (checkObjectFields(element)) {
              return true;
            }
          }
        } else if (typeof value === 'object' && value !== null) {
          // If the value is an object, recursively check its fields
          if (checkObjectFields(value)) {
            return true;
          }
        } else if (value !== '' && value !== null) {
          // If the value is not an array or object and is non-empty, return true
          return true;
        }
      }
    }
    return false;
  };

  const [photoPopOut, setPhotoPopOut] = useState<boolean>(false);
  const [popOutIndex, setPopOutIndex] = useState<number>();

  function enlargePhoto(index: number) {
    setPopOutIndex(index);
    setPhotoPopOut(true);
  }

  return (
    <>
      {!projectData ? (
        <LoadingSpinner />
      ) : (
        <div className='grid grid-cols-12'>
          {photoPopOut && (
            <PhotoPopOut
              imageArray={images}
              imageIndex={popOutIndex}
              closeFunction={() => setPhotoPopOut(false)}
            />
          )}
          <div className='col-span-12 lg:col-span-8 relative'>
            <div className='grid grid-cols-2 text-white font-semibold items-center text-xl mb-2 w-full'>
              <button
                className='col-start-1 w-fit whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 py-2 px-4 text-sm font-semibold rounded-full'
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
              {userData._id === projectData.createdBy && (
                <Link
                  to={`/edit-project/${projectData._id}`}
                  className='flex flex-row text-white font-semibold items-center text-xl justify-end ml-4'
                >
                  <div className='col-start-2 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 py-2 px-4 text-sm font-semibold rounded-full'>
                    Edit Project
                  </div>
                </Link>
              )}
            </div>

            <div className='relative grid grid-cols-12'>
              <div
                className='cursor-pointer col-span-12 rounded-t-md h-[420px] bg-cover bg-center'
                style={{
                  backgroundImage: `url(${projectData && images[0] ? images[0] : TestImage})`,
                }}
                onClick={() => enlargePhoto(0)}
              />
            </div>

            <div className='p-6 gap-2 space-y-6 mb-6 text-white rounded-b-md bg-[#241B23]'>
              <div className='font-[Montserrat] font-extrabold italic text-5xl mb-2 line-clamp-2'>
                {projectData?.name}
              </div>
              <span className='font-[Montserrat] font-regular text-lg'>
                {year} {make} {model} {badge} | {paint}
              </span>

              <Link to={`/profile/${projectCreatorData?.username}`}>
                <div className='col-start-1 col-span-6 font-extrabold uppercase text-[8pt] text-red mt-2 mb-3 hover:underline'>
                  Project Created by{' '}
                  {projectCreatorData?.advertiserDetails.companyName ||
                    projectCreatorData?.username ||
                    `${projectCreatorData?.firstName} ${projectCreatorData?.lastName}`}
                </div>
              </Link>
              {tags[0] && (
                <div>
                  <ul className='flex flex-wrap'>
                    {tags.map((tag: string) => {
                      return (
                        <li
                          className='inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-[#352833] text-red text-[8pt] font-bold'
                          key={tag}
                        >
                          {tag}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              <div className='bg-[#705F6E]/30 h-[1px] col-span-full'></div>

              <div className='space-y-8'>
                <div>
                  <div className='font-[Montserrat] font-extrabold text-xl'>Owner description</div>
                  <div className='overflow-y-scroll max-h-[200px] font-light pr-2'>
                    <div>{description}</div>
                  </div>
                </div>

                {checkObjectFields(projectData?.engine) && (
                  <div className='grid grid-cols-3 lg:grid-cols-4 gap-4'>
                    <div className='font-[Montserrat] -mb-2 font-extrabold text-xl col-span-full'>
                      Engine details
                    </div>
                    {engine?.engineManufacturer && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Engine</div>
                        <div className='font-light'>{engine?.engineManufacturer}</div>
                      </div>
                    )}
                    {engine?.displacement && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Displacement</div>
                        <div className='font-light'>{engine?.displacement}</div>
                      </div>
                    )}
                    {engine?.manifold && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Manifold</div>
                        <div className='font-light'>{engine?.manifold}</div>
                      </div>
                    )}
                    {engine?.cylinderHeads && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Cylinder heads</div>
                        <div className='font-light'>{engine?.cylinderHeads}</div>
                      </div>
                    )}
                    {engine?.fuel && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Fuel</div>
                        <div className='font-light'>{engine?.fuel}</div>
                      </div>
                    )}
                    {engine?.exhaust && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Exhaust</div>
                        <div className='font-light'>{engine?.exhaust}</div>
                      </div>
                    )}
                    {engine?.horsepower && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Horsepower</div>
                        <div className='font-light'>{engine?.horsepower}</div>
                      </div>
                    )}
                    {engine?.induction && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Induction</div>
                        <div className='font-light'>{engine?.induction}</div>
                      </div>
                    )}
                    {engine?.inductionInfo && (
                      <div className='col-span-full'>
                        <div className='font-extrabold uppercase text-[8pt]'>Induction info</div>
                        <div className='overflow-y-scroll max-h-[150px] font-light'>
                          {engine?.inductionInfo}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {checkObjectFields(projectData?.engine?.internals) && (
                  <div className='grid grid-cols-3 lg:grid-cols-4 gap-4'>
                    <div className='font-[Montserrat] -mb-2 font-extrabold text-xl col-span-full'>
                      Engine internals
                    </div>
                    {engine?.internals?.camshaft && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Camshaft</div>
                        <div className='font-light'>{engine?.internals?.camshaft}</div>
                      </div>
                    )}
                    {engine?.internals?.crankshaft && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Crankshaft</div>
                        <div className='font-light'>{engine?.internals?.crankshaft}</div>
                      </div>
                    )}
                    {engine?.internals?.pistons && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Pistons</div>
                        <div className='font-light'>{engine?.internals?.pistons}</div>
                      </div>
                    )}
                    {engine?.internals?.rotors && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Rotors</div>
                        <div className='font-light'>{engine?.internals?.rotors}</div>
                      </div>
                    )}
                    {engine?.internals?.conrods && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Conrods</div>
                        <div className='font-light'>{engine?.internals?.conrods}</div>
                      </div>
                    )}
                    {engine?.internals?.ignition && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Ignition</div>
                        <div className='font-light'>{engine?.internals?.ignition}</div>
                      </div>
                    )}
                  </div>
                )}

                {checkObjectFields(projectData?.driveline) && (
                  <div className='grid grid-cols-3 lg:grid-cols-4 gap-4'>
                    <div className='font-[Montserrat] -mb-2 font-extrabold text-xl col-span-full'>
                      Driveline
                    </div>
                    {driveline?.gearbox && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Gearbox</div>
                        <div className='font-light'>{driveline?.gearbox}</div>
                      </div>
                    )}
                    {driveline?.clutch && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Clutch</div>
                        <div className='font-light'>{driveline?.clutch}</div>
                      </div>
                    )}
                    {driveline?.differential && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Differential</div>
                        <div className='font-light'>{driveline?.differential}</div>
                      </div>
                    )}
                    {driveline?.torqueConverter && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Torque converter</div>
                        <div className='font-light'>{driveline?.torqueConverter}</div>
                      </div>
                    )}
                  </div>
                )}

                {checkObjectFields(projectData?.undercarriage) && (
                  <div className='grid grid-cols-3 lg:grid-cols-4 gap-4'>
                    <div className='font-[Montserrat] -mb-2 font-extrabold text-xl col-span-full'>
                      Undercarriage
                    </div>
                    {undercarriage?.brakes && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Brakes</div>
                        <div className='font-light'>{undercarriage?.brakes}</div>
                      </div>
                    )}
                    {checkObjectFields(projectData?.undercarriage?.suspension) && (
                      <>
                        {undercarriage?.suspension?.springs && (
                          <div>
                            <div className='font-extrabold uppercase text-[8pt]'>Springs</div>
                            <div className='font-light'>{undercarriage?.suspension?.springs}</div>
                          </div>
                        )}
                        {undercarriage?.suspension?.shocks && (
                          <div>
                            <div className='font-extrabold uppercase text-[8pt]'>Shocks</div>
                            <div className='font-light'>{undercarriage?.suspension?.shocks}</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {checkObjectFields(projectData?.rollingStock) && (
                  <div className='grid grid-cols-3 lg:grid-cols-4 gap-4'>
                    <div className='font-[Montserrat] -mb-2 font-extrabold text-xl col-span-full'>
                      Rolling stock
                    </div>
                    {rollingStock?.wheels && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Rims</div>
                        <div className='font-light'>{rollingStock?.wheels}</div>
                      </div>
                    )}
                    {rollingStock?.tyres && (
                      <div>
                        <div className='font-extrabold uppercase text-[8pt]'>Tyres</div>
                        <div className='font-light'>{rollingStock?.tyres}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-span-full lg:col-span-4 lg:ml-9 mb-10 space-y-8'>
            {projectData && images[1] && (
              <div>
                <div className='font-[Montserrat] font-extrabold italic text-xl col-span-full mt-3 mb-5'>
                  Gallery
                </div>
                <div className='grid grid-cols-2 gap-4'>
                  {images
                    .map((image: string, index: number) => {
                      return (
                        <img
                          key={index}
                          className='object-cover col-span-1 lg:col-span-2 cursor-pointer rounded-md transition-all duration-200 ease-in-out'
                          src={image}
                          alt={`Gallery pic ${index}`}
                          onClick={() => enlargePhoto(index)}
                        />
                      );
                    })
                    .splice(1)}
                </div>{' '}
              </div>
            )}

            {articlesData2?.findArticlesByMainProjectId[0] && (
              <RelatedArticles articleData={relatedArticlesData} />
            )}

            {projectData.taggedAdvertisers[0] && (
              <TaggedAdvertisers advertiserData={taggedAdvertisersData} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SingleProject;

const TaggedAdvertisers = ({ advertiserData }: any) => {
  return (
    <div className='p-2 bg-[#241B23] rounded-md'>
      <h4 className='text-[8pt] font-black uppercase mb-2'>Related Suppliers</h4>
      <ul className='space-y-2'>
        {advertiserData?.map((advertiser: any) => {
          return (
            <Link
              key={advertiser._id}
              to={`/profile/${advertiser.username}`}
              className={`transition-all duration-100 bg-cover bg-center h-full flex text-center font-semibold cursor-pointer rounded-md`}
              style={{
                backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
              }}
            >
              <div className='bg-[#00000080] hover:bg-[#00000040] h-full w-full rounded-md transition-all duration-100 flex items-center justify-center'>
                <div
                  className={`text-white m-4 font-[Montserrat] rounded-md group-hover:opacity-75`}
                >
                  <div className='italic font-bold'>{advertiser.advertiserDetails.companyName}</div>
                  <div className='text-xs font-light'>Click to visit</div>
                </div>
              </div>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

const RelatedArticles = ({ articleData }: any) => {
  return (
    <div>
      <h2 className='text-lg font-bold mb-3'>Related Articles</h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4'>
        {articleData
          ?.map((article: any) => {
            return (
              <div key={article._id}>
                <Link to={`/articles/${article._id}`}>
                  <div className='grid grid-cols-2 col-span-1 h-full items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                    <div
                      className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                      style={{
                        backgroundImage: `url(${article.images[0].url})`,
                      }}
                    ></div>
                    <div className='col-span-1 p-4 text-white'>
                      <h4 className='font-extrabold uppercase text-[8pt] mb-1'>
                        {article.articleTemplate}
                      </h4>
                      <h4 className='line-clamp-2 mb-1 font-semibold'>{article.heading}</h4>
                      <h5 className='text-[8pt] line-clamp-3 font-light'>{article.subHeading}</h5>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
          .reverse()
          .splice(0, 5)}
      </div>
    </div>
  );
};
