import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { XIcon } from '@heroicons/react/solid';
import { useTagSearch } from './search/searchHooks';
import { useEffect } from 'react';

export enum MHPSearchTagType {
  Article = 'article',
  Project = 'project',
  Product = 'product',
  QuickEnquiry = 'quick enquiry',
  Event = 'event',
  Video = 'video',
}

function TagSearch({
  tagType,
  handleFormTags,
  tagLimit,
  editTags,
}: {
  tagType: MHPSearchTagType;
  handleFormTags: Function;
  tagLimit?: number | undefined;
  editTags?: string[] | undefined;
}) {
  const {
    selectedTags,
    tagSearchLoading,
    suggestionsActive,
    searchResultTags,
    handleRemoveTag,
    handleTagSearch,
    toggleSelect,
  } = useTagSearch(tagLimit);

  useEffect(() => {
    if (editTags) {
      editTags?.forEach((tag) => {
        toggleSelect(tag, handleFormTags);
      });
    }
  }, [editTags]);

  return (
    <div>
      <div className='mt-1'>
        <input
          type='search'
          placeholder={
            tagType === MHPSearchTagType.QuickEnquiry
              ? `What are you looking for?`
              : `Help people find your ${tagType} by adding tags`
          }
          className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          onChange={handleTagSearch}
        />
        {selectedTags && (
          <ul>
            {selectedTags.map((tag, index) => (
              <div
                className='bg-red50 inline-flex items-center text-sm rounded mt-2 mr-1 p-1 mb-1'
                key={index}
              >
                <span
                  className='ml-2 mr-1 leading-relaxed truncate max-w-xs text-gray-700 font-bold'
                  key={index}
                >
                  {tag}
                </span>
                <span
                  onClick={() => handleRemoveTag(tag, handleFormTags)}
                  className='ml-1 cursor-pointer'
                >
                  <XIcon className='h-4 w-4 text-red' aria-hidden='true' />
                </span>
              </div>
            ))}
          </ul>
        )}
        {tagSearchLoading ? <LoadingSpinner /> : null}
        {suggestionsActive && searchResultTags ? (
          <div
            className={
              tagType === MHPSearchTagType.QuickEnquiry || tagType === MHPSearchTagType.Video
                ? `mt-1 grid grid-cols gap-y-1 gap-x-1 grid-cols-1 xl:gap-x-8 max-h-[200px] overflow-x-scroll`
                : `mt-1 grid grid-cols gap-y-1 gap-x-1 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8`
            }
          >
            {searchResultTags.map((tag: any, i: any) => (
              <div
                key={i}
                onClick={() => {
                  toggleSelect(tag, handleFormTags);
                }}
                className='group relative cursor-pointer'
              >
                <div
                  className={`py-1 px-2 w-full text-black bg-gray-100 rounded-md group-hover:opacity-75 border-2 ${
                    selectedTags.includes(tag) ? 'border-red' : 'border-transparent'
                  } hover:border-red hover:bg-gray-200 transition duration-300 ease-in-out`}
                >
                  <div>{tag}</div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default TagSearch;
