import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  GET_VIDEO_BY_ID,
  GET_USER_BY_ID,
  GET_RANDOM_VIDEO,
  GET_RANDOM_SHORT,
} from '@/utils/queries/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useVideo } from '@/components/VideoContext';

interface Video {
  _id: string;
  createdBy: string;
  createdAt: Date;
  videoUrl: string;
  caption: string;
  tags: string[];
}

export default function SingleVideoPage() {
  const navigate = useNavigate();
  const { videoId } = useParams();
  const { prevVideoId, setPreviousVideoId } = useVideo();

  const { data: videoData2 } = useQuery(GET_VIDEO_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      videoId,
    },
  });

  const [getUserById, { data: videoCreatorData2 }] = useLazyQuery(GET_USER_BY_ID);
  const [getPrevVideoById, { data: prevVideoData2 }] = useLazyQuery(GET_VIDEO_BY_ID);
  const [getRandomVideo, { data: nextVideoData2 }] = useLazyQuery(GET_RANDOM_VIDEO);
  const [getRandomShort, { data: nextShortData2 }] = useLazyQuery(GET_RANDOM_SHORT);

  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(window.innerHeight);
  const videoData = videoData2?.getVideoById;
  const videoCreatorData = videoCreatorData2?.getUserById;
  const prevVideoData = prevVideoData2?.getVideoById;

  const [nextVideoData, setNextVideoData] = useState<Video>();

  useEffect(() => {
    if (videoData) {
      getUserById({
        variables: {
          userId: videoData.createdBy,
        },
      });
      if (videoData.videoUrl.includes('/shorts/')) {
        getRandomShort({ fetchPolicy: 'no-cache', variables: { videoId } });
      } else {
        getRandomVideo({ fetchPolicy: 'no-cache', variables: { videoId } });
      }
    }
  }, [videoData]);

  useEffect(() => {
    if (nextShortData2) {
      setNextVideoData(nextShortData2?.getRandomShort);
    }
  }, [nextShortData2]);

  useEffect(() => {
    if (nextVideoData2) {
      setNextVideoData(nextVideoData2?.getRandomVideo);
    }
  }, [nextVideoData2]);

  useEffect(() => {
    if (prevVideoId) {
      getPrevVideoById({ fetchPolicy: 'no-cache', variables: { videoId: prevVideoId } });
    }
  }, [prevVideoId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowInnerHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function handleNextVideo() {
    setPreviousVideoId(videoId);
    navigate(`/videos/${nextVideoData?._id}`);
  }

  return (
    <div
      style={{ height: windowInnerHeight - 110 + 'px' }}
      className='flex mt-3 items-center justify-center relative'
    >
      {videoData && (
        <>
          <div className='h-full sm:h-full w-full sm:max-w-full sm:w-fit z-20 relative'>
            <div
              className={`overflow-hidden rounded-t-md ${
                videoData.videoUrl.includes('/shorts/')
                  ? 'aspect-[9/16] sm:h-[88%]'
                  : 'aspect-[16/9] sm:h-[68%] w-full sm:mt-[8%]'
              }`}
            >
              <VideoPlayer videoData={videoData} isPlaying={true} />
            </div>
            {videoCreatorData && (
              <div className='p-3 space-y-2 h-[12%] bg-black2 rounded-b-md'>
                <Link
                  className='flex space-x-1 w-fit items-center group'
                  to={`/profile/${videoCreatorData.username}`}
                >
                  <div
                    className='h-[20px] w-[20px] rounded-full bg-contain bg-center'
                    style={{ backgroundImage: `url(${videoCreatorData.profilePicture})` }}
                  />
                  <div className='text-[8pt] group-hover:underline'>
                    {videoCreatorData.username}
                  </div>
                </Link>
                <div className='max-h-20 overflow-y-scroll line-clamp-2'>{videoData?.caption}</div>

                <ul className='flex flex-wrap'>
                  {videoData.tags &&
                    videoData.tags.map((tag: string) => {
                      return (
                        <li
                          className='inline-flex select-none rounded-full mr-1 mt-[2px] py-[2px] px-[6px] uppercase bg-[#352833] text-red text-[7pt] font-bold'
                          key={tag}
                        >
                          {tag}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>

          {prevVideoId ? (
            <div
              className={`group cursor-pointer hidden md:block absolute transition-all duration-200 ease-in-out rounded-md overflow-hidden ${
                videoData.videoUrl.includes('/shorts/')
                  ? 'left-[220px] hover:left-[215px] h-[75%] aspect-[9/16]'
                  : 'left-[10px] hover:left-[5px] h-[50%] aspect-[16/9]'
              }`}
            >
              {prevVideoData && <VideoPlayer videoData={prevVideoData} isPlaying={false} />}
              <Link
                to={`/videos/${prevVideoId}`}
                className='absolute flex items-center inset-0 bg-black/60 hover:bg-black/50 transition-all duration-200 backdrop-blur-md'
              >
                <div className='absolute left-4 rotate-180'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-[15px] fill-white opacity-70 group-hover:opacity-100 transition-all duration-200'
                    viewBox='0 0 13.503 23.616'
                  >
                    <path
                      d='M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z'
                      transform='translate(-11.246 -6.196)'
                    />
                  </svg>
                </div>
              </Link>
            </div>
          ) : (
            <div
              className={`group hidden md:block bg-black2/50 absolute transition-all duration-200 ease-in-out rounded-md overflow-hidden ${
                videoData.videoUrl.includes('/shorts/')
                  ? 'left-[220px] h-[75%] aspect-[9/16]'
                  : 'left-[10px] h-[50%] aspect-[16/9]'
              }`}
            ></div>
          )}

          {nextVideoData && (
            <div
              className={`group cursor-pointer hidden md:block absolute transition-all duration-200 ease-in-out rounded-md overflow-hidden ${
                videoData.videoUrl.includes('/shorts/')
                  ? 'right-[220px] hover:right-[215px] h-[75%] aspect-[9/16]'
                  : 'right-[10px] hover:right-[5px] h-[50%] aspect-[16/9]'
              }`}
            >
              <VideoPlayer videoData={nextVideoData} isPlaying={false} />
              <div
                onClick={handleNextVideo}
                className='absolute flex items-center inset-0 bg-black/60 hover:bg-black/50 transition-all duration-200 backdrop-blur-md'
              >
                <div className='absolute right-4'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-[15px] fill-white opacity-70 group-hover:opacity-100 transition-all duration-200'
                    viewBox='0 0 13.503 23.616'
                  >
                    <path
                      d='M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z'
                      transform='translate(-11.246 -6.196)'
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function VideoPlayer({ videoData, isPlaying }: { videoData: Video; isPlaying: Boolean }) {
  const playerRef = useRef<ReactPlayer | null>(null);
  const { videoUrl } = videoData;
  let playerProps: any = {};

  const handleVideoEnded = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      if (videoUrl.includes('youtube.com')) {
        playerRef.current.getInternalPlayer().playVideo();
      }
    }
  };

  if (videoUrl.includes('youtube.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      config: {
        youtube: {
          playerVars: { autoplay: isPlaying ? 1 : 0, loop: 1 },
        },
      },
    };
  } else if (videoUrl.includes('vimeo.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      config: {
        vimeo: {
          playerOptions: {
            title: false,
            portrait: true,
          },
        },
      },
    };
  } else if (videoUrl.includes('facebook.com')) {
    playerProps = {
      url: videoUrl,
      playing: isPlaying,
      config: {
        facebook: {
          autoplay: isPlaying ? 1 : 0,
        },
      },
    };
  } else {
    return <div>Unsupported video source</div>;
  }

  return (
    <ReactPlayer
      {...playerProps}
      onEnded={() => {
        return videoUrl.includes('/shorts/') ? handleVideoEnded() : null;
      }}
      ref={playerRef}
      width='100%'
      height='100%'
      controls
    />
  );
}
