import React, { useEffect } from 'react';
import BinIcon from '@/assets/icons/BinIcon';
import CrossIcon from '@/assets/icons/CrossIcon';
import TickIcon from '@/assets/icons/TickIcon';
import { useState } from 'react';

function DeleteItemButton({ deleteFunction, crossColour }: any) {
  const [deleteClicked, setDeleteClicked] = useState<Boolean>(false);

  useEffect(() => {
    setDeleteClicked(false);
  }, [deleteFunction]);

  return (
    <div className='drop-shadow-md transition-all duration-100 ease-in-out'>
      {deleteClicked ? (
        <div className='flex justify-center items-center'>
          <span className='text-[8pt] align-middle mr-2 text-white'>Delete?</span>
          <div
            className='hover:fill-[#00A61C] mt-1 w-7 h-7 p-[6px] rounded-full  bg-opacity-0 fill-[#11F237] align-middle cursor-pointer transition-all duration-100 ease-in-out'
            onClick={deleteFunction}
          >
            <TickIcon />
          </div>
          <div
            className={`hover:fill-[#A60626] w-7 h-7 p-[8px] rounded-full bg-opacity-0 align-middle cursor-pointer transition-all duration-100 ease-in-out`}
            style={{ fill: `${crossColour ? `${crossColour}` : 'red'}` }}
            onClick={() => setDeleteClicked(false)}
          >
            <CrossIcon />
          </div>
        </div>
      ) : (
        <div
          className='hover:fill-red fill-white w-7 h-7 p-[8px] rounded-full pt-[6px] bg-opacity-0 align-middle cursor-pointer transition duration-100 ease-in-out'
          onClick={() => setDeleteClicked(true)}
        >
          <BinIcon />
        </div>
      )}
    </div>
  );
}

export default DeleteItemButton;
