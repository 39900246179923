import { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';

interface Category {
  name: string;
  subcategories: Subcategory[];
}

interface Subcategory {
  name: string;
  products: Product[];
}

interface Product {
  __typename: string;
  _id: string;
  createdBy: string;
  title: string;
  price: string;
  productImageURL: string[];
  comparePrice: null | string;
  bodyContent: string;
  type: null | string;
  vendor: string;
  categories: string[];
  subcategories: string[];
  tags: string[];
}

const CategoryTile = ({
  category,
  backgroundColour,
}: {
  category: Category;
  backgroundColour: string;
}) => {
  const [displayImage, setDisplayImage] = useState<string>(
    category.subcategories[0].products[0].productImageURL[0],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const productImageURLs = category.subcategories.flatMap((subcategory) =>
        subcategory.products.flatMap((product) => product.productImageURL),
      );
      const randomIndex = Math.floor(Math.random() * productImageURLs.length);
      setDisplayImage(productImageURLs[randomIndex]);
    }, getRandomTime(1000, 3000));

    return () => {
      clearInterval(intervalId);
    };
  }, [category]);

  const getRandomTime = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div
      className='col-span-1 cursor-pointer transition-all duration-150 hover:bg-[#110d11] rounded-md'
      style={{ backgroundColor: `${backgroundColour}` }}
    >
      <div
        className='h-52 bg-cover bg-center rounded-t-md relative transition-all ease-in-out duration-100'
        style={{
          backgroundImage: `url(${displayImage})`,
        }}
      ></div>
      <div className='w-full h-full flex items-center justify-center p-2'>{category.name}</div>
    </div>
  );
};

const SubcategoryTile = ({
  tagInfo: subcategory,
  backgroundColour,
}: {
  tagInfo: Subcategory;
  backgroundColour: string;
}) => {
  const [displayImage, setDisplayImage] = useState<string>(
    subcategory.products[0].productImageURL[0],
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const productImageURLs = subcategory.products.flatMap((product) => product.productImageURL);
      const randomIndex = Math.floor(Math.random() * productImageURLs.length);
      setDisplayImage(productImageURLs[randomIndex]);
    }, getRandomTime(1000, 3000));

    return () => {
      clearInterval(intervalId);
    };
  }, [subcategory]);

  const getRandomTime = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div
      className='col-span-1 cursor-pointer transition-all duration-150 hover:bg-[#110d11] rounded-md h-full'
      style={{ backgroundColor: `${backgroundColour}` }}
    >
      <div
        className='h-52 bg-cover bg-center rounded-t-md relative'
        style={{
          backgroundImage: `url(${displayImage})`,
        }}
      ></div>
      <div className='w-full flex items-center justify-center p-2'>{subcategory.name}</div>
    </div>
  );
};

const ProductTile = ({
  product,
  backgroundColour,
  deleteButton,
  handleDeleteProduct,
}: {
  product: Product;
  backgroundColour: string;
  deleteButton: boolean;
  handleDeleteProduct: (e: React.MouseEvent, id: string) => void; // Specify the correct type for handleDeleteProduct
}) => {
  const [displayImage, setDisplayImage] = useState<string>(product.productImageURL[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const productImageURLs = product.productImageURL;
      const randomIndex = Math.floor(Math.random() * productImageURLs.length);
      setDisplayImage(productImageURLs[randomIndex]);
    }, getRandomTime(1000, 3000));

    return () => {
      clearInterval(intervalId);
    };
  }, [product]);

  const getRandomTime = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  return (
    <div className='relative h-full'>
      <Link to={`/products/${product._id}`}>
        <div
          className='col-span-1 cursor-pointer transition-all duration-150 hover:bg-[#110d11] rounded-md h-full'
          style={{ backgroundColor: `${backgroundColour}` }}
        >
          <div
            className='h-40 bg-cover bg-center rounded-t-md relative'
            style={{
              backgroundImage: `url(${displayImage})`,
            }}
          ></div>
          <div className='w-full items-center justify-center max-h-auto p-3 relative'>
            {product.price && <div className='mb-1 line-clamp-2 font-bold'>${product.price}</div>}
            <div className='mb-1 line-clamp-2'>{product.title}</div>
            <div className='line-clamp-2 text-[10pt] font-light'>{product.bodyContent}</div>
          </div>
        </div>
      </Link>
      {deleteButton && (
        <div className='top-0 right-0 absolute p-2'>
          <DeleteItemButton deleteFunction={(e: any) => handleDeleteProduct(e, product._id)} />
        </div>
      )}
    </div>
  );
};

const Shop = ({ backgroundColour, deleteButton, productData, handleDeleteProduct }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    setBreadcrumb([]);
  }, [productData]);

  useEffect(() => {
    setCategoryList(sortProductsByCategory(productData));
  }, [productData]);

  const [categoryList, setCategoryList] = useState<Category[] | undefined>();

  const sortProductsByCategory = (products: Product[]): Category[] => {
    if (!productData) {
      return [];
    }

    const categoryMap: { [category: string]: Category } = {};

    products.forEach((product) => {
      const { categories, subcategories } = product;

      categories.forEach((category) => {
        if (!categoryMap[category]) {
          categoryMap[category] = {
            name: category,
            subcategories: [],
          };
        }

        const categoryObj = categoryMap[category];

        subcategories.forEach((subcategory) => {
          let subcategoryObj = categoryObj.subcategories.find((sub) => sub.name === subcategory);

          if (!subcategoryObj) {
            subcategoryObj = {
              name: subcategory,
              products: [],
            };
            categoryObj.subcategories.push(subcategoryObj);
          }

          subcategoryObj.products.push(product);
        });
      });
    });

    const categories = Object.values(categoryMap).sort((a, b) => a.name.localeCompare(b.name));

    return categories;
  };

  const [breadcrumb, setBreadcrumb] = useState<string[]>([]);
  const [categoryIndex, setCategoryIndex] = useState<number>(0);
  const [subcategoryIndex, setSubcategoryIndex] = useState<number>(0);

  function resetBreadcrumb() {
    setBreadcrumb([]);
  }

  function openCategory(categoryName: string, index: number) {
    setCategoryIndex(index);
    setBreadcrumb([...breadcrumb, categoryName]);
  }

  function openSubcategory(subcategoryName: string, index: number) {
    setSubcategoryIndex(index);
    setBreadcrumb([...breadcrumb, subcategoryName]);
  }

  function closeProducts() {
    if (breadcrumb.length === 2) {
      const newArray = [...breadcrumb];
      newArray.pop();
      setBreadcrumb(newArray);
    }
  }

  return (
    <div className=''>
      <div className='text-[10pt] font-medium text-[#705F6E] mb-4 cursor-pointer'>
        <span
          onClick={() => resetBreadcrumb()}
          className='hover:text-white transition-all duration-150'
        >
          Products & Services
        </span>
        {breadcrumb[0] && (
          <span
            onClick={() => closeProducts()}
            className='hover:text-white transition-all duration-150'
          >
            {' > '}
            {breadcrumb[0]}
          </span>
        )}
        {breadcrumb[1] && (
          <span className='hover:text-white transition-all duration-150'>
            {' > '}
            {breadcrumb[1]}
          </span>
        )}
      </div>

      {breadcrumb?.length === 0 && (
        <div className='grid grid-cols-2 gap-4'>
          {!categoryList?.length && <div className='text-[#705F6E]'>No products listed yet</div>}
          {categoryList?.map((category: Category, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  if (category.name !== category.subcategories[0].name) {
                    openCategory(category.name, index);
                  } else if (
                    category.subcategories.length === 1 &&
                    category.subcategories[0].products.length === 1
                  ) {
                    navigate(`/products/${category.subcategories[0].products[0]._id}`);
                  }
                }}
              >
                <CategoryTile category={category} backgroundColour={backgroundColour} />
              </div>
            );
          })}
        </div>
      )}

      {breadcrumb?.length === 1 && (
        <div className='grid grid-cols-3 gap-4'>
          {categoryList && (
            <>
              {categoryList[categoryIndex].subcategories.map(
                (subcategory: Subcategory, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        if (subcategory.products.length > 1) {
                          openSubcategory(subcategory.name, index);
                        } else if (subcategory.products.length === 1) {
                          navigate(`/products/${subcategory.products[0]._id}`);
                        }
                      }}
                    >
                      <SubcategoryTile tagInfo={subcategory} backgroundColour={backgroundColour} />
                    </div>
                  );
                },
              )}
            </>
          )}
        </div>
      )}

      {breadcrumb?.length === 2 && (
        <div className='grid grid-cols-4 gap-4'>
          {categoryList && (
            <>
              {categoryList[categoryIndex].subcategories[subcategoryIndex].products.map(
                (product: Product, index: number) => {
                  return (
                    <div key={product._id}>
                      <ProductTile
                        product={product}
                        backgroundColour={backgroundColour}
                        deleteButton={deleteButton}
                        handleDeleteProduct={handleDeleteProduct}
                      />
                    </div>
                  );
                },
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Shop;
