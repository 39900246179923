import { useEffect, useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import TagSearch, { MHPSearchTagType } from '../utils/TagSearch';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';

const EDIT_PRODUCT = gql`
  mutation Mutation($input: ProductInput, $productId: ID) {
    editProduct(input: $input, productId: $productId) {
      _id
      createdBy
      title
      price
      comparePrice
      bodyContent
      productImageURL
      vendor
      type
      categories
      tags
    }
  }
`;

const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productId: ID) {
    getProductById(productId: $productId) {
      product {
        _id
        createdBy
        title
        price
        productImageURL
        comparePrice
        bodyContent
        type
        vendor
        categories
        subcategories
        tags
      }
    }
  }
`;

const EditProductForm = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const {
    error,
    loading: productDataLoading,
    data: productData2,
  } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      productId: productId,
    },
  });

  const [productData, setProductDataLoaded] = useState<any>();

  useEffect(() => {
    if (productData) {
      setImagePreview(productData.productImageURL);
      setProductImages(productData.productImageURL);
    }
  }, [productData]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: productData?.title,
      price: productData?.price,
      comparePrice: productData?.comparePrice,
      bodyContent: productData?.bodyContent,
      type: productData?.type,
      vendor: productData?.vendor,
      categories: productData?.categories,
      subcategories: productData?.subcategories,
      tags: productData?.tags,
    },
  });

  useEffect(() => {
    if (productData2 && !productDataLoading) {
      setProductDataLoaded(productData2?.getProductById?.product);

      const {
        title,
        price,
        comparePrice,
        bodyContent,
        type,
        vendor,
        categories,
        subcategories,
        tags,
      } = productData2?.getProductById?.product || {};

      setValue('title', title);
      setValue('price', price);
      setValue('comparePrice', comparePrice);
      setValue('bodyContent', bodyContent);
      setValue('type', type);
      setValue('vendor', vendor);
      setValue('categories', categories);
      setValue('subcategories', subcategories);
      setValue('tags', tags);
    }
  }, [productData2, productDataLoading, setValue]);

  const [formError, setFormError] = useState('');
  const [formTags, setFormTags] = useState<any[]>([]);
  const [categoryTags, setCategoryTags] = useState<any[]>([]);
  const [subcategoryTags, setSubcategoryTags] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<any>([]);

  const [productImages, setProductImages] = useState<string[]>([]);
  const [productImageError, setProductImageError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [subcategoryError, setSubcategoryError] = useState(false);
  const [tagsError, setTagsError] = useState(false);

  const [updateProductMutation, { data: addProductData, loading: addProductloading }] =
    useMutation(EDIT_PRODUCT);

  const [editProductMutation, { loading: editProductLoading }] = useMutation(EDIT_PRODUCT);

  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  const handleImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        // setImagePreview([reader.result]);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          if (productImages[0] === '') {
            setProductImages([data?.data?.uploadPhoto]);
            setImagePreview([reader.result]);
            setProductImageError(false);
          } else {
            setProductImages([...productImages, data?.data?.uploadPhoto]);
            setImagePreview([...imagePreview, reader.result]);
            setProductImageError(false);
          }
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleDeleteImage = (index: number) => {
    const newProjectImages = [...productImages];
    newProjectImages.splice(index, 1);
    setProductImages(newProjectImages);

    const newPreviewImages = [...imagePreview];
    newPreviewImages.splice(index, 1);
    setImagePreview(newPreviewImages);
  };

  const handleUpdateProduct = async (inputs: any) => {
    if (!productImages[0]) {
      setProductImageError(true);
      return;
    }

    if (!categoryTags[0]) {
      setCategoryError(true);
      return;
    } else {
      setCategoryError(false);
    }

    if (!subcategoryTags[0]) {
      setSubcategoryError(true);
      return;
    } else {
      setSubcategoryError(false);
    }

    if (!formTags[0]) {
      setTagsError(true);
      return;
    } else {
      setTagsError(false);
    }

    setFormError('');
    const { title, price, comparePrice, bodyContent, type, vendor } = inputs;
    try {
      const { data } = await editProductMutation({
        variables: {
          productId: productId,
          input: {
            title: title,
            price: price,
            bodyContent: bodyContent,
            vendor: vendor,
            categories: categoryTags,
            subcategories: subcategoryTags,
            tags: formTags,
            productImageURL: productImages,
          },
        },
      });

      if (data) {
        navigate(`/products/${productId}`);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateProduct)} className='space-y-6'>
      <div className='bg-white grid grid-cols-5 mb-10 rounded-md'>
        <div className='p-6 grid gap-6 col-span-full'>
          {/* Image 1 */}
          <div>
            <div
              className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                !productImages[0] ? 'p-6' : ''
              }`}
            >
              <div className='space-y-1 text-center'>
                {uploadPhotoLoading ? (
                  <LoadingSpinner />
                ) : !productImages[0] ? (
                  <>
                    <svg
                      className='mx-auto h-12 w-12 text-gray-400'
                      stroke='currentColor'
                      fill='none'
                      viewBox='0 0 48 48'
                      aria-hidden='true'
                    >
                      <path
                        d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    <div className='text-sm text-gray-600'>
                      <label
                        htmlFor='file-upload'
                        className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                      >
                        <div>Upload cover photo</div>
                        <input
                          id='file-upload'
                          name='file-upload'
                          type='file'
                          onChange={(event) => {
                            handleImageUpload(event);
                          }}
                          className='sr-only'
                        />
                      </label>
                    </div>
                    <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                  </>
                ) : (
                  <div className='relative w-full h-full'>
                    <div className='absolute right-0 m-2'>
                      <DeleteItemButton
                        deleteFunction={() => handleDeleteImage(0)}
                      ></DeleteItemButton>
                    </div>

                    <img
                      className='object-cover w-full h-full'
                      src={imagePreview[0]}
                      alt='Project pic 1'
                    />
                  </div>
                )}
              </div>
            </div>
            {productImageError ? <span className='text-red'>Product image required</span> : null}
          </div>

          <div className='grid grid-cols-3 gap-6'>
            {/* Image 2 */}
            <div>
              <div
                className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                  !productImages[1] ? 'p-6' : ''
                }`}
              >
                <div className='space-y-1 text-center'>
                  {uploadPhotoLoading ? (
                    <LoadingSpinner />
                  ) : !productImages[1] ? (
                    <>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <div>Upload a photo</div>
                          <input
                            id='file-upload'
                            name='file-upload'
                            type='file'
                            onChange={(event) => {
                              handleImageUpload(event);
                            }}
                            className='sr-only'
                          />
                        </label>
                      </div>
                      <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                    </>
                  ) : (
                    <div className='relative w-full h-full'>
                      <div className='absolute right-0 m-2'>
                        <DeleteItemButton
                          deleteFunction={() => handleDeleteImage(1)}
                        ></DeleteItemButton>
                      </div>

                      <img
                        className='object-cover w-full h-full'
                        src={imagePreview[1]}
                        alt='Product pic 2'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Image 3 */}
            <div>
              <div
                className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                  !productImages[2] ? 'p-6' : ''
                }`}
              >
                <div className='space-y-1 text-center'>
                  {uploadPhotoLoading ? (
                    <LoadingSpinner />
                  ) : !productImages[2] ? (
                    <>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <div>Upload a photo</div>
                          <input
                            id='file-upload'
                            name='file-upload'
                            type='file'
                            onChange={(event) => {
                              handleImageUpload(event);
                            }}
                            className='sr-only'
                          />
                        </label>
                      </div>
                      <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                    </>
                  ) : (
                    <div className='relative w-full h-full'>
                      <div className='absolute right-0 m-2'>
                        <DeleteItemButton
                          deleteFunction={() => handleDeleteImage(2)}
                        ></DeleteItemButton>
                      </div>

                      <img
                        className='object-cover w-full h-full'
                        src={imagePreview[2]}
                        alt='Product pic 3'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Image 4 */}
            <div>
              <div
                className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                  !productImages[3] ? 'p-6' : ''
                }`}
              >
                <div className='space-y-1 text-center'>
                  {uploadPhotoLoading ? (
                    <LoadingSpinner />
                  ) : !productImages[3] ? (
                    <>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                        >
                          <div>Upload a photo</div>
                          <input
                            id='file-upload'
                            name='file-upload'
                            type='file'
                            onChange={(event) => {
                              handleImageUpload(event);
                            }}
                            className='sr-only'
                          />
                        </label>
                      </div>
                      <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                    </>
                  ) : (
                    <div className='relative w-full h-full'>
                      <div className='absolute right-0 m-2'>
                        <DeleteItemButton
                          deleteFunction={() => handleDeleteImage(3)}
                        ></DeleteItemButton>
                      </div>

                      <img
                        className='object-cover w-full h-full'
                        src={imagePreview[3]}
                        alt='Product pic 4'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <label htmlFor='title' className='block text-sm font-medium text-gray-700'>
              Product/Service Title
            </label>
            <div className=''>
              <input
                id='title'
                {...register('title', { required: 'Product name required' })}
                className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                placeholder='Enter product/service title'
                defaultValue={''}
              />
              {errors.title !== undefined && (
                <span className='text-red'>{`${errors.title && errors.title.message}`}</span>
              )}
            </div>
          </div>

          <div className=''>
            <div className='col-span-2'>
              <div>
                <label htmlFor='price' className='block text-sm font-medium text-gray-700'>
                  Price (optional)
                </label>
                <div className='flex'>
                  <span className='text-gray-700 text-xl font-light self-center mr-2 mt-1'>$</span>
                  <input
                    id='price'
                    {...register('price')}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter price'
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-1'>
              {/* <div>
                    <label
                      htmlFor='compareprice'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Compare Price
                    </label>
                    <div className='mt-1'>
                      <input
                        id='compareprice'
                        {...register('compareprice')}
                        className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                        placeholder='Pre-discount price(optional)'
                        defaultValue={''}
                      />
                      <span className='text-red'>{errors.heading && errors.heading.message}</span>
                    </div>
                  </div> */}
            </div>
          </div>

          <div>
            <label htmlFor='content' className='block text-sm font-medium text-gray-700'>
              Product/Service Description
            </label>
            <div className='mt-1'>
              <textarea
                id='content'
                {...register('bodyContent', { required: 'Description required.' })}
                className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                placeholder='Enter product/service description'
                defaultValue={''}
              />
              <span className='text-red'>{`${
                errors.bodyContent && errors.bodyContent.message
              }`}</span>
            </div>
          </div>

          {/* <div>
                <label htmlFor='type' className='block text-sm font-medium text-gray-700'>
                  Product Type
                </label>
                <div className='mt-1'>
                  <input
                    id='type'
                    {...register('type', { required: 'Product type is required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder=''
                    defaultValue={''}
                  />
                </div>
              </div> */}
          <div>
            <label htmlFor='vendor' className='block text-sm font-medium text-gray-700'>
              Product/Service Brand (optional)
            </label>
            <div className=''>
              <input
                id='vendor'
                {...register('vendor')}
                className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                placeholder='Enter product/service brand'
                defaultValue={''}
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700'>Category</label>
            <TagSearch
              tagType={MHPSearchTagType.Product}
              handleFormTags={setCategoryTags}
              tagLimit={1}
              editTags={productData?.categories}
            />
          </div>
          {categoryError && <span className='text-red -mt-4'>Category required</span>}
          <div>
            <label className='block text-sm font-medium text-gray-700'>Subcategory</label>
            <TagSearch
              tagType={MHPSearchTagType.Product}
              handleFormTags={setSubcategoryTags}
              tagLimit={1}
              editTags={productData?.subcategories}
            />
          </div>
          {subcategoryError && <span className='text-red -mt-4'>Subcategory required</span>}
          {/* <div>
            <label className='block text-sm font-medium text-gray-700'>Subcategory</label>
            <TagSearch
              tagType={MHPSearchTagType.Product}
              handleFormTags={setSubcategoryTags}
              tagLimit={1}
            />
          </div> */}
          <div>
            <label className='block text-sm font-medium text-gray-700'>Tags</label>
            <TagSearch
              tagType={MHPSearchTagType.Product}
              handleFormTags={setFormTags}
              editTags={productData?.tags}
            />
          </div>
          {tagsError && <span className='text-red -mt-4'>Tags required</span>}
        </div>
        <div className='flex justify-end col-start-1 col-span-full p-4 bg-gray-50 rounded-b-md'>
          {editProductLoading ? (
            <LoadingSpinner />
          ) : (
            <button
              type='submit'
              className='col-span-1 transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-full'
            >
              Update Product
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditProductForm;
