import { Link } from 'react-router-dom';
import TestImage from '@/assets/images/no_image_found.png';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useEffect } from 'react';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';

// GQL QUERIES AND MUTATIONS
const GET_PROJECTS = gql`
  query Query($getProjectsUserId: ID) {
    getProjects(userId: $getProjectsUserId) {
      _id
      createdBy
      firstName
      lastName
      name
      year
      make
      model
      badge
      tags
      images
      description
      paint
      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProjectMutation($deleteProjectProjectId: ID) {
    deleteProject(projectId: $deleteProjectProjectId)
  }
`;

function MyProjects() {
  const [deleteProject, { loading: deleteLoading }] = useMutation(DELETE_PROJECT, {
    refetchQueries: [GET_PROJECTS, 'Query'],
  });

  const { loading, data, refetch } = useQuery(GET_PROJECTS);
  // const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    // TODO - Resolve to a better solution, this is for the navigation back from creating a new project.
    // Query was not working correctly by firing off a new fetch.
    refetch();
  }, [refetch]);

  const handleDeleteProject = (data: any, _id: any) => {
    if (data) {
      deleteProject({
        variables: {
          deleteProjectProjectId: _id,
        },
      });
    }
  };

  return (
    <>
      {loading || deleteLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='grid grid-cols-12'>
          <div className='col-span-12 lg:col-span-8 relative'>
            <div className='text-white font-semibold items-center text-xl mt-1 flex mb-3'>
              <h1>My Projects</h1>
              <Link
                className='transition ease-in-out duration-200 bg-red hover:bg-[#A60626] my-2 py-2 px-4 ml-4 text-sm font-semibold rounded-full'
                to='/projects/add'
              >
                Add Project
              </Link>
            </div>

            {data ? (
              data.getProjects
                .map((project: any) => {
                  return (
                    <div
                      key={project._id}
                      className='grid grid-cols-12 mt-1 mb-8 text-[0pt] hover:text-[2pt] relative'
                    >
                      <Link className='col-span-12' to={`/project/${project._id}`}>
                        <div
                          className='h-[400px] bg-center bg-cover col-span-12 rounded-md lg:bg-[length:100%] lg:hover:bg-[length:102%] transition-all ease-in-out duration-200'
                          style={{
                            backgroundImage: `url(${
                              project?.images[0] !== '' ? project.images[0] : TestImage
                            })`,
                          }}
                        >
                          <div className='h-auto w-full bg-gradient-to-t from-[#080607d5] to-[#08060700] absolute bottom-0'>
                            <h2 className='text-white text-8xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
                              {project.make}
                            </h2>

                            <h2 className='text-2xl font-[Montserrat] text-white font-extrabold bottom-0 p-5'>
                              {project.name}
                            </h2>
                            <div className='opacity-0 relative'>
                              Hello, I hope you're having a good day.
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className='right-3 bottom-[360px] absolute'>
                        <DeleteItemButton
                          binColour='white'
                          deleteFunction={(e: any) => handleDeleteProject(e, project._id)}
                        />
                      </div>
                    </div>
                  );
                })
                .reverse()
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MyProjects;
