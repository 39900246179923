export const MHPLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='262.947'
      height='54.602'
      data-name='Component 33 – 2'
      viewBox='0 0 262.947 54.602'
    >
      <g fill='#fff' data-name='Group 68' transform='translate(-1861.027 -1309.473)'>
        <path
          d='M1910.715 1364.073l-.078-32.762-16.068 26.99h-5.7l-15.99-26.287v32.059h-11.855v-54.6h10.451l20.437 33.93 20.125-33.93h10.373l.157 54.6z'
          data-name='Path 46'
        ></path>
        <path
          d='M1980.133 1309.472v54.6H1967.5v-22.387h-24.806v22.387h-12.635v-54.6h12.635V1331h24.806v-21.528z'
          data-name='Path 47'
        ></path>
        <path
          d='M2034.967 1329.284c0 12.168-9.127 19.734-23.713 19.734h-11v15.055h-12.637v-54.6h23.635c14.588-.001 23.715 7.564 23.715 19.811zm-12.793 0c0-6.007-3.9-9.517-11.621-9.517h-10.3v18.956h10.3c7.723 0 11.621-3.511 11.621-9.439z'
          data-name='Path 48'
        ></path>
      </g>
      <path
        fill='#f2133c'
        d='M2121 1320.2a46.209 46.209 0 00-5.71-10.73h-10.09c.33.329.64.669.95 1.009a5.773 5.773 0 01-1.86 9.071l-15.85 7.46a5.27 5.27 0 01-5.031-.36 11.691 11.691 0 00-17.29 6.29 5.253 5.253 0 01-3.62 3.51l-16.94 4.48a5.781 5.781 0 01-7.26-5.76 39.05 39.05 0 0110.949-25.7h-10.09a46.7 46.7 0 00.3 54.6h10.191a38.541 38.541 0 01-2.76-3.08 5.774 5.774 0 013.04-9.19l15.38-4.06a5.743 5.743 0 016.23 2.33l5.03 7.45 3.7 6.55h13.52a5.931 5.931 0 01-.75-2l-1.831-9.87-.939-8.951a5.741 5.741 0 013.27-5.78l14.391-6.78a5.794 5.794 0 017.239 1.96 5.688 5.688 0 011 3.12 38.968 38.968 0 01-11.369 28.3h10.2a46.654 46.654 0 006-43.869zm-41.11 23.681a7.786 7.786 0 114.66-9.981 7.788 7.788 0 01-4.657 9.983z'
        data-name='Path 49'
        transform='translate(-1861.027 -1309.473)'
      ></path>
    </svg>
  );
};
