export default function RightArrowCircle({
  width,
  height,
  className,
}: {
  width?: string;
  height?: string;
  className?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '30'}
      height={height || '30'}
      viewBox='0 0 29.25 29.25'
      className={className}
      fill='#f2123d'
    >
      <path
        d='M3.375,18A14.625,14.625,0,1,0,18,3.375,14.623,14.623,0,0,0,3.375,18Zm17.191,0-5.759-5.7a1.357,1.357,0,0,1,1.92-1.92l6.708,6.729a1.356,1.356,0,0,1,.042,1.87l-6.609,6.63a1.355,1.355,0,1,1-1.92-1.912Z'
        transform='translate(-3.375 -3.375)'
      />
    </svg>
  );
}
