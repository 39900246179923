import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { XIcon } from '@heroicons/react/solid';
import { useProjectSearch } from './search/searchHooks';
import { useEffect } from 'react';

function ProjectSearch({
  handleFormUpdates,
  maxSelection,
}: {
  handleFormUpdates: Function;
  maxSelection?: number;
}) {
  const {
    selectedProjects,
    projectSearchLoading,
    suggestionsActive,
    searchResultProjects,
    handleProjectSearch,
    handleRemoveProject,
    toggleSelect,
  } = useProjectSearch();

  const hasSelectedProjects = selectedProjects && selectedProjects?.length;

  return (
    <div>
      <div className='mt-1'>
        <input
          type='search'
          placeholder={`Search Projects by Name`}
          className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          onChange={handleProjectSearch}
        />
        {hasSelectedProjects
          ? selectedProjects.map((selectedProject, i) => (
              <ul key={i} className='inline-flex'>
                <li className='inline-flex items-center text-sm rounded mt-2 mr-1 p-1 mb-1 bg-gray-100'>
                  <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs text-gray-700 font-bold'>
                    {selectedProject?.name}
                  </span>
                  <span
                    onClick={() => handleRemoveProject(selectedProject, handleFormUpdates)}
                    className='ml-1 cursor-pointer'
                  >
                    <XIcon className='h-4 w-4 text-red' aria-hidden='true' />
                  </span>
                </li>
              </ul>
            ))
          : null}
        {projectSearchLoading ? <LoadingSpinner /> : null}
        {suggestionsActive && searchResultProjects ? (
          <div className='mt-2 grid grid-cols gap-1 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
            {searchResultProjects.map((project: any, i: any) => (
              <div
                key={i}
                onClick={() => {
                  toggleSelect(project, handleFormUpdates, maxSelection);
                }}
                className={`transition-all duration-100 bg-cover bg-center h-full flex text-center font-semibold cursor-pointer rounded-md`}
                style={{
                  backgroundImage: `url(${project?.images[0]})`,
                }}
              >
                <div className='bg-[#00000080] hover:bg-[#00000040] w-full rounded-md transition-all duration-100'>
                  <div
                    className={`text-white m-4 font-[Montserrat] rounded-md shadow-md group-hover:opacity-75 ${
                      hasSelectedProjects
                        ? selectedProjects?.some((n) => n?.name === project?.name)
                        : false
                        ? ''
                        : ''
                    } `}
                  >
                    <div className='italic font-bold'>{project.name}</div>
                    <div className='text-xs font-light'>Click to add</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProjectSearch;
