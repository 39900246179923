import { useParams } from 'react-router-dom';
import { ArticleTemplate } from './AddArticle';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ArticleTemplateOneDisplay } from './templates/displays/ArticleTemplateOneDisplay';
import { ArticleTemplateTwoDisplay } from './templates/displays/ArticleTemplateTwoDisplay';
import { ArticleTemplateAdminDisplay } from './templates/displays/ArticleTemplateAdminDisplay';
import { ProjectData } from '../projects/SingleProject';

export interface ArticleData {
  taggedAdvertisers: {
    __typename: string;
    companyName: string;
    id: string;
  }[];
  __typename: string;
  approved: boolean;
  _id: string;
  createdAt: string;
  creatorId: string;
  firstName: string;
  lastName: string;
  username: string;
  profilePicture: string;
  heading: string;
  subHeading: string;
  type: string;
  tags: string[];
  bodyContentList: {
    __typename: string;
    index: number;
    text: string;
  }[];
  images: {
    __typename: string;
    index: number;
    caption: string;
    url: string;
    projects: {
      __typename: string;
      name: string;
      id: string;
    }[];
  }[];
  articleTemplate: ArticleTemplate;
  mainProject: {
    __typename: string;
    name: string;
    id: string;
  };
}

export const GET_ARTICLE_BY_ID = gql`
  query GetArticleById($articleId: ID) {
    getArticleById(articleId: $articleId) {
      approved
      _id
      createdAt
      creatorId
      firstName
      lastName
      username
      profilePicture
      heading
      subHeading
      type
      tags
      bodyContentList {
        index
        text
      }
      images {
        index
        caption
        url
        projects {
          name
          id
        }
      }
      articleTemplate
      mainProject {
        name
        id
      }
      taggedAdvertisers {
        id
        companyName
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: ID) {
    getProjectById(projectId: $projectId) {
      _id

      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query Query($userId: ID) {
    getUserById(userId: $userId) {
      _id
      username
      advertiserDetails {
        companyName
        coverPhoto
      }
    }
  }
`;

const SingleArticle = () => {
  const { articleSlug } = useParams();

  const {
    error,
    loading,
    data: articleData2,
  } = useQuery(GET_ARTICLE_BY_ID, {
    variables: {
      articleId: articleSlug,
    },
  });

  const {
    error: userDataError,
    loading: userDataLoading,
    data: userData2,
  } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: articleData2?.getArticleById?.creatorId,
    },
  });

  const {
    error: mainProjectError,
    loading: mainProjectLoading,
    data: mainProjectData2,
  } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: articleData2?.getArticleById?.mainProject?.id,
    },
  });

  const [getAdvertiserById, { loading: advertiserSearchLoading, error: advertiserError }] =
    useLazyQuery(GET_USER_BY_ID);

  const [articleData, setArticleDataLoaded] = useState();
  const [mainProjectData, setMainProjectDataLoaded] = useState();
  const [userData, setUserDataLoaded] = useState();
  const [taggedAdvertisersData, setTaggedAdvertisersLoaded] = useState<any[]>();

  useEffect(() => {
    const fetchTaggedAdvertisers = async () => {
      if (articleData2) {
        setArticleDataLoaded(articleData2.getArticleById);

        if (articleData2.getArticleById.taggedAdvertisers) {
          const taggedAdvertisers = articleData2.getArticleById.taggedAdvertisers;
          const fetchedAdvertisers = [];

          for (const advertiser of taggedAdvertisers) {
            const { data, loading, error } = await getAdvertiserById({
              variables: { userId: advertiser.id },
            });

            if (!loading && !error && data && data.getUserById) {
              fetchedAdvertisers.push(data.getUserById);
            }
          }
          setTaggedAdvertisersLoaded(fetchedAdvertisers);
        }
      }

      if (mainProjectData2) {
        setMainProjectDataLoaded(mainProjectData2.getProjectById);
      }
      if (userData2) {
        setUserDataLoaded(userData2.getUserById);
      }
    };

    fetchTaggedAdvertisers();
  }, [articleData2, mainProjectData2, userData2]);

  // Render based on article template
  if (articleData) {
    const { articleTemplate } = articleData;
    switch (articleTemplate) {
      case ArticleTemplate.TEMPLATE_ONE:
        return (
          <ArticleTemplateOneDisplay
            userData={userData}
            articleData={articleData}
            mainProjectData={mainProjectData}
            advertiserData={taggedAdvertisersData}
          />
        );
      case ArticleTemplate.TEMPLATE_TWO:
        return (
          <ArticleTemplateTwoDisplay
            userData={userData}
            articleData={articleData}
            advertiserData={taggedAdvertisersData}
          />
        );
      case ArticleTemplate.TEMPLATE_ADMIN:
        return (
          <ArticleTemplateAdminDisplay
            userData={userData}
            articleData={articleData}
            advertiserData={taggedAdvertisersData}
          />
        );
      default:
        return (
          <ArticleTemplateOneDisplay
            userData={userData}
            articleData={articleData}
            mainProjectData={mainProjectData}
            advertiserData={taggedAdvertisersData}
          />
        );
    }
  } else {
    return null;
  }
};

export default SingleArticle;
