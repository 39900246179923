import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getAccessToken } from './token';

const AUTHENTICATE_ACCESS_TOKEN = gql`
  mutation Mutation($accessToken: String!) {
    authenticateAccessToken(accessToken: $accessToken)
  }
`;

export default function useIsAuthenticated(): {
  loading: boolean,
  isAuthenticated: boolean
} {
  const [authenticateAccessTokenMutation] = useMutation(AUTHENTICATE_ACCESS_TOKEN);

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAccessToken = async () => {
      try {
        const { data } = await authenticateAccessTokenMutation({
          variables: {
            accessToken: getAccessToken(),
          }
        })
        if(data.authenticateAccessToken) {
          setIsAuthenticated(data.authenticateAccessToken)
          setLoading(false)
        } else {
          setLoading(false)
        }
      } catch(err) {
        setLoading(false)
        setIsAuthenticated(false)
      }
    }
    checkAccessToken();
  }, []);

  return {
    loading,
    isAuthenticated
  }
}