import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';
import { MHPLogo } from '@/components/MHPLogo';
import { REDIRECT_TO_LOGIN_HREF } from '@/components/LoginModal';
// import { GoogleLogin } from 'react-google-login'

const FORGOT_PASSWORD_MUTATION = gql`
  mutation Mutation($email: String!) {
    resetUserPassword(email: $email)
  }
`;

const Forgot = () => {
  const [resetUserPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION);
  const [formError, setFormError] = useState('');
  const [formMessageType, setFormMessageType] = useState('');

  const handlePasswordReset = async (event: any) => {
    event.preventDefault();
    setFormError('');
    const { email } = event.target.elements;

    try {
      const resetPassword = await resetUserPasswordMutation({
        variables: {
          email: email.value,
        },
      });
      setFormMessageType('green');
      setFormError('If the account exists, you will receive an email with password reset link.');
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  return (
    <div className='min-h-full flex flex-col justify-center h-[100vh] sm:px-6 px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center'>
        <MHPLogo />
        <h2 className='mt-6 text-center text-3xl font-extrabold text-white'>
          No worries, let's reset it!
        </h2>
      </div>

      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        {formError && (
          <div
            className={`${
              formMessageType === 'green' ? 'bg-green-50' : 'bg-red50'
            } rounded-md p-4 mb-5`}
          >
            <div className='flex'>
              <div className='flex-shrink-0'>
                {formMessageType === 'green' ? (
                  <svg
                    className='h-5 w-5 text-green-400'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                      clipRule='evenodd'
                    />
                  </svg>
                ) : (
                  <svg
                    className='h-5 w-5 text-red'
                    xmlns='http:www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                      clipRule='evenodd'
                    />
                  </svg>
                )}
              </div>
              <div className='ml-3'>
                <h3
                  className={`${
                    formMessageType === 'green' ? 'text-green-800' : 'text-red'
                  } text-sm font-medium`}
                >
                  {formError}
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className='bg-white py-8 px-4 shadow rounded-lg sm:px-10'>
          <form onSubmit={handlePasswordReset} className='space-y-6'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-black2 font-semibold'
              >
                Email address
              </label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                />
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='w-full flex justify-center py-2 px-4 font-bold text-lg border border-transparent rounded-md shadow-sm text-sm text-white transition-all duration-300 bg-red hover:bg-darkgrey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
              >
                Reset password
              </button>
              <Link
                to={REDIRECT_TO_LOGIN_HREF}
                className='mt-4 w-full flex justify-center py-2 px-4 font-bold text-lg border border-transparent rounded-md shadow-sm text-sm text-white transition-all duration-300 bg-red hover:bg-darkgrey focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red'
              >
                Back to login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
