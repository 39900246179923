import { Dispatch, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/loading/LoadingSpinner';
import { UserActionType, UserActions } from '@/store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import { useForm } from 'react-hook-form';
import { Utilities } from '@/utils/utilities';
import { XIcon, CheckCircleIcon } from '@heroicons/react/outline';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import { REDIRECT_TO_LOGIN_HREF } from '@/components/LoginModal';

// TODO: Fix routing for unauthenticated user - should redirect to login screen!

const USER_PROFILE_QUERY = gql`
  query Query {
    me {
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      _id
    }
  }
`;

// TODO - Paginate this query to get the first 20 or so results
// const GET_ALL_TAGS = gql`
//   query GetAllTags {
//     getAllTags
//   }
// `;

const CREATE_PROFILE = gql`
  mutation UpdateUser($input: NewProfile!) {
    updateUser(input: $input) {
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      username
      profilePicture
      referralCode
      state
      completedInitialProfileSetup
      tags
      locations {
        locationName
        locationAddress
        locationPhone
      }
    }
  }
`;

const SET_INITIAL_PROFILE_PICTURE = gql`
  mutation SetInitialProfilePicture($defaultProfilePicture: String!, $userId: ID!) {
    setInitialProfilePicture(defaultProfilePicture: $defaultProfilePicture, userId: $userId) {
      profilePicture
    }
  }
`;

const UPLOAD_PHOTO = gql`
  mutation UploadPhotoMutation($uploadPhotoPhoto: String) {
    uploadPhoto(photo: $uploadPhotoPhoto)
  }
`;

const CHECK_USERNAME_EXIST = gql`
  query CheckUsernameExist($username: String!) {
    checkUsernameExists(username: $username)
  }
`;

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const { data, loading: profileLoading } = useQuery(USER_PROFILE_QUERY);
  const { userData } = useSelector((state: RootState) => state.user);
  const [imagePreview, setImagePreview] = useState<any>('');
  const [profileImageURL, setProfileImageUrl] = useState('');
  const [profileCreating, setProfileCreating] = useState<boolean | null>(null);
  const [newUsername, setNewUsername] = useState('');
  const [validatingUsername, setValidatingUsername] = useState(false);
  const [validNewUsername, setValidNewUsername] = useState(false);
  const [isUsernameUsed, setIsUsernameUsed] = useState(false);
  const [allTags, setAllTags] = useState<Array<any>>([]);
  const [userTags, setUserTags] = useState<Array<any>>([]);
  const [displayTags, setDisplayTags] = useState<Array<any>>([]);
  const [suggestedTags, setSuggestedTags] = useState<Array<any>>([]);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [defaultNumberOfTagsToDisplay, setDefaultNumberOfTagsToDisplay] = useState(20);
  const [createUserProfile] = useMutation(CREATE_PROFILE);
  const [selectedState, setSelectedState] = useState<string>('');
  const [stateError, setStateError] = useState(false);
  const [tagsSelectedError, setTagsSelectedError] = useState(false);
  const states = ['WA', 'NSW', 'QLD', 'VIC', 'NT', 'ACT', 'SA', 'TAS'];
  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );
  const [checkUsernameExist, { data: isUsernameAvailable }] = useLazyQuery(CHECK_USERNAME_EXIST, {
    variables: { username: newUsername },
    onCompleted: (data) => {
      setValidatingUsername(false);
      if (!data.checkUsernameExists) {
        setValidNewUsername(true);
      } else {
        setIsUsernameUsed(true);
      }
    },
  });

  useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {
      if (componentMounted) {
        // if (data && data.me.completedInitialProfileSetup === true) navigate('/');
      }
    };
    fetchData();
    return () => {
      componentMounted = false;
    };
  }, [data]);

  useEffect(() => {
    if (allTags.length) {
      Array.from({ length: defaultNumberOfTagsToDisplay }, (_, i) => {
        const tagText = allTags[Math.floor(Math.random() * allTags.length)];
        setDisplayTags((displayTags) => [...displayTags, tagText]);
      });
    }
  }, [allTags]);

  const [uploadPhoto] = useMutation(UPLOAD_PHOTO, {
    onCompleted(data) {
      let newImageObj = { imageUrl: data.uploadPhoto };
      setProfileImageUrl(newImageObj.imageUrl);
    },
  });

  const [setDefaultProfilePicture] = useMutation(SET_INITIAL_PROFILE_PICTURE);

  useEffect(() => {
    if (!profileLoading && data?.me) {
      setDefaultProfilePicture({
        variables: {
          defaultProfilePicture:
            'https://res.cloudinary.com/my-hi-performance/image/upload/v1688289115/mhp_media/649bdfe5c229ff822cb6776f/kn7t7dcfw9zaybw0qgpe.jpg',
          userId: data.me._id,
        },
      });
    }
  }, [profileLoading]);

  async function handleImageUpload(e: any) {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      setImagePreview(reader.result);
      await uploadPhoto({
        variables: { uploadPhotoPhoto: reader.result },
      });
    };
  }

  const handleUserProfileCreation = async (data: any) => {
    let abortController = new AbortController();
    const { username, referral } = data;
    try {
      if (selectedState === '') {
        setStateError(true);
        return;
      }
      // if (userTags.length < 5) {
      //   setTagsSelectedError(true);
      //   return;
      // }
      // setProfileCreating(true);
      const { data } = await createUserProfile({
        variables: {
          input: {
            username: username,
            profilePicture:
              profileImageURL !== ''
                ? profileImageURL
                : 'https://res.cloudinary.com/my-hi-performance/image/upload/v1688289115/mhp_media/649bdfe5c229ff822cb6776f/kn7t7dcfw9zaybw0qgpe.jpg',
            tags: userTags,
            referralCode: referral,
            state: selectedState,
            completedInitialProfileSetup: true,
            locations: { locationName: '', locationAddress: '', locationPhone: '' },
          },
        },
      });

      if (data && data.updateUser.completedInitialProfileSetup) {
        navigate('/');
        return;
      }
    } catch (err: any) {
      console.log(err);
    }

    return () => {
      abortController.abort();
    };
  };

  const handleTagSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 1) {
      const filterSuggestions = allTags.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(query) > -1,
      );
      setSuggestedTags(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const handleRemoveTag = (tag: string) => {
    setUserTags(userTags.filter((e) => e !== tag));
  };

  const handleUsernameChange = (username: string) => {
    setValidNewUsername(false);
    setIsUsernameUsed(false);
    if (username.length > 1 && errors.username === undefined && !errors.username) {
      setValidatingUsername(true);
      setNewUsername(username);
      setTimeout(() => {
        checkUsernameExist();
      }, 1000);
    }
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
    navigate(REDIRECT_TO_LOGIN_HREF);
    window.location.reload();
  };

  // if (tagsError) {
  //   return <>Error loading form: ${tagsError}</>;
  // }

  // text-#080607 - adding this style broke all input fields - could not see text?

  return (
    <>
      <div className='bg-#080607 flex justify-center items-center '>
        <div className='my-12 bg-white rounded-md'>
          <form
            onSubmit={handleSubmit(handleUserProfileCreation)}
            className='bg-white p-8 rounded-lg shadow-lg min-w-full'
          >
            <h1 className='text-center text-4xl text-black font-[Montserrat] font-extrabold italic'>
              Welcome to My Hi Performance!
            </h1>
            <h2 className='text-center text-black text-[14pt] font-normal mt-3'>
              Setup your Profile
            </h2>
            <div className='text-center text-black text-[10pt] pb-6'>
              It looks like this is your first time logging in.
              <br />
              Fill out your details below so we can tailor your MHP experience!
            </div>
            <div className='flex justify-center items-center'>
              <div className='group cursor-pointer mr-4 border-2 border-radius-50 border-red w-16 h-16 flex justify-center items-center rounded-full hover:bg-red transition duration-300 ease-in-out overflow-hidden'>
                {imagePreview !== '' ? (
                  <img className='object-cover' src={imagePreview} alt='ProfileImage' />
                ) : (
                  <>
                    <input
                      className='h-24 w-24 opacity-0'
                      type='file'
                      onChange={(event) => {
                        handleImageUpload(event);
                      }}
                    />
                    <div className='absolute text-2xl text-red group-hover:text-white'>+</div>
                  </>
                )}
              </div>
              <label
                className='-mt-4 pt-6 text-gray-800 font-semibold block my-3 text-md flex justify-center'
                htmlFor='profilePicture'
              >
                Upload Profile Picture
              </label>
            </div>
            <div>
              <label
                className='pt-4 text-gray-800 font-semibold block my-3 text-md'
                htmlFor='username'
              >
                Choose a Username
              </label>
              <div className='relative'>
                <input
                  className='text-black p-2 focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:border-red w-full'
                  type='text'
                  placeholder='Enter Username'
                  id='username'
                  {...register('username', {
                    required: 'Username is required.',
                    minLength: {
                      value: 4,
                      message: 'Minimum 4 characters for username.',
                    },
                    pattern: {
                      value: /^[\w.-]*$/,
                      message: 'Invalid username format.',
                    },
                    onChange: (e) => handleUsernameChange(e.target.value),
                  })}
                />
                {/* // Hiding the below for now, feel free to uncomment an use - just the styling was way off - Checkmark appearing below the input field etc.. */}

                {/* { validatingUsername && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white absolute right-3 top-5 text-indigo-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> } */}
                {/* { validNewUsername && <div className="absolute bottom-4">
                  <CheckCircleIcon className="-ml-1 mr-3 h-5 w-5 text-green-500 absolute right-3 top-5" />
                </div> } */}
              </div>
              {errors?.username && (
                <div className='text-red text-sm'>{`${errors.username.message}`}</div>
              )}
              {isUsernameUsed ? (
                <div className='text-red text-sm'>Username already exists</div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <label
                className='pt-2 text-gray-800 font-semibold block my-3 text-md'
                htmlFor='referral'
              >
                Enter a referral code if you have one. (Optional)
              </label>
              <input
                className='w-full text-black p-2 focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md focus:outline-none focus:border-red'
                type='text'
                id='referral'
                {...register('referral', {
                  minLength: 3,
                })}
                placeholder='Enter referral'
              />
              {errors?.referral && (
                <div className='text-red text-sm'>Min. referral code length is 3</div>
              )}
            </div>
            <div>
              <label
                className='pt-2 text-gray-800 font-semibold block my-3 text-md'
                htmlFor='state'
              >
                Choose your state
              </label>
              <div className='mt-2 grid grid-cols text-center gap-y-1 gap-x-1 sm:grid-cols-4 lg:grid-cols-6'>
                {states.map((state) => (
                  <div
                    key={state}
                    className={`text-gray-800 cursor-pointer p-3 w-full bg-gray-200 rounded-md group-hover:opacity-75 border-2 hover:border-red transition duration-300 ease-in-out ${
                      selectedState === state ? 'border-red' : 'border-transparent'
                    }`}
                    onClick={() => {
                      setSelectedState(state);
                      setStateError(false);
                    }}
                  >
                    {state}
                  </div>
                ))}
              </div>
              {stateError && <div className='text-red text-sm'>Please select your state</div>}
            </div>
            {/* <label className='pt-2 text-gray-800 font-semibold block my-3 text-md' htmlFor='tags'>
              Choose some topics of interest
            </label>
            <div className='leading-snug text-slate-700 text-xs mb-5 font-semibold transition duration-300 ease-in-out'>
              You can only select 5 Tags to begin with!
            </div>
            <TagSearch tagType={MHPSearchTagType.Article} handleFormTags={setUserTags} />
            {tagsSelectedError && (
              <div className='text-red text-sm'>Please select 5 tags to start off with</div>
            )} */}
            {profileCreating !== null && profileCreating ? (
              <LoadingSpinner />
            ) : (
              <div className='grid grid-cols-2 mt-8 gap-6'>
                <button
                  onClick={handleLogout}
                  type='button'
                  className='w-full bg-slate-200 rounded-lg px-4 py-2 text-lg text-gray-800 hover:bg-slate-300 font-semibold font-sans transition duration-300 ease-in-out'
                >
                  <Link to='/'>Logout</Link>
                </button>
                <button
                  type='submit'
                  className='w-full bg-red hover:bg-red rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans transition duration-300 ease-in-out'
                >
                  Confirm
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
