import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation CreateUserChatMutation($input: MessageInput) {
    createChatMessage(input: $input) {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;

export const SET_USER_TYPING = gql`
  mutation UserTyping($input: UserTypingData) {
    userTyping(input: $input) {
      fullName
      chatId
    }
  }
`;
