import { Fragment, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { SearchIcon, UserGroupIcon } from '@heroicons/react/solid';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import { RootState } from '@/store/reducers/rootReducer';
import { useSelector } from 'react-redux';

import MHPLogo from '@images/logo-dark.svg';
import TestImage from '@/assets/images/no_image_found.png';
import QuickEnquiry from '@/parts/QuickEnquiry';
import {
  GatedLink,
  GatedNavLink,
  REDIRECT_TO_LOGIN_HREF,
  useShowLogin,
} from '@/components/LoginModal';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

enum SearchCategory {
  ALL = 'all',
  ARTICLE = 'articles',
  PRODUCT = 'products',
  PROJECT = 'projects',
  USER = 'users',
  ADVERTISER = 'advertisers',
}

const GLOBAL_APP_SEARCH = gql`
  query GlobalAppSearch($input: GlobalAppSearchInput) {
    globalAppSearch(input: $input) {
      users {
        _id
        username
        firstName
        lastName
        profilePicture
        userAccountType
      }
      projects {
        _id
        firstName
        lastName
        images
        name
        createdBy
      }
      products {
        price
        productImageURL
        title
        _id
      }
      articles {
        _id
        firstName
        lastName
        heading
        images {
          url
        }
      }
      advertisers {
        _id
        username
        userAccountType
        advertiserDetails {
          companyName
          companyLogo
          primaryTags
        }
      }
    }
  }
`;

const Header = ({
  userData = {},
  quickEnquiry,
  openQuickEnquiry,
  showQuickEnquiry,
}: {
  userData?: any;
  quickEnquiry: any;
  openQuickEnquiry: () => void;
  showQuickEnquiry: any;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [searchParam, filterParam] = useWatch({
    control,
    name: ['search', 'filter'],
  });
  const [searchDropdown, setSearchDropdown] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<any>([]);
  const navigate = useNavigate();
  const [runGlobalIntelligentSearch] = useLazyQuery(GLOBAL_APP_SEARCH, {
    variables: {
      input: {
        searchQuery: searchParam,
        category: filterParam,
      },
    },
    onCompleted: (data: any) => {
      setSearchResult([]);
      const allResults = [];

      // Collect the search results from the query and push them to the allResults array before mapping them
      if (filterParam === SearchCategory.ALL) {
        allResults.push(...data.globalAppSearch.users);
        allResults.push(...data.globalAppSearch.advertisers);
        allResults.push(...data.globalAppSearch.articles);
        allResults.push(...data.globalAppSearch.products);
        allResults.push(...data.globalAppSearch.projects);
      } else {
        allResults.push(...data.globalAppSearch[filterParam]);
      }

      // Map different fields for items based on category selected
      const mapFields = (item: any, category: any) => {
        switch (category) {
          case SearchCategory.ALL:
            let title, subHeading, link, image;
            let actionText = 'View';

            if (item && item?.__typename === 'Project') {
              title = item?.name;
              subHeading = `Project by ${item?.firstName} ${item?.lastName}`;
              link = `/project/${item?._id}`;
              image = item?.images && item?.images?.length ? item?.images[0] : '';
            } else if (item && item?.__typename === 'Product') {
              title = item?.title;
              subHeading = `Product price: $${item?.price}`;
              link = `/products/${item?._id}`;
              image = item?.productImageURL ? item?.productImageURL[0] : '';
            } else if (item && item?.__typename === 'Article') {
              title = item?.heading;
              subHeading = `Article by ${item?.firstName} ${item?.lastName}`;
              link = `/articles/${item?._id}`;
              image = item?.images && item?.images.length ? item?.images[0].url : '';
            } else if (item?.userAccountType === 'advertiser') {
              title = item?.advertiserDetails?.companyName || item?.username;
              subHeading = `${
                item?.advertiserDetails?.primaryTags[0]
                  ? `${item?.advertiserDetails?.primaryTags[0]}`
                  : ''
              }${
                item?.advertiserDetails?.primaryTags[1]
                  ? `${', ' + item?.advertiserDetails?.primaryTags[1]}`
                  : ''
              }${
                item?.advertiserDetails?.primaryTags[2]
                  ? `${', ' + item?.advertiserDetails?.primaryTags[2]}`
                  : ''
              }`;
              link = `/profile/${item?.username}`;
              image =
                item?.advertiserDetails?.companyLogo ||
                item?.profilePicture ||
                'https://res.cloudinary.com/my-hi-performance/image/upload/v1688289115/mhp_media/649bdfe5c229ff822cb6776f/kn7t7dcfw9zaybw0qgpe.jpg';
            } else if (item?.userAccountType === 'standard') {
              title = `${item?.firstName} ${item?.lastName}`;
              subHeading = `${item?.username}`;
              link = `/profile/${item?.username}`;
              image =
                item?.profilePicture ||
                'https://res.cloudinary.com/my-hi-performance/image/upload/v1688289115/mhp_media/649bdfe5c229ff822cb6776f/kn7t7dcfw9zaybw0qgpe.jpg';
            }

            return {
              title,
              subHeading,
              actionText,
              link,
              image,
            };

          case SearchCategory.ARTICLE:
            return {
              title: item?.heading,
              subHeading: item?.subHeading,
              actionText: 'View',
              link: `/articles/${item?._id}`,
              image: item?.images ? item?.images[0]?.url : '',
            };

          case SearchCategory.PROJECT:
            return {
              title: item?.name,
              subHeading: `By ${item?.firstName} ${item?.lastName}`,
              actionText: 'View',
              link: `/project/${item?._id}`,
              image: item?.images ? item?.images[0] : '',
            };

          case SearchCategory.PRODUCT:
            return {
              title: item?.title,
              subHeading: `Price: $${item?.price}`,
              actionText: 'View',
              link: `/products/${item?._id}`,
              image: item?.productImageURL,
            };

          case SearchCategory.ADVERTISER:
            return {
              title: item?.advertiserDetails?.companyName,
              subHeading: `${
                item?.advertiserDetails?.primaryTags[0]
                  ? `${item?.advertiserDetails?.primaryTags[0]}`
                  : ''
              }${
                item?.advertiserDetails?.primaryTags[1]
                  ? `${', ' + item?.advertiserDetails?.primaryTags[1]}`
                  : ''
              }${
                item?.advertiserDetails?.primaryTags[2]
                  ? `${', ' + item?.advertiserDetails?.primaryTags[2]}`
                  : ''
              }`,
              link: `/profile/${item?.username}`,
              image:
                item?.advertiserDetails?.companyLogo ||
                item?.profilePicture ||
                'https://res.cloudinary.com/my-hi-performance/image/upload/v1688289115/mhp_media/649bdfe5c229ff822cb6776f/kn7t7dcfw9zaybw0qgpe.jpg',
            };

          default:
            return {
              title: `${item?.firstName} ${item?.lastName}`,
              subHeading: 'Click to view',
              actionText: 'View',
              link: `/profile/${item?.username}`,
              image: item?.profilePicture,
            };
        }
      };

      // Map the filtered results to the list
      const searchResults = allResults.map((item: any) => {
        const fields = mapFields(item, filterParam);
        return fields;
      });

      setSearchResult(searchResults);
    },
  });

  useEffect(() => {
    handleSearchSite();
  }, [searchParam, handleSubmit]);

  const handleSearchSite = () => {
    if (searchParam && searchParam.length > 0) {
      setSearchDropdown(true);
      runGlobalIntelligentSearch();
    } else {
      setSearchDropdown(false);
    }
  };

  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );

  const { requiresLogin, showLogin } = useShowLogin();

  const handleLogout = (e: any) => {
    e.preventDefault();
    localStorage.removeItem(accessTokenName);
    localStorage.removeItem(refreshTokenName);
    navigate(REDIRECT_TO_LOGIN_HREF);
    window.location.reload();
  };

  return (
    <Disclosure as='nav' className='fixed left-0 right-0 top-0 z-20'>
      {({ open, close }: any) => (
        <>
          <div className='container py-4 px-2 bg-black/80 backdrop-blur-md'>
            <div className='relative items-center grid grid-cols-6 lg:grid-cols-12 gap-2'>
              <div
                className={
                  'items-center flex logo-container col-start-1 col-span-3 lg:col-span-3' +
                  (quickEnquiry ? 'opacity-50' : '')
                }
              >
                <Link to='/' className='h-full'>
                  <img className='h-8 w-auto col-start-1' src={MHPLogo} alt='Workflow' />
                  <span className='text-[7pt] text-[#705F6E]'>
                    Beta Version 1.0.0 | © 2024 My Hi Performance Pty Ltd
                  </span>
                </Link>
              </div>

              <div
                className={
                  'flex col-start-6 lg:hidden justify-end ' + (quickEnquiry ? 'opacity-50' : '')
                }
              >
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-white transition-ease-in-out duration-250 hover:text-white bg-red hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <MenuIcon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>

              <form
                className={`
                  'col-start-1 col-span-full lg:mt-0 lg:relative grid mg -ml-1 -mr-1 lg:col-span-6 lg:w-auto '
                  ${quickEnquiry ? 'opacity-50' : ''}
                `}
                onSubmit={handleSubmit(handleSearchSite)}
              >
                <div className='w-full'>
                  <label htmlFor='search' className='sr-only'>
                    Search
                  </label>
                  <div className='relative'>
                    <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none'>
                      <SearchIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </div>
                    <input
                      {...register('search')}
                      id='search'
                      name='search'
                      className='block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-[#241B23] text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-gray-100 focus:border-gray-100 focus:ring-white focus:text-gray-900 sm:text-sm'
                      placeholder='Search'
                      type='text'
                    />
                    <div className='absolute inset-y-0 right-0 flex items-center text-red'>
                      <label htmlFor='filter' className='sr-only'>
                        Filter
                      </label>
                      <select
                        {...register('filter')}
                        id='filter'
                        name='filter'
                        className='h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-center lg:text-left'
                      >
                        <option value={SearchCategory.ALL}>All</option>
                        <option value={SearchCategory.USER}>People</option>
                        <option value={SearchCategory.ADVERTISER}>Suppliers</option>
                        <option value={SearchCategory.ARTICLE}>Articles</option>
                        <option value={SearchCategory.PROJECT}>Projects</option>
                        <option value={SearchCategory.PRODUCT}>Products</option>
                      </select>
                    </div>
                    {searchDropdown && searchResult && (
                      <div className='absolute left-0 w-full top-full bg-white border border-transparent rounded-md mt-2'>
                        <ul role='list' className='divide-y divide-gray-200 text-gray-500'>
                          {searchResult &&
                            searchResult.map((item: any, index: any) =>
                              item?.link ? (
                                <li key={index}>
                                  <a
                                    className='block p-2 hover:bg-gray-200 cursor-pointer'
                                    href={item?.link}
                                  >
                                    <div className='flex space-x-3 items-center'>
                                      <div
                                        className='h-14 w-20 rounded-md bg-center bg-cover'
                                        style={{ backgroundImage: `url(${item?.image})` }}
                                      ></div>

                                      <div className='flex-1'>
                                        <div className='flex items-center justify-between'>
                                          <h3 className='text-sm font-bold text-black'>
                                            {item?.title}
                                          </h3>
                                          <p className='text-sm text-red'>{item?.actionText}</p>
                                        </div>
                                        <p className='text-sm text-gray-500'>{item?.subHeading}</p>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              ) : null,
                            )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </form>

              <div className='hidden lg:ml-4 lg:grid lg:col-span-3'>
                <div className='items-center'>
                  {/* Profile dropdown */}

                  <Menu as='div' className='relative flex-shrink-0'>
                    <div className='col-span-6 pl-4 lg:col-span-3 relative'>
                      <div className='relative'>
                        <button
                          onClick={openQuickEnquiry}
                          style={{
                            borderBottomLeftRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                            borderBottomRightRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                          }}
                          className='headerbutton items-center justify-center flex w-full rounded-md text-white p-1 transition ease-in-out duration-300 bg-red hover:bg-[#A60626]'
                        >
                          <UserGroupIcon
                            className='h-5 w-5 text-white-400 mr-2'
                            aria-hidden='true'
                          />
                          Quick Enquiry
                        </button>
                        {quickEnquiry ? <QuickEnquiry showQuickEnquiry={showQuickEnquiry} /> : null}
                      </div>
                    </div>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href='#'
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }: any) => (
                            <a
                              href='#'
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='lg:hidden w-full absolute z-5 bg-black/80 backdrop-blur-md'>
            <div className='px-6 pb-6 pt-2 space-y-4'>
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <div className='quick-enquiry-holder col-span-full relative'>
                <div className='enquiry__wrapper relative'>
                  <button
                    onClick={openQuickEnquiry}
                    style={{
                      borderBottomLeftRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                      borderBottomRightRadius: `${quickEnquiry ? '0' : '0.375rem'}`,
                    }}
                    className='headerbutton w-full rounded-md text-white p-1 py-2 transition ease-in-out duration-300 bg-red hover:bg-[#A60626]'
                  >
                    <div className='absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none'>
                      <UserGroupIcon className='h-5 w-5 text-white-400' aria-hidden='true' />
                    </div>
                    Quick Enquiry
                  </button>
                  {quickEnquiry ? <QuickEnquiry /> : null}
                </div>
              </div>
              <div
                className={
                  'quickenquiry-enabled-overlay space-y-2 ' + (quickEnquiry ? 'opacity-50' : '')
                }
              >
                <Link
                  to='/'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Home
                </Link>
                <Link
                  to='/featured-articles'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Featured Articles
                </Link>
                <Link
                  to='/videos'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Videos
                </Link>
                <GatedLink
                  to='/messages'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Messages
                </GatedLink>
                <Link
                  to='/clubs'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Clubs
                </Link>
                <Link
                  to='/suppliers'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Suppliers
                </Link>
                <Link
                  to='/events'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Events
                </Link>
                <Link
                  to='/map'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  Map
                </Link>
                <GatedLink
                  to='/projects'
                  onClick={() => close()}
                  className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                >
                  My Projects
                </GatedLink>
                {userData.userAccountType === 'admin' && (
                  <GatedLink
                    to='/articles'
                    onClick={() => close()}
                    className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    My Articles
                  </GatedLink>
                )}
                {userData.userAccountType === 'advertiser' ||
                userData.userAccountType === 'admin' ? (
                  <GatedLink
                    to='/products'
                    onClick={() => close()}
                    className='text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
                  >
                    My Products
                  </GatedLink>
                ) : (
                  ''
                )}
              </div>
            </div>
            {!requiresLogin ? (
              <div
                className={
                  'pt-4 pb-3 border-t border-gray-700 ' + (quickEnquiry ? 'opacity-50' : '')
                }
              >
                <div className='flex items-center px-5'>
                  <div className='flex-shrink-0'>
                    <img
                      className='h-10 w-10 rounded-full'
                      src={userData.profilePicture ? userData.profilePicture : TestImage}
                      alt=''
                    />
                  </div>
                  <div className='ml-3'>
                    <div className='text-base font-medium text-white'>
                      {userData.firstName} {userData.lastName}
                    </div>
                    <div className='text-sm font-medium text-gray-400'>{userData.email}</div>
                  </div>
                </div>

                <div className='mt-3 px-2 space-y-1'>
                  <GatedLink
                    to={`/profile/${userData.username}`}
                    onClick={() => close()}
                    className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                  >
                    Your Profile
                  </GatedLink>
                  {userData.userAccountType === 'admin' && (
                    <GatedLink
                      to='/adminarea/tags'
                      onClick={() => close()}
                      className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                    >
                      Manage Tags
                    </GatedLink>
                  )}
                  <Disclosure.Button
                    as='a'
                    href='#'
                    onClick={handleLogout}
                    className='block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700'
                  >
                    Logout
                  </Disclosure.Button>
                </div>
              </div>
            ) : (
              <div className='px-6 -mt-4'>
                <GatedNavLink
                  to='/'
                  className='flex mt-5 rounded-full bg-red hover:bg-[#A60626] transition-all cursor-pointer text-white text-center w-full justify-center px-8 py-2 mb-5'
                >
                  <div>Sign in</div>
                  <div className='fill-white mt-[2px] ml-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='20'
                      viewBox='0 0 36 27.565'
                    >
                      <path
                        d='M29.25,31.5H23.344a.846.846,0,0,1-.844-.844V27.844A.846.846,0,0,1,23.344,27H29.25a2.248,2.248,0,0,0,2.25-2.25V11.25A2.248,2.248,0,0,0,29.25,9H23.344a.846.846,0,0,1-.844-.844V5.344a.846.846,0,0,1,.844-.844H29.25A6.752,6.752,0,0,1,36,11.25v13.5A6.752,6.752,0,0,1,29.25,31.5Zm-3.3-14.133L14.133,5.555a1.69,1.69,0,0,0-2.883,1.2V13.5H1.688A1.683,1.683,0,0,0,0,15.188v6.75a1.683,1.683,0,0,0,1.688,1.688H11.25v6.75a1.69,1.69,0,0,0,2.883,1.2L25.945,19.758A1.7,1.7,0,0,0,25.945,17.367Z'
                        transform='translate(0 -4.5)'
                      />
                    </svg>
                  </div>
                </GatedNavLink>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
