const BinIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 596.26 800'>
      <path d='m69.98,761.42c.9,21.56,18.65,38.58,40.22,38.58h375.85c21.58,0,39.32-17.02,40.22-38.58l26.84-566.62H43.14l26.84,566.62Zm313.17-425.88c0-9.03,7.32-16.35,16.35-16.35h26.16c9.03,0,16.35,7.32,16.35,16.35v323.72c0,9.03-7.32,16.35-16.35,16.35h-26.16c-9.03,0-16.35-7.32-16.35-16.35v-323.72Zm-114.45,0c0-9.03,7.32-16.35,16.35-16.35h26.16c9.03,0,16.35,7.32,16.35,16.35v323.72c0,9.03-7.32,16.35-16.35,16.35h-26.16c-9.03,0-16.35-7.32-16.35-16.35v-323.72h0Zm-114.45,0c0-9.03,7.32-16.35,16.35-16.35h26.16c9.03,0,16.35,7.32,16.35,16.35v323.72c0,9.03-7.32,16.35-16.35,16.35h-26.16c-9.03,0-16.35-7.32-16.35-16.35v-323.72Z' />
      <path d='m570.99,41.21h-173.39V8.43c0-4.66-3.77-8.43-8.43-8.43h-182.08c-4.66,0-8.43,3.78-8.43,8.43v32.78H25.26c-13.95,0-25.26,11.31-25.26,25.27v79.37h596.26v-79.37c0-13.95-11.31-25.27-25.26-25.27Z' />
    </svg>
  );
};

export default BinIcon;
