import { gql } from '@apollo/client';

export const MESSAGE_SENT_SUB = gql`
  subscription MessageSentSubscription {
    messagePosted {
      createdAt
      readerStatus
      senderStatus
      chatId
      messageContent
      senderId
    }
  }
`;

export const USER_TYPING_SUB = gql`
  subscription UserIsTyping {
    userTyping {
      fullName
      chatId
    }
  }
`;
