import { useEffect, useRef, useState, useContext } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
// import Map from 'mapbox-gl';

type LocationGeo = {
  locationName: string;
  locationAddress: string;
  latitude: number;
  longitude: number;
  _id?: string;
  username?: string | null;
  pinType?: string;
  date?: string;
};

type Location = {
  _id?: string;
  username?: string | null;
  locationName: string;
  locationAddress: string;
  pinType?: string;
  date?: string;
};

export default function MapComponent({
  locations,
  variant,
}: {
  locations: Location[];
  variant: string;
}) {
  const navigate = useNavigate();

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 10,
  });
  const [locationsGeo, setLocationsGeo] = useState<LocationGeo[]>([]);

  const initialViewportRef = useRef(viewport);

  function handleGeocode(location: Location) {
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          location.locationAddress,
        )}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
      )
      .then((response) => {
        const features = response.data.features;
        if (features.length > 0) {
          const [longitude, latitude] = features[0].center;
          setLocationsGeo((prev) => [
            ...prev,
            {
              locationName: location.locationName,
              locationAddress: location.locationAddress,
              latitude,
              longitude,
              username: location.username,
              _id: location._id,
              pinType: location.pinType || 'Supplier',
              date: location.date,
            },
          ]);
          if (variant === 'Map') {
          } else {
            if (locations.length > 1) {
              setViewport({
                ...viewport,
                latitude: -27,
                longitude: 133.5,
                zoom: 2,
              });
            } else {
              setViewport({ ...viewport, latitude, longitude, zoom: 10 });
            }
          }
        } else {
          console.log(`No results found for ${location.locationName}.`);
        }
      })
      .catch((error: any) => {
        console.error(`Geocoding error for ${location.locationName}:`, error);
      });
  }

  useEffect(() => {
    setLocationsGeo([]);
    locations.forEach((location) => handleGeocode(location));
    if (variant === 'Map') {
      setViewport({
        ...viewport,
        latitude: -26.8,
        longitude: 132.5,
        zoom: 3.5,
      });
    }
    initialViewportRef.current = viewport;
  }, [locations]);

  return (
    <div className='w-full h-full'>
      <ReactMapGL
        {...viewport}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onMove={(evt: any) => setViewport(evt.viewState)}
        mapStyle='mapbox://styles/pathetic/clp23vmnd00e801of6uk43as3'
      >
        {locationsGeo.map((geoLocation, index) => {
          return (
            <Marker
              key={index}
              longitude={geoLocation.longitude}
              latitude={geoLocation.latitude}
              style={{ position: 'absolute', top: 0, left: 0 }}
              onClick={() => {
                if (variant === 'Map' && geoLocation.pinType === 'Supplier') {
                  navigate(`/profile/${geoLocation.username}`);
                } else if (variant === 'Map' && geoLocation.pinType === 'Event') {
                  navigate(`/events/${geoLocation._id}`);
                }
              }}
            >
              <div className={`group ${variant === 'Map' ? 'cursor-pointer' : 'cursor-default'}`}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 21 30'
                  className={`h-[24px] -mt-[12px] ${
                    geoLocation?.pinType === 'Supplier' ? 'fill-red' : 'fill-black'
                  }`}
                >
                  <path
                    d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
                    transform='translate(-7.5 -3)'
                  />
                </svg>

                <div className='h-[24px] flex items-center opacity-0 text-[8pt] group-hover:opacity-100 absolute -mt-6 ml-[25px] text-black w-[180px] font-semibold transition-all duration-100'>
                  <div
                    className={`font-[Montserrat] text-[11pt] ${
                      geoLocation?.pinType === 'Supplier' ? 'text-red' : 'text-black'
                    }`}
                  >
                    <div className='font-extrabold'>{geoLocation.locationName}</div>
                    <div className='text-[7pt] uppercase'>
                      {geoLocation.pinType}
                      {geoLocation.date && <> | {geoLocation.date}</>}
                    </div>
                  </div>
                </div>
              </div>
            </Marker>
          );
        })}
      </ReactMapGL>
    </div>
  );
}
