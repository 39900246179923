import React, { useState } from 'react';
import { useMutation, gql, useQuery, useLazyQuery } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { TrashIcon, XCircleIcon } from '@heroicons/react/outline';
import { useForm } from 'react-hook-form';

const CREATE_TAG = gql`
  mutation Mutation($input: TagInput) {
    createTag(input: $input) {
      tag
    }
  }
`;

const GET_TAGS = gql`
  query Query {
    getAllTags
  }
`;

const DELETE_TAG = gql`
  mutation Mutation($tagName: String!) {
    deleteTag(tagName: $tagName)
  }
`;

const ManageTags = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const [deleteTag] = useMutation(DELETE_TAG, { refetchQueries: [GET_TAGS, 'Query'] });
  const [addTagMutation, { data: addTagData, loading: addTagLoading }] = useMutation(CREATE_TAG);
  const [getAllTags, { loading, error, data }] = useLazyQuery(GET_TAGS);

  const handleDeleteTag = (tag: any) => {
    deleteTag({
      variables: {
        tagName: tag,
      },
    });
  };

  const handleAddTag = async (inputs: any) => {
    setFormError('');
    const { tag } = inputs;
    try {
      const { data } = await addTagMutation({
        variables: {
          input: {
            tag,
          },
        },
      });

      if (data) {
        getAllTags();
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  if (error) {
    return (
      <>
        <h1>Failed to get tags, please log out and try again.</h1>
      </>
    );
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleAddTag)} className='space-y-6 mt-6'>
        <div className='shadow sm:rounded-md sm:overflow-hidden'>
          <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
            <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4'>
              <div className='sm:col-span-1'>
                <label htmlFor='tag' className='block text-sm font-medium text-gray-700'>
                  Tag
                </label>
                <div className='mt-1'>
                  <input
                    id='tag'
                    {...register('tag', { required: 'Tag required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter tag'
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
            {addTagLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                >
                  Create Tag
                </button>
                <button
                  onClick={() => getAllTags()}
                  className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
                >
                  Fetch all tags
                </button>
              </>
            )}
          </div>
        </div>
      </form>

      <ul className='flex flex-wrap mt-8'>
        {data &&
          data.getAllTags.map((tag: any, index: any) => (
            <li
              key={index}
              className='inline-flex justify-center items-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            >
              <span className='block'>{tag}</span>
              <XCircleIcon
                className='ml-2 h-6 w-6 text-white cursor-pointer hover:text-red'
                aria-hidden='true'
                onClick={() => handleDeleteTag(tag)}
              />
            </li>
          ))}
      </ul>
    </>
  );
};

export default ManageTags;
