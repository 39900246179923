import draftToMarkdown from 'draftjs-to-markdown';
import draftToHtml from 'draftjs-to-html';
export class Utilities {
  static getImagePreview(e: FileList) {
    const reader = new FileReader();
    const file = e[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      return reader.result;
    };
  }

  /// Returns random element from array of elements
  static getRandomElementFromArray(arr: any[]): any {
    let arrIndex = Math.floor(Math.random() * arr.length);
    return arr[arrIndex];
  }

  /// Return string with capital letter for the first word
  /// EG, hello -> Hello
  static capitalizeFirstLetter(input: string) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  /// Generate random number in range and return the result
  static getRandomNumberFromRange = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min)) + min;
}

/// Parse incoming WYSIWYG payload from DB and return Markdown or ""
export const getHTMLFromDraftState = (input: string) => {
  try {
    // If JSON Parse fails, return empty string to prevent crashes
    return draftToHtml(JSON.parse(input));
    // return draftToMarkdown(JSON.parse(input));
  } catch (error) {
    // Protect for edge case of old articles sections that are only strings
    if (typeof input === 'string' && input?.length > 0) {
      return input;
    }
    return '';
  }
};

export const disableKeyEnter = (e: any) => {
  if (e.code === 'Enter') e.preventDefault();
};
