import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import CreateProductForm from '@/components/products/CreateProductForm';

const AddProduct = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const navigate = useNavigate();

  return (
    <div className='grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-8 mt-3'>
          <h1>Add New Product or Service</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-full'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <CreateProductForm />
      </div>
    </div>
  );
};

export default AddProduct;
