import { Dispatch, createContext, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Outlet } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { RootState } from '@/store/reducers/rootReducer';
import { BaseActionType, BaseActions } from '@/store/actions/baseActions';
import { UserActionType, UserActions } from '@/store/actions/userActions';
import Header from '@/parts/Header';
import UserMenu from '@/parts/UserMenu';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import useIsAuthenticated from '@/utils/useIsAuthenticated';
import LoginModal, { useShowLogin } from '@/components/LoginModal';

const USER_PROFILE_QUERY = gql`
  query Query {
    me {
      _id
      email
      userAccountType
      firstName
      lastName
      username
      account_status
      completedInitialProfileSetup
      profilePicture
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
      locations {
        locationName
        locationAddress
        locationPhone
      }
    }
  }
`;

function LoadedDashboard({ userData = undefined }: { userData?: any }) {
  const [quickEnquiry, showQuickEnquiry] = useState(false);

  const { showLogin } = useShowLogin();

  const openQuickEnquiry = () => {
    if (userData === undefined) {
      showLogin();
    } else {
      showQuickEnquiry(!quickEnquiry);
    }
  };

  return (
    <>
      <Header
        userData={userData}
        quickEnquiry={quickEnquiry}
        showQuickEnquiry={showQuickEnquiry}
        openQuickEnquiry={openQuickEnquiry}
      />
      <div
        className={'contentwrapper container pt-40 lg:pt-20 ' + (quickEnquiry ? 'opacity-50' : '')}
      >
        <div className='grid grid-cols-12'>
          <div className='col-span-12 lg:col-span-3 mr-9 hidden lg:block'>
            <UserMenu userData={userData} />
          </div>
          <div className='col-span-12 lg:col-span-9'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

function LoadingDashboard() {
  return (
    <div className='flex justify-center items-center h-screen w-full'>
      <LoadingSpinner />
    </div>
  );
}

function AuthenticatedDashboard() {
  const { loading, error, data } = useQuery(USER_PROFILE_QUERY);

  const userDispatch = useDispatch<Dispatch<UserActions>>();
  const { userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let isMounted = true;

    async function fetchData() {
      if (isMounted && data && data.me.completedInitialProfileSetup !== true) {
        window.location.href = '/welcome';
      }
    }

    fetchData();

    if (data && data?.me?.email !== '' && userData.email === '') {
      userDispatch({ type: UserActionType.SET_USERDATA, payload: data.me });
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [data]);

  if (error) return <h1>'Error...'</h1>;
  if (loading || !userData) return <LoadingDashboard />;

  return <LoadedDashboard userData={userData} />;
}

export default function Dashboard() {
  const { loading, isAuthenticated } = useIsAuthenticated();

  if (loading) return <LoadingDashboard />;

  if (isAuthenticated) return <AuthenticatedDashboard />;

  return (
    <LoginModal>
      <LoadedDashboard />
    </LoginModal>
  );
}
