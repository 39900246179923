import steeringWheel from '@images/steering-wheel-icon.svg';
import { GET_ALL_PROJECTS } from '../../utils/queries/queries';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function CommunityProjects({ projects }: any) {
  //   const {
  //     loading: projectsLoading,
  //     error: projectsError,
  //     data: projectsData,
  //   } = useQuery(GET_ALL_PROJECTS);

  return (
    <>
      {!projects ? (
        <LoadingSpinner />
      ) : (
        <div className='bg-[#241B23] rounded-md p-3'>
          <div className='mb-3 flex align-middle'>
            <img className='mt-1 h-6 mr-2' src={steeringWheel} alt='Steering icon' />
            <span className='text-white text-2xl font-[Montserrat] font-extrabold italic'>
              Latest Projects
            </span>
          </div>
          <div className='grid grid-cols-1 gap-3'>
            {projects
              .map((project: any) => (
                <div
                  key={project?._id}
                  className='col-span-1 md:col-span-1 relative text-[0pt] hover:text-[10pt] transition-all duration-150 ease-in-out'
                >
                  <Link to={`/project/${project?._id}`}>
                    <div
                      className='h-48 lg:h-[160px] rounded-md bg-cover bg-center'
                      style={{
                        backgroundImage: `url(${project?.images[0]})`,
                      }}
                    >
                      <div className='text-white absolute p-2 h-auto w-full bg-gradient-to-t from-[#080607d5] to-[#08060700] bottom-0 rounded-b-md'>
                        <div className='text-xl font-[Montserrat] font-extrabold italic line-clamp-2'>
                          {project?.name}
                        </div>
                        <div className='line-clamp-1'>
                          {project?.year} {project?.make} {project?.model}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
              .reverse()
              .splice(0, 8)}
          </div>
        </div>
      )}
    </>
  );
}

export default CommunityProjects;
