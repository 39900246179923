import { useForm } from 'react-hook-form';
import { useState, Dispatch, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { RootState } from '@/store/reducers/rootReducer';
import { BaseActionType, BaseActions } from '@/store/actions/baseActions';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { MHPLogo } from '@/components/MHPLogo';
import TOSPopOut from './TOSPopOut';
import PolicyPopOut from './PolicyPopOut copy';
import LandingPage from './LandingPage';

const GOOGLE_AUTH = gql`
  mutation Mutation($input: AuthInput!) {
    authGoogle(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

const SIGN_IN_MUTATION = gql`
  mutation Mutation($input: Credentials!) {
    createUserSession(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

declare global {
  var google: any;
}

const loadScript = (src: string) =>
  new Promise((resolve: any, reject: any) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

export function LoginForm({
  setTOSPopOutOpen,
  setPolicyPopOutOpen,
  onLogin,
}: {
  setTOSPopOutOpen: (state: boolean) => void;
  setPolicyPopOutOpen: (state: boolean) => void;
  onLogin: () => void;
}) {
  const { accessTokenName, refreshTokenName } = useSelector(
    (state: RootState) => state.base.baseToken,
  );

  const { googleLoginClientId } = useSelector((state: RootState) => state.base.socialAuth);
  const { isAppReady } = useSelector((state: RootState) => state.base.rootState);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const baseDispatch = useDispatch<Dispatch<BaseActions>>();
  const [googleAuth, { data, loading, error }] = useMutation(GOOGLE_AUTH);
  const [signInMutation, { loading: userLoginLoading }] = useMutation(SIGN_IN_MUTATION);
  const [formError, setFormError] = useState('');
  const googleButton = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';

    loadScript(src)
      .then(() => {
        google.accounts.id.initialize({
          client_id: '775736342698-k8bvs5e7hjai4krc4pu97mm4gdl3c5j6.apps.googleusercontent.com',
          callback: handleGoogleLogin,
        });
        google.accounts.id.renderButton(
          googleButton.current, //this is a ref hook to the div in the official example
          { theme: 'outline' }, // customization attributes
        );
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  useEffect(() => {
    if (isAppReady) {
      onLogin();
    }
  }, [isAppReady]);

  const handleGoogleLogin = async (data: any) => {
    if (data) {
      try {
        const authData = await googleAuth({
          variables: { input: { accessToken: data.credential } },
        });
        if (authData?.data.authGoogle) {
          const { accessToken, refreshToken } = authData.data.authGoogle;
          signInLogic(accessToken, refreshToken);
        }
      } catch (err: any) {
        setFormError(err.message);
      }
    }
  };

  const handleSignIn = async (inputs: any) => {
    setFormError('');
    const { email, password } = inputs;

    try {
      const { data } = await signInMutation({
        variables: {
          input: {
            email: email,
            password: password,
          },
        },
      });

      if (data?.createUserSession) {
        const { accessToken, refreshToken } = data.createUserSession;
        signInLogic(accessToken, refreshToken);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const signInLogic = (access: string, refresh: string) => {
    localStorage.setItem(accessTokenName, access);
    localStorage.setItem(refreshTokenName, refresh);
    baseDispatch({
      type: BaseActionType.SET_ROOT_STATE,
      payload: {
        isAppReady: true,
        isUserAuthenticated: true,
      },
    });
    onLogin();
  };

  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6 lg:z-10 lg:relative overflow-x-scroll absolute inset-0'>
      <div className='bg-black lg:border-[1px] lg:border-red flex-1 flex flex-col justify-center p-12 rounded-md items-center'>
        <img
          className='select-none h-auto w-auto lg:hidden mb-16'
          draggable='false'
          src='https://res.cloudinary.com/my-hi-performance/image/upload/v1695184993/sed4uzqfzli317yvw5in.png'
          alt='Example Project'
        />

        <div className='col-span-1'>
          <div>
            <MHPLogo />
            <h2 className='mt-6 text-3xl font-extrabold text-white'>Sign in to your account</h2>
            <p className='mt-2 text-sm text-gray-600'>
              Or{' '}
              <Link to='/register' className='hover:underline font-medium text-red hover:text-red'>
                create a free account.
              </Link>
            </p>
          </div>

          <div className='mt-8'>
            <div>
              {formError && (
                <div className='rounded-md bg-red50 p-4 mb-5'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <svg
                        className='h-5 w-5 text-red'
                        xmlns='http:www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        aria-hidden='true'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                          clipRule='evenodd'
                        />
                      </svg>
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red'>{formError}</h3>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <p className='text-sm font-medium text-gray-200'>Sign in with</p>
                <div className='mt-1 grid grid-cols-2 gap-3'>
                  <div>
                    <div ref={googleButton}></div>
                  </div>
                  {/* <div>
              <LoginSocialFacebook
                appId={process.env.REACT_APP_FB_APP_ID || ''}
                onResolve={({ provider, data }: IResolveParams) =>
                  handleSocialLogin(provider, data)
                }
                onReject={(err: any) => handleSocialLoginFailure(err)}
              >
                <button className='w-full inline-flex justify-center py-3 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'>
                  <svg
                    className='inline-block mt-[1px] w-4 h-4 mr-3 text-gray-900 fill-current'
                    viewBox='0 0 20 20'
                    width='48px'
                    height='48px'
                  >
                    <path
                      fillRule='evenodd'
                      d='M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z'
                      clipRule='evenodd'
                    />
                  </svg>
                  Facebook
                </button>
              </LoginSocialFacebook>
            </div> */}
                </div>
              </div>
              <div className='mt-6 relative'>
                <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                  <div className='w-full border-t border-gray-300' />
                </div>
                <div className='relative flex justify-center text-sm'>
                  <span className='px-2 bg-black rounded-lg text-gray-500 dark:text-gray-200  font-semibold'>
                    Or continue with
                  </span>
                </div>
              </div>
            </div>
            <div className='mt-6'>
              <form onSubmit={handleSubmit(handleSignIn)} className='space-y-6'>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm text-gray-700 dark:text-gray-200  font-semibold'
                  >
                    Email address
                  </label>
                  <div className='mt-1'>
                    <input
                      id='email'
                      {...register('email', {
                        required: 'required',
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: 'Email is not valid',
                        },
                      })}
                      type='email'
                      autoComplete='email'
                      className='text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                    />
                    {errors.email !== undefined && (
                      <span role='alert' className='text-xs'>
                        {`${errors.email.message}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className='space-y-1'>
                  <label
                    htmlFor='password'
                    className='block text-sm text-gray-700 dark:text-gray-200  font-semibold'
                  >
                    Password
                  </label>
                  <div className='mt-1'>
                    <input
                      id='password'
                      {...register('password', {
                        required: 'required',
                        minLength: {
                          value: 5,
                          message: 'Minimum 5 character for password.',
                        },
                      })}
                      type='password'
                      autoComplete='current-password'
                      className='text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red focus:border-red sm:text-sm'
                    />
                    {errors.password !== undefined && (
                      <span role='alert' className='text-xs'>
                        {`${errors.password.message}`}
                      </span>
                    )}
                  </div>
                </div>
                <div className='grid justify-items-end'>
                  <div className='text-[8pt] -mt-4'>
                    <Link
                      to='/forgot'
                      className='hover:underline font-medium text-red hover:text-red'
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div>
                  {userLoginLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className='space-y-4'>
                      <button
                        type='submit'
                        className='w-full flex justify-center py-2 font-bold px-4 rounded-md shadow-sm text-sm text-white bg-red hover:bg-[#A60626] transition-all duration-100'
                      >
                        Sign in
                      </button>

                      <Link
                        to='/register'
                        className='w-full flex justify-center py-2 font-bold px-4 border-red border-[1px] rounded-md shadow-sm text-sm text-white border-1-red hover:bg-black2 transition-all duration-100'
                      >
                        Create your free account
                      </Link>
                    </div>
                  )}
                </div>
                <div>
                  <div className='text-[#705F6E] cursor-pointer font-extrabold uppercase text-[8pt]'>
                    <span className='hover:underline' onClick={() => setTOSPopOutOpen(true)}>
                      Terms of Service
                    </span>
                    <span> | </span>
                    <span className='hover:underline' onClick={() => setPolicyPopOutOpen(true)}>
                      Privacy Policy
                    </span>
                  </div>
                  <div className='text-[#705F6E] font-extrabold uppercase text-[8pt] mt-4'>
                    Contact Us
                  </div>
                  <a
                    className='text-[#705F6E] hover:underline'
                    href={`mailto: sendit@myhiperformance.com`}
                  >
                    sendit@myhiperformance.com
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className='hidden lg:col-span-1 lg:rounded-l-md lg:items-center lg:justify-center lg:flex'>
        <ExampleProject />
      </div>
    </div>
  );
}

const Login = () => {
  const [tosPopOutOpen, setTOSPopOutOpen] = useState(false);
  const [policyPopOutOpen, setPolicyPopOutOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {tosPopOutOpen && <TOSPopOut closeFunction={() => setTOSPopOutOpen(false)} />}
      {policyPopOutOpen && <PolicyPopOut closeFunction={() => setPolicyPopOutOpen(false)} />}

      <div className='min-h-screen flex'>
        <div className='hidden lg:block relative w-full'>
          <LandingPage />
        </div>
        <LoginForm onLogin={() => navigate('/')} {...{ setTOSPopOutOpen, setPolicyPopOutOpen }} />
      </div>
    </>
  );
};

export default Login;

const ExampleProject = () => {
  return (
    <div className='w-[570px] -mt-2 relative'>
      <img
        className='select-none'
        draggable='false'
        src='https://res.cloudinary.com/my-hi-performance/image/upload/v1695184993/sed4uzqfzli317yvw5in.png'
        alt='Example Project'
      />
    </div>
  );
};
