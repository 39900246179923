import { Link } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import TestImage from '@/assets/images/no_image_found.png';
import { useEffect, useState } from 'react';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import ConfirmPopup from '@/components/ConfirmPopup';
import { PopupModalActionTypes } from '@/utils/types';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';

const GET_ARTICLES = gql`
  query Query($userId: ID) {
    getArticles(userId: $userId) {
      _id
      heading
      subHeading
      images {
        index
        caption
        url
        projects {
          id
          name
        }
      }
      articleTemplate
    }
  }
`;

const DELETE_ARTICLE = gql`
  mutation DeleteArticleMutation($deleteArticleId: ID) {
    deleteArticle(articleId: $deleteArticleId)
  }
`;

const Articles = () => {
  const [deleteArticle] = useMutation(DELETE_ARTICLE, {
    refetchQueries: [GET_ARTICLES, 'Query'],
  });

  const { loading, data, refetch, error } = useQuery(GET_ARTICLES);
  const [popupActive, setPopupActive] = useState(false);

  useEffect(() => {
    // TODO - Resolve to a better solution, this is for the navigation back from creating a new article.
    // Query was not working correctly by firing off a new fetch.
    refetch();
  }, [refetch]);

  const handleDeleteArticle = (data: any, _id: any) => {
    if (data) {
      deleteArticle({
        variables: {
          deleteArticleId: _id,
        },
      });
    }
  };

  if (error) {
    return (
      <>
        <h1>
          Oops! Something went wrong. Failed to get your articles, please log out and try again.
        </h1>
      </>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className='relative'>
          <div className='text-white font-semibold items-center text-xl mt-1 flex mb-3'>
            <h1>My Articles</h1>
            <Link
              className='transition ease-in-out duration-200 bg-red hover:bg-[#A60626] my-2 py-2 px-4 ml-4 text-sm font-semibold rounded-full'
              to='/articles/add'
            >
              Add Article
            </Link>
          </div>

          <div className='grid grid-cols-12 gap-6'>
            {data &&
              data.getArticles.creatorId !== null &&
              data.getArticles
                .map((article: any) => (
                  <div
                    key={article._id}
                    className='col-span-6 md:col-span-4 lg:col-span-3 bg-[#241B23] hover:bg-[#30232e] rounded-md relative transition-all ease-in-out duration-150'
                  >
                    <Link className='' to={`/articles/${article._id}`}>
                      <div
                        className='h-[200px] bg-cover bg-center rounded-tl-md rounded-tr-md'
                        style={{
                          backgroundImage: `url(${
                            article?.images[0]?.url
                              ? // If article image list exists, grab the first image from the imageList arr
                                article.images[0].url
                              : TestImage
                          })`,
                        }}
                      />
                      <div className='p-3'>
                        <h4 className='font-extrabold uppercase text-[8pt] mb-1'>
                          {article.articleTemplate}
                        </h4>
                        <h3 className='text-lg leading-6 text-left font-bold line-clamp-2'>
                          {article.heading}
                        </h3>
                        <h4 className='mb-6 mt-1 text-[10pt] font-light line-clamp-2'>
                          {article.subHeading}
                        </h4>
                      </div>
                    </Link>
                    <div className='bottom-0 right-0 absolute p-3'>
                      <DeleteItemButton
                        deleteFunction={(e: any) => handleDeleteArticle(e, article._id)}
                      />
                    </div>
                  </div>
                ))
                .reverse()}
            <Link
              className='col-span-6 md:col-span-4 lg:col-span-3 bg-[#110d11] hover:bg-[#241B23] rounded-md transition-all ease-in-out duration-200 items-center justify-center flex'
              to='/articles/add'
            >
              <div className='relative text-center p-6'>
                <div className='text-4xl font-bold'>+</div>
                <div>Add Article</div>
              </div>
            </Link>
          </div>
        </div>
      )}
      <br></br>
    </>
  );
};

export default Articles;
