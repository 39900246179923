import { Link, useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import PhotoPopOut from '@/utils/photos/PhotoPopOut';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';
import NewMessagePopup from '@/components/usercomponents/NewMessagePopup';
import MessageIcon from '@/assets/icons/MessageIcon';
import { useShowLogin } from '@/components/LoginModal';

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($productId: ID) {
    getProductById(productId: $productId) {
      product {
        _id
        createdBy
        title
        price
        comparePrice
        bodyContent
        productImageURL
        vendor
        type
        categories
        tags
      }
      firstName
      lastName
      username
      profilePicture
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProductMutation($deleteProductId: ID) {
    deleteProduct(productId: $deleteProductId)
  }
`;

export const GET_USER_BY_ID = gql`
  query Query($userId: ID) {
    getUserById(userId: $userId) {
      _id
      firstName
      lastName
      username
      profilePicture
      advertiserDetails {
        companyName
        companyLogo
      }
    }
  }
`;

const SingleProduct = () => {
  const { productSlug } = useParams();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const [messageUser, setMessageUser] = useState<boolean>(false);

  const {
    error,
    loading,
    data: productData2,
  } = useQuery(GET_PRODUCT_BY_ID, {
    variables: {
      productId: productSlug,
    },
  });

  const {
    error: userDataError,
    loading: userDataLoading,
    data: userData2,
  } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: productData2?.getProductById?.product?.createdBy,
    },
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  const handleDeleteProduct = (data: any, _id: any) => {
    if (data) {
      deleteProduct({
        variables: {
          deleteProductId: _id,
        },
      }).then(() => {
        navigate('/products');
      });
    }
  };

  const [product, setProductDataLoaded] = useState<any>();
  const [productCreatorData, setProductCreatorDataLoaded] = useState<any>();

  useEffect(() => {
    if (productData2) {
      setProductDataLoaded(productData2?.getProductById?.product);
    }
    if (userData2) {
      setProductCreatorDataLoaded(userData2?.getUserById);
    }
  }, [productData2, userData2]);

  const [photoPopOut, setPhotoPopOut] = useState<boolean>(false);
  const [popOutIndex, setPopOutIndex] = useState<number>();

  const { requiresLogin, showLogin } = useShowLogin();

  function enlargePhoto(index: number) {
    setPopOutIndex(index);
    setPhotoPopOut(true);
  }

  const handleMessageUserClickFnc = () => {
    setMessageUser(!messageUser);
  };

  return (
    <>
      <div className='flex flex-row text-white font-semibold items-center text-xl mb-2 justify-end w-full'>
        <button
          className='col-span-6 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 py-2 px-4 text-sm font-semibold rounded-full'
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
        {userData?._id === product?.createdBy && (
          <>
            <Link
              to={`/edit-product/${product?._id}`}
              className='flex flex-row text-white font-semibold items-center text-xl justify-end ml-4'
            >
              <div className='col-span-6 whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 py-2 px-4 text-sm font-semibold rounded-full'>
                Edit Product
              </div>
            </Link>
            <div className='p-1 bg-red rounded-full ml-4'>
              <DeleteItemButton
                deleteFunction={(e: Event) => handleDeleteProduct(e, product._id)}
                crossColour={'#ffffff'}
              />
            </div>
          </>
        )}
      </div>

      <div className='grid grid-cols-6 rounded-md relative bg-[#241B23] mb-10 gap-4 p-4 md:gap-6 md:p-6'>
        {photoPopOut && (
          <PhotoPopOut
            imageArray={product?.productImageURL}
            imageIndex={popOutIndex}
            closeFunction={() => setPhotoPopOut(false)}
          />
        )}

        <div className='md:col-span-4 col-span-full'>
          <div
            className={`flex items-center justify-center ${
              !product?.productImageURL[1] ? 'h-full' : ''
            }`}
          >
            <img
              src={`${product?.productImageURL[0]}`}
              alt=''
              className='w-full h-full max-h-[550px] object-contain cursor-pointer rounded-md'
              onClick={() => enlargePhoto(0)}
            />
          </div>

          {product?.productImageURL[1] && (
            <div className='h-fit gap-6 flex overflow-x-scroll mt-6'>
              {product?.productImageURL.map((image: string, index: number) => {
                return (
                  <img
                    className='cursor-pointer h-20 md:h-28 lg:h-32 rounded-md mb-2'
                    src={`${image}`}
                    alt={`Gallery pic ${index}`}
                    onClick={() => enlargePhoto(index)}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div className='md:col-start-5 md:col-span-2 col-start-1 col-span-full flex items-center'>
          <div className='space-y-6'>
            <Link
              to={`/profile/${productCreatorData?.username}`}
              className='flex items-center gap-4'
            >
              {productCreatorData?.advertiserDetails?.companyLogo && (
                <img
                  className='h-auto w-auto max-h-[50px] max-w-[150px] object-contain'
                  src={productCreatorData?.advertiserDetails?.companyLogo}
                  alt='Company Logo'
                  draggable={false}
                />
              )}
              <div className='flex flex-col border-l-[1px] border-[#705F6E] pl-4 font-extrabold uppercase text-[8pt] text-red'>
                <div className='text-[#705F6E]'>Listed by</div>
                <div>
                  {productCreatorData?.advertiserDetails?.companyName ||
                    productCreatorData?.username}
                </div>
              </div>
            </Link>

            <div className='h-[1px] w-full bg-[#705F6E]' />

            <div className='font-[Montserrat] font-bold italic text-2xl md:text-3xl lg:text-4xl'>
              {product?.title}
            </div>

            <div>
              {product?.price && (
                <div className='font-[Montserrat] text-4xl font-semibold text-red'>
                  ${product?.price}
                </div>
              )}
            </div>

            {product?.vendor && (
              <>
                <div className='text-red font-extrabold uppercase text-[8pt]'>Brand</div>
                <div className=''>{product?.vendor}</div>
              </>
            )}
            {product?.bodyContent && (
              <div className=''>
                <div className='text-red font-extrabold uppercase text-[8pt]'>Product Details</div>
                <div className='overflow-y-scroll max-h-[280px] pr-2'>{product?.bodyContent}</div>
              </div>
            )}
            <ul className='flex flex-wrap'>
              {product?.tags.map((tag: string) => {
                return (
                  <li
                    className='inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-[#352833] text-red text-[8pt] font-bold'
                    key={tag}
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>

            <div
              className='cursor-pointer w-fit flex py-2 px-4 bg-red hover:bg-[#A60626] rounded-full'
              onClick={requiresLogin ? showLogin : handleMessageUserClickFnc}
            >
              <MessageIcon />
              <div className='text-[10pt] w-auto ml-2 font-semibold'>{`Message ${productCreatorData?.advertiserDetails?.companyName}`}</div>
            </div>
          </div>
        </div>
      </div>

      {messageUser && <NewMessagePopup userId={productCreatorData?._id} />}
    </>
  );
};

export default SingleProduct;
