import React, { useEffect } from 'react';
import { useState } from 'react';

function PhotoPopOut({ closeFunction, imageArray, imageIndex }: any) {
  let [currentIndex, setCurrentIndex] = useState(imageIndex);
  const [displayPhoto, setDisplayPhoto] = useState(imageArray[imageIndex]);

  function shiftDisplayPhotoForward() {
    if (currentIndex < imageArray.length - 1) {
      let newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setDisplayPhoto(imageArray[newIndex]);
    } else {
      setCurrentIndex(0);
      setDisplayPhoto(imageArray[0]);
    }
  }

  function shiftDisplayPhotoBackward() {
    if (currentIndex === 0) {
      setCurrentIndex(imageArray.length - 1);
      setDisplayPhoto(imageArray[imageArray.length - 1]);
    } else {
      let newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setDisplayPhoto(imageArray[newIndex]);
    }
  }

  function galleryClickHandler(index: number) {
    setDisplayPhoto(imageArray[index]);
    setCurrentIndex(index);
  }

  return (
    <div className='bg-black/80 fixed top-0 left-0 bottom-0 right-0 backdrop-blur-sm z-[100] items-center align-middle justify-center'>
      <div className='flex justify-center h-[75%]'>
        {imageArray[1] && (
          <div className='w-auto flex cursor-pointer my-6' onClick={shiftDisplayPhotoBackward}>
            <button className='select-none p-6 w-full text-right font-bold text-xl hover:animate-pulse transition-all duration-150 ease-in-out'>{`<`}</button>
          </div>
        )}

        <div className='my-6 flex'>
          <div className=''>
            <img className='max-h-full w-auto' src={displayPhoto} alt='Main gallery pic' />
          </div>
        </div>

        {imageArray[1] && (
          <div className='w-auto flex cursor-pointer my-6' onClick={shiftDisplayPhotoForward}>
            <button className='select-none p-6 w-full text-left font-bold text-xl hover:animate-pulse transition-all duration-150 ease-in-out'>
              {`>`}
            </button>
          </div>
        )}
        <div
          className='select-none hover:text-red absolute right-8 top-5 cursor-pointer text-lg transition-all duration-150 ease-in-out'
          onClick={closeFunction}
        >
          x
        </div>
      </div>
      <div className='h-[25%] flex gap-6 items-center justify-center overflow-x-scroll px-6'>
        {imageArray.map((image: string, index: number) => (
          <img
            key={index}
            className={`h-[60%] transition-all duration-150 ease-in-out cursor-pointer mb-6 ${
              currentIndex === index ? 'h-[68%]' : ''
            }`}
            src={image}
            alt='Gallery sub pic'
            onClick={() => galleryClickHandler(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default PhotoPopOut;
