import { GET_ALL_VIDEOS } from '@/utils/queries/queries';
import VideoSwiper from './videos/VideoSwiper';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

interface Video {
  _id: string;
  createdBy: string;
  createdAt: Date;
  videoUrl: string;
  caption: String;
  tags: string[];
}

export default function VideosPage() {
  const { data: videosData2 } = useQuery(GET_ALL_VIDEOS);

  return (
    <div className='mt-4 mb-14 space-y-14'>
      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter((video: Video) =>
            video.videoUrl.includes('/shorts/'),
          )}
          swiperTitle='Latest Shorts'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={5}
          aspectRatio='aspect-[9/16]'
        />
      )}

      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter(
            (video: Video) => !video.videoUrl.includes('/shorts/'),
          )}
          swiperTitle='Latest Videos'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={4}
          aspectRatio='aspect-[16/9]'
        />
      )}

      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter(
            (video: Video) =>
              !video.videoUrl.includes('/shorts/') && video.tags.includes('Drag Racing'),
          )}
          swiperTitle='Drag Racing'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={4}
          aspectRatio='aspect-[16/9]'
        />
      )}

      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter(
            (video: Video) =>
              !video.videoUrl.includes('/shorts/') && video.tags.includes('Burnout'),
          )}
          swiperTitle='Burnouts'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={4}
          aspectRatio='aspect-[16/9]'
        />
      )}

      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter(
            (video: Video) => !video.videoUrl.includes('/shorts/') && video.tags.includes('Drift'),
          )}
          swiperTitle='Drifting'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={4}
          aspectRatio='aspect-[16/9]'
        />
      )}

      {videosData2 && (
        <VideoSwiper
          videosData={videosData2.getAllVideos.filter(
            (video: Video) => !video.videoUrl.includes('/shorts/') && video.tags.includes('MHP How To'),
          )}
          swiperTitle='MHP How To'
          viewAllActive={false}
          isPlaying={false}
          slidesPerView={4}
          aspectRatio='aspect-[16/9]'
        />
      )}
    </div>
  );
}
