import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { GET_ALL_CLUBS } from '@/utils/queries/queries';

export default function Clubs() {
  const { data: clubData2, error: clubError, loading: clubsLoading } = useQuery(GET_ALL_CLUBS);

  const [clubData, setClubDataLoaded] = useState<any>();

  useEffect(() => {
    setClubDataLoaded(clubData2?.getAllClubs);
  }, [clubData2]);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredClubData = clubData?.filter((club: any) => {
    const { companyName, primaryTags, secondaryTags } = club.advertiserDetails;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      companyName.toLowerCase().includes(lowerCaseSearchQuery) ||
      primaryTags.some((tag: string) => tag.toLowerCase().includes(lowerCaseSearchQuery)) ||
      secondaryTags.some((tag: string) => tag.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  return (
    <>
      <div className='text-white font-semibold items-center text-xl mt-4 flex mb-6'>
        <h1>Our Clubs</h1>
      </div>
      {clubsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className='mb-6 text-lg'>Recent Additions</h1>
          <div className='grid grid-cols-12'>
            <div className='col-start-1 col-span-full lg:col-span-8 grid grid-cols-2 gap-8'>
              {clubData
                ?.map((advertiser: any) => {
                  return (
                    <>
                      {advertiser.advertiserDetails.coverPhoto &&
                        advertiser.advertiserDetails.companyLogo && (
                          <Link to={`/profile/${advertiser.username}`}>
                            <div
                              key={advertiser._id}
                              className='rounded-md h-40 bg-cover bg-center cursor-pointer'
                              style={{
                                backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
                              }}
                            >
                              <div className='bg-[#0000009a] hover:bg-[#00000070] transition-all ease-in-out duration-100 w-full h-full p-4 flex flex-col justify-center items-center backdrop-blur-sm hover:backdrop-blur-none'>
                                <div className='flex justify-center items-center'>
                                  <img
                                    className='max-h-16'
                                    src={`${advertiser.advertiserDetails.companyLogo}`}
                                    alt='Company Logo'
                                  />
                                </div>
                                <div className='line-clamp-3 md:line-clamp-2 text-center mt-2'>
                                  {advertiser.advertiserDetails.primaryTags.map(
                                    (tag: string, index: number) => {
                                      return (
                                        <span key={index}>
                                          {tag}
                                          {index !==
                                            advertiser.advertiserDetails.primaryTags.length - 1 &&
                                            ', '}
                                        </span>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                        )}
                    </>
                  );
                })
                .reverse()
                .splice(0, 4)}
            </div>
          </div>

          <div className='grid grid-cols-12'>
            <div className='mb-8 mt-12 col-span-full lg:col-span-8 grid grid-cols-3'>
              <h1 className='text-lg self-center'>Clubs A-Z</h1>
              <input
                placeholder='Search by name, product or service'
                className='col-start-2 col-span-2 py-2 px-3 mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-white border-black text-black rounded-full'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mb-14'>
            <div className='col-start-1 col-span-full lg:col-span-8 grid grid-cols-2 gap-8'>
              {filteredClubData
                ?.slice()
                .sort((a: any, b: any) =>
                  a.advertiserDetails.companyName.localeCompare(b.advertiserDetails.companyName),
                )
                .map((advertiser: any) => {
                  return (
                    <>
                      {advertiser.advertiserDetails.coverPhoto &&
                        advertiser.advertiserDetails.companyLogo && (
                          <Link to={`/profile/${advertiser.username}`}>
                            <div
                              key={advertiser._id}
                              className='rounded-md h-40 bg-cover bg-center cursor-pointer'
                              style={{
                                backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
                              }}
                            >
                              <div className='bg-[#0000009a] hover:bg-[#00000070] transition-all ease-in-out duration-100 w-full h-full p-4 flex flex-col justify-center items-center backdrop-blur-sm hover:backdrop-blur-none'>
                                <div className='flex justify-center items-center'>
                                  <img
                                    className='max-h-16'
                                    src={`${advertiser.advertiserDetails.companyLogo}`}
                                    alt='Company Logo'
                                  />
                                </div>
                                <div className='line-clamp-3 md:line-clamp-2 text-center mt-2'>
                                  {advertiser.advertiserDetails.primaryTags.map(
                                    (tag: string, index: number) => {
                                      return (
                                        <span key={index}>
                                          {tag}
                                          {index !==
                                            advertiser.advertiserDetails.primaryTags.length - 1 &&
                                            ', '}
                                        </span>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                          </Link>
                        )}
                    </>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
