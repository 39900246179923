import { RootState } from '@/store/reducers/rootReducer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GET_USER_BY_ID, GET_EVENT_BY_ID } from '@/utils/queries/queries';
import { gql, useLazyQuery, useQuery, useMutation } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { getHTMLFromDraftState } from '@/utils/utilities';
import EventProjectSearch from '@/components/utils/EventProjectSearch';
import LoginModal, { GatedButton, GatedLink } from '@/components/LoginModal';
import useIsAuthenticated from '@/utils/useIsAuthenticated';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';
import CopyURLButton from '@/components/utils/CopyURLButton';
import PhotoPopOut from '@/utils/photos/PhotoPopOut';

const FIND_ATTENDEE = gql`
  query FindAttendee($eventId: ID) {
    findAttendee(eventId: $eventId) {
      selectedProjects
      eventId
      createdAt
      attendeeId
    }
  }
`;

const FIND_ALL_EVENT_ATTENDEES = gql`
  query FindAllEventAttendees($eventId: ID) {
    findAllEventAttendees(eventId: $eventId) {
      selectedProjects
      eventId
      createdAt
      attendeeId
    }
  }
`;

const CREATE_ATTENDEE = gql`
  mutation Mutation($eventId: ID) {
    createAttendee(eventId: $eventId) {
      selectedProjects
      eventId
      createdAt
      attendeeId
    }
  }
`;

const UPDATE_ATTENDEE_SELECTED_PROJECTS = gql`
  mutation UpdateAttendeeSelectedProjects($eventId: ID, $selectedProjects: [ID]) {
    updateAttendeeSelectedProjects(eventId: $eventId, selectedProjects: $selectedProjects) {
      selectedProjects
      eventId
      createdAt
      attendeeId
    }
  }
`;

const DELETE_ATTENDEE = gql`
  mutation DeleteAttendee($eventId: ID!) {
    deleteAttendee(eventId: $eventId)
  }
`;

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: ID) {
    getProjectById(projectId: $projectId) {
      name
      _id
      images
      createdBy
    }
  }
`;

const DELETE_EVENT = gql`
  mutation DeleteEvent($eventId: ID) {
    deleteEvent(eventId: $eventId) {
      _id
    }
  }
`;

const SingleEvent = () => {
  const { userData } = useSelector((state: RootState) => state.user);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const pageUrl = window.location.href;

  const [getUserById, { data: eventCreatorData2 }] = useLazyQuery(GET_USER_BY_ID);

  const [createAttendee, { loading: createAttendeeLoading }] = useMutation(CREATE_ATTENDEE);
  const [deleteAttendee, { loading: deleteAttendeeLoading }] = useMutation(DELETE_ATTENDEE);
  const [deleteEvent] = useMutation(DELETE_EVENT);
  const [updateSelectedProjects] = useMutation(UPDATE_ATTENDEE_SELECTED_PROJECTS);

  const [eventData, setEventDataLoaded] = useState<any>();
  const [eventAttendeesData, setEventAttendeeDataLoaded] = useState<any>();
  const [eventContent, setEventContent] = useState<string>('');
  const [eventCreatorData, setEventCreatorDataLoaded] = useState<any>();
  const [attendingProjectsArray, setAttendingProjectsArray] = useState<string[]>();
  const [attendingUsersArray, setAttendingUsersArray] = useState<string[]>();

  const [photoPopOut, setPhotoPopOut] = useState<boolean>(false);
  const [popOutIndex, setPopOutIndex] = useState<number>();

  function enlargePhoto(index: number) {
    setPopOutIndex(index);
    setPhotoPopOut(true);
  }

  const { data: eventData2 } = useQuery(GET_EVENT_BY_ID, {
    variables: {
      eventId: eventId,
    },
  });

  const { refetch: findAllEventAttendees, data: eventAttendeesData2 } = useQuery(
    FIND_ALL_EVENT_ATTENDEES,
    {
      variables: {
        eventId: eventId,
      },
    },
  );

  const {
    refetch: findAttendeeRefetch,
    data: findAttendee2,
    loading: findAttendeeLoading,
  } = useQuery(FIND_ATTENDEE, {
    variables: {
      eventId: eventId,
    },
  });

  useEffect(() => {
    setEventDataLoaded(eventData2?.getEventById);
    if (eventData2?.getEventById?.createdBy) {
      getUserById({
        variables: {
          userId: eventData2.getEventById.createdBy,
        },
      });
    }
  }, [eventData2]);

  useEffect(() => {
    setEventAttendeeDataLoaded(eventAttendeesData2?.findAllEventAttendees);
    setAttendingProjectsArray(
      eventAttendeesData2?.findAllEventAttendees?.flatMap(
        (attendee: any) => attendee?.selectedProjects,
      ),
    );
    setAttendingUsersArray(
      eventAttendeesData2?.findAllEventAttendees?.flatMap((attendee: any) => attendee?.attendeeId),
    );
  }, [eventAttendeesData2]);

  useEffect(() => {
    setEventContent(getHTMLFromDraftState(eventData?.eventDetails));
  }, [eventData]);

  useEffect(() => {
    if (eventCreatorData2) {
      setEventCreatorDataLoaded(eventCreatorData2.getUserById);
    }
  }, [eventCreatorData2]);

  const handleCreateAttendee = async () => {
    if (findAttendee2?.findAttendee === null) {
      createAttendee({ variables: { eventId: eventId } });
    }
  };

  const handleDeleteAttendee = async () => {
    if (findAttendee2?.findAttendee !== null) {
      deleteAttendee({ variables: { eventId: eventId } });
    }
  };

  const handleSelectedProjectsChange = (newSelectedProjects: string[]) => {
    updateSelectedProjects({
      variables: { eventId: eventId, selectedProjects: newSelectedProjects || [] },
    });
  };

  const handleDeleteEvent = (eventId: string | undefined) => {
    deleteEvent({
      variables: { eventId: eventId },
    }).then(() => {
      navigate('/events');
    });
  };

  useEffect(() => {
    findAttendeeRefetch();
    findAllEventAttendees();
  }, [createAttendeeLoading, deleteAttendeeLoading, handleSelectedProjectsChange]);

  return (
    <>
      {photoPopOut && (
        <PhotoPopOut
          imageArray={eventData.eventImages[1] ? eventData.eventImages : [eventData.eventImages[0]]}
          imageIndex={popOutIndex}
          closeFunction={() => setPhotoPopOut(false)}
        />
      )}
      {!eventData ? (
        <LoadingSpinner />
      ) : (
        <div className='grid grid-cols-12 mt-6 mb-10'>
          <div className='bg-black2 col-span-12 h-auto rounded-md'>
            <div
              className={`relative rounded-tl-md rounded-tr-md h-[200px] md:h-[350px] bg-center bg-cover cursor-pointer`}
              style={{
                backgroundImage: `url(${eventData.eventImages[0]})`,
              }}
              onClick={() => enlargePhoto(0)}
            />

            <div className={`col-start-1 col-span-12 flex space-x-6 px-6 -mt-10 z-1 relative`}>
              <div className='min-w-[90px]'>
                <div
                  className={`w-full rounded-t-lg text-center uppercase ${
                    eventData.eventType === 'event' ? 'bg-red' : 'bg-blue'
                  }`}
                >
                  {new Date(eventData.dates[0].date).toLocaleDateString(undefined, {
                    month: 'short',
                  })}
                </div>
                <div className='w-full bg-[#352833] h-[65px] flex text-center justify-center text-5xl font-[Montserrat] font-extrabold'>
                  <div className='self-center'>
                    {new Date(eventData.dates[0].date).toLocaleDateString(undefined, {
                      day: 'numeric',
                    })}
                  </div>
                </div>
              </div>
              <h1
                className={`text-2xl sm:text-4xl 2xl:text-5xl px-4 py-2 w-fit flex justify-center font-[Montserrat] font-extrabold italic ${
                  eventData.eventType === 'event' ? 'bg-red' : 'bg-blue'
                }`}
              >
                <div className='self-center'>{eventData.name}</div>
              </h1>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 p-6 gap-y-6 sm:gap-6 space-y-4'>
              <div className='col-start-1 col-span-2'>
                <div className='space-y-6 mt-1'>
                  <div
                    className={`text-sm font-extrabold uppercase ${
                      eventData.eventType === 'event' ? 'text-red' : 'text-blue'
                    }`}
                  >
                    {`${eventData.eventType} starts ${new Date(
                      eventData.dates[0].date,
                    ).toLocaleDateString()} at ${
                      eventData.eventType === 'event'
                        ? eventData.dates[0].startTime
                        : eventData.destinations[0].arrivalTime
                    }`}
                  </div>

                  <div className='flex'>
                    <div className='h-[10px] fill-white mr-3'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        viewBox='0 0 36 36'
                      >
                        <path d='M18,20.25A10.125,10.125,0,1,0,7.875,10.125,10.128,10.128,0,0,0,18,20.25Zm9,2.25H23.126a12.24,12.24,0,0,1-10.252,0H9a9,9,0,0,0-9,9v1.125A3.376,3.376,0,0,0,3.375,36h29.25A3.376,3.376,0,0,0,36,32.625V31.5A9,9,0,0,0,27,22.5Z' />
                      </svg>
                    </div>
                    <div className='-mt-[4px]'>
                      {eventData.eventType === 'event' ? 'Event' : 'Cruise'} by{' '}
                      <Link
                        to={`/profile/${eventCreatorData?.username}`}
                        className='font-semibold hover:underline'
                      >
                        {eventCreatorData?.advertiserDetails?.companyName ||
                          eventCreatorData?.username}
                      </Link>
                    </div>
                  </div>

                  {eventData.eventType === 'event' && (
                    <div className='flex'>
                      <div className='h-[10px] fill-white mr-3'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='20'
                          viewBox='0 0 21 30'
                        >
                          <path
                            d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
                            transform='translate(-7.5 -3)'
                          />
                        </svg>
                      </div>
                      <div className='-mt-[2px]'>{eventData.location}</div>
                    </div>
                  )}

                  {eventData.eventType === 'event' && eventData.eventType.length > 1 && (
                    <div className='space-y-6'>
                      {eventData.dates.map((date: any) => {
                        return (
                          <div className='flex' key={date.date}>
                            <div className='h-[10px] fill-white mr-3'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='16'
                                height='20'
                                viewBox='0 0 31.5 36'
                              >
                                <path
                                  id='Icon_awesome-calendar-day'
                                  data-name='Icon awesome-calendar-day'
                                  d='M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0Zm4.5-13.5A1.128,1.128,0,0,1,5.625,18h6.75A1.128,1.128,0,0,1,13.5,19.125v6.75A1.128,1.128,0,0,1,12.375,27H5.625A1.128,1.128,0,0,1,4.5,25.875ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z'
                                />
                              </svg>
                            </div>
                            <div className='-mt-[2px]'>
                              {new Date(date.date).toLocaleDateString(undefined, {
                                weekday: 'long',
                              })}
                              , {new Date(date.date).toLocaleDateString()} from {date.startTime} to{' '}
                              {date.endTime}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div
                    className='space-y-2 overflow-y-scroll max-h-[600px]'
                    dangerouslySetInnerHTML={{ __html: eventContent }}
                  ></div>

                  <div className='items-center gap-6 grid grid-cols-2 xl:grid-cols-4'>
                    {findAttendee2?.findAttendee === null ? (
                      <GatedButton
                        onClick={() => {
                          handleCreateAttendee();
                        }}
                        className={`cursor-pointer transition ease-in-out duration-100 py-2 px-4 text-sm font-semibold rounded-full ${
                          eventData.eventType === 'event'
                            ? 'bg-red hover:bg-[#A60626]'
                            : 'bg-blue hover:bg-[#3da797]'
                        }`}
                      >
                        Click to Attend
                      </GatedButton>
                    ) : (
                      <GatedButton
                        onClick={() => {
                          handleDeleteAttendee();
                        }}
                        className={`cursor-pointer transition ease-in-out duration-100 py-2 px-4 text-sm font-semibold rounded-full ${
                          eventData.eventType === 'event'
                            ? 'bg-transparent border-[1px] border-red text-red hover:bg-black'
                            : 'bg-transparent border-[1px] border-blue text-blue hover:bg-black'
                        }`}
                      >
                        Click to Leave
                      </GatedButton>
                    )}

                    {eventData.ticketLink && (
                      <Link
                        to={eventData.ticketLink}
                        target='_blank'
                        rel='noreferrer'
                        className={`transition ease-in-out duration-100 py-2 px-4 text-sm text-center font-semibold rounded-full ${
                          eventData.eventType === 'event'
                            ? 'bg-red hover:bg-[#A60626]'
                            : 'bg-blue hover:bg-[#3da797]'
                        }`}
                      >
                        Get Tickets
                      </Link>
                    )}

                    <CopyURLButton
                      urlToCopy={pageUrl}
                      buttonColour={
                        eventData.eventType === 'event'
                          ? 'bg-red hover:bg-[#A60626]'
                          : 'bg-blue hover:bg-[#3da797]'
                      }
                    />

                    {/* {eventData.createdBy === userData._id && (
                      <GatedLink
                        to={`/edit-event/${eventData._id}`}
                        className={`cursor-pointer transition ease-in-out duration-100 py-2 px-4 text-sm font-semibold rounded-full ${
                          eventData.eventType === 'event'
                            ? 'bg-red hover:bg-[#A60626]'
                            : 'bg-blue hover:bg-[#3da797]'
                        }`}
                      >
                        Edit Event
                      </GatedLink>
                    )} */}

                    {eventData.createdBy === userData._id && (
                      <DeleteItemButton deleteFunction={(e: Event) => handleDeleteEvent(eventId)} />
                    )}
                  </div>

                  {findAttendee2?.findAttendee?.selectedProjects && (
                    <div>
                      <EventProjectSearch
                        userId={userData._id}
                        onSelectedProjectsChange={handleSelectedProjectsChange}
                        initialSelectedProjects={findAttendee2?.findAttendee?.selectedProjects}
                      />
                    </div>
                  )}

                  {eventData.tags[0] && (
                    <div className='col-start-1 col-span-full'>
                      <ul className='flex flex-wrap'>
                        {eventData.tags.map((tag: string) => {
                          return (
                            <li
                              className={`inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-[#352833] text-[8pt] font-bold ${
                                eventData.eventType === 'event' ? 'text-red' : 'text-blue'
                              }`}
                              key={tag}
                            >
                              {tag}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-span-1 space-y-6'>
                <div>
                  <img
                    src={eventData.eventImages[1] || eventData.eventImages[0]}
                    alt='Event Poster'
                    className='w-full -mt-2 cursor-pointer'
                    onClick={() => {
                      if (!eventData.eventImages[1]) {
                        enlargePhoto(0);
                      } else {
                        enlargePhoto(1);
                      }
                    }}
                  />
                </div>
                <div className='space-y-3'>
                  <div
                    className={`text-[8pt] font-extrabold uppercase ${
                      eventData.eventType === 'event' ? 'text-red' : 'text-blue'
                    }`}
                  >
                    Users attending
                  </div>
                  <div className='flex'>
                    <div className='-ml-1 flex'>
                      {attendingUsersArray
                        ?.map((userId: string) => {
                          return <AttendingUser userId={userId} key={userId} />;
                        })
                        .reverse()
                        .slice(0, 6)}
                    </div>

                    {attendingUsersArray && !attendingUsersArray[0] && (
                      <div className='italic ml-1 text-[#886883]'>No users attending yet</div>
                    )}
                    {attendingUsersArray && attendingUsersArray?.length > 6 && (
                      <div className='ml-6 mt-3'>+ {attendingUsersArray?.length - 6} more</div>
                    )}
                  </div>
                </div>

                <div className='space-y-4'>
                  <div
                    className={`text-[8pt] font-extrabold uppercase ${
                      eventData.eventType === 'event' ? 'text-red' : 'text-blue'
                    }`}
                  >
                    Cars appearing
                  </div>
                  <div className='grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4'>
                    {attendingProjectsArray
                      ?.map((projectId: string) => {
                        return <AttendingProject projectId={projectId} key={projectId} />;
                      })
                      .reverse()
                      .slice(0, 10)}
                    {attendingProjectsArray && !attendingProjectsArray[0] && (
                      <div className='italic text-[#886883]'>No cars listed yet</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {eventData.eventType === 'cruise' && (
              <div className='bg-[#352833] p-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6'>
                {eventData.destinations.map((destination: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className='flex'>
                        <div className='h-[10px] fill-white mr-2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='10'
                            height='20'
                            viewBox='0 0 21 30'
                          >
                            <path
                              d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
                              transform='translate(-7.5 -3)'
                            />
                          </svg>
                        </div>
                        <div className='font-bold -mt-[1px]'>Cruise location {index + 1}</div>
                      </div>
                      <div className=''>{destination.location}</div>
                      <div className='text-blue'>{`${destination.arrivalTime}`}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default function SingleEventGated() {
  const { loading, isAuthenticated } = useIsAuthenticated();

  if (loading) return <LoadingSpinner />;
  if (isAuthenticated) return <SingleEvent />;

  return (
    <LoginModal>
      <SingleEvent />
    </LoginModal>
  );
}

function AttendingProject({ projectId }: { projectId: string }) {
  const [projectData, setProjectData] = useState<any>();
  const [projectUserData, setAttendeeUserData] = useState<any>();

  const { data: projectData2 } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: projectId,
    },
  });

  const [getProjectUserById, { data: projectUserData2 }] = useLazyQuery(GET_USER_BY_ID);

  useEffect(() => {
    setProjectData(projectData2?.getProjectById);
    if (projectData2?.getProjectById?.createdBy) {
      getProjectUserById({
        variables: {
          userId: projectData2.getProjectById.createdBy,
        },
      });
    }
  }, [projectData2]);

  useEffect(() => {
    setAttendeeUserData(projectUserData2?.getUserById);
  }, [projectUserData2]);

  return (
    <>
      <div
        className={`h-[120px] w-full rounded-md bg-cover bg-center relative`}
        style={{
          backgroundImage: `url(${projectData?.images[0]})`,
        }}
      >
        <GatedLink
          to={`/project/${projectData?._id}`}
          className={`font-[Montserrat] transition-all duration-100 col-start-10 col-span-1 absolute inset-0 rounded-md cursor-pointer select-none justify-center text-center flex bg-[#00000090] hover:bg-[#00000040]`}
        >
          <div className='self-center'>
            <div className='italic font-bold line-clamp-1 shadow-md'>{projectData?.name}</div>
            <div className='text-xs font-light'>
              {projectUserData?.advertiserDetails?.companyName || projectUserData?.username}
            </div>
          </div>
        </GatedLink>
      </div>
    </>
  );
}

function AttendingUser({ userId }: { userId: string }) {
  const [attendingUserData, setAttendingUserData] = useState<any>();

  const { data: attendingUserData2 } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: userId,
    },
  });

  useEffect(() => {
    setAttendingUserData(attendingUserData2?.getUserById);
  }, [attendingUserData2]);

  return (
    <GatedLink to={`/profile/${attendingUserData?.username}`}>
      <div
        className={`h-[50px] w-[50px] transition-all duration-100 border-[3px] border-black2 rounded-full bg-cover bg-center -mr-4 text-center justify-center flex relative`}
        style={{
          backgroundImage: `url(${
            attendingUserData?.advertiserDetails?.coverPhoto || attendingUserData?.profilePicture
          })`,
        }}
      ></div>
    </GatedLink>
  );
}
