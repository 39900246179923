import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LeftArrowCircle from '@/assets/icons/LeftArrowCircle';
import RightArrowCircle from '@/assets/icons/RightArrowCircle';

SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay, A11y]);

SwiperCore.use([Autoplay]);

interface Product {
  _id: string;
  title: string;
  productImageURL: string;
  bodyContent: string;
  price: number;
  createdBy: string;
}

interface RecommendedProducts {
  productData: {
    getAllProducts: Product[];
  };
}

function RecommendedProducts({ productData }: RecommendedProducts) {
  const swiperRef = useRef<SwiperCore | null>(null);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(1);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex);
    setCurrentSlidesPerView(swiper.params.slidesPerView);
  };

  useEffect(() => {
    swiperRef.current = new SwiperCore('.custom-swiper', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
    });
  }, []);

  return (
    <div className=''>
      <div className=''>
        {productData && productData?.getAllProducts && (
          <Swiper
            autoplay
            slidesPerView={2}
            spaceBetween={12}
            className='relative'
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            onSlideChange={handleSlideChange}
            breakpoints={{
              500: {
                spaceBetween: 16,
                slidesPerView: 2,
              },
              768: {
                spaceBetween: 16,
                slidesPerView: 3,
              },
              1400: { spaceBetween: 16, slidesPerView: 4 },
            }}
          >
            <div className='text-white font-semibold text-xl absolute overflow-visible w-full top-0 z-10 flex space-x-2 items-center'>
              <div className='mr-2'>New Products & Services</div>
              <SwiperButtonPrevious currentSlideIndex={currentSlideIndex}>
                <LeftArrowCircle width='20px' height='20px' />
              </SwiperButtonPrevious>
              <SwiperButtonNext
                eventArrayLength={productData?.getAllProducts?.length}
                currentSlideIndex={currentSlideIndex}
                slidesPerView={currentSlidesPerView}
              >
                <RightArrowCircle width='20px' height='20px' />
              </SwiperButtonNext>
            </div>

            <div>
              {productData?.getAllProducts
                ?.map((product: Product) => {
                  return (
                    <SwiperSlide className='mt-11' key={product?._id}>
                      <Link
                        className='group transition-all duration-150 relative'
                        to={`/products/${product?._id}`}
                      >
                        <div className='absolute inset-0 group-hover:bg-white/10 rounded-md transition-all duration-150' />
                        <div className='rounded-t-md group w-auto h-[160px] overflow-hidden items-center flex'>
                          <img
                            className='w-fit min-w-full h-fit min-h-full object-cover'
                            src={product?.productImageURL[0]}
                            alt='Product'
                          />
                        </div>
                        <div className='bg-black2 p-2 text-[10pt] font-light rounded-b-md text-center'>
                          <div className='line-clamp-1'>{product?.title}</div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })
                .reverse()
                .slice(0, 20)}
            </div>
          </Swiper>
        )}
      </div>
    </div>
  );
}

export default RecommendedProducts;

function SwiperButtonPrevious({
  children,
  className,
  currentSlideIndex,
}: {
  children?: React.ReactNode;
  className?: string;
  currentSlideIndex: number;
}) {
  const swiper = useSwiper();

  return (
    <>
      {currentSlideIndex < 1 ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}

function SwiperButtonNext({
  children,
  eventArrayLength,
  className,
  currentSlideIndex,
  slidesPerView,
}: {
  children?: React.ReactNode;
  eventArrayLength: number;
  className?: string;
  currentSlideIndex: number;
  slidesPerView: number;
}) {
  const swiper = useSwiper();
  const [isSlideEnd, setIsSlideEnd] = useState<Boolean>();
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setIsSlideEnd(swiper.isEnd);
    setMaxIndex(eventArrayLength - slidesPerView);
  }, [swiper, eventArrayLength, slidesPerView, currentSlideIndex]);

  return (
    <>
      {isSlideEnd || currentSlideIndex >= maxIndex ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}
