import { RootState } from '@/store/reducers/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { FetchResult, gql } from '@apollo/client/core';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';
import LoadingSpinner from '@/components/LoadingSpinner';
import { UserActionType, UserActions } from '@/store/actions/userActions';
import TagSearch from '@/components/utils/TagSearch';

export enum MHPSearchTagType {
  Article = 'article',
  Project = 'project',
  Product = 'product',
}

const EDIT_PROFILE = gql`
  mutation EditUserProfile($input: EditProfile!) {
    editProfile(input: $input) {
      firstName
      lastName
      username
      about
      tags
      state
      profilePicture
      advertiserDetails {
        companyName
        companyLogo
        coverPhoto
        primaryTags
        secondaryTags
        companyPhoneNumber
        companyAddress
        companyEmail
        companyWebsite
      }
      locations {
        locationName
        locationAddress
        locationPhone
      }
    }
  }
`;

const UPLOAD_PHOTO = gql`
  mutation UploadPhotoMutation($uploadPhotoPhoto: String) {
    uploadPhoto(photo: $uploadPhotoPhoto)
  }
`;

const CHECK_USERNAME_EXIST = gql`
  query CheckUsernameExist($username: String!) {
    checkUsernameExists(username: $username)
  }
`;

const CHECK_EMAIL_EXIST = gql`
  query CheckEmailExist($email: String!) {
    checkEmailExists(email: $email)
  }
`;

type AdvertiserDetails = {
  companyName: string;
  companyLogo: string;
  coverPhoto: string;
  primaryTags: Array<string>;
  secondaryTags: Array<string>;
  companyPhoneNumber: string;
  companyAddress: string;
  companyEmail: string;
  companyWebsite: string;
};

type Location = {
  locationName: string;
  locationAddress: string;
  locationPhone: string;
};

export default function EditProfile() {
  const { userData } = useSelector((state: RootState) => state.user);

  // console.log(userData);
  const userDispatch = useDispatch<Dispatch<UserActions>>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({});

  useEffect(() => {
    if (userData) {
      const {
        firstName,
        lastName,
        username,
        profilePicture,
        tags,
        state,
        advertiserDetails,
        locations,
      } = userData || {};

      reset({
        firstName,
        lastName,
        username,
        profilePicture,
        tags,
        state,
        advertiserDetails,
        locations,
      });
    }
  }, [userData]);

  const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
  const [newUsername, setNewUsername] = useState('');
  const [newTags, setNewTags] = useState<Array<string>>([]);
  const [newPrimaryCompanyTags, setNewPrimaryCompanyTags] = useState<string[]>();
  const [newSecondaryCompanyTags, setNewSecondaryCompanyTags] = useState<string[]>();

  const [newLocationsArray, setNewLocationsArray] = useState<Location[]>(
    userData?.locations.map((location: Location) => ({
      locationName: location.locationName,
      locationAddress: location.locationAddress,
      locationPhone: location.locationPhone,
    })) || [
      {
        locationName: '',
        locationAddress: '',
        locationPhone: '',
      },
    ],
  );

  const [validatingUsername, setValidatingUsername] = useState(false);
  const [validNewUsername, setValidNewUsername] = useState(false);
  const [isUsernameUsed, setIsUsernameUsed] = useState(false);
  const [profileUpdateSuccessful, setProfileUpdateSuccessful] = useState(false);
  const [profilePhotoUpdate, setProfilePhotoUpdate] = useState({ success: false, message: '' });

  const [imagePreviewProfilePicture, setImagePreviewProfilePicture] = useState<any>('');
  const [imagePreviewCompanyLogo, setImagePreviewCompanyLogo] = useState<any>();
  const [imagePreviewCoverPhoto, setImagePreviewCoverPhoto] = useState<any>();

  const [overwritePrimaryTags, setOverwritePrimaryTags] = useState<boolean>(false);
  const [overwriteSecondaryTags, setOverwriteSecondaryTags] = useState<boolean>(false);

  const [profileImageURL, setProfileImageUrl] = useState('');
  const [checkUsernameExist, { data: isUsernameAvailable }] = useLazyQuery(CHECK_USERNAME_EXIST, {
    variables: { username: newUsername },
    onCompleted: (data) => {
      setValidatingUsername(false);
      if (!data.checkUsernameExists) {
        setValidNewUsername(true);
      } else {
        setIsUsernameUsed(true);
      }
    },
  });
  const [formError, setFormError] = useState('');
  const [editUserProfile, { loading: updateProfileLoading }] = useMutation(EDIT_PROFILE);
  const [uploadPhoto] = useMutation(UPLOAD_PHOTO, {
    onCompleted(data) {
      let newImageObj = { imageUrl: data.uploadPhoto };
      setProfileImageUrl(newImageObj.imageUrl);
    },
  });

  const handleEditProfile = async (inputs: any) => {
    setUpdatingProfile(true);
    setFormError('');
    const {
      firstName,
      lastName,
      username,
      profilePicture,
      about,
      tags,
      state,
      advertiserDetails: { companyName, companyEmail, companyWebsite, primaryTags, secondaryTags },
      locations,
    } = inputs;

    try {
      const photoInputs = [
        imagePreviewProfilePicture,
        imagePreviewCompanyLogo,
        imagePreviewCoverPhoto,
      ];
      const fields = [
        'profilePicture',
        'advertiserDetails.companyLogo',
        'advertiserDetails.coverPhoto',
      ];

      const uploadPromises: (
        | Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
        | undefined
      )[] = photoInputs?.map((input, index) => uploadPhotoAndUpdateProfile(input, fields[index]));

      const res = await Promise.all(uploadPromises);

      // Nasty but working atm
      const newProfilePicture: string | undefined = res?.[0]?.data?.uploadPhoto;
      const companyLogoSuccessfulUpload: string | undefined = res?.[1]?.data?.uploadPhoto;
      const companyCoverPhotoSuccessfulUpload: string | undefined = res?.[2]?.data?.uploadPhoto;

      const { data } = await editUserProfile({
        variables: {
          input: {
            username: username,
            firstName: firstName,
            lastName: lastName,
            about: about,
            tags: newTags || tags,
            profilePicture: newProfilePicture ?? '',
            state: state,
            advertiserDetails: {
              companyName: companyName,
              companyLogo: companyLogoSuccessfulUpload ?? '',
              coverPhoto: companyCoverPhotoSuccessfulUpload ?? '',
              companyEmail: companyEmail,
              companyWebsite: companyWebsite,
              primaryTags: newPrimaryCompanyTags || primaryTags,
              secondaryTags: newSecondaryCompanyTags || secondaryTags,
            },
            locations: newLocationsArray || locations,
          },
        },
      });

      if (data) {
        setProfileUpdateSuccessful(true);
        window.location.reload();
      }
      setUpdatingProfile(false);
    } catch (err: any) {
      setUpdatingProfile(false);
      setFormError(err.message);
    }
  };

  const handleUsernameChange = (username: string) => {
    setValidNewUsername(false);
    setIsUsernameUsed(false);
    if (username.length > 1 && errors.username === undefined && !errors.username) {
      setValidatingUsername(true);
      setNewUsername(username);
      setTimeout(() => {
        checkUsernameExist();
      }, 3000);
    }
  };

  const uploadPhotoAndUpdateProfile = (dataUrl: any, field: string) => {
    if (!dataUrl || !dataUrl.length) return;

    return uploadPhoto({
      variables: { uploadPhotoPhoto: dataUrl },
    });
  };

  async function handleImagePreview(e: any, field: string) {
    if (!e.target.files || !e.target.files.length) return;

    const reader = new FileReader();
    // Extract image file from input event & create a data url.
    const file = e.target.files[0];
    reader.readAsDataURL(file);

    // We need to readAsDataURL prior to this event or it will not work.
    reader.onloadend = () => {
      const image = reader.result;

      switch (field) {
        case 'profilePicture':
          setImagePreviewProfilePicture(image);
          break;
        case 'companyLogo':
          setImagePreviewCompanyLogo(image);
          break;
        case 'coverPhoto':
          setImagePreviewCoverPhoto(image);
          break;
        default:
          break;
      }
    };
  }

  const addLocation = () => {
    if (newLocationsArray.length < 60) {
      const newArray = [...newLocationsArray];
      newArray.push({ locationName: '', locationAddress: '', locationPhone: '' });
      setNewLocationsArray(newArray);
    }
  };

  const removeLocation = (removeIndex: number) => {
    const newArray = [...newLocationsArray];
    newArray.splice(removeIndex, 1);
    setNewLocationsArray(newArray);
  };

  const populateLocationArray = (
    index: number,
    newLocationName: string,
    newLocationAddress: string,
    newLocationPhone: string,
  ) => {
    const newArray = [...newLocationsArray];
    newArray[index] = {
      locationName: newLocationName,
      locationAddress: newLocationAddress,
      locationPhone: newLocationPhone,
    };
    setNewLocationsArray(newArray);
  };

  return (
    <form className='' onSubmit={handleSubmit(handleEditProfile)}>
      <div className='gap-6'>
        <div className='md:col-span-1'>
          <h3 className='text-white font-semibold text-xl mt-4 mb-4'>Edit Profile</h3>
          <p className='mt-1 text-sm text-white mb-6'>Make changes to your profile here.</p>
        </div>
        <div className='mt-5 space-y-6 md:mt-0'>
          <div className='flex items-center'>
            {/* // TODO: Some funky business below - where button becomes unclickable after setting a new profile :)  */}
            <div
              className='mr-4 border-[2px] border-[#241B23] w-32 h-32 flex justify-center items-center rounded-full overflow-hidden bg-cover bg-center'
              style={{
                backgroundImage: `url(${
                  imagePreviewProfilePicture ? imagePreviewProfilePicture : userData?.profilePicture
                })`,
              }}
            >
              <div className='cursor-pointer absolute flex text-4xl text-red rounded-full w-10 h-10 justify-center bg-[#ffffff80] backdrop-blur-sm'>
                <div className='self-center cursor-pointer'>+</div>
              </div>
              {/* <img className='object-cover' src={userData?.profilePicture} alt='ProfileImage' /> */}

              <input
                className='w-10 h-10 cursor-pointer rounded-full opacity-0'
                {...register('profilePicture')}
                type='file'
                onChange={(event) => {
                  handleImagePreview(event, 'profilePicture');
                }}
              />
            </div>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
            <div className='col-span-1'>
              <label htmlFor='firstName' className='text-[10pt] font-medium text-white'>
                First name
              </label>
              <input
                {...register('firstName', { required: 'Firstname is required' })}
                type='text'
                name='firstName'
                id='firstName'
                autoComplete='given-name'
                className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
              />
            </div>

            <div className='col-span-full lg:col-span-1'>
              <label htmlFor='lastName' className='text-[10pt] font-medium text-white'>
                Last name
              </label>
              <input
                {...register('lastName', { required: 'Lastname is required' })}
                type='text'
                name='lastName'
                id='lastName'
                autoComplete='family-name'
                className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
              />
            </div>

            <div className='col-span-1 mb-4'>
              <label htmlFor='username' className='text-[10pt] font-medium text-white'>
                Username
              </label>
              <div className='relative'>
                <input
                  className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
                  type='text'
                  placeholder='Enter Username'
                  id='username'
                  {...register('username', {
                    required: 'Username is required.',
                    minLength: {
                      value: 4,
                      message: 'Minimum 4 characters for username.',
                    },
                    pattern: {
                      value: /^[\w.-]*$/,
                      message: 'Invalid username format.',
                    },
                    onChange: (e) => handleUsernameChange(e.target.value),
                  })}
                />
                {validatingUsername && (
                  <svg
                    className='animate-spin -ml-1 mr-3 h-5 w-5 text-white absolute right-1 top-2 text-indigo-500'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                )}
                {validNewUsername && (
                  <CheckCircleIcon className='-ml-1 mr-3 h-5 w-5 text-green-500 absolute right-1 top-2' />
                )}
              </div>
              {errors?.username && (
                <div className='text-red text-sm'>{`${errors.username.message}`}</div>
              )}
              {isUsernameUsed && (
                <>
                  {userData?.username === newUsername ? (
                    <></>
                  ) : (
                    <div className='text-red text-sm'>Username already in use</div>
                  )}
                </>
              )}
              {/* {userData?.username === newUsername && (
                <div className='text-red text-sm'>You ar</div>
              )} */}
            </div>

            {(userData.userAccountType === 'advertiser' ||
              userData.userAccountType === 'admin' ||
              userData.userAccountType === 'club') && (
              <div className='space-y-4 col-start-1 col-span-full grid grid-cols-2 lg:grid-cols-3 gap-6 mt-6'>
                <div className='col-span-full font-bold'>
                  {userData.userAccountType === 'club' ? 'Club' : 'Company'} Details
                </div>

                {/* COMPANY LOGO UPLOAD */}
                <div className='col-start-1 col-span-1'>
                  <div className='border-[2px] border-[#241B23] h-44 flex justify-center items-center rounded-md overflow-hidden'>
                    <img
                      className='bg-cover'
                      src={
                        imagePreviewCompanyLogo
                          ? imagePreviewCompanyLogo
                          : userData?.advertiserDetails?.companyLogo
                      }
                      alt='Company Logo'
                    />

                    <div className='cursor-pointer absolute flex text-4xl text-red rounded-full w-10 h-10 justify-center bg-[#ffffff80] backdrop-blur-sm'>
                      <div className='self-center cursor-pointer'>+</div>
                    </div>

                    <input
                      className='cursor-pointer absolute w-10 h-10 opacity-0 '
                      {...register('advertiserDetails.companyLogo')}
                      type='file'
                      onChange={(event) => {
                        handleImagePreview(event, 'companyLogo');
                      }}
                    />
                  </div>
                  <div className='mt-2 text-[10pt] font-medium text-white'>
                    Upload {userData.userAccountType === 'club' ? 'club' : 'company'} logo
                    (transparent .PNG recommended)
                  </div>
                </div>

                {/* COMPANY COVER PHOTO UPLOAD */}
                <div className='col-span-1 lg:col-span-2'>
                  <div className='border-[2px] border-[#241B23] h-44 flex justify-center items-center rounded-md overflow-hidden'>
                    <img
                      className='bg-cover'
                      src={
                        imagePreviewCoverPhoto
                          ? imagePreviewCoverPhoto
                          : userData?.advertiserDetails?.coverPhoto
                      }
                      alt='ProfileImage'
                    />
                    <div className='cursor-pointer absolute flex text-4xl text-red rounded-full w-10 h-10 justify-center bg-[#ffffff80] backdrop-blur-sm'>
                      <div className='self-center cursor-pointer'>+</div>
                    </div>

                    <input
                      className='cursor-pointer absolute w-10 h-10 opacity-0'
                      {...register('advertiserDetails.coverPhoto')}
                      type='file'
                      onChange={(event) => {
                        handleImagePreview(event, 'coverPhoto');
                      }}
                    />
                  </div>
                  <div className='mt-2 text-[10pt] font-medium text-white'>
                    Upload {userData.userAccountType === 'club' ? 'club' : 'company'} cover photo
                  </div>
                </div>

                <div className='col-span-full lg:col-span-1'>
                  <label className='text-[10pt] font-medium text-white'>
                    {userData.userAccountType === 'club' ? 'Club' : 'Company'} name
                  </label>
                  <input
                    {...register('advertiserDetails.companyName')}
                    type='text'
                    className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
                  />
                </div>

                <div className='col-span-full lg:col-span-1'>
                  <label className='text-[10pt] font-medium text-white'>
                    {userData.userAccountType === 'club' ? 'Club' : 'Company'} email
                  </label>
                  <input
                    {...register('advertiserDetails.companyEmail')}
                    type='text'
                    className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
                  />
                </div>

                <div className='col-span-full lg:col-span-1'>
                  <label className='text-[10pt] font-medium text-white'>
                    {userData.userAccountType === 'club' ? 'Club' : 'Company'} website
                  </label>
                  <input
                    {...register('advertiserDetails.companyWebsite')}
                    type='text'
                    className='mt-1 focus:ring-red focus:border-red focus:border-1 block w-full sm:text-sm border-1 bg-[#241B23] border-black text-white rounded-full'
                  />
                </div>

                <div className='col-span-full col-start-1'>
                  <label htmlFor='primary-tags' className='text-[10pt] font-medium text-white'>
                    {userData.userAccountType === 'club'
                      ? 'Club tags'
                      : 'Primary products & services'}
                  </label>
                  <div className='mt-1 mb-3'>
                    {!overwritePrimaryTags ? (
                      <>
                        {userData?.advertiserDetails?.primaryTags.map(
                          (tag: string, index: number) => {
                            return (
                              <span key={index}>
                                {tag}
                                {index !== userData.advertiserDetails.primaryTags.length - 1 &&
                                  ', '}
                              </span>
                            );
                          },
                        )}
                        <div
                          onClick={() => setOverwritePrimaryTags(true)}
                          className='cursor-pointer italic text-red mt-1 hover:font-semibold'
                        >
                          {`${
                            userData.userAccountType === 'club'
                              ? `Click here to ${
                                  userData.advertiserDetails.primaryTags.length ? 'replace' : 'add'
                                } your club tags`
                              : `Click here to ${
                                  userData.advertiserDetails.primaryTags.length ? 'replace' : 'add'
                                } your primary services`
                          }`}
                        </div>
                      </>
                    ) : (
                      <div className='mt-2'>
                        <TagSearch
                          tagType={MHPSearchTagType.Article}
                          handleFormTags={setNewPrimaryCompanyTags}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {userData.userAccountType !== 'club' && (
                  <div className='col-span-full col-start-1'>
                    <label htmlFor='primary-tags' className='text-[10pt] font-medium text-white'>
                      Secondary products & services
                    </label>
                    <div className='mt-1 mb-3'>
                      {!overwriteSecondaryTags ? (
                        <>
                          {userData?.advertiserDetails?.secondaryTags.map(
                            (tag: string, index: number) => {
                              return (
                                <span key={index}>
                                  {tag}
                                  {index !==
                                    userData?.advertiserDetails?.secondaryTags?.length - 1 && ', '}
                                </span>
                              );
                            },
                          )}
                          <div
                            onClick={() => setOverwriteSecondaryTags(true)}
                            className='cursor-pointer italic text-red mt-1 hover:font-semibold'
                          >
                            {`Click here to ${
                              userData?.advertiserDetails?.secondaryTags?.length ? 'replace' : 'add'
                            } your secondary services`}
                          </div>
                        </>
                      ) : (
                        <div className='mt-2'>
                          <TagSearch
                            tagType={MHPSearchTagType.Article}
                            handleFormTags={setNewSecondaryCompanyTags}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className='col-span-full space-y-4'>
                  {newLocationsArray.map((item: Location, index: number) => {
                    return (
                      <div key={index}>
                        <div className='text-white w-full grid grid-cols-1 gap-4 bg-black2 p-4 rounded-md'>
                          <div className='w-full grid grid-cols-2'>
                            <div className='col-span-1'>
                              {userData.userAccountType === 'club' ? 'Club' : 'Store'} Location{' '}
                              {index + 1}
                            </div>
                            {index >= 1 && (
                              <div
                                className='col-span-1 text-end text-red cursor-pointer hover:underline'
                                onClick={() => removeLocation(index)}
                              >
                                Remove location
                              </div>
                            )}
                          </div>

                          <div>
                            <label htmlFor='primary-tags' className='text-[10pt] font-medium'>
                              Location name
                            </label>
                            <input
                              {...register(`locations.${index}.locationName` as const)}
                              className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder={`Enter store location ${index + 1} name`}
                              value={item.locationName || ''}
                              onChange={(e) => {
                                populateLocationArray(
                                  index,
                                  e.target.value,
                                  item.locationAddress,
                                  item.locationPhone,
                                );
                              }}
                            />
                          </div>
                          {/* {errors[`locationName${index + 1}`] && (
                            <span className='text-red'>{`Store location ${
                              index + 1
                            } name is required`}</span>
                          )} */}

                          <div>
                            <label htmlFor='primary-tags' className='text-[10pt] font-medium'>
                              Location address
                            </label>
                            <input
                              {...register(`locations.${index}.locationAddress` as const)}
                              className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder={`Enter store location ${index + 1} address`}
                              value={item.locationAddress || ''}
                              onChange={(e) => {
                                populateLocationArray(
                                  index,
                                  item.locationName,
                                  e.target.value,
                                  item.locationPhone,
                                );
                              }}
                            />
                          </div>
                          {/* {errors[`locationAddress${index + 1}`] && (
                            <span className='text-red'>{`Store location ${
                              index + 1
                            } address is required`}</span>
                          )} */}

                          <div>
                            <label htmlFor='primary-tags' className='text-[10pt] font-medium'>
                              Location phone number
                            </label>
                            <input
                              {...register(`locations.${index}.locationPhone` as const)}
                              className='text-black p-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder={`Enter store location ${index + 1} phone number`}
                              value={item.locationPhone || ''}
                              onChange={(e) => {
                                populateLocationArray(
                                  index,
                                  item.locationName,
                                  item.locationAddress,
                                  e.target.value,
                                );
                              }}
                            />
                          </div>
                          {/* {errors[`locationPhone${index + 1}`] && (
                            <span className='text-red'>{`Store location ${
                              index + 1
                            } phone number is required`}</span>
                          )} */}
                        </div>
                        {index + 1 === newLocationsArray.length && (
                          <div
                            className='mt-4 transition-all duration-150 cursor-pointer text-white gap-6 bg-black2/70 hover:bg-black2 p-4 rounded-md text-center'
                            onClick={() => addLocation()}
                          >
                            + Add Another Location
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='mt-2 mb-14'>
        {updateProfileLoading || updatingProfile ? (
          <LoadingSpinner />
        ) : (
          <button
            type='submit'
            className='justify-center whitespace-nowrap transition ease-in-out duration-100 bg-red hover:bg-[#A60626] my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
          >
            Save Changes
          </button>
        )}
      </div>
    </form>
  );
}
