import { gql } from '@apollo/client/core';

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($input: ArticleInput) {
    createArticle(input: $input) {
      approved
      _id
      createdAt
      creatorId
      firstName
      lastName
      username
      profilePicture
      heading
      subHeading
      type
      tags
      taggedAdvertisers {
        companyName
        id
      }
      bodyContentList {
        index
        text
      }
      images {
        index
        caption
        url
        projects {
          name
          id
        }
      }
      articleTemplate
      mainProject {
        id
        name
      }
    }
  }
`;
