import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { RootState } from '@/store/reducers/rootReducer';

import PrivateRoute from '@/router/PrivateRoute';
import Login from '@/pages/auth/Login';
import Register from '@/pages/auth/Register';
import Forgot from '@/pages/auth/Forgot';
import Dashboard from '@/pages/user/Dashboard';
import WelcomeScreen from '@/pages/user/WelcomeScreen';
import Homepage from '@/pages/user/Homepage';

// Project imports
import Projects from '@/pages/user/Projects';
import AddNewProject from '@/pages/user/projects/AddNewProject';
import SingleProject from '@/pages/user/projects/SingleProject';

// Articles imports
import Articles from '@/pages/user/Articles';
import AddArticle from '@/pages/user/articles/AddArticle';
import SingleArticle from '@/pages/user/articles/SingleArticle';

// Product imports
import Products from '@/pages/user/Products';
import AddProduct from '@/pages/user/products/AddProduct';
import SingleProduct from '@/pages/user/products/SingleProduct';

// Messaging system
import Messages from '@/pages/user/Messages';
import AddMessage from '@/pages/user/messages/AddMessage';

// Advertisers import
import Advertisers from '@/pages/user/Advertisers';

// Coming Soon
import ComingSoon from '@/pages/ComingSoon';

// Profile Routes
import Profile from '@/pages/user/Profile';
import EditProfile from '@/pages/user/EditProfile';
import Shop from '@/pages/user/Shop';

// Admin Routes
import ManageTags from '@/pages/admin/ManageTags';

// Feeds/posts
import AddPost from '@/pages/user/posts/AddPost';
import EditProject from '@/pages/user/projects/EditProject';
import EditProduct from '@/pages/user/products/EditProduct';

// Events
import Events from '@/pages/user/Events';
import AddEvent from '@/pages/user/events/AddEvent';
import SingleEvent from '@/pages/user/events/SingleEvent';
// import EditEvent from '@/pages/user/events/EditEvent';

// Featured Articles Page
import FeaturedArticlesPage from '@/pages/user/FeaturedArticlesPage';

//Videos
import SingleVideoPage from '@/pages/user/videos/SingleVideoPage';
import VideosPage from '@/pages/user/VideosPage';
import { VideoProvider } from '@/components/VideoContext';

// Maps
import MHPMapPage from '@/pages/user/MHPMap';
import EditPost from '@/pages/user/posts/EditPost';

// Clubs
import Clubs from '@/pages/user/Clubs';

const Router = () => {
  const USER_PROFILE_QUERY = gql`
    query Query {
      me {
        email
        userAccountType
        firstName
        lastName
        username
        account_status
        completedInitialProfileSetup
      }
    }
  `;

  const { userData } = useSelector((state: RootState) => state.user);
  const { data } = useQuery(USER_PROFILE_QUERY);

  const RestrictedRoute = ({ children, allowedRoles }: any) => {
    const accountType = userData?.userAccountType;

    if (!allowedRoles.includes(accountType)) {
      return <Navigate to='/' />;
    }

    return children;
  };

  const WelcomeRoute = ({ children }: any) => {
    const profileSetup = data?.me?.completedInitialProfileSetup;

    if (profileSetup === true) {
      return <Navigate to='/' />;
    }

    return children;
  };

  return (
    <BrowserRouter>
      <script src='https://api.tiles.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.js'></script>
      <link href='https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css' rel='stylesheet' />
      <VideoProvider>
        <Routes>
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='forgot' element={<Forgot />} />
          <Route path='message' element={<Messages />} />
          <Route
            path='welcome'
            element={
              <WelcomeRoute>
                <WelcomeScreen />
              </WelcomeRoute>
            }
          />
          <Route path='/' element={<Dashboard />}>
            <Route index element={<Homepage />} />
            <Route
              path='adminarea/tags'
              element={
                <RestrictedRoute allowedRoles={['admin']}>
                  <ManageTags />
                </RestrictedRoute>
              }
            />
            <Route path='profile/:username' element={<Profile />} />
            <Route
              path='profile/:username/edit'
              element={
                <PrivateRoute>
                  <EditProfile />
                </PrivateRoute>
              }
            />
            {/* <Route
            path='/shop'
            element={
              <PrivateRoute>
                <Shop />
              </PrivateRoute>
            }
          /> */}
            <Route path='/featured-articles' element={<FeaturedArticlesPage />} />
            <Route path='/videos' element={<VideosPage />} />
            <Route path='/clubs' element={<Clubs />} />
            <Route path='/suppliers' element={<Advertisers />} />
            <Route path='/events' element={<Events />} />
            <Route
              path='articles'
              element={
                <RestrictedRoute allowedRoles={['admin']}>
                  <Articles />
                </RestrictedRoute>
              }
            />
            <Route
              path='articles/add'
              element={
                <RestrictedRoute allowedRoles={['admin']}>
                  <AddArticle />
                </RestrictedRoute>
              }
            />
            <Route path='articles/:articleSlug' element={<SingleArticle />} />
            <Route path='/post/add' element={<AddPost />} />
            <Route path='projects' element={<Projects />} />
            <Route path='projects/add' element={<AddNewProject />} />
            <Route path='/project/:projectId' element={<SingleProject />} />
            <Route path='/edit-project/:projectId' element={<EditProject />} />
            <Route path='/edit-product/:productId' element={<EditProduct />} />
            <Route path='/edit-post/:postId' element={<EditPost />} />
            <Route path='/events/add' element={<AddEvent />} />
            <Route path='/events/:eventId' element={<SingleEvent />} />
            <Route path='/videos/:videoId' element={<SingleVideoPage />} />
            <Route path='/map' element={<MHPMapPage />} />

            {/* <Route path='/edit-event/:eventId' element={<EditEvent />} /> */}
            <Route path='groups' element={<ComingSoon />} />
            <Route
              path='messages'
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path='/messages/:chatId'
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path='/messages/enquiry/:chatId'
              element={
                <PrivateRoute>
                  <Messages />
                </PrivateRoute>
              }
            />
            <Route
              path='messages/add'
              element={
                <PrivateRoute>
                  <AddMessage />
                </PrivateRoute>
              }
            />
            <Route path='categories' element={<ComingSoon />} />
            <Route
              path='products'
              element={
                <RestrictedRoute allowedRoles={['admin', 'advertiser']}>
                  <Products />
                </RestrictedRoute>
              }
            />
            <Route
              path='products/add'
              element={
                <RestrictedRoute allowedRoles={['admin', 'advertiser']}>
                  <AddProduct />
                </RestrictedRoute>
              }
            />
            <Route path='products/:productSlug' element={<SingleProduct />} />
          </Route>
          <Route
            path='*'
            element={
              <PrivateRoute>
                <Navigate to='/' />
              </PrivateRoute>
            }
          />
        </Routes>
      </VideoProvider>
    </BrowserRouter>
  );
};

export default Router;
