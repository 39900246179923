import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import { XIcon } from '@heroicons/react/solid';
import { useAdvertiserSearch } from './search/searchHooks';
import { useEffect } from 'react';

function AdvertiserSearch({
  handleFormUpdates,
  maxSelection,
  editTags,
}: {
  handleFormUpdates: Function;
  maxSelection?: number;
  editTags?: any[] | undefined;
}) {
  const {
    selectedAdvertisers,
    advertiserSearchLoading,
    suggestionsActive,
    searchResultAdvertisers,
    handleAdvertiserSearch,
    handleRemoveAdvertiser,
    toggleSelect,
  } = useAdvertiserSearch();

  const hasSelectedAdvertisers = selectedAdvertisers && selectedAdvertisers?.length;

  // useEffect(() => {
  //   if (editTags) {
  //     editTags.forEach((advertiser) => {
  //       toggleSelect(advertiser, handleFormUpdates);
  //       // console.log(advertiser);
  //     });
  //   }
  //   console.log(editTags);
  // }, [editTags]);

  return (
    <div>
      <div className='mt-1'>
        <input
          type='search'
          placeholder={`Search Projects by Name`}
          className='text-black p-2 shadow-sm focus:ring-red-500 focus:border-red-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
          onChange={handleAdvertiserSearch}
        />
        {hasSelectedAdvertisers
          ? selectedAdvertisers.map((selectedAdvertiser, i) => (
              <ul key={i} className='inline-flex'>
                <li className='inline-flex items-center text-sm rounded mt-2 mr-1 p-1 mb-1 bg-gray-100'>
                  <span className='ml-2 mr-1 leading-relaxed truncate max-w-xs text-gray-700 font-bold'>
                    {selectedAdvertiser?.companyName}
                  </span>
                  <span
                    onClick={() => handleRemoveAdvertiser(selectedAdvertiser, handleFormUpdates)}
                    className='ml-1 cursor-pointer'
                  >
                    <XIcon className='h-4 w-4 text-red' aria-hidden='true' />
                  </span>
                </li>
              </ul>
            ))
          : null}
        {advertiserSearchLoading ? <LoadingSpinner /> : null}
        {suggestionsActive && searchResultAdvertisers ? (
          <div className='mt-2 grid grid-cols gap-y-1 gap-x-1 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8'>
            {searchResultAdvertisers.map((advertiser: any, i: any) => (
              <div
                key={i}
                onClick={() => {
                  toggleSelect(advertiser, handleFormUpdates, maxSelection);
                }}
                className={`transition-all duration-100 bg-cover bg-center h-full flex text-center font-semibold cursor-pointer rounded-md`}
                style={{
                  backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
                }}
              >
                <div className='bg-[#00000080] hover:bg-[#00000040] h-full w-full rounded-md transition-all duration-100 flex items-center justify-center'>
                  <div
                    className={`text-white m-4 font-[Montserrat] rounded-md shadow-md group-hover:opacity-75 ${
                      hasSelectedAdvertisers
                        ? selectedAdvertisers?.some(
                            (n) => n?.companyName === advertiser?.companyName,
                          )
                        : false
                        ? ''
                        : ''
                    } `}
                  >
                    <div className='italic font-bold'>
                      {advertiser.advertiserDetails.companyName}
                    </div>
                    <div className='text-xs font-light'>Click to add</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AdvertiserSearch;
