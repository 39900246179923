import React from 'react';

const PolicyPopOut = ({ closeFunction }: any) => {
  return (
    <div className='absolute inset-0 bg-black/80 backdrop-blur-sm z-10 flex justify-center items-center'>
      <div className='w-full sm:w-[80%] md:w-[60%] lg:w-[50%] xl:w-[30%] bg-[#241B23] rounded-md p-6'>
        <div className='overflow-y-scroll'>
          <h3 className='font-bold mb-2'>Privacy Policy for My Hi Performance</h3>
          <div className='h-[500px]'>
            <h4 className='mt-2'>Effective Date: 06/07/2023</h4>
            <p className='mt-2'>
              Welcome to My Hi Performance! This Privacy Policy describes how we collect, use,
              disclose, and protect your personal information when you use our website located at
              www.myhiperformance.com (the "Site"). We are committed to protecting your privacy and
              ensuring the security of your personal information. By accessing or using our Site,
              you consent to the collection, use, disclosure, and storage of your personal
              information as described in this Privacy Policy.
            </p>

            <h4 className='font-bold mt-4'>1.0 Information We Collect</h4>
            <h5 className='mt-2'>1.1 Personal Information:</h5>
            <p className='mt-2'>
              We may collect personal information from you when you voluntarily provide it to us
              through the Site. This includes information such as your name, email address, postal
              address, telephone number, and any other information you provide to us.
            </p>

            <h5 className='mt-2'>1.2 Non-Personal Information:</h5>
            <p>
              We may also collect non-personal information automatically as you navigate through the
              Site. This information may include your IP address, browser type, operating system,
              referring URLs, and other technical information about your computer or device.
            </p>

            <h4 className='font-bold mt-4'>2.0 Use of Information</h4>
            <h5 className='mt-2'>2.1 Non-Personal Information:</h5>
            <p>
              We may use the personal information we collect for the following purposes:
              <ul className='list-disc space-y-2 ml-6 mt-2'>
                <li>
                  To provide you with the products, services, and information you request from us.
                </li>
                <li>To process and fulfill your enquiries.</li>
                <li>
                  To communicate with you regarding your account, updates, and promotional
                  materials.
                </li>
                <li>To respond to your inquiries and provide customer support.</li>
                <li>To improve our Site, products, and services.</li>
              </ul>
            </p>

            <h5 className='mt-2'>2.2 Non-Personal Information:</h5>
            <p>
              Non-personal information may be used for various purposes, such as analysing trends,
              administering the Site, tracking user movements, and gathering demographic information
              for aggregate use. This information helps us understand how users interact with our
              Site and allows us to improve its functionality and performance.
            </p>

            <h4 className='font-bold mt-4'>3.0 Disclosure of Information</h4>
            <h5 className='mt-2'>3.1 Service Providers:</h5>
            <p>
              We may disclose your personal information to third-party service providers who assist
              us in operating our Site, conducting our business, or providing services to you. These
              service providers have access to your personal information only to perform specific
              tasks on our behalf and are obligated to keep it confidential.
            </p>
            <h5 className='mt-2'>3.2 Advertisers:</h5>
            <p>
              We may share non-personal information with advertisers or other third parties for
              advertising and promotional purposes. However, we do not disclose personal information
              to advertisers without your explicit consent.
            </p>

            <h5 className='mt-2'>3.3 Legal Compliance:</h5>
            <p>
              We may disclose your personal information if required to do so by law or if we believe
              that such action is necessary to comply with legal obligations, protect our rights, or
              protect the safety of others.
            </p>

            <h4 className='font-bold mt-4'>4.0 Cookies and Tracking Technologies</h4>
            <h5 className='mt-2'>4.1 Cookies:</h5>
            <p>
              We may disclose your personal information to third-party service providers who assist
              us in operating our Site, conducting our business, or providing services to you. These
              service providers have access to your personal information only to perform specific
              tasks on our behalf and are obligated to keep it confidential.
            </p>
            <h5 className='mt-2'>4.2 Analytics:</h5>
            <p>
              We may share non-personal information with advertisers or other third parties for
              advertising and promotional purposes. However, we do not disclose personal information
              to advertisers without your explicit consent.
            </p>

            <h4 className='font-bold mt-4'>Security</h4>
            <p>
              We take reasonable measures to protect your personal information from unauthorized
              access, disclosure, alteration, or destruction. However, no method of transmission
              over the internet or electronic storage is completely secure. Therefore, while we
              strive to use commercially acceptable means to protect your personal information, we
              cannot guarantee its absolute security.
            </p>

            <h4 className='font-bold mt-4'>Children's Privacy</h4>
            <p>
              Our Site is not intended for use by individuals under the age of 18. We do not
              knowingly collect personal information from individuals under the age of 18. If we
              become aware that we have collected personal information from a child under the age of
              18 without parental consent, we will take steps to delete that information from our
              servers.
            </p>

            <h4 className='font-bold mt-4'>Third-Party Websites</h4>
            <p>
              Our Site may contain links to third-party websites that are not operated or controlled
              by us. This Privacy Policy does not apply to such third-party websites, and we are not
              responsible for their privacy practices. We encourage you to review the privacy
              policies of any third-party websites you visit.
            </p>

            <h4 className='font-bold mt-4'>International Transfers</h4>
            <p>
              Your personal information may be transferred to and processed in countries other than
              the country in which you reside. These countries may have data protection laws that
              are different from the laws in your country. By using our Site, you consent to the
              transfer of your personal information to countries outside your country of residence.
            </p>

            <h4 className='font-bold mt-4'>Updates to this Privacy Policy</h4>
            <p>
              We may update this Privacy Policy from time to time to reflect changes in our
              practices or applicable laws. We will notify you of any material changes by posting
              the updated Privacy Policy on our Site. Please review this Privacy Policy periodically
              for any updates.
            </p>

            <h4 className='font-bold mt-4'>Contact Us</h4>
            <p>If you have any questions or concerns about these Terms, please contact us at:</p>
            <h4 className='font-bold mt-4'>My Hi Performance</h4>
            <p>134A Charles Street, West Perth, WA 6005</p>
            <p>trevor@myhiperformance.com</p>
            <p>+61 429 916 282</p>
            <p className='mt-4'>
              Please note that while we strive to resolve any issues or concerns you may have
              regarding your privacy, you also have the right to lodge a complaint with the
              appropriate data protection authority in your jurisdiction.
            </p>

            <h4 className='font-bold mt-4'>Governing Law</h4>
            <p>
              This Privacy Policy shall be governed by and construed in accordance with the laws of
              Australia, without regard to its conflict of law principles.
            </p>
            <p className='mt-2'>
              By using our Site, you acknowledge that you have read and understood this Privacy
              Policy and consent to the collection, use, and disclosure of your personal information
              as described herein.
            </p>
          </div>
        </div>
        <div className='w-full flex justify-center'>
          <div
            className='cursor-pointer transition ease-in-out duration-100 bg-red hover:bg-[#A60626] py-2 px-4 mt-6 w-fit text-sm font-semibold rounded-full'
            onClick={closeFunction}
          >
            Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPopOut;
