import { RootState } from '@/store/reducers/rootReducer';
import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_USER_BY_ID, GET_ALL_EVENTS } from '@/utils/queries/queries';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LeftArrowCircle from '@/assets/icons/LeftArrowCircle';
import RightArrowCircle from '@/assets/icons/RightArrowCircle';

SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay, A11y]);

interface EventDate {
  date: Date;
  startTime: string;
  endTime: string;
}

interface Destination {
  location: string;
  arrivalTime: string;
}

interface Event {
  _id: string;
  name: string;
  location: string;
  eventImages: string[];
  eventType: string;
  eventDetails: string;
  dates: EventDate[];
  destinations: Destination[];
  createdBy: string;
  createdAt: Date;
  tags: string[];
}

const EventSwiper = () => {
  const { data: eventsData2, refetch } = useQuery(GET_ALL_EVENTS);

  useEffect(() => {
    refetch();
  }, [eventsData2]);

  const [eventsData, setEventsData] = useState<Event[]>([]);

  useEffect(() => {
    setEventsData(eventsData2?.getAllEvents);
  }, [eventsData2]);

  return (
    <div>
      <div className='space-y-4'>
        <CustomSwiper events={eventsData} />
      </div>
    </div>
  );
};

export default EventSwiper;

function EventSlide({ eventData }: { eventData: Event }) {
  // const { data: eventCreatorData2 } = useQuery(GET_USER_BY_ID, {
  //   variables: {
  //     userId: eventData?.createdBy,
  //   },
  // });

  // const [eventCreatorData, setEventCreatorDataLoaded] = useState<any>();

  // useEffect(() => {
  //   setEventCreatorDataLoaded(eventCreatorData2?.getUserById);
  // }, [eventCreatorData2]);

  return (
    <Link to={`/events/${eventData._id}`} className='w-full group'>
      <div
        className='h-[160px] w-full bg-center bg-cover rounded-md mb-2'
        style={{ backgroundImage: `url(${eventData.eventImages[0]})` }}
      ></div>
      <div className='absolute top-0 h-[160px] w-full rounded-md group-hover:bg-white/10 transition-all ease-in-out duration-100' />

      <h2 className='group-hover:underline font-[Montserrat] text-lg font-extrabold italic'>
        {eventData.name}
      </h2>

      {/* <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 36 36'>
            <path d='M18,20.25A10.125,10.125,0,1,0,7.875,10.125,10.128,10.128,0,0,0,18,20.25Zm9,2.25H23.126a12.24,12.24,0,0,1-10.252,0H9a9,9,0,0,0-9,9v1.125A3.376,3.376,0,0,0,3.375,36h29.25A3.376,3.376,0,0,0,36,32.625V31.5A9,9,0,0,0,27,22.5Z' />
          </svg>
        </div>

        <div className={`mt-[1px] text-white text-[10pt] font-light`}>
          Event by {eventCreatorData?.advertiserDetails?.companyName || eventCreatorData?.username}
        </div>
      </div> */}

      <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 21 30'>
            <path
              d='M18,3A10.492,10.492,0,0,0,7.5,13.5C7.5,21.375,18,33,18,33S28.5,21.375,28.5,13.5A10.492,10.492,0,0,0,18,3Zm0,14.25a3.75,3.75,0,1,1,3.75-3.75A3.751,3.751,0,0,1,18,17.25Z'
              transform='translate(-7.5 -3)'
            />
          </svg>
        </div>

        <h3 className='text-white text-[10pt] font-light line-clamp-2'>
          {eventData.eventType === 'event'
            ? eventData.location
            : `Starts at: ${eventData.destinations[0].location} at ${eventData.destinations[0].arrivalTime}`}
        </h3>
      </div>

      <div className='w-full flex space-x-2 mt-1'>
        <div className='h-[10px] fill-white'>
          <svg xmlns='http://www.w3.org/2000/svg' width='10' height='20' viewBox='0 0 31.5 36'>
            <path
              id='Icon_awesome-calendar-day'
              data-name='Icon awesome-calendar-day'
              d='M0,32.625A3.376,3.376,0,0,0,3.375,36h24.75A3.376,3.376,0,0,0,31.5,32.625V13.5H0Zm4.5-13.5A1.128,1.128,0,0,1,5.625,18h6.75A1.128,1.128,0,0,1,13.5,19.125v6.75A1.128,1.128,0,0,1,12.375,27H5.625A1.128,1.128,0,0,1,4.5,25.875ZM28.125,4.5H24.75V1.125A1.128,1.128,0,0,0,23.625,0h-2.25A1.128,1.128,0,0,0,20.25,1.125V4.5h-9V1.125A1.128,1.128,0,0,0,10.125,0H7.875A1.128,1.128,0,0,0,6.75,1.125V4.5H3.375A3.376,3.376,0,0,0,0,7.875V11.25H31.5V7.875A3.376,3.376,0,0,0,28.125,4.5Z'
            />
          </svg>
        </div>

        <h3 className='text-white text-[10pt] font-light line-clamp-2 mt-[1px]'>
          {new Date(eventData.dates[0].date).toDateString()}
        </h3>
      </div>

      {eventData.tags && (
        <ul className='flex flex-wrap mt-3'>
          {eventData.tags
            .map((tag: string) => {
              return (
                <li
                  className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'
                  key={tag}
                >
                  {tag}
                </li>
              );
            })
            .splice(0, 2)}
          {eventData.tags.length > 2 && (
            <li className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'>
              + {eventData.tags.length - 2} more
            </li>
          )}
        </ul>
      )}
    </Link>
  );
}

function CustomSwiper({ events }: { events: Event[] }) {
  const swiperRef = useRef<SwiperCore | null>(null);
  const currentDate: Date = new Date();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(1);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex);
    setCurrentSlidesPerView(swiper.params.slidesPerView);
  };

  useEffect(() => {
    swiperRef.current = new SwiperCore('.custom-swiper', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
    });
  }, []);

  return (
    <div>
      <Swiper
        className='relative'
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        onSlideChange={handleSlideChange}
        autoplay
        breakpoints={{
          500: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
          1400: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
        }}
      >
        <div className='text-white font-semibold text-xl absolute overflow-visible w-full top-0 z-10 grid grid-cols-5'>
          <div className='col-span-4 flex space-x-2 items-center'>
            <div className='mr-2'>Upcoming Events</div>
            <SwiperButtonPrevious currentSlideIndex={currentSlideIndex}>
              <LeftArrowCircle width='20px' height='20px' />
            </SwiperButtonPrevious>
            <SwiperButtonNext
              eventArrayLength={events?.length}
              currentSlideIndex={currentSlideIndex}
              slidesPerView={currentSlidesPerView}
            >
              <RightArrowCircle width='20px' height='20px' />
            </SwiperButtonNext>
          </div>

          <div className='w-auto flex justify-end'>
            <Link to={'/events'} className='text-[10pt] text-red hover:underline font-regular'>
              View all
            </Link>
          </div>
        </div>

        <div>
          {events
            ?.filter((event) => {
              const eventDate = new Date(event?.dates[0]?.date);
              return eventDate >= currentDate;
            })
            .sort((eventA, eventB) => {
              const dateA = new Date(eventA?.dates[0]?.date)?.getTime();
              const dateB = new Date(eventB?.dates[0]?.date)?.getTime();

              if (isNaN(dateA) || isNaN(dateB)) {
                return 0;
              }

              return dateA - dateB;
            })
            .map((event: Event) => {
              return (
                <SwiperSlide className='relative mt-11' key={event?._id}>
                  <EventSlide eventData={event} />
                </SwiperSlide>
              );
            })}
        </div>
      </Swiper>
    </div>
  );
}

function SwiperButtonPrevious({
  children,
  className,
  currentSlideIndex,
}: {
  children?: React.ReactNode;
  className?: string;
  currentSlideIndex: number;
}) {
  const swiper = useSwiper();

  return (
    <>
      {currentSlideIndex < 1 ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}

function SwiperButtonNext({
  children,
  eventArrayLength,
  className,
  currentSlideIndex,
  slidesPerView,
}: {
  children?: React.ReactNode;
  eventArrayLength: number;
  className?: string;
  currentSlideIndex: number;
  slidesPerView: number;
}) {
  const swiper = useSwiper();
  const [isSlideEnd, setIsSlideEnd] = useState<Boolean>();
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setIsSlideEnd(swiper.isEnd);
    setMaxIndex(eventArrayLength - slidesPerView);
  }, [swiper, eventArrayLength, slidesPerView, currentSlideIndex]);

  return (
    <>
      {isSlideEnd || currentSlideIndex >= maxIndex ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}
