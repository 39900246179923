import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import EditPostForm from '@/components/posts/EditPostForm';

const AddPost = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [formError, setFormError] = useState('');
  const navigate = useNavigate();

  return (
    <div className='grid grid-cols-12'>
      <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-8'>
          <h1>Edit Post</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 py-2 px-4 text-sm font-semibold rounded-3xl'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <EditPostForm />
      </div>
    </div>
  );
};

export default AddPost;
