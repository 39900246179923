import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function ArticlePhotoPopOut({ closeFunction, imageArray, imageIndex }: any) {
  let [currentIndex, setCurrentIndex] = useState(imageIndex);
  const filteredImageArray = imageArray.filter((image: any) => image !== null);
  const [displayPhoto, setDisplayPhoto] = useState(filteredImageArray[imageIndex]);

  function shiftDisplayPhotoForward() {
    if (currentIndex < filteredImageArray.length - 1) {
      let newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setDisplayPhoto(filteredImageArray[newIndex]);
    } else {
      setCurrentIndex(0);
      setDisplayPhoto(filteredImageArray[0]);
    }
  }

  function shiftDisplayPhotoBackward() {
    if (currentIndex === 0) {
      setCurrentIndex(filteredImageArray.length - 1);
      setDisplayPhoto(filteredImageArray[filteredImageArray.length - 1]);
    } else {
      let newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setDisplayPhoto(filteredImageArray[newIndex]);
    }
  }

  function galleryClickHandler(index: number) {
    setDisplayPhoto(filteredImageArray[index]);
    setCurrentIndex(index);
  }

  return (
    <div className='bg-black/80 fixed top-0 left-0 bottom-0 right-0 backdrop-blur-sm z-[100] items-center align-middle justify-center'>
      <div className='flex justify-center h-[75%] relative'>
        {filteredImageArray[1] && (
          <div className='w-auto flex cursor-pointer my-6' onClick={shiftDisplayPhotoBackward}>
            <button className='select-none p-6 w-full text-right font-bold text-xl hover:animate-pulse transition-all duration-150 ease-in-out'>{`<`}</button>
          </div>
        )}
        <div className='my-6 flex text-[10pt]'>
          <div className='flex-col'>
            <img
              className='max-h-full w-auto item-center'
              src={displayPhoto?.url}
              alt='Main gallery pic'
            />
            <div className='text-white flex justify-center py-2 text-center w-full'>
              <div className='italic max-w-[900px]'>{displayPhoto?.caption}</div>

              {displayPhoto?.projects[0]?.id && (
                <>
                  {displayPhoto?.caption && <div>|</div>}
                  <div className='mx-1'>Projects pictured:</div>

                  <div>
                    {displayPhoto?.projects?.map((project: any, index: number) => (
                      <Link className='' to={`/project/${project?.id}`} key={project?.id}>
                        <span className='hover:underline ml-1'>{project?.name}</span>
                        {index !== displayPhoto.projects.length - 1 && (
                          <span className='mr-1'>,</span>
                        )}
                      </Link>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {filteredImageArray[1] && (
          <div className='w-auto flex cursor-pointer my-6' onClick={shiftDisplayPhotoForward}>
            <button className='select-none p-6 w-full text-left font-bold text-xl hover:animate-pulse transition-all duration-150 ease-in-out'>
              {`>`}
            </button>
          </div>
        )}
        <div
          className='select-none hover:text-red absolute right-8 top-5 cursor-pointer text-lg transition-all duration-150 ease-in-out'
          onClick={closeFunction}
        >
          x
        </div>
      </div>
      <div className='h-[25%] flex gap-6 items-center overflow-x-scroll px-6'>
        {filteredImageArray.map((image: any, index: number) => (
          <img
            key={image?.url}
            className={`h-[60%] transition-all duration-150 ease-in-out cursor-pointer ${
              currentIndex === index ? 'h-[68%]' : ''
            }`}
            src={image?.url}
            alt='Gallery sub pic'
            onClick={() => galleryClickHandler(index)}
          />
        ))}
      </div>
    </div>
  );
}

export default ArticlePhotoPopOut;
