import React, { createContext, useContext, useState, ReactNode } from 'react';

interface VideoContextType {
  prevVideoId: string | undefined;
  setPreviousVideoId: (videoId: string | undefined) => void;
}

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export function VideoProvider({ children }: { children: ReactNode }) {
  const [prevVideoId, setPrevVideoId] = useState<string | undefined>(undefined);

  const setPreviousVideoId = (videoId: string | undefined) => {
    setPrevVideoId(videoId);
  };

  return (
    <VideoContext.Provider value={{ prevVideoId, setPreviousVideoId }}>
      {children}
    </VideoContext.Provider>
  );
}

export function useVideo() {
  const context = useContext(VideoContext);
  if (context === undefined) {
    throw new Error('useVideo must be used within a VideoProvider');
  }
  return context;
}
