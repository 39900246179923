import { GET_ALL_ARTICLES } from '@/utils/queries/queries';
import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LeftArrowCircle from '@/assets/icons/LeftArrowCircle';
import RightArrowCircle from '@/assets/icons/RightArrowCircle';

SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay, A11y]);

function FeaturedArticlesPage() {
  const {
    loading: articlesLoading,
    error: articlesError,
    data: articlesData2,
  } = useQuery(GET_ALL_ARTICLES);

  const [articlesData, setArticleData] = useState<any>();

  useEffect(() => {
    setArticleData(articlesData2?.getAllArticles);
  }, [articlesData2]);

  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    swiperRef.current = new SwiperCore('.custom-swiper', {
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
    });
  }, []);

  return (
    <div className='w-full space-y-14 mt-4 mb-14'>
      {articlesData && <CustomSwiper articlesData={articlesData} articleType='Car Feature' />}
      {articlesData && <CustomSwiper articlesData={articlesData} articleType='Event Feature' />}
      {articlesData && <CustomSwiper articlesData={articlesData} articleType='Technical Article' />}
    </div>
  );
}

export default FeaturedArticlesPage;

function ArticleCard({ article }: { article: any }) {
  return (
    <Link to={`/articles/${article._id}`} className='w-full'>
      <div
        className='h-[180px] w-full bg-center bg-cover rounded-md mb-2'
        style={{ backgroundImage: `url(${article.images[0].url})` }}
      ></div>
      <div className='absolute top-0 h-[180px] w-full rounded-md group-hover:bg-white/10 transition-all ease-in-out duration-100'></div>

      <h2 className='group-hover:underline font-[Montserrat] text-lg font-extrabold italic'>
        {article.heading}
      </h2>
      <h2 className='text-[10pt] mt-1 font-light line-clamp-3'>{article.subHeading}</h2>

      {article.tags && (
        <ul className='flex flex-wrap mt-3'>
          {article.tags
            .map((tag: string) => {
              return (
                <li
                  className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'
                  key={tag}
                >
                  {tag}
                </li>
              );
            })
            .splice(0, 2)}
          {article.tags.length > 2 && (
            <li className='inline-flex select-none rounded-full mr-2 mb-2 py-[3px] px-[6px] uppercase bg-black2 text-[#8f728b] text-[7pt] font-bold'>
              + {article.tags.length - 2} more
            </li>
          )}
        </ul>
      )}
    </Link>
  );
}

function CustomSwiper({ articlesData, articleType }: { articlesData: any[]; articleType: string }) {
  const swiperRef = useRef<SwiperCore | null>(null);
  const currentDate: Date = new Date();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlidesPerView, setCurrentSlidesPerView] = useState(1);

  const handleSlideChange = (swiper: any) => {
    setCurrentSlideIndex(swiper.activeIndex);
    setCurrentSlidesPerView(swiper.params.slidesPerView);
  };

  useEffect(() => {
    swiperRef.current = new SwiperCore('.custom-swiper', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      },
    });
  }, []);

  return (
    <div>
      <Swiper
        className='relative'
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        onSlideChange={handleSlideChange}
        autoplay
        breakpoints={{
          500: {
            spaceBetween: 16,
            slidesPerView: 2,
          },
          768: {
            spaceBetween: 16,
            slidesPerView: 3,
          },
          1400: {
            spaceBetween: 16,
            slidesPerView: 5,
          },
        }}
      >
        <div className='absolute w-full top-0 z-10 flex space-x-2 items-center'>
          <div className='text-white font-[Montserrat] font-extrabold text-2xl italic mr-2'>
            {articleType === 'Event Feature' && 'Event Coverage'}
            {articleType !== 'Event Feature' && `${articleType}s`}
          </div>
          <SwiperButtonPrevious currentSlideIndex={currentSlideIndex}>
            <LeftArrowCircle width='20px' height='20px' />
          </SwiperButtonPrevious>
          <SwiperButtonNext
            eventArrayLength={articlesData?.length}
            currentSlideIndex={currentSlideIndex}
            slidesPerView={currentSlidesPerView}
          >
            <RightArrowCircle width='20px' height='20px' />
          </SwiperButtonNext>
        </div>
        <div>
          {articlesData
            .map((article: any) => {
              if (article.articleTemplate === articleType) {
                return (
                  <SwiperSlide
                    className='relative cursor-pointer group rounded-md transition-all ease-in-out duration-100 mt-12'
                    key={article._id}
                  >
                    <ArticleCard article={article} />
                  </SwiperSlide>
                );
              }
            })
            .reverse()
            .slice(0, 60)}
        </div>
      </Swiper>
    </div>
  );
}

function SwiperButtonPrevious({
  children,
  className,
  currentSlideIndex,
}: {
  children?: React.ReactNode;
  className?: string;
  currentSlideIndex: number;
}) {
  const swiper = useSwiper();

  return (
    <>
      {currentSlideIndex < 1 ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}

function SwiperButtonNext({
  children,
  eventArrayLength,
  className,
  currentSlideIndex,
  slidesPerView,
}: {
  children?: React.ReactNode;
  eventArrayLength: number;
  className?: string;
  currentSlideIndex: number;
  slidesPerView: number;
}) {
  const swiper = useSwiper();
  const [isSlideEnd, setIsSlideEnd] = useState<Boolean>();
  const [maxIndex, setMaxIndex] = useState<number>(0);

  useEffect(() => {
    setIsSlideEnd(swiper.isEnd);
    setMaxIndex(eventArrayLength - slidesPerView);
  }, [swiper, eventArrayLength, slidesPerView, currentSlideIndex]);

  return (
    <>
      {isSlideEnd || currentSlideIndex >= maxIndex ? (
        <button className={`${className} opacity-50`} disabled>
          {children}
        </button>
      ) : (
        <button
          className={className}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          {children}
        </button>
      )}
    </>
  );
}
