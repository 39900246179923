import CommunityProjects from './CommunityProjects';
import RecommendedProducts from './RecommendedProducts';
import { useQuery } from '@apollo/client';
import {
  GET_ALL_TAGS,
  GET_ALL_POSTS,
  GET_ALL_ARTICLES,
  GET_ALL_PROJECTS,
  GET_ALL_PRODUCTS,
  GET_USER_BY_ID,
  GET_USER_PROJECTS,
  GET_ALL_VIDEOS,
} from '@/utils/queries/queries';
import { Link } from 'react-router-dom';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import FeaturedArticles from './FeaturedArticles';
import { useEffect, useState } from 'react';
import TestImage from '@/assets/images/no_image_found.png';
import { getHTMLFromDraftState } from '@/utils/utilities';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/reducers/rootReducer';
import { GatedLink } from '../LoginModal';
import { GatedNavLink, useShowLogin } from '@/components/LoginModal';
import EventSwiper from './EventSwiper';
import VideoSwiper from '@/pages/user/videos/VideoSwiper';

interface Article {
  _id: string;
  heading: string;
  subHeading: string;
  images: {
    __typename: string;
    index: number;
    caption: string;
    url: string;
    projects: {
      __typename: string;
      name: string;
      id: string;
    }[];
  }[];
}

interface Video {
  _id: string;
  createdBy: string;
  createdAt: Date;
  videoUrl: string;
  caption: String;
  tags: string[];
}

function Activity() {
  const { userData } = useSelector((state: RootState) => state.user);
  const { requiresLogin, showLogin } = useShowLogin();
  const { data: videosData2 } = useQuery(GET_ALL_VIDEOS);

  const [videoShorts, setVideoShorts] = useState<Video[]>();

  useEffect(() => {
    if (videosData2) {
      const filteredShorts = videosData2.getAllVideos.filter((video: Video) =>
        video.videoUrl.includes('/shorts/'),
      );
      setVideoShorts(filteredShorts);
    }
  }, [videosData2]);

  const {
    loading: articlesLoading,
    error: articlesError,
    data: articlesData,
  } = useQuery(GET_ALL_ARTICLES);

  const {
    loading: projectsLoading,
    error: projectsError,
    data: projectsData,
  } = useQuery(GET_ALL_PROJECTS);

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
      filters: {
        vendor: [],
        category: [],
        tag: [],
      },
    },
  });

  const { loading: userProjectLoading, data: userProjectsData } = useQuery(GET_USER_PROJECTS, {
    variables: { userId: userData._id },
  });

  const { loading: postLoading, error: postError, data: postData2 } = useQuery(GET_ALL_POSTS);

  const [item, setShuffledFeaturedArticles] = useState();

  const ActivityArray = projectsData?.getAllProjects
    ?.concat(articlesData?.getAllArticles?.concat(postData2?.getAllFeeds))
    ?.sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1));

  const allArticles = articlesData?.getAllArticles;
  const featuredArticles = articlesData?.getAllArticles?.filter((item: any) => {
    return (
      item?.articleTemplate.includes('Car Feature') ||
      item?.articleTemplate.includes('Event Feature') ||
      item?.articleTemplate.includes('Technical Article')
    );
  });

  useEffect(() => {
    setShuffledFeaturedArticles(featuredArticles?.sort(() => 0.5 - Math.random()));
  }, [allArticles]);

  useEffect(() => {
    const interval = setInterval(() => {
      setShuffledFeaturedArticles(featuredArticles?.sort(() => 0.5 - Math.random()));
    }, 10000);
    return () => clearInterval(interval);
  }, [featuredArticles]);

  return (
    <div className='grid grid-cols-12'>
      {requiresLogin && (
        <GatedNavLink
          to='/'
          className='lg:hidden col-span-12 mb-6 flex rounded-full bg-red hover:bg-[#A60626] transition-all cursor-pointer text-white text-center w-full justify-center px-8 py-2'
        >
          <div>Sign in</div>
          <div className='fill-white mt-[2px] ml-2'>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='20' viewBox='0 0 36 27.565'>
              <path
                d='M29.25,31.5H23.344a.846.846,0,0,1-.844-.844V27.844A.846.846,0,0,1,23.344,27H29.25a2.248,2.248,0,0,0,2.25-2.25V11.25A2.248,2.248,0,0,0,29.25,9H23.344a.846.846,0,0,1-.844-.844V5.344a.846.846,0,0,1,.844-.844H29.25A6.752,6.752,0,0,1,36,11.25v13.5A6.752,6.752,0,0,1,29.25,31.5Zm-3.3-14.133L14.133,5.555a1.69,1.69,0,0,0-2.883,1.2V13.5H1.688A1.683,1.683,0,0,0,0,15.188v6.75a1.683,1.683,0,0,0,1.688,1.688H11.25v6.75a1.69,1.69,0,0,0,2.883,1.2L25.945,19.758A1.7,1.7,0,0,0,25.945,17.367Z'
                transform='translate(0 -4.5)'
              />
            </svg>
          </div>
        </GatedNavLink>
      )}

      {!userProjectLoading && !userProjectsData?.getProjects[0] && (
        <div className='mt-4 col-span-12 h-auto px-4 py-3 bg-red rounded-md grid grid-cols-8 text-center sm:text-left items-center gap-2'>
          <div className='text-lg font-[Montserrat] font-extrabold italic col-span-full sm:col-span-6'>
            We've noticed that you haven't added your car yet!
          </div>
          <GatedLink
            className='col-span-full sm:col-span-2 text-center transition ease-in-out duration-200 border-[1px] border-white hover:bg-[#A60626] py-2 px-4 text-sm font-semibold rounded-full'
            to='/projects/add'
          >
            + Create Project
          </GatedLink>
        </div>
      )}

      <div className='col-span-12 space-y-16 2xl:col-span-8 select-none mt-4'>
        {!articlesLoading && articlesData && (
          <FeaturedArticles
            shuffledFeaturedArticles={item}
            featuredArticlesLoading={articlesLoading}
          />
        )}

        {videoShorts && (
          <VideoSwiper
            videosData={videoShorts}
            swiperTitle='Latest Videos'
            viewAllActive={true}
            isPlaying={true}
            slidesPerView={3}
            aspectRatio='aspect-[9/16]'
          />
        )}

        <EventSwiper />

        {!productLoading && productData?.getAllProducts.length && (
          <RecommendedProducts productData={productData} />
        )}

        <div>
          <h4 className='text-white font-semibold text-xl mb-4'>Recent Activity</h4>
          {articlesLoading || projectsLoading ? (
            <LoadingSpinner />
          ) : (
            <div className='space-y-10'>
              {ActivityArray?.slice(0, 30).map((item: any) => {
                return (
                  <div className='' key={item?._id}>
                    {item?.__typename === 'Article' && <ActivityArticle item={item} />}
                    {item?.__typename === 'Project' && <ActivityProject item={item} />}
                    {item?.__typename === 'Feed' && <ActivityPost item={item} />}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className='invisible 2xl:visible 2xl:start-col-8 2xl:col-span-4 ml-9 mt-4'>
        <h4 className='text-white font-semibold text-xl mb-3'>Latest Articles</h4>
        <div className='space-y-10'>
          <div>
            <div className='text-[#4adec7] font-extrabold uppercase text-[8pt] mb-2'>
              Car Features
            </div>
            <div className='gap-4 grid'>
              {featuredArticles
                ?.filter((article: any) => article.articleTemplate === 'Car Feature')
                .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1))
                .slice(0, 3)
                .map((article: any) => (
                  <Link to={`/articles/${article?._id}`} key={article._id}>
                    <div className='grid grid-cols-2 h-auto items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                      <div
                        className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                        style={{
                          backgroundImage: `url(${article?.images[0]?.url})`,
                        }}
                      ></div>
                      <div className='col-span-1 p-4 text-white'>
                        <h4 className='line-clamp-2 mb-1 font-semibold'>{article?.heading}</h4>
                        <h5 className='text-[8pt] line-clamp-3 font-light'>
                          {article?.subHeading}
                        </h5>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div>
            <div className='text-[#4adec7] font-extrabold uppercase text-[8pt] mb-2'>
              Event Features
            </div>
            <div className='gap-4 grid'>
              {featuredArticles
                ?.filter((article: any) => article.articleTemplate === 'Event Feature')
                .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1))
                .slice(0, 3)
                .map((article: any) => (
                  <Link to={`/articles/${article?._id}`} key={article._id}>
                    <div className='grid grid-cols-2 h-auto items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                      <div
                        className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                        style={{
                          backgroundImage: `url(${article?.images[0]?.url})`,
                        }}
                      ></div>
                      <div className='col-span-1 p-4 text-white'>
                        <h4 className='line-clamp-2 mb-1 font-semibold'>{article?.heading}</h4>
                        <h5 className='text-[8pt] line-clamp-3 font-light'>
                          {article?.subHeading}
                        </h5>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div>
            <div className='text-[#4adec7] font-extrabold uppercase text-[8pt] mb-2'>
              Technical Articles
            </div>
            <div className='gap-4 grid'>
              {featuredArticles
                ?.filter((article: any) => article.articleTemplate === 'Technical Article')
                .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1))
                .slice(0, 3)
                .map((article: any) => (
                  <Link to={`/articles/${article?._id}`} key={article._id}>
                    <div className='grid grid-cols-2 h-auto items-center transition-all ease-in-out duration-200 rounded-r-md hover:bg-[#110d11] bg-[#241B23]'>
                      <div
                        className='col-span-1 bg-cover bg-center h-full rounded-l-md'
                        style={{
                          backgroundImage: `url(${article?.images[0]?.url})`,
                        }}
                      ></div>
                      <div className='col-span-1 p-4 text-white'>
                        <h4 className='line-clamp-2 mb-1 font-semibold'>{article?.heading}</h4>
                        <h5 className='text-[8pt] line-clamp-3 font-light'>
                          {article?.subHeading}
                        </h5>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          {!projectsLoading && projectsData && (
            <CommunityProjects projects={projectsData?.getAllProjects} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Activity;

function ActivityPost({ item }: any) {
  const [textContent, setTextContent] = useState<string>('');

  useEffect(() => {
    setTextContent(getHTMLFromDraftState(item.bodyContent));
  }, [item]);

  const {
    error: postCreatorError,
    loading: postCreatorLoading,
    data: postCreatorData2,
  } = useQuery(GET_USER_BY_ID, {
    variables: {
      userId: item?.createdBy,
    },
  });

  const [postCreatorData, setProjectCreatorDataLoaded] = useState<any>();

  useEffect(() => {
    setProjectCreatorDataLoaded(postCreatorData2?.getUserById);
  }, [postCreatorData2]);

  return (
    <div className='border-[1px] border-black2 rounded-md'>
      <img
        className='h-full rounded-t-md w-full'
        src={item.feedImage}
        referrerPolicy='no-referrer'
        alt='Post'
      ></img>

      <div className='bg-black2 w-full h-auto rounded-b-md relative'>
        <div className='p-4'>
          <Link to={`/profile/${postCreatorData?.username}`}>
            <div className='col-start-1 col-span-6 font-extrabold uppercase text-[8pt] text-red mb-3 hover:underline'>
              Post by{' '}
              {postCreatorData?.advertiserDetails.companyName ||
                postCreatorData?.username ||
                `${postCreatorData?.firstName} ${postCreatorData?.lastName}`}
            </div>
          </Link>
          <div className='text-xl text-left font-bold'>{item?.heading}</div>

          <div
            className='text-[10pt] font-light space-y-2 mt-2 max-h-[250px] overflow-y-scroll pr-2 mb-4'
            dangerouslySetInnerHTML={{ __html: textContent }}
          ></div>
          {item.tags ? (
            <ul className='flex flex-wrap -mb-2'>
              {item.tags.map((tag: string) => {
                return (
                  <li
                    className='inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-[#352833] text-red text-[8pt] font-bold'
                    key={tag}
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

function ActivityArticle({ item }: any) {
  return (
    <div className='h-[300px] md:h-[400px]'>
      <Link to={`/articles/${item?._id}`}>
        <div
          className='rounded-md col-span-4 bg-center bg-cover lg:bg-[length:100%] h-[300px] md:h-[400px] relative text-opacity-0 transition-all ease-in-out duration-150 text-[8pt] lg:text-[0pt] lg:hover:text-[8pt] hover:text-opacity-100 lg:hover:bg-[length:102%]'
          style={{
            backgroundImage: `url(${item?.images[0]?.url})`,
          }}
        >
          <div className='rounded-full m-5 py-1 px-2 w-auto uppercase bg-[#219181] text-[#4adec7] text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
            {item?.articleTemplate}
          </div>

          <div className='h-auto w-full p-5 bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
            <h2 className='text-white text-4xl font-[Montserrat] font-extrabold italic line-clamp-2'>
              {item?.heading}
            </h2>
            <h3 className='text-white text-[10pt] font-light line-clamp-2'>{item?.subHeading}</h3>

            <span className='text-red font-black uppercase'>
              By {item?.firstName}
              {' | '}
            </span>
            <span className='text-red font-black'>
              {new Date(item?.createdAt)?.toLocaleDateString()}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}

function ActivityProject({ item }: any) {
  return (
    <div className='h-[300px] md:h-[400px]'>
      <div
        key={item?.id}
        className='grid grid-cols-12 mt-1 mb-8 text-[0pt] hover:text-[2pt] relative'
      >
        <Link className='col-span-12' to={`/project/${item?._id}`}>
          <div
            className='h-[300px] md:h-[400px] bg-center bg-cover col-span-12 rounded-md lg:bg-[length:100%] lg:hover:bg-[length:102%] transition-all ease-in-out duration-200'
            style={{
              backgroundImage: `url(${item?.images[0] !== '' ? item?.images[0] : TestImage})`,
            }}
          >
            <div className='rounded-full m-5 py-1 px-2 w-auto uppercase bg-[#c0c0c080] text-white text-[8pt] font-bold bg-opacity-60 absolute backdrop-blur-sm'>
              User Project
            </div>
            <div className='h-auto w-full bg-gradient-to-t rounded-b-md from-[#080607d5] to-[#08060700] absolute bottom-0'>
              <h2 className='text-white text-8xl font-[Montserrat] font-black italic opacity-[15%] absolute bottom-0 uppercase whitespace-nowrap overflow-hidden w-full'>
                {item?.make}
              </h2>

              <h2 className='text-2xl font-[Montserrat] text-white font-extrabold bottom-0 p-5'>
                {item?.name}
              </h2>
              <div className='opacity-0 relative'>Hello, I hope you're having a good day.</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
