import {
  FIND_ADVERTISER_BY_NAME,
  FIND_PROJECT_BY_NAME,
  FIND_TAG_BY_QUERY,
} from '@/utils/queries/queries';
import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';

export function useTagSearch(tagLimit: number | undefined) {
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [searchResultTags, setData] = useState<Array<string> | undefined>(undefined); // Add typing - replace any
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isEnabled = (searchQuery?.length ?? 0) >= 2 ? true : false;
  //
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [tagsSelectedError, setTagsSelectedError] = useState(false);

  // Fetching tags by query
  const [findTags, { loading: tagSearchLoading, error: tagsError }] = useLazyQuery(
    FIND_TAG_BY_QUERY,
    {
      onCompleted: (data) => {
        const res = data?.getTagsBySearch;
        if (res?.length) {
          const transformedData = res?.map((tag: { tag: string }) => tag.tag);
          setData(transformedData ?? []);
          setSuggestionsActive(true);
        }
      },
    },
  );

  // Handle query refetch if valid & input has changed
  useEffect(() => {
    if (isEnabled) {
      findTags({ variables: { searchQuery: searchQuery } });
    }
  }, [findTags, isEnabled, searchQuery]);

  // Toggle selected tags - required for form functionality
  function toggleSelect(newTag: any, callback: Function): void {
    // Remove tag if already selected
    if (selectedTags.includes(newTag)) {
      // handleRemoveTag(newTag, callback);
      return;
    }

    // Hide dropdown tag selections and set error to true
    // Signifying to user that max tag selection is 5.
    if (selectedTags.length === tagLimit || selectedTags.length === 5) {
      setTagsSelectedError(false);
      setSuggestionsActive(false);
      return;
    }

    // Otherwise, updated users selected tags
    var newTags: any = [...selectedTags, newTag];
    setSelectedTags((selectedTags) => [...selectedTags, newTag]);
    callback(newTags);
  }

  // Remove tag if already selected
  const handleRemoveTag = (tag: string, callback: Function): void => {
    let updatedselectedTags = selectedTags.filter((e) => e !== tag);
    setSelectedTags(updatedselectedTags);
    callback(updatedselectedTags);
  };

  // Handle input field changes
  // - as user types, only ping a search query if string length > 2
  const handleTagSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 1) {
      setSearchQuery(query);
    }
    // For searchs with 1 or less characters, hide the dropdown field
    if (query.length <= 1) {
      setSuggestionsActive(false);
    }
  };

  return {
    selectedTags,
    searchResultTags,
    tagSearchLoading,
    suggestionsActive,
    tagsSelectedError,
    setSearchQuery,
    handleRemoveTag,
    handleTagSearch,
    toggleSelect,
  };
}

export function useProjectSearch() {
  const [selectedProjects, setSelectedProjects] = useState<Array<any>>([]); // TODO - update typing
  const [searchResultProjects, setSearchResultProjects] = useState<Array<string> | undefined>(
    undefined,
  ); // Add typing - replace any
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isEnabled = (searchQuery?.length ?? 0) >= 2 ? true : false;
  //
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [projectsSelectedError, setProjectsSelectedError] = useState(false);

  const [findProjectByName, { loading: projectSearchLoading, error }] = useLazyQuery(
    FIND_PROJECT_BY_NAME,
    {
      onCompleted: (data) => {
        const res = data?.findProjectByName;
        if (res?.length) {
          setSearchResultProjects(res);
          setSuggestionsActive(true);
        }
      },
    },
  );

  // Handle query refetch if valid & input has changed
  useEffect(() => {
    if (isEnabled) {
      findProjectByName({ variables: { name: searchQuery } });
    }
  }, [findProjectByName, isEnabled, searchQuery]);

  function toggleSelect(newProject: any, callback: Function, maxSelection: number = 100): void {
    const projectAlreadyExists = selectedProjects?.length
      ? selectedProjects?.some((project) => newProject.name === project?.name)
      : false;

    // Remove project if already selected
    if (projectAlreadyExists) {
      handleRemoveProject(newProject, callback);
      return;
    }

    // Check if max selection has been reached
    if (selectedProjects?.length >= maxSelection) return;

    // Hide dropdown project selections and set error to true
    // Signifying to user that max project selection is 5.
    if (selectedProjects?.length === 5) {
      setProjectsSelectedError(false);
      setSuggestionsActive(false);
      return;
    }

    const nProject = {
      id: newProject._id,
      name: newProject.name,
    };

    // Otherwise, updated users selected projects
    var newProjects: any = selectedProjects?.length ? [...selectedProjects, nProject] : [nProject];
    setSelectedProjects(newProjects);
    callback(newProjects);
  }

  // Remove project if already selected
  const handleRemoveProject = (project: string, callback: Function): void => {
    let updatedselectedProjects = selectedProjects.filter((e) => e !== project);
    setSelectedProjects(updatedselectedProjects);
    callback(updatedselectedProjects);
  };

  // Handle input field changes
  // - as user types, only ping a search query if string length > 2
  const handleProjectSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 1) {
      setSearchQuery(query);
    }
    // For searchs with 1 or less characters, hide the dropdown field
    if (query.length <= 1) {
      setSuggestionsActive(false);
    }
  };

  return {
    selectedProjects,
    searchResultProjects,
    projectSearchLoading,
    suggestionsActive,
    projectsSelectedError,
    setSearchQuery,
    handleRemoveProject,
    handleProjectSearch,
    toggleSelect,
  };
}

export function useAdvertiserSearch() {
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<Array<any>>([]); // TODO - update typing
  const [searchResultAdvertisers, setSearchResultAdvertisers] = useState<Array<string> | undefined>(
    undefined,
  ); // Add typing - replace any
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const isEnabled = (searchQuery?.length ?? 0) >= 2 ? true : false;
  //
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [advertisersSelectedError, setAdvertisersSelectedError] = useState(false);

  const [findAdvertiserByName, { loading: advertiserSearchLoading, error }] = useLazyQuery(
    FIND_ADVERTISER_BY_NAME,
    {
      onCompleted: (data) => {
        const res = data?.findAdvertiserByCompanyName;
        if (res?.length) {
          setSearchResultAdvertisers(res);
          setSuggestionsActive(true);
        }
      },
    },
  );

  // Handle query refetch if valid & input has changed
  useEffect(() => {
    if (isEnabled) {
      findAdvertiserByName({ variables: { companyName: searchQuery } });
    }
  }, [findAdvertiserByName, isEnabled, searchQuery]);

  function toggleSelect(newAdvertiser: any, callback: Function, maxSelection: number = 100): void {
    const advertiserAlreadyExists = selectedAdvertisers?.length
      ? selectedAdvertisers?.some(
          (advertiser) => newAdvertiser.advertiserDetails.companyName === advertiser.companyName,
        )
      : false;

    // Remove advertiser if already selected
    if (advertiserAlreadyExists) {
      handleRemoveAdvertiser(newAdvertiser, callback);
      return;
    }

    // Check if max selection has been reached
    if (selectedAdvertisers?.length >= maxSelection) return;

    // Hide dropdown project selections and set error to true
    // Signifying to user that max project selection is 5.
    if (selectedAdvertisers?.length === 5) {
      setAdvertisersSelectedError(false);
      setSuggestionsActive(false);
      return;
    }

    const nAdvertiser = {
      id: newAdvertiser._id,
      companyName: newAdvertiser.advertiserDetails.companyName,
    };

    // Otherwise, updated users selected projects
    var newAdvertiser: any = selectedAdvertisers?.length
      ? [...selectedAdvertisers, nAdvertiser]
      : [nAdvertiser];
    setSelectedAdvertisers(newAdvertiser);
    callback(newAdvertiser);
  }

  // Remove project if already selected
  const handleRemoveAdvertiser = (advertiser: string, callback: Function): void => {
    let updatedselectedAdvertiser = selectedAdvertisers.filter((e) => e !== advertiser);
    setSelectedAdvertisers(updatedselectedAdvertiser);
    callback(updatedselectedAdvertiser);
  };

  // Handle input field changes
  // - as user types, only ping a search query if string length > 2
  const handleAdvertiserSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    if (query.length > 1) {
      setSearchQuery(query);
    }
    // For searchs with 1 or less characters, hide the dropdown field
    if (query.length <= 1) {
      setSuggestionsActive(false);
    }
  };

  return {
    selectedAdvertisers,
    searchResultAdvertisers,
    advertiserSearchLoading,
    suggestionsActive,
    advertisersSelectedError,
    setSearchQuery,
    handleRemoveAdvertiser,
    handleAdvertiserSearch,
    toggleSelect,
  };
}
