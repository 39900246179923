import { getHTMLFromDraftState, Utilities } from '@/utils/utilities';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ArticleData } from '../../SingleArticle';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import { gql, useQuery } from '@apollo/client';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import LoadingIcon from '@/utils/LoadingIcon';
import ArticlePhotoPopOut from '@/utils/photos/ArticlePhotoPopOut';
import MHPLogo from '@images/steeringWheelLogo.svg';

export const ArticleTemplateAdminDisplay = ({
  userData,
  articleData,
  advertiserData,
}: {
  articleData: ArticleData;
  userData: any;
  advertiserData: any | undefined;
}) => {
  const { heading, subHeading, bodyContentList, images, createdAt } =
    articleData || ({} as ArticleData);

  const navigate = useNavigate();

  // Parse WYSIWYG Content
  // const markup = getHTMLFromDraftState(bodyContentList[0].text);

  const [textContentArray, setTextContentArray] = useState<string[]>([]);

  useEffect(() => {
    setTextContentArray(() => {
      let newArray: string[] = [];
      bodyContentList.forEach((content) => {
        newArray.push(getHTMLFromDraftState(content.text));
      });
      return newArray;
    });
  }, [articleData]);

  const [photoPopOut, setPhotoPopOut] = useState<boolean>(false);
  const [popOutIndex, setPopOutIndex] = useState<number>();

  function enlargePhoto(index: number) {
    setPopOutIndex(index);
    setPhotoPopOut(true);
  }

  return (
    <>
      {photoPopOut && (
        <ArticlePhotoPopOut
          imageArray={images}
          imageIndex={popOutIndex}
          closeFunction={() => setPhotoPopOut(false)}
        />
      )}

      {articleData ? (
        <div className='text-gray-700 mt-2 relative grid grid-cols-12'>
          <div
            className='text-white cursor-pointer bg-center bg-cover h-[350px] sm:h-[450px] md:h-[600px] relative rounded-t-md col-span-full'
            style={{ backgroundImage: `url(${articleData?.images[0]?.url})` }}
            onClick={() => enlargePhoto(0)}
          >
            <div className='h-auto w-full p-4 lg:p-6 bg-gradient-to-b from-[#080607d5] to-[#08060700] absolute top-0'>
              <h1 className='font-[Montserrat] font-black italic text-4xl lg:text-6xl'>
                {articleData?.heading}
              </h1>
              <h2 className='font-[Montserrat] font-regular italic text-xl mt-1'>
                {articleData?.subHeading}
              </h2>
            </div>
          </div>

          <div className={`h-auto col-start-1 col-span-full bg-white p-4 lg:p-6 rounded-b-md`}>
            <div className='grid grid-cols-8 col-start-1 gap-4 lg:gap-6'>
              {/* Article body starts here  */}

              <div className={`col-start-1 col-span-full md:col-span-6`}>
                {articleData?.tags && (
                  <ul className='flex flex-wrap mb-2'>
                    {articleData.tags.map((tag: string) => {
                      return (
                        <li
                          className='inline-flex select-none rounded-full mr-2 mb-2 py-1 px-2 uppercase bg-gray-300 text-white text-[8pt] font-bold'
                          key={tag}
                        >
                          {tag}
                        </li>
                      );
                    })}
                  </ul>
                )}
                <Link to={`/profile/${userData?.username}`}>
                  <div className='col-start-1 col-span-6 font-extrabold uppercase text-[8pt] text-red mb-3 hover:underline'>
                    {articleData?.articleTemplate} by {userData?.username} |{' '}
                    {new Date(articleData?.createdAt).toLocaleDateString()}
                  </div>
                </Link>

                {textContentArray[0] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[0] }}
                  />
                )}
                {textContentArray[1] && articleData?.images[1]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[1].url}`}
                      alt='Article Pic 1'
                      onClick={() => enlargePhoto(1)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[1]?.caption}</p>
                  </>
                )}

                {textContentArray[1] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[1] }}
                  />
                )}
                {textContentArray[2] && articleData?.images[2]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[2]?.url}`}
                      alt='Article Pic 2'
                      onClick={() => enlargePhoto(2)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[2]?.caption}</p>
                  </>
                )}

                {textContentArray[2] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[2] }}
                  />
                )}
                {textContentArray[3] && articleData?.images[3]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[3]?.url}`}
                      alt='Article Pic 3'
                      onClick={() => enlargePhoto(3)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[3]?.caption}</p>
                  </>
                )}

                {textContentArray[3] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[3] }}
                  />
                )}
                {textContentArray[4] && articleData?.images[4]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[4]?.url}`}
                      alt='Article Pic 4'
                      onClick={() => enlargePhoto(4)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[4]?.caption}</p>
                  </>
                )}

                {textContentArray[4] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[4] }}
                  />
                )}
                {textContentArray[5] && articleData?.images[5]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[5]?.url}`}
                      alt='Article Pic 5'
                      onClick={() => enlargePhoto(5)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[5]?.caption}</p>
                  </>
                )}

                {textContentArray[5] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[5] }}
                  />
                )}
                {textContentArray[6] && articleData?.images[6]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[6]?.url}`}
                      alt='Article Pic 6'
                      onClick={() => enlargePhoto(6)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[6]?.caption}</p>
                  </>
                )}

                {textContentArray[6] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[6] }}
                  />
                )}
                {textContentArray[7] && articleData?.images[7]?.url && (
                  <>
                    <img
                      className='cursor-pointer mt-8 mb-2 w-full'
                      src={`${articleData?.images[7]?.url}`}
                      alt='Article Pic 7'
                      onClick={() => enlargePhoto(7)}
                    />
                    <p className='mb-8 text-[10pt] italic'>{articleData?.images[7]?.caption}</p>
                  </>
                )}

                {textContentArray[7] && (
                  <p
                    className='space-y-3'
                    dangerouslySetInnerHTML={{ __html: textContentArray[7] }}
                  />
                )}

                <div className='w-full flex justify-center'>
                  <img className='pt-2 w-5 col-start-1' src={MHPLogo} alt='Workflow' />
                </div>
              </div>
              <div className='col-start-1 col-span-full md:col-start-7 md:col-span-2 space-y-6'>
                {articleData.taggedAdvertisers[0] && (
                  <TaggedAdvertisers advertiserData={advertiserData} />
                )}

                {articleData?.images && (
                  <>
                    {articleData?.images ? (
                      <>
                        {articleData?.images
                          .map((image: any, index: number) => {
                            return (
                              <img
                                key={index}
                                src={image?.url}
                                alt=''
                                className='mb-6 cursor-pointer'
                                onClick={() => enlargePhoto(index)}
                              />
                            );
                          })
                          .splice(1, 10)}
                      </>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </>
                )}
                {articleData?.images[1] && (
                  <div
                    onClick={() => enlargePhoto(0)}
                    className='w-full h-auto p-2 text-center bg-gray-400 text-white hover:bg-gray-500 transition-all ease-in-out duration-100 rounded-md font-semibold cursor-pointer'
                  >
                    View All Images
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export const TaggedAdvertisers = ({ advertiserData }: any) => {
  return (
    <div className='p-2 bg-gray-200 rounded-md'>
      <h4 className='text-[8pt] font-black uppercase mb-2'>Related Suppliers</h4>
      <ul className='space-y-2'>
        {advertiserData?.map((advertiser: any) => {
          return (
            <Link
              key={advertiser._id}
              to={`/profile/${advertiser.username}`}
              className={`transition-all duration-100 bg-cover bg-center h-full flex text-center font-semibold cursor-pointer rounded-md`}
              style={{
                backgroundImage: `url(${advertiser.advertiserDetails.coverPhoto})`,
              }}
            >
              <div className='bg-[#00000080] hover:bg-[#00000040] h-full w-full rounded-md transition-all duration-100 flex items-center justify-center'>
                <div
                  className={`text-white m-4 font-[Montserrat] rounded-md group-hover:opacity-75`}
                >
                  <div className='italic font-bold'>{advertiser.advertiserDetails.companyName}</div>
                  <div className='text-xs font-light'>Click to visit</div>
                </div>
              </div>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};
