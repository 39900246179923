// Import packages and hooks
import { useEffect, useState } from 'react';
import { useMutation, gql, useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import { UPLOAD_PHOTO } from '@/utils/mutations/mutations';
import LoadingSpinner from '@/utils/loading/LoadingSpinner';
import DeleteItemButton from '@/utils/deleting/DeleteItemButton';
import TagSearch, { MHPSearchTagType } from '@/components/utils/TagSearch';
import { ProjectData } from './SingleProject';
import AdvertiserSearch from '@/components/utils/AdvertiserSearch';

const EDIT_PROJECT = gql`
  mutation EditProject($projectId: ID, $input: ProjectInput) {
    editProject(projectId: $projectId, input: $input) {
      _id
      createdBy
      name
      year
      make
      badge
      model
      tags
      taggedAdvertisers {
        companyName
        id
      }
      images
      description
      paint
      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: ID) {
    getProjectById(projectId: $projectId) {
      _id
      createdBy
      name
      year
      make
      model
      badge
      tags
      taggedAdvertisers {
        companyName
        id
      }
      images
      description
      paint
      engine {
        engineManufacturer
        displacement
        manifold
        cylinderHeads
        fuel
        exhaust
        horsepower
        induction
        inductionInfo
        internals {
          camshaft
          crankshaft
          pistons
          rotors
          conrods
          ignition
        }
      }
      driveline {
        gearbox
        clutch
        differential
        torqueConverter
      }
      undercarriage {
        brakes
        suspension {
          springs
          shocks
        }
      }
      rollingStock {
        wheels
        tyres
      }
    }
  }
`;

const EditProject = () => {
  const { projectId } = useParams();

  const {
    error,
    loading: projectDataLoading,
    data: projectData2,
  } = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: projectId,
    },
  });

  const [projectData, setProjectDataLoaded] = useState<ProjectData>();
  const [engineDropDown, setEngineDropDown] = useState<Boolean>(false);
  const [engineInternalsDropDown, setEngineInternalsDropDown] = useState<Boolean>(true);
  const [drivelineDropDown, setDrivelineDropDown] = useState<Boolean>(false);
  const [undercarriageDropDown, setUndercarriageDropDown] = useState<Boolean>(false);
  const [wheelDropDown, setWheelDropDown] = useState<Boolean>(false);

  const navigate = useNavigate();

  const [formError, setFormError] = useState('');
  const [projectTags, setProjectTags] = useState<any[]>([]);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState<any>([]);
  const [projectImages, setProjectImages] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<any[]>([]);

  const [editProjectMutation, { loading: editProjectLoading }] = useMutation(EDIT_PROJECT);

  const [projectImageError, setProjectImageError] = useState(false);
  const [uploadPhoto, { data: uploadPhotoData, loading: uploadPhotoLoading }] =
    useMutation(UPLOAD_PHOTO);

  useEffect(() => {
    if (projectData) {
      setImagePreview(projectData.images);
      setProjectImages(projectData.images);
    }
  }, [projectData]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: projectData?.name,
      year: projectData?.year,
      make: projectData?.make,
      model: projectData?.model,
      badge: projectData?.badge,
      paint: projectData?.paint,
      description: projectData?.description,
      engine: projectData?.engine,
      driveline: projectData?.driveline,
      undercarriage: projectData?.undercarriage,
      rollingStock: projectData?.rollingStock,
    },
  });

  useEffect(() => {
    if (projectData2 && !projectDataLoading) {
      setProjectDataLoaded(projectData2?.getProjectById);

      const {
        name,
        year,
        make,
        model,
        badge,
        paint,
        description,
        engine,
        driveline,
        undercarriage,
        rollingStock,
      } = projectData2?.getProjectById || {};

      setValue('name', name);
      setValue('year', year);
      setValue('make', make);
      setValue('model', model);
      setValue('badge', badge);
      setValue('paint', paint);
      setValue('description', description);

      if (engine) {
        setValue('engine.engineManufacturer', engine.engineManufacturer);
        setValue('engine.displacement', engine.displacement);
        setValue('engine.manifold', engine.manifold);
        setValue('engine.cylinderHeads', engine.cylinderHeads);
        setValue('engine.fuel', engine.fuel);
        setValue('engine.exhaust', engine.exhaust);
        setValue('engine.horsepower', engine.horsepower);
        setValue('engine.induction', engine.induction);
        setValue('engine.inductionInfo', engine.inductionInfo);
        setValue('engine.internals.camshaft', engine.internals.camshaft);
        setValue('engine.internals.crankshaft', engine.internals.crankshaft);
        setValue('engine.internals.pistons', engine.internals.pistons);
        setValue('engine.internals.rotors', engine.internals.rotors);
        setValue('engine.internals.conrods', engine.internals.conrods);
        setValue('engine.internals.ignition', engine.internals.ignition);
      }

      if (driveline) {
        setValue('driveline.gearbox', driveline.gearbox);
        setValue('driveline.clutch', driveline.clutch);
        setValue('driveline.differential', driveline.differential);
        setValue('driveline.torqueConverter', driveline.torqueConverter);
      }

      if (undercarriage) {
        setValue('undercarriage.brakes', undercarriage.brakes);
        setValue('undercarriage.suspension.springs', undercarriage.suspension.springs);
        setValue('undercarriage.suspension.shocks', undercarriage.suspension.shocks);
      }

      if (rollingStock) {
        setValue('rollingStock.wheels', rollingStock.wheels);
        setValue('rollingStock.tyres', rollingStock.tyres);
      }
    }
  }, [projectData2, projectDataLoading, setValue]);

  const handleImageUpload = async (e: any) => {
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        // setImagePreview([reader.result]);
        await uploadPhoto({
          variables: { uploadPhotoPhoto: reader.result },
        }).then((data: any) => {
          if (projectImages[0] === '') {
            setProjectImages([data?.data?.uploadPhoto]);
            setImagePreview([reader.result]);
            setProjectImageError(false);
          } else {
            setProjectImages([...projectImages, data?.data?.uploadPhoto]);
            setImagePreview([...imagePreview, reader.result]);
            setProjectImageError(false);
          }
        });
      };
    } catch (err: any) {
      console.log('err: ', err);
    }
  };

  const handleUpdateProject = async (inputs: any) => {
    if (!projectImages[0]) {
      setProjectImageError(true);
      return;
    }
    // setFormError('');
    const {
      name,
      year,
      make,
      model,
      badge,
      paint,
      description,
      engine,
      driveline,
      undercarriage,
      rollingStock,
    } = inputs;

    try {
      const { data } = await editProjectMutation({
        variables: {
          projectId: projectId,
          input: {
            name: name,
            year: year,
            make: make,
            model: model,
            badge: badge,
            paint: paint,
            tags: projectTags,
            taggedAdvertisers: selectedAdvertisers,
            description: description,
            images: projectImages,
            engine: engine,
            driveline: driveline,
            undercarriage: undercarriage,
            rollingStock: rollingStock,
          },
        },
      });
      if (data) {
        navigate(`/project/${projectId}`);
      }
    } catch (err: any) {
      setFormError(err.message);
    }
  };

  const handleDeleteImage = (index: number) => {
    const newProjectImages = [...projectImages];
    newProjectImages.splice(index, 1);
    setProjectImages(newProjectImages);

    const newPreviewImages = [...imagePreview];
    newPreviewImages.splice(index, 1);
    setImagePreview(newPreviewImages);
  };

  return (
    <div className='grid grid-cols-12 mb-10'>
      <div className='col-span-12 lg:col-span-8 lg:pr-3 relative'>
        <div className='flex flex-row text-white font-semibold items-center text-xl justify-between mb-2'>
          <h1>Edit Project</h1>
          <button
            className='col-span-6 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 py-2 px-4 text-sm font-semibold rounded-3xl'
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </div>
        <form onSubmit={handleSubmit(handleUpdateProject)} className='space-y-6'>
          <div className='shadow sm:rounded-md sm:overflow-hidden'>
            <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
              <div>
                <label htmlFor='name' className='block text-sm font-medium text-gray-700'>
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    id='name'
                    type='text'
                    {...register('name', { required: 'Project name required' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the name of your new project'
                  />
                  <span className='text-red'>{errors.name && errors.name.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='year' className='block text-sm font-medium text-gray-700'>
                  Year
                </label>
                <div className='mt-1'>
                  <input
                    id='year'
                    type='text'
                    {...register('year', { required: 'Vehicle year required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the name of your new project'
                  />
                  <span className='text-red'>{errors.year && errors.year.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='make' className='block text-sm font-medium text-gray-700'>
                  Make
                </label>
                <div className='mt-1'>
                  <input
                    id='make'
                    type='text'
                    {...register('make', { required: 'Vehicle make required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the make/manufacturer of your vehicle'
                  />
                  <span className='text-red'>{errors.make && errors.make.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='model' className='block text-sm font-medium text-gray-700'>
                  Model
                </label>
                <div className='mt-1'>
                  <input
                    id='model'
                    type='text'
                    {...register('model', { required: 'Vehicle model required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the model of your vehicle'
                  />
                  <span className='text-red'>{errors.model && errors.model.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='about' className='block text-sm font-medium text-gray-700'>
                  Badge
                </label>
                <div className='mt-1'>
                  <input
                    id='badge'
                    type='text'
                    {...register('badge')}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter the badge of your vehicle'
                  />
                  <span className='text-red'>{errors.model && errors.model.message}</span>
                </div>
              </div>
              <div>
                <label htmlFor='paint' className='block text-sm font-medium text-gray-700'>
                  Paint
                </label>
                <div className='mt-1'>
                  <input
                    id='paint'
                    type='text'
                    {...register('paint')}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Paint colour'
                  />
                </div>
              </div>
              <div>
                <label htmlFor='description' className='block text-sm font-medium text-gray-700'>
                  Project Description
                </label>
                <div className='mt-1'>
                  <textarea
                    id='description'
                    {...register('description', { required: 'Vehicle description required.' })}
                    className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                    placeholder='Enter a basic summary of your vehicle'
                  />
                  <span className='text-red'>
                    {errors.description && errors.description.message}
                  </span>
                </div>
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>Tags</label>
                <div className='-mb-6'>Help people find your project.</div>
                <TagSearch
                  tagType={MHPSearchTagType.Project}
                  handleFormTags={setProjectTags}
                  editTags={projectData?.tags}
                />
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Add related suppliers
                </label>
                <span className='text-[8pt] font-medium text-gray-700'>Select up to five</span>
                <AdvertiserSearch
                  handleFormUpdates={setSelectedAdvertisers}
                  maxSelection={5}
                  editTags={projectData?.taggedAdvertisers}
                />
              </div>

              <div>
                <div className='flex pb-3'>
                  <span className='text-gray-700'>Engine details</span>
                  {engineDropDown ? (
                    <span
                      onClick={() => setEngineDropDown(false)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[3px] ml-[8px]'
                    >
                      −
                    </span>
                  ) : (
                    <span
                      onClick={() => setEngineDropDown(true)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[2px] ml-2'
                    >
                      +
                    </span>
                  )}
                </div>
                {engineDropDown ? (
                  <div className='grid grid-cols-2 gap-4 ml-4'>
                    {/* <div>
                      <label
                        htmlFor='engine-manufacturer'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Engine manufacturer
                      </label>
                      <div className='mt-1'>
                        <input
                          id='engine-manufacturer'
                          {...register('engine.engineManufacturer')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the manufacturer of your engine'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div> */}
                    <div>
                      <label
                        htmlFor='displacement'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Displacement
                      </label>
                      <div className='mt-1'>
                        <input
                          id='displacement'
                          {...register('engine.displacement')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the displacement of your engine'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='manifold' className='block text-sm font-medium text-gray-700'>
                        Manifold
                      </label>
                      <div className='mt-1'>
                        <input
                          id='manifold'
                          {...register('engine.manifold')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder="Enter your engine's manifold"
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='cylinder-heads'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Cylinder heads
                      </label>
                      <div className='mt-1'>
                        <input
                          id='cylinder-heads'
                          {...register('engine.cylinderHeads')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder="Enter your engine's cylinder-heads"
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='fuel' className='block text-sm font-medium text-gray-700'>
                        Fuel / octane
                      </label>
                      <div className='mt-1'>
                        <input
                          id='fuel'
                          {...register('engine.fuel')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the fuel your engine runs on'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='exhaust' className='block text-sm font-medium text-gray-700'>
                        Exhaust
                      </label>
                      <div className='mt-1'>
                        <input
                          id='exhaust'
                          {...register('engine.exhaust')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the exhaust on your engine'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='horsepower'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Horsepower
                      </label>
                      <div className='mt-1'>
                        <input
                          id='horsepower'
                          {...register('engine.horsepower')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the horsepower of your engine'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='induction'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Induction
                      </label>
                      <div className='mt-1'>
                        <select
                          id='induction'
                          {...register('engine.induction')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder="Enter your engine's induction"
                        >
                          <option value='Naturally aspirated'>Naturally aspirated</option>
                          <option value='Single turbocharged'>Single turbocharged</option>
                          <option value='Twin turbocharged'>Twin turbocharged</option>
                          <option value='Supercharged'>Supercharged</option>
                          <option value='Roots supercharged'>Roots supercharged</option>
                          <option value='Screw supercharged'>Screw supercharged</option>
                          <option value='Centrifugal supercharged'>Centrifugal supercharged</option>
                          <option value='Nitrous oxide injected'>Nitrous oxide injected</option>
                        </select>
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='induction-info'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Induction info
                      </label>
                      <div className='mt-1'>
                        <input
                          id='induction-info'
                          {...register('engine.inductionInfo')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter more information about your induction system'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div className='col-start-1 flex'>
                      <span className='text-gray-700'>Engine internals</span>
                      {engineInternalsDropDown ? (
                        <span
                          onClick={() => setEngineInternalsDropDown(false)}
                          className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[3px] ml-[8px]'
                        >
                          −
                        </span>
                      ) : (
                        <span
                          onClick={() => setEngineInternalsDropDown(true)}
                          className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[2px] ml-2'
                        >
                          +
                        </span>
                      )}
                    </div>
                    {engineInternalsDropDown ? (
                      <>
                        <div className='col-start-1'>
                          <label
                            htmlFor='camshaft'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Camshaft
                          </label>
                          <div className='mt-1'>
                            <input
                              id='camshaft'
                              {...register('engine.internals.camshaft')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the camshaft(s) in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='crankshaft'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Crankshaft
                          </label>
                          <div className='mt-1'>
                            <input
                              id='crankshaft'
                              {...register('engine.internals.crankshaft')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the crankshaft in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='pistons'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Pistons
                          </label>
                          <div className='mt-1'>
                            <input
                              id='pistons'
                              {...register('engine.internals.pistons')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the pistons in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='rotors'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Rotors
                          </label>
                          <div className='mt-1'>
                            <input
                              id='rotors'
                              {...register('engine.internals.rotors')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the rotors in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='conrods'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Conrods
                          </label>
                          <div className='mt-1'>
                            <input
                              id='conrods'
                              {...register('engine.internals.conrods')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the conrods in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor='ignition'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Ignition
                          </label>
                          <div className='mt-1'>
                            <input
                              id='ignition'
                              {...register('engine.internals.ignition')}
                              className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                              placeholder='Enter the ignition in your engine.'
                            />
                            <span className='text-red'>{errors.model && errors.model.message}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className='flex pb-3'>
                  <span className='text-gray-700'>Driveline details</span>
                  {drivelineDropDown ? (
                    <span
                      onClick={() => setDrivelineDropDown(false)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[3px] ml-[8px]'
                    >
                      −
                    </span>
                  ) : (
                    <span
                      onClick={() => setDrivelineDropDown(true)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[2px] ml-2'
                    >
                      +
                    </span>
                  )}
                </div>
                {drivelineDropDown ? (
                  <div className='grid grid-cols-2 gap-4 ml-4'>
                    <div>
                      <label htmlFor='gearbox' className='block text-sm font-medium text-gray-700'>
                        Gearbox
                      </label>
                      <div className='mt-1'>
                        <input
                          id='gearbox'
                          {...register('driveline.gearbox')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the gearbox in your driveline.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='clutch' className='block text-sm font-medium text-gray-700'>
                        Clutch
                      </label>
                      <div className='mt-1'>
                        <input
                          id='clutch'
                          {...register('driveline.clutch')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the clutch in your driveline.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='differential'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Differential
                      </label>
                      <div className='mt-1'>
                        <input
                          id='differential'
                          {...register('driveline.differential')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the differential in your driveline.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='torque-converter'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Torque converter
                      </label>
                      <div className='mt-1'>
                        <input
                          id='torque-converter'
                          {...register('driveline.torqueConverter')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the torque converter in your driveline.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className='flex pb-3'>
                  <span className='text-gray-700'>Undercarriage details</span>
                  {undercarriageDropDown ? (
                    <span
                      onClick={() => setUndercarriageDropDown(false)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[3px] ml-[8px]'
                    >
                      −
                    </span>
                  ) : (
                    <span
                      onClick={() => setUndercarriageDropDown(true)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[2px] ml-2'
                    >
                      +
                    </span>
                  )}
                </div>
                {undercarriageDropDown ? (
                  <div className='grid grid-cols-2 gap-4 ml-4'>
                    <div>
                      <label htmlFor='brakes' className='block text-sm font-medium text-gray-700'>
                        Brakes
                      </label>
                      <div className='mt-1'>
                        <input
                          id='brakes'
                          {...register('undercarriage.brakes')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the brakes on your car.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='springs' className='block text-sm font-medium text-gray-700'>
                        Springs
                      </label>
                      <div className='mt-1'>
                        <input
                          id='springs'
                          {...register('undercarriage.suspension.springs')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the springs on your car.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='shocks' className='block text-sm font-medium text-gray-700'>
                        Shocks
                      </label>
                      <div className='mt-1'>
                        <input
                          id='shocks'
                          {...register('undercarriage.suspension.shocks')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the shock absorbers on your car.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className='flex pb-3'>
                  <span className='text-gray-700'>Wheel details</span>
                  {wheelDropDown ? (
                    <span
                      onClick={() => setWheelDropDown(false)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[3px] ml-[8px]'
                    >
                      −
                    </span>
                  ) : (
                    <span
                      onClick={() => setWheelDropDown(true)}
                      className='text-gray-700 text-xl font-extrabold cursor-pointer hover:text-red -mt-[2px] ml-2'
                    >
                      +
                    </span>
                  )}
                </div>
                {wheelDropDown ? (
                  <div className='grid grid-cols-2 gap-4 ml-4'>
                    <div>
                      <label htmlFor='wheels' className='block text-sm font-medium text-gray-700'>
                        Rims
                      </label>
                      <div className='mt-1'>
                        <input
                          id='wheels'
                          {...register('rollingStock.wheels')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the rims on your car.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='tyres' className='block text-sm font-medium text-gray-700'>
                        Tyres
                      </label>
                      <div className='mt-1'>
                        <input
                          id='tyres'
                          {...register('rollingStock.tyres')}
                          className='text-black p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md'
                          placeholder='Enter the tyres on your car.'
                        />
                        <span className='text-red'>{errors.model && errors.model.message}</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <label className='block text-md font-medium text-gray-700'>Photos</label>
                <div
                  className={`mt-1 flex h-auto max-h-[350px] justify-center border-2 border-gray-300 border-dashed rounded-md ${
                    !projectImages[0] ? 'p-6' : ''
                  }`}
                >
                  <div className='space-y-1 text-center'>
                    {uploadPhotoLoading ? (
                      <LoadingSpinner />
                    ) : !projectImages[0] ? (
                      <>
                        <svg
                          className='mx-auto h-12 w-12 text-gray-400'
                          stroke='currentColor'
                          fill='none'
                          viewBox='0 0 48 48'
                          aria-hidden='true'
                        >
                          <path
                            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <div className='flex text-sm text-gray-600'>
                          <label
                            htmlFor='file-upload'
                            className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                          >
                            <span>Upload a cover photo</span>
                            <input
                              id='file-upload'
                              name='file-upload'
                              type='file'
                              onChange={(event) => {
                                handleImageUpload(event);
                              }}
                              className='sr-only'
                            />
                          </label>
                        </div>
                        <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                      </>
                    ) : (
                      <div className='relative w-full h-full'>
                        <div className='absolute right-0 m-2'>
                          <DeleteItemButton
                            deleteFunction={() => handleDeleteImage(0)}
                          ></DeleteItemButton>
                        </div>
                        <img
                          className='object-cover w-full h-full'
                          src={imagePreview[0]}
                          alt='ProfileImage'
                        />
                      </div>
                    )}
                  </div>
                </div>
                {projectImageError ? (
                  <span className='text-red'>Project cover image required</span>
                ) : null}
              </div>

              <div className='grid grid-cols-3 grid-auto-flow-row gap-4'>
                <div>
                  <div className='mb-4'>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[1] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[1] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(1)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[1]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[2] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[2] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(2)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[2]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='mb-4'>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[3] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[3] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(3)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[3]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[4] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[4] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(4)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[4]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='mb-4'>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[5] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[5] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(5)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[5]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div
                      className={`flex h-auto w-full justify-center border-2 border-gray-300 border-dashed rounded-md ${
                        !projectImages[6] ? 'p-6' : ''
                      }`}
                    >
                      <div className='space-y-1 text-center'>
                        {uploadPhotoLoading ? (
                          <LoadingSpinner />
                        ) : !projectImages[6] ? (
                          <>
                            <svg
                              className='mx-auto h-12 w-12 text-gray-400'
                              stroke='currentColor'
                              fill='none'
                              viewBox='0 0 48 48'
                              aria-hidden='true'
                            >
                              <path
                                d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                            <div className='text-sm text-gray-600'>
                              <label
                                htmlFor='file-upload'
                                className='relative cursor-pointer bg-white rounded-md font-medium text-red transition duration-300 hover:text-darkgrey focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
                              >
                                <div>Upload a photo</div>
                                <input
                                  id='file-upload'
                                  name='file-upload'
                                  type='file'
                                  onChange={(event) => {
                                    handleImageUpload(event);
                                  }}
                                  className='sr-only'
                                />
                              </label>
                            </div>
                            <p className='text-xs text-gray-500'>PNG, JPG up to 5MB</p>
                          </>
                        ) : (
                          <div className='relative w-full h-full'>
                            <div className='absolute right-0 m-2'>
                              <DeleteItemButton
                                deleteFunction={() => handleDeleteImage(6)}
                              ></DeleteItemButton>
                            </div>

                            <img
                              className='object-cover w-full h-full'
                              src={imagePreview[6]}
                              alt='Project pic 2'
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='px-4 py-3 bg-gray-50 text-right sm:px-6'>
              {editProjectLoading ? (
                <LoadingSpinner />
              ) : (
                <button
                  type='submit'
                  className='inline-flex justify-center py-2 px-4 whitespace-nowrap transition ease-in-out duration-300 bg-red hover:bg-darkgrey my-2 mx-1 text-sm font-semibold rounded-3xl'
                >
                  Update Project
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProject;
